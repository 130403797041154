import { ActionType } from './../constants';
import { DataApi ,DataGetApi} from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Company info Begin Action
 */
export const companyStart = () => ({
    type: ActionType.COMPANY_START
});

/**
* Company info Success Action
*/
export const companySuccess = companyData => {
    return {
        type: ActionType.COMPANY_SUCCESS,
        payload: { companyData }
    }
};

/**
* Company info Failure Action
*/
export const companyError = error => ({
    type: ActionType.COMPANY_ERROR,
    payload: { error }
});

/**
 * Company driver info
 */
export const companyAdd = (data) => {
     
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companyAdd').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const companyList = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'companyList',data,token).then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}


export const countryDataList = (data) => {
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'countryDataList').then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const CompanyDetailById = (id) => { 
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'CompanyDetailById',{id:id}).then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const CompanyAllDetailById = (id) => { 
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'CompanyAllDetailById',{id:id}).then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const CompanyLocationDataById = (id) => { 
    return dispatch => {
        
        return DataGetApi(config.BASEURL + 'CompanyLocationDataById',{id:id}).then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const AllCompany = (id) => { 
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'AllCompany').then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}


export const companyEdit = (data) => {
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companyEdit').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
 
export const companyDelete = (data) => { 
    
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companyDelete').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const companyStatus = (data) => { 
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companyStatus').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const companySettings = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companySettings',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response));
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const companyTermCondition = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(companyStart());
        return DataApi(data, config.BASEURL + 'companyTermCondition',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const companyGetTermCondition = (data) => {
    return dispatch => {
        dispatch(companyStart());
        return DataGetApi(config.BASEURL + 'companyGetTermCondition',data).then((response) => {
            if (response.status === 200) {
                return dispatch(companySuccess(response))
            } else {
                return dispatch(companyError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}