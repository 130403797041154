import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import cookie from 'react-cookies'
import { setLocation, setAllLocation } from '../../actions/LocationAction'
import { connect } from 'react-redux'
import { fetchDetails, updateDefaultLocation } from '../../actions/AdminUserAction'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'right',
    // maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  Mupadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  Mumargin: {
    marginTop: 0,
    marginBottom: 0,
    color: '#fff',
    whiteSpace: 'nowrap',
  },
  locationIcon: {
    marginTop: 0,
    color: '#fff',
    minWidth: '1rem',
  },
  locationIconBox: {
    backgroundColor: '#117CC1',
    color: '#fff',
    paddingTop: '0',
    paddingBottom: '0',
    width: 'auto',
    float: 'right !important'
  },
  displayRight: {
    justifyContent: 'flex-end !important',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  displayRightChild: {
    flex: 'unset !important'
  }
}));

class LocationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selected: -1,
      anchorEl: null,
      sideShow: true,
      isLoading: true,
      allLocation: true,
      defaultLocation: null,
      defaultlocationMid: null,
      defaultmidType: null,
      defaultmidSelect: null
    }
  }
  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  /**
   * Handle update default location
   * 
   * @param {Object} item
   */
  updateDefaultLocation = (item) => {   
    let userData = JSON.parse(localStorage.getItem("user"));
    this.props.updateDefaultLocation({
        _id: userData._id,
        defaultLocation: item._id,
        defaultmidType: item.midType ? item.midType : '',
        defaultlocationMid: item.midType === 'Surcharge' ? item.mId2 : item.mId,
        defaultmidSelect: item.midType === 'Surcharge' ? 'mId2' : 'mId',
      }).then( (res) => {
        // console.log("-----------------SSSSSSSSSSs--------------------",res)
          localStorage.setItem('localDefaultLocation', item._id);
          this.locationDataLoad();          
          if (window.location.pathname == '/Terminal' ) { 
            this.props.clickEventHandle();  
          }else{
            window.location.reload();           
          }
        },
        (error) => window.location.reload()
      );
  };


  handleMenuItemClick = (event, index, item) => {    
    this.props.setLocation(item);
    localStorage.setItem('locationId', JSON.stringify(item._id));
    localStorage.setItem('locationPaydetails', JSON.stringify(item));
    let setArr = [item._id];
    localStorage.setItem('locationArrIds', JSON.stringify(setArr));
    localStorage.setItem('selected', index + 1);
    this.setState({ selected: index + 1 });
    this.setState({ anchorEl: null });
    this.updateDefaultLocation(item);
  }

  handleProps = () => {
    const data = JSON.parse(localStorage.getItem('binLook'));
    this.handleMenuItemClick({}, data.index, data.item);
  }

  async componentDidMount() {
    await this.props.setCallableLocation(this.handleProps);
    await this.locationDataLoad();
  }

  async locationDataLoad() {
    let userData = JSON.parse(localStorage.getItem("user"));
    let data = await fetchDetails({ id: userData._id }); 
      let defaultLocation = data.payload.userData.defaultLocation;
      this.setState({ defaultLocation: defaultLocation });
      this.setState({ defaultlocationMid: data.payload.userData.defaultlocationMid });
      this.setState({ defaultmidSelect: data.payload.userData.defaultmidSelect });
      this.setState({ defaultmidType: data.payload.userData.defaultmidType });
      if (!defaultLocation && this.props.location.length > 1) {
        localStorage.setItem('selected', 0);
        this.setState({ selected: 0 });
      } else {
        localStorage.setItem('selected', -1);
        this.setState({ selected: -1 });
        if (this.props.location.length === 1 || this.state.defaultLocation === null) {
          this.setState({ defaultLocation: this.props.location[0]._id });
          this.setState({ defaultlocationMid: this.props.location[0].mId });
          this.setState({ defaultmidSelect: this.props.location[0].midSelect });
          this.setState({ defaultmidType: this.props.location[0].midType });
        }
      }
      const locationId = JSON.parse(localStorage.getItem('locationId'));
      let locationArrIds = localStorage.getItem('locationArrIds');
      // console.log(this.props.location);
  
      var locationIds;
      if(this.state.defaultLocation === null){
        locationIds  = this.props.location.map( item => item._id );
      }else{
        locationIds = this.props.location.map((curr) => {
          if(curr._id === this.state.defaultLocation && curr.midType === this.state.defaultmidType){
            return curr._id
          }
        });
      }
  
      if (this.state.defaultLocation === null) {
        // console.log(locationIds , "efaultLocation === null")
        localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
        localStorage.setItem('locationPaydetails', JSON.stringify(locationIds[0]));
        this.handleMenuItemClick({}, 0, this.props.location[0]);
      } else {
        let locationIds = this.props.location.filter((curr) => {
          return curr._id === this.state.defaultLocation && curr.midType === this.state.defaultmidType
        });
        localStorage.setItem('locationPaydetails', JSON.stringify(locationIds[0]));
        locationIds = locationIds.map((curr) => { return curr._id })
        localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
        // localStorage.setItem('locationPaydetails', JSON.stringify(defaultLocation));
      }
    // if(!locationId){
    //   let defaultLocation = this.props.location[0] 
    //   this.props.setLocation(defaultLocation)
    //   localStorage.setItem('locationId', JSON.stringify(defaultLocation._id));
    //   localStorage.setItem('locationPaydetails', JSON.stringify(defaultLocation));
    //   this.setState({selected:0});
    // }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAllMenu = () => {
    localStorage.setItem('selected', 0);
    this.setState({ selected: 0 });
    this.setState({ anchorEl: null });
   
    let locationIds = this.props.location.reduce((acc, curr) => {
      acc.push(curr._id)
      return acc
    }, []);
    
    locationIds = locationIds.filter( function( item, index, inputArray ) {
      return inputArray.indexOf(item) == index;
    });

    localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
    localStorage.removeItem('locationPaydetails');
    this.updateDefaultLocation({ _id: null });
  }

  render() {
    const locationId = JSON.parse(localStorage.getItem('locationId'));
    const selected = JSON.parse(localStorage.getItem('selected'));
    const showAllLocation = this.props.location.length > 1 ? true : false;
    const { classes } = this.props;

    return (
      <div className={classes.root}   >
        <List className={classes.locationIconBox} component="nav" aria-label="Device settings" >
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="location"
            onClick={(e) => this.handleClickListItem(e)}
            className={classes.displayRight}
          >
            <ListItemIcon className={classes.locationIcon}>
              <LocationOnIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={(selected !== 0) ? this.props.location.map((location) => {
              let loc_with_mid = location.locationName;
              if (location._id === this.state.defaultLocation && location.midType === this.state.defaultmidType) {
                return (loc_with_mid.length > 40) ? loc_with_mid.substr(0, 40) + '...' + ((location.midType !== '') ? "(" + location.midType + ")" : location.midType) : loc_with_mid + ((location.midType !== '') ? "(" + location.midType + ")" : location.midType);
              } else {
                return '';
              }
            }) : ''} className={classes.Mumargin, classes.displayRightChild} />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={(e) => this.handleClose(e)}
        >
          {/* {showAllLocation && <MenuItem
            selected={selected === 0}
            onClick={(e) => this.handleAllMenu(e)}>
            <ListItemIcon>
              <LocationOnIcon fontSize="small" />
            </ListItemIcon> All location </MenuItem>} */}
          {this.props.location.map((item, index) => (
            <MenuItem
              key={index}
              selected={item._id === this.state.defaultLocation && item.midType === this.state.defaultmidType}
              onClick={(event) => this.handleMenuItemClick(event, index, item)}
            >
              <ListItemIcon>
                <LocationOnIcon fontSize="small" />
              </ListItemIcon>
              {item.locationName + (item.midType !== '' ? "(" + item.midType + ")" : '')}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info12: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return (<LocationBar classes={classes} {...props} />)
}

export default connect(mapStateToProps, { setLocation, setAllLocation, updateDefaultLocation })(ApplyingStylesOnClasses)

