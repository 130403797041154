import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Table } from '../../components/Table'
import { Topheader } from './../../components/Topheader';
import { fetchDetails ,adminUserDelete ,adminUserStatus} from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      sideShow: true,
      isLoading: false,
      reload: false,
      userData: {permissions:{}},
    }
    this.tableRef = React.createRef();
  }
  componentDidMount(){
    let user = JSON.parse(localStorage.getItem('user'))
    let data = fetchDetails({id: user._id})
    data.then((res)=>{
      if(res.type ==="FETCH_SUCCESS" && this.props.info.userId==="")
      {
        this.setState({userData:{...res.payload.userData}},()=>{
        })
      }
    })
  }
  companyDeleteStatus(e, data) {
    this.props.adminUserDelete(adminUserStatus,data).then((res) => {
      // this.tableReload(true)
    })
  }

  userStatusToggle(e, data) {
    this.props.adminUserStatus(data).then((res) => {
      // this.tableReload()
    })
  }
  tableReload(status){
    this.setState({reload:status})
  }
   
  getTable(query){
    // console.log(query);
    // console.log("orderBy="+query.orderBy);
    // console.log("orderDirection="+query.orderDirection);
    let orderBy = (query.orderBy!==undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection!=="") ? query.orderDirection : "";
    if(this.state.reload){
      query.search=""
      this.setState({reload:false})
    }
  return   new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1),'searchAll':(typeof query.search=="undefined" || query.search=="")?false:true,
        'orderBy':orderBy,'orderDirection':orderDirection
      };
      DataGetApi(url + 'getUserList', param, token).then(result => {
        resolve({
          data: (typeof query.search=="undefined" || query.search=="")?result.data.response:result.data.response.filter((row=>{
            return (String(row.phone).indexOf(String(query.search)) !== -1||
              row.userName.toLowerCase().indexOf(String(query.search)) !== -1 || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !=-1 ||  row.firstName.toLowerCase().indexOf(String(query.search)) !== -1 || String(row.lastName).toLowerCase().indexOf(query.search) !== -1)
          })),
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
        // console.log(result);
      }).catch(error => {
        console.log('fetching clients error : ', error);
        reject(error);
      })
    })
  }
  render() {
    
   const  { data } = this.props
    const columns = [
      { title: 'First Name', field: 'firstName', render:(rowData)=>rowData.firstName?rowData.firstName:'N/A'  },
      { title: 'Last Name', field: 'lastName', render:(rowData)=>rowData.lastName?rowData.lastName:'N/A'  },
      { title: 'Phone', field: 'phone', render:(rowData)=>rowData.phone?rowData.phone:'N/A' },
      { title: 'Role', field: 'role', lookup: { 2: 'Admin', 3: 'Manager', 4: 'Regular User' } },
      { title: 'Active Status', field: 'status', lookup: { true: 'Active', false: 'In Active' } },
      { title: 'Company Name', field: 'company', render:(rowData)=>rowData.company.dbaName },
      { title: 'Locations', field: 'location', render:(rowData)=>rowData.locationSelectedwithmid.reduce((location, curr, i) =>  (location + (i > 0 ? ', ' +  ((curr.midType ) ?  curr.locationName +'('+ curr.midType +')' : curr.locationName) : curr.locationName +'('+ curr.midType +')')), ''),  }
    ];
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
            tableReload={(e)=>this.tableReload(e)}
              companyDeleteAction={(e, data) => this.companyDeleteStatus(e, data)}
              companyActiveAction={(e, data) => this.userStatusToggle(e, data)}
              createNew={(this.props.info.userId ==="")?this.state.userData.permissions.creatNewUser:this.props.info.permissions.creatNewUser}
              permissions={(this.props.info.userId ==="")?this.state.userData.permissions:this.props.info.permissions}
              options={{
                exportButton: true
              }}
              columns={columns}
              data={query =>this.getTable(query)}
              title={(this.state.userData.role === 1) ? 'Admin' : 'User'}
              search={true}
              // exports={true}
              exports={(this.props.info.userId ==="")?this.state.userData.permissions.exportRecord:this.props.info.permissions.exportRecord}
              paging={true}
              tablesName={'Users'}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    currentLocation: state.LocationReducer.currentLocation
  };
}

export default connect(mapStateToProps, {adminUserDelete,adminUserStatus,fetchDetails})(withTranslation()(Users));
