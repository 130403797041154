import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { ticketList } from '../../actions/TicketAction'
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Tickets.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';

const date = new Date();

class Tickets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      totalCountNew:'',
      reload: false,
      toDate: new Date(),
      fromDate: new Date(new Date().setDate((new Date().getDate()) - 30 )),
    
    }
  }

  toDateFilter(date) {
    this.setState({ toDate: date })
  }
fromDateFilter(date) {
    this.setState({ fromDate:date })
  }
  
  handleChangeId(id) {
    this.props.history.push('/AddTickets/?ticket=' + id)
  }
  tableReload(status) {
    this.setState({reload: status })
  }
  getTableData(query) {
    console.log(this.state, " <<<<<<<<<<<<<<<< date");
    if (this.state.reload) {
      query.search = ""
      this.setState({ reload: false })
    }
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company ? user.company._id : '',
        'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search == "undefined" || query.search == "") ? false : true
        , 'toDate': this.state.toDate, 'fromDate':  this.state.fromDate
      };
      DataGetApi(url + 'ticketList', param, token).then(result => {
        this.setState({totalCountNew: result.data.pagination.totalCount})
        resolve({
          data: (typeof query.search == "undefined" || query.search == "") ? result.data.response : result.data.response.filter((row => {
            return (String(row.ticketNumber).indexOf(String(query.search)) !== -1 || row.issue.toLowerCase().indexOf(String(query.search)) !== -1 || row.email.toLowerCase().indexOf(String(query.search)) !== -1 || row.email.toLowerCase().indexOf(String(query.search)) !== -1 || String(new Date(row.createdDate).toLocaleString()).indexOf(String(query.search)) != -1 || row.phone.indexOf(String(query.search)) !== -1)
          })),
          
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
      }).catch(error => {
        console.log('fetching clients error : ', error);
        // toast.error('There was an error trying to fetch clients');
        reject(error);
      })
    })
  }

  render() {  
    const columns = [
      { title: 'Id', field: 'ticketNumber', render: rowData => <Tooltip title="View Ticket"><span className={classes.Underline} onClick={(e) => this.handleChangeId(rowData.ticketNumber)}  >{rowData.ticketNumber}</span></Tooltip> },
      { title: 'Email', field: 'email' },
      { title: 'Phone', field: 'phone' },
      { title: 'TicketStatus', field: 'status', render: rowData => (rowData.status) ? <button type="button" className="btn paidBtn" >Closed</button> : <button type="button" className="btn RefundedBtn" >Open</button> },
      { title: 'Issue', field: 'issue', render: rowData => (rowData.issue.length > 25) ? rowData.issue.substr(0, 25) + '...' : rowData.issue },
      { title: 'Date', field: 'createdDate', render: (date) => (new Date(date.createdDate).toLocaleString()) },];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true
              }}
              columns={columns}
              createNew={false}
              data={(query) => this.getTableData(query)}
              title={'Tickets'}
              search={true}
              exports={ (this.props.UserDetail.role ==1 ) ? true  :  this.props.UserDetail.permissions.exportRecord}
              paging={this.state.totalCountNew<5?false:true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={true}
              tablesName="tickets"
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.TicketReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { ticketList })(withTranslation()(Tickets));