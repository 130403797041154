import React from 'react';

const styles = {
    left: { textAlign: 'left' },
    center: { textAlign: 'center' },
    right: { textAlign: 'right' }
};

export const PaymentInfo = ({ style, data }) => (
    <div className="payment-info" style={style}>
        <span style={styles.left}> Hi <strong> {data.firstName}, </strong> </span> <br/> <br/>

        <span style={styles.center}>
            Your truck and rate are reserved for the time above. <br /> <br />
            Please note other customers also need our assistance, and your rate may change once the time expires. <br />
        </span> <br />

        <div style={styles.right}>
            <strong style={styles.right}>
                Subtotal:
                ${(parseFloat(data.amount) - parseFloat(data.amount) * 3.50 / 100).toFixed(2)}
            </strong> <br/>
            <strong style={styles.right}>
                Convenience Fee:
                ${(((parseFloat(data.amount) - parseFloat(data.amount) * 3.50 / 100).toFixed(2)) * 3.50 / 100).toFixed(2)}
            </strong> <br/>
            <strong>
                Total Amount Due: ${(parseFloat(data.amount)).toFixed(2)}
            </strong>
        </div>
    </div>
);