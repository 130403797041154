import React, { Component, CSSProperties }  from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import BarLoader from 'react-spinners/BarLoader'
import { Table } from '../../components/Table'
import classes from './Transactions.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import config from '../../constants/config'
import { DataApi, DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency, getBaseAmount, getBaseAmount1, getBaseAmount2 } from '../../helpers/commonFunction'
import { NyTaxReports } from './../../pages/NyTaxReports';
import { Colorstatus } from '../ColorCodeStatus';
import PropTypes, { element } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { capturePreAuth, voidPreAuth, updatePreAuth } from '../../actions/RequestFormAction';
import { getCsrfToken } from '../../actions/PaymentAction';
import { FormControl, InputLabel, Switch } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavigateBeforeSharp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MUIDataTable from "mui-datatables";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import Checkbox from "@material-ui/core/Checkbox";
// import { ThreeSixty } from '@material-ui/icons';

import {  settingDataByCompanyIdmId } from '../../actions/AdminUserAction'
import { MuiDataTableCustomeSearch } from '../../utils/search';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) { 
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    marginLeft: 15,
    width: 200
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '@media (max-width:768px)': {
      display: 'block',
      alignItems: 'center',
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mT1: {
    marginTop: `-1.5rem`,
    '@media (max-width:768px)': {
      marginTop: `0`,
    },
  },
  tab4: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },
  tab3: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },
  tabBtn: {
    '@media (max-width:768px)': {
      maxWidth: 'inherit',
    },
  },
  tpTableFullW: {
    width: '100%',
  },
  MuiTabwidth: {
    minWidth: 'inherit',
  },
  contenWtrapperNew: {
    width: 'calc(100% - 110px)',
  },

}));


class Transactions extends Component {
  constructor(props) {
    
    const date = new Date();
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate()-30)),
      tab: 0,
      preAuthActiveData: true,
      successMessages: false,
      misMatchError: [],
      msg: "",
      settingData: {},
      viewAllTransactions: true,
      data1:[]
    }
   }
  //mounting method
   componentDidMount(){
    this.getdata();
   }
  UNSAFE_componentWillMount() {
    //redirect to admin portal
    if (this.props.UserDetail.companyId.id === '5ee85adef500853860700888') {
      this.props.history.push('/AdminPortal')
    }
    this.setState({viewAllTransactions: JSON.parse(localStorage.getItem('user')).permissions.viewAllTransactions});
    this.getSettingData();
  }

  tableReload(status) {
    this.setState({ ...this.state, reload: status });
  }

  toDateFilter(date) {
    this.setState({ toDate:date });
  }

  fromDateFilter(date) {
    this.setState({ fromDate:date });
  }

  handleChangeId(id) {
    this.props.history.push('/Viewtransaction/' + id)
  }

  handleTxnCopy(id) {
    this.props.history.push('/Terminal/?' + id)
  }

  handleChangeSelect(e) {
    this.setState({ preAuthActiveData: (e.target.value === 'Expire') ? false : true }, () => {
      this.getTableDataAuth({});
      setTimeout(() => {
        this.state.tableObje.onQueryChange();
      }, 1000);
    });
  }

  getPaymentStatus(val) {
    let value = val.toLowerCase();
    let Credit = 'credit';
    let ACH = 'ach';
    if (Credit.indexOf(value) !== -1) {
      return "101";
    } else if (ACH.indexOf(value) !== -1) {
      return "120";
    }
  }
  
  handleDateChange = (date, key) => {
    if(date=='Invalid Date' || date==null){
      return false
    }else{
      this.setState({ [key]: date },()=>{
        (key==="toDate")?this.toDateFilter(this.state.toDate):this.fromDateFilter(this.state.fromDate)
        this.getdata();
      })
    }
    }
  

  hardRefresh(data) {
    this.setState({ tableObje: data.current });
  }
  getdata(){
    let orderBy = '';
    let orderDirection = '';
    this.setState({isLoading:true});
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let locationId = localStorage.getItem('locationArrIds');
      let url = config.BASEURL;
      let midType = '';
      if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      
      }
      let param = {
        'locationIds': locationId,
        'midType': midType,
        'role': user.role, 'userId': user._id,
        'companyId': user.company._id,
        'preAuthActiveData': this.state.preAuthActiveData,
        'per_page': '',
        'page':'',
        'searchAll':  false,
        'toDate': this.state.toDate, 'fromDate': this.state.fromDate,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'viewAllTransactions': this.state.viewAllTransactions
      };
      DataGetApi(url + 'getTransactionList', param, token).then(result => {
        let modifiedData = [];
        let misMatchError = [];
        if (result.data && result.data.response) {

          console.log(result.data, '---data')
          modifiedData = result.data.response.map(item => ({
            ...item,
            amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
            calc_total: item.CAPTURE_AMOUNT ? formatCurrency((item.CAPTURE_AMOUNT + parseFloat(item.SVC_FEE)  + parseFloat(item.TAX) ) ) : formatCurrency(item.AMOUNT) ,
            capture_amount: item.CAPTURE_AMOUNT ? formatCurrency(item.CAPTURE_AMOUNT) : '$0.00',
            SVC_FEE: item.SVC_FEE ? formatCurrency(item.SVC_FEE) : '$0.00',
            SURCHARGE_FEE: item.SURCHARGE_FEE ? formatCurrency(item.SURCHARGE_FEE) : '$0.00',
            base_amount: item.BASE_AMOUNT ? formatCurrency(item.BASE_AMOUNT) : 
            getBaseAmount(item),
            TAX: item.TAX ? formatCurrency(item.TAX) : '$0.00',
            inventedBy: (typeof item.createdBy !== "undefined") ? item.createdBy.firstName + ' ' + item.createdBy.lastName : '',
            location: item.locationId ? item.locationId.locationName : '',
            name:(item.CUSTOMERDATA && item.CUSTOMERDATA.name) ? item.CUSTOMERDATA.name:'',
            customer_id:(item.CUSTOMERDATA && item.CUSTOMERDATA.customer_id )? item.CUSTOMERDATA.customer_id:'',
            Invoice:(item.ORDERID !== null && item.ORDERID)? item.ORDERID:'',
            MASKCARDNUMBER:item.CUSTOMERDATA && item.CUSTOMERDATA.masked_card_number ? item.CUSTOMERDATA.masked_card_number : item.MASKCARDNUMBER
          }));
          this.setState({data1:modifiedData, isLoading:false})
          // if (typeof query.search === "undefined" || query.search === "") {
            resolve({
              data: modifiedData,
              page: result.data.pagination.page - 1,
              totalCount: result.data.pagination.totalCount,
            });
          // } else {
          //   let filterData = modifiedData.filter((row => {
          //     return (row.RESPONSECODE === this.getPaymentStatus(String(query.search)) || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || String(row.ORDERID).indexOf((query.search)) !== -1 || String(row.AMOUNT).indexOf((query.search)) !== -1 || String(row.TXNID).indexOf((query.search)) !== -1 || String(row.inventedBy).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.location).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.CARDTYPE).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.FINAL_STATUS).toLowerCase().indexOf((query.search)) !== -1)
          //   }))
          //   if (filterData.length > 0) {
          //     query.filters.push(filterData)
          //   }
          //   resolve({
          //     data: filterData,
          //     page: result.data.pagination.page - 1,
          //     totalCount: filterData.length,
          //   });
          // }
        } else {
          // let msg = 'Something wrong happened';
          // misMatchError.push(msg)
          // this.setState({ misMatchError });
          window.location.reload();
        }
      });
    });
  }
  getTransaction=(e)=>
  {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let locationId = localStorage.getItem('locationArrIds');
      let url = config.BASEURL;
      let midType = '';
      if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      }
      // let allmids = this.state.locationSelectedwithmid.map( item => (item.midType === "Surcharge") ? item.mId2:item.mId );
      // let locationId = this.state.locationSelectedwithmid.length
      //   ? this.state.locationSelectedwithmid.map( item => item._id )
      //   : [] ;
       
    let param = {
        'locationIds':locationId , 
        //'allmids ': allmids,
        'midType': midType,
        'companyId': user.company._id,
        'toDate': this.state.toDate,
        'fromDate':this.state.fromDate
      };
      this.setState({ isLoading : true })
      DataGetApi(url + 'getAllTransactionsExports', param, {}).then(result => {
        this.setState({ isLoading : false })
        const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "Transaction data"; 
        let  newArray = [];
        result.data.response.response.map((d,i)=>{
          
          let svcFee = d.SVC_FEE ? d.SVC_FEE.toString().replace(/\$/g, "") : 0.00
          let surcharge_fee = d.SURCHARGE_FEE ? d.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0.00
          let tax = d.TAX ? d.TAX.toString().replace(/\$/g, "") : 0.00
          let base = formatCurrency(parseFloat(d.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax)))         
          let total=formatCurrency(d.AMOUNT); 
          let midType= (d.midType)? d.midType :'';
          let location=(d.locationId && d.locationId.locationName) ?`${ d.locationId.locationName }${'(' + midType + ')'}`: '';
          let name=`${ d.CUSTOMERDATA && d.CUSTOMERDATA.name ? d.CUSTOMERDATA.name : d.CUSTOMERDATA && d.CUSTOMERDATA.firstName  ? d.CUSTOMERDATA.firstName : ''}`;
          let obj={TXNID: d.TXNID, Type: d.payment_type, Status: d.FINAL_STATUS, Name: name, Location: location, Invoice: d.ORDERID, MID:d.pay_mId, Base111:base, Total: total,date: d.createdAt};
          newArray.push(obj)
           
          });
          // **********for export to excel************
        const ws = XLSX.utils.json_to_sheet(newArray);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      
       })
      })
}
  getSettingData(){
    //for column svc and surcharge
    let user = JSON.parse(localStorage.getItem('user'));
    if(localStorage.getItem('locationArrIds') && JSON.parse(localStorage.getItem('locationArrIds')).length === 1){       
      let param2 = {
        id: user.company.id ? user.company.id : null,
        userId: user._id ? user._id : null,
        locationId: JSON.parse(localStorage.getItem('locationPaydetails'))._id,
        midType: JSON.parse(localStorage.getItem('locationPaydetails')).midType
      };

      this.props.settingDataByCompanyIdmId(param2).then((res) => {         
      let datasetting = (res.payload.hasOwnProperty('userData')) ? res.payload.userData.data.response : "";
      this.setState({settingData: datasetting});         
      });
      
    }
  }
  getTableData(query) {
    const state = this.state ? this.state : query.props.componentState;
    let orderBy = (query.orderBy !== undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection !== "") ? query.orderDirection : "";;
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let locationId = localStorage.getItem('locationArrIds');
      let url = config.BASEURL;
      let midType = '';
      if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      }
      let param = {
        'locationIds': locationId,
        'midType': midType,
        'role': user.role, 
        'userId': user._id, 
        'companyId': user.company._id,
        'per_page': query.pageSize, 
        'page': + (query.page + 1),
        'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true,
         'toDate': query.toDate ? query.toDate : state.toDate,
        'fromDate': query.toDate ? query.fromDate : state.fromDate,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'viewAllTransactions': state.viewAllTransactions
      };
      
        DataGetApi(url + 'getTransactionList', param, token).then(result => {
        let modifiedData = []
        modifiedData = result.data.response.map((item) => ({          
          ...item,         
          amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
          calc_total: item.CAPTURE_AMOUNT ? formatCurrency( ( parseFloat(item.CAPTURE_AMOUNT) + parseFloat(item.SVC_FEE) + parseFloat(item.SURCHARGE_FEE) + parseFloat(item.TAX) ) ) : formatCurrency(item.AMOUNT) ,
          capture_amount: item.CAPTURE_AMOUNT ? formatCurrency(item.CAPTURE_AMOUNT) : '$0.00',
          SVC_FEE: item.SVC_FEE ? formatCurrency(item.SVC_FEE) : '$0.00',
          SURCHARGE_FEE: item.SURCHARGE_FEE ? formatCurrency(item.SURCHARGE_FEE) : '$0.00',
          base_amount: item.BASE_AMOUNT ? formatCurrency(item.BASE_AMOUNT) : getBaseAmount(item),
          TAX: item.TAX ? formatCurrency(item.TAX) : '$0.00',
          inventedBy: (typeof item.createdBy !== "undefined") ? item.createdBy.firstName + ' ' + item.createdBy.lastName : '',
          location: item.locationId ? item.locationId.locationName : '',
          name:(item.CUSTOMERDATA && item.CUSTOMERDATA.name) ? item.CUSTOMERDATA.name:'',
          customer_id:(item.CUSTOMERDATA && item.CUSTOMERDATA.customer_id )? item.CUSTOMERDATA.customer_id:'',
          Invoice:(item.invoices !== null && item.invoices.invoice)? item.invoices.invoice:'',
          MASKCARDNUMBER:item.CUSTOMERDATA && item.CUSTOMERDATA.masked_card_number ? item.CUSTOMERDATA.masked_card_number : item.MASKCARDNUMBER
        
        }));
        if (typeof query.search === "undefined" || query.search === "") {
          resolve({
            data: modifiedData,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        } else {   
          let filterData = modifiedData.filter((row => {
            return (row.RESPONSECODE === this.getPaymentStatus(String(query.search)) || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || String(row.ORDERID).indexOf((query.search)) !== -1 || String(row.AMOUNT).indexOf((query.search)) !== -1 || String(row.TXNID).indexOf((query.search)) !== -1 || String(row.inventedBy).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.location).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.CARDTYPE).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.FINAL_STATUS).toLowerCase().indexOf((query.search)) !== -1 || String((row.name).toLowerCase()).indexOf((String(query.search).toLowerCase())) !== -1 || String(row.customer_id).indexOf((query.search)) !== -1 ||String((row.Invoice).toLowerCase()).indexOf((String(query.search).toLowerCase())) !== -1 || String(row.MASKCARDNUMBER).indexOf((query.search)) !== -1)
          }))
          if (filterData.length > 0) {
            query.filters.push(filterData)
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          })
        }
      })
    })
  }

  getTransaction(query) {
    let orderBy = (query.orderBy !== undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection !== "") ? query.orderDirection : "";
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false });
    }
    this.setState({ isLoading : true });
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let locationId = localStorage.getItem('locationArrIds');
      let url = config.BASEURL;
      let midType = '';
      if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      }
      let param = {
        'locationIds': locationId,
        'midType': midType,
        'role': user.role, 
        'userId': user._id, 
        'companyId': user.company._id,
        'per_page': query.pageSize, 
        'page': + (query.page + 1),
        'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true,
        'toDate': this.state.toDate, 
        'fromDate': this.state.fromDate,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'viewAllTransactions': this.state.viewAllTransactions
      };
      
        DataGetApi(url + 'getTransactionList', param, token).then(result => {
        this.setState({ isLoading : false });
        let modifiedData = []
        modifiedData = result.data.response.map((item) => ({          
          ...item,         
          amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
          calc_total: item.CAPTURE_AMOUNT ? formatCurrency( ( parseFloat(item.CAPTURE_AMOUNT) + parseFloat(item.SVC_FEE) + parseFloat(item.SURCHARGE_FEE) + parseFloat(item.TAX) ) ) : formatCurrency(item.AMOUNT) ,
          capture_amount: item.CAPTURE_AMOUNT ? formatCurrency(item.CAPTURE_AMOUNT) : '$0.00',
          SVC_FEE: item.SVC_FEE ? formatCurrency(item.SVC_FEE) : '$0.00',
          SURCHARGE_FEE: item.SURCHARGE_FEE ? formatCurrency(item.SURCHARGE_FEE) : '$0.00',
          base_amount: item.BASE_AMOUNT ? formatCurrency(item.BASE_AMOUNT) : getBaseAmount(item),
          TAX: item.TAX ? formatCurrency(item.TAX) : '$0.00',
          inventedBy: (typeof item.createdBy !== "undefined") ? item.createdBy.firstName + ' ' + item.createdBy.lastName : '',
          location: item.locationId ? item.locationId.locationName : '',
          name:(item.CUSTOMERDATA && item.CUSTOMERDATA.name) ? item.CUSTOMERDATA.name:'',
          customer_id:(item.CUSTOMERDATA && item.CUSTOMERDATA.customer_id )? item.CUSTOMERDATA.customer_id:'',
          Invoice:(item.invoices !== null && item.invoices.invoice)? item.invoices.invoice:'',
          MASKCARDNUMBER:item.CUSTOMERDATA && item.CUSTOMERDATA.masked_card_number ? item.CUSTOMERDATA.masked_card_number : item.MASKCARDNUMBER
        
        }));
        if (typeof query.search === "undefined" || query.search === "") {
          resolve({
            data: modifiedData,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        } else {   
          let filterData = modifiedData.filter((row => {
            return (row.RESPONSECODE === this.getPaymentStatus(String(query.search)) || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || String(row.ORDERID).indexOf((query.search)) !== -1 || String(row.AMOUNT).indexOf((query.search)) !== -1 || String(row.TXNID).indexOf((query.search)) !== -1 || String(row.inventedBy).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.location).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.CARDTYPE).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.FINAL_STATUS).toLowerCase().indexOf((query.search)) !== -1 || String((row.name).toLowerCase()).indexOf((String(query.search).toLowerCase())) !== -1 || String(row.customer_id).indexOf((query.search)) !== -1 ||String((row.Invoice).toLowerCase()).indexOf((String(query.search).toLowerCase())) !== -1 || String(row.MASKCARDNUMBER).indexOf((query.search)) !== -1)
          }))
          if (filterData.length > 0) {
            query.filters.push(filterData)
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          })
        }
      })
    })
  }
 
  getTableDataAuth(query) {
    let orderBy = (query.orderBy !== undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection !== "") ? query.orderDirection : "";
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false });
    }
    
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let locationId = localStorage.getItem('locationArrIds');
      let url = config.BASEURL;
      let midType = '';
      if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      }
      let param = {
        'locationIds': locationId,
        'midType': midType,
        'role': user.role, 'userId': user._id,
        'companyId': user.company._id,
        'preAuthActiveData': this.state.preAuthActiveData,
        'per_page': query.pageSize,
        'page': + (query.page + 1),
        'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true,
        'toDate': this.state.toDate, 'fromDate': this.state.fromDate,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'viewAllTransactions': this.state.viewAllTransactions
      };
      DataGetApi(url + 'getTransactionListAuth', param, token).then(result => {
        console.log(result,'--res')
        let modifiedData = [];
        let misMatchError = [];
        if (result.data && result.data.response) {
          console.log(result, '---response table')
          modifiedData = result.data.response.map(item => ({
            ...item,
            amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
            capture_amount: parseFloat(item.CAPTURE_AMOUNT) ? parseFloat(item.CAPTURE_AMOUNT) : parseFloat(item.AMOUNT),
            calc_total: item.BASE_AMOUNT ? formatCurrency( ( parseFloat(item.BASE_AMOUNT) + parseFloat(item.SVC_FEE) + parseFloat(item.SURCHARGE_FEE) + parseFloat(item.TAX) ) ) : formatCurrency(item.AMOUNT) ,
            SVC_FEE: item.SVC_FEE ? formatCurrency(item.SVC_FEE) : '$0.00',
            base_amount: item.BASE_AMOUNT ? formatCurrency(item.BASE_AMOUNT) : getBaseAmount(item),
            SURCHARGE_FEE: item.SURCHARGE_FEE ? formatCurrency(item.SURCHARGE_FEE) : '$0.00',
            TAX: item.TAX ? formatCurrency(item.TAX) : '$0.00',
            inventedBy: (typeof item.createdBy !== "undefined") ? item.createdBy.firstName + ' ' + item.createdBy.lastName : '',
            location: item.locationId ? item.locationId.locationName : '',
            customer_id:(item.CUSTOMERDATA && item.CUSTOMERDATA.customer_id )? item.CUSTOMERDATA.customer_id:'',
          }));
          console.log(modifiedData, " <<<<<<<<<<<<<>>>>>>>>>>>> ");
          if (typeof query.search === "undefined" || query.search === "") {
            resolve({
              data: modifiedData,
              page: result.data.pagination.page - 1,
              totalCount: result.data.pagination.totalCount,
            });
          } else {
            let filterData = modifiedData.filter((row => {
              return (row.RESPONSECODE === this.getPaymentStatus(String(query.search)) || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || String(row.ORDERID).indexOf((query.search)) !== -1 || String(row.AMOUNT).indexOf((query.search)) !== -1 || String(row.TXNID).indexOf((query.search)) !== -1 || String(row.inventedBy).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.location).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.CARDTYPE).toLowerCase().indexOf((String(query.search).toLowerCase())) !== -1 || String(row.FINAL_STATUS).toLowerCase().indexOf((query.search)) !== -1)
            }))
            if (filterData.length > 0) {
              query.filters.push(filterData)
            }
            resolve({
              data: filterData,
              page: result.data.pagination.page - 1,
              totalCount: filterData.length,
            });
          }
        } else {
          // let msg = 'Something wrong happened';
          // misMatchError.push(msg)
          // this.setState({ misMatchError });
          window.location.reload();
        }
      });
    });
  }

  handleChangeTab = (e, val, Id = null) => {
    switch (val) {
      case 0:
        this.setState({ tab: 0 });
        this.getdata()
        break;
      case 1:
        this.setState({ tab: 1 });
        break;
      default:
        this.setState({ tab: 0 });
        break;
    }
  }

  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    })
  }

  captureAmountModify(newValue, oldValue, rowData) {     
    let misMatchError = [];
    try{
      let datas = { "newValue": parseFloat(newValue), "oldValue": parseFloat(oldValue), "rowData": rowData };
      this.props.updatePreAuth(datas).then(res => {   
        if(res.payload.invoiceData.data.success){
          this.setState({ successMessages: true, msg: "Successfully updated capture amount." });
          setTimeout(() => {
            this.setState({ successMessages: false, msg: "" });           
          }, 1000);
        }
        else{
          let msg = 'Something wrong happened!';
          misMatchError.push(msg);
          this.setState({ misMatchError });
          setTimeout(() => {
            misMatchError=[];        
            this.setState({ misMatchError }); 
          }, 1000);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  captureAuthPayment(ev, datas) {
    let misMatchError = [];
  
    try{
      this.setState({ isLoading: true });
        this.props.capturePreAuth(datas, this.state.csrf).then(res  => {
          this.setState({ isLoading: false, reload: true });  
          if(res.payload.invoiceData && res.payload.invoiceData.data.success){        
            console.log(res.payload.invoiceData, '---invoice data')  
            this.setState({ successMessages: true, msg: "Successfully captured the transaction." });  
            setTimeout(() => {         
              this.state.tableObje.onQueryChange(); 
              this.setState({ successMessages: false, msg: "", reload: true });  
            }, 1000);        
          }else{ 
            if((res.payload.invoiceData && res.payload.invoiceData.data.response.data.data.errors) || (res.payload.error && res.payload.error.data.response.data.data.errors)){
              if(res.payload.invoiceData && res.payload.invoiceData.data.response.data.data.errors[84]){
                misMatchError.push(res.payload.invoiceData.data.response.data.data.errors[84][0]);
              }else if(res.payload.error && res.payload.error.data.response.data.data.errors[84]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[84][0]);
              }
              if(res.payload.invoiceData && res.payload.invoiceData.data.response.data.data.errors[979]){
                misMatchError.push(res.payload.invoiceData.data.response.data.data.errors[979][0]);
              }else if(res.payload.error && res.payload.error.data.response.data.data.errors[979]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[979][0]);
              }
              if(res.payload.error && res.payload.error.data.response.data.data.errors[811]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[811][0]);
              }else if(res.payload.error && res.payload.error.data.response.data.data.errors[811]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[811][0]);
              }
              if(res.payload.error && res.payload.error.data.response.data.data.errors[816]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[816][0]);
              }else if(res.payload.error && res.payload.error.data.response.data.data.errors[816]){
                misMatchError.push(res.payload.error.data.response.data.data.errors[816][0]);
              }
              this.setState({ misMatchError });              
            }else{
              let msg = 'Something wrong happened!';
              misMatchError.push(msg);            
              this.setState({ misMatchError });
            }
            setTimeout(() => {
              misMatchError=[];        
              this.setState({ misMatchError }); 
              this.state.tableObje.onQueryChange();
            }, 5000);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  voidAuthPayment(ev, datas) {
    let misMatchError = [];
    try{
      this.setState({ isLoading: true }); 
      this.props.voidPreAuth(datas,  this.state.csrf).then(res  => {
        this.setState({ isLoading: false }); 
        if(res.payload.invoiceData.data.success){          
          this.setState({ successMessages: true, msg: "Successfully voided the transaction.", reload: true  });   
          this.state.tableObje.onQueryChange(); 
          setTimeout(() => {         
            this.setState({ successMessages: false, msg: "", reload: false });  
          }, 1000);        
        }
        else{
          let msg = 'Something wrong happened!';
          misMatchError.push(msg);
          this.state.tableObje.onQueryChange();
          this.setState({ misMatchError });
          setTimeout(() => {
            misMatchError=[];        
            this.setState({ misMatchError }); 
          }, 1000);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
 

  syncTransactions = (e) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let userId = user._id || ""
    let companyId = user.company._id ? user.company._id : ""
    let locationMidDetails = JSON.parse(localStorage.getItem("locationPaydetails")) || ""
    let midTypeWithMidDetails = locationMidDetails ? locationMidDetails.midType === 'Traditional' ? { 'midType' : locationMidDetails.midType, 'mId':locationMidDetails.mId } : { 'midType' : locationMidDetails.midType, 'mId2':locationMidDetails.mId2 } : ""
    let token = user.token;
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let misMatchError = [];
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword }
    let data  = {'companyId': companyId,'locationId':locationId}
    this.setState({ isLoading : true })
    
    DataApi({...data, ...midTypeWithMidDetails, 'userId' : userId }, config.BASEURL + 'syncTransactionList', token, true, {}, paytraceCredentials).then((response) => {
      this.setState({ isLoading : false })
      console.log(response,'-----resssss')
      if (response.data.success) {
        console.log(response.data)
        this.setState({ successMessages: true, msg : response.data.message, reload : true })
      } else {
        misMatchError.push(response.data.message)
        this.setState({ misMatchError, reload : true })
      }
      setTimeout( () => {
        this.setState({ misMatchError:[], msg: "" })
      },6000)

    }).catch((err) => {
        console.log(err);
        this.setState({ isLoading : false })
    });

  }

  transformData = (data) => {
    for(let i=0; i<data.length; i++){
      for(let j=0; j<data[i].data.length; j++){
        if (typeof data[i].data[j] === "object") {
          if(data[i].data[j].props && data[i].data[j].props.children && data[i].data[j].props.children.type ==="span"){
            data[i].data[j] = data[i].data[j].props.children.props.children;
          
          }
          if(data[i].data[j].props && data[i].data[j].props && data[i].data[j].props.type ==="button"){
            data[i].data[j] = data[i].data[j].props.children;
            
           }
      }
    }
  }
   return data;
  };

  render() {
    const map1= this.state.data1;
    let options = { day: '2-digit', year: '2-digit' ,month: '2-digit'} 
     const spinnerStyle = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
    
    const optionsmui = {
      filter: true,
      filterType: "dropdown",
      responsive: "standard",
      // tableBodyHeight,
      // tableBodyMaxHeight,
      selectableRows: false, 
      customSearch: MuiDataTableCustomeSearch,
      download: true,
      onDownload:(buildHead, buildBody, columns, data) =>{
           const dataToExport = data.map(item =>{
            item.data.splice(0, 1); 
            return item;        
          })
          const transformedData = this.transformData(dataToExport);
          columns.splice(0, 1);
          return buildHead(columns) + buildBody(transformedData)
          
          // let val = buildHead(columns) + buildBody(transformedData);
          // console.log(val);
          // return val;
      }
    };
    const result = Object.values(map1); 
      const res = result.map((result)=>{  
        return({"Action":<Tooltip title="Copy Transaction"><Button  variant="contained" color="primary" onClick={(e) => this.handleTxnCopy(result.TXNID)}  > <FileCopyIcon fontSize={"small"} /> </Button></Tooltip>,"TXN ID":<Tooltip title="View Transaction"><span className={classes.Underline} onClick={(e) => this.handleChangeId(result.TXNID)}  >{result.TXNID}</span></Tooltip>,
        "Type":(result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120') ? 'ACH' :  (result.payment_type ==="Credit")  ? 'Credit' : "Debit",
        "Status":(result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120') ? <button type="button" className="btn PenddingBtn" >Requested</button> :
          (result.FINAL_STATUS === "Paid") ? <button type="button" className="btn paidBtn" >Paid</button> :
            // (rowData.FINAL_STATUS === "Refunded") ? <button type="button" className="btn RefundedBtn" >Refunded</button> : 
            (result.hasOwnProperty('REFUNDED') && result.hasOwnProperty('ORDERID') && result.FINAL_STATUS !== "Voided" && result.FINAL_STATUS !== "Declined" && parseFloat(result.REFUNDED.reduce((a, b) => { return (parseFloat(a) + parseFloat(b.amt)).toFixed(2) }, 0)) < parseFloat(result.AMOUNT)) && (result.FINAL_STATUS !== "authOnly") ? <button type="button" className="btn Refunding" >Partial-Refund</button> :
              (result.FINAL_STATUS === "Voided") ? <button type="button" className="btn PenddingBtn" >Voided</button> :
                (result.FINAL_STATUS === "Declined") ? <button type="button" className="btn DeclinedBtn" >Declined</button> :
                  (result.FINAL_STATUS === "authOnly" && ((new Date().getTime() - (new Date(result.createdAt)).getTime()) <= 2592000000)) ? <button type="button" className="btn RefundedBtn" >Auth Only  </button> :
                    (result.FINAL_STATUS === "authOnly" && ((new Date().getTime() - (new Date(result.createdAt)).getTime()) > 2592000000)) ? <button type="button" className="btn DeclinedBtn" >Expired </button> :
                      <button type="button" className="btn RefundedBtn" >Refunded</button>,
                "Name":(result.CUSTOMERDATA) ? `${ result.CUSTOMERDATA.name ? result.CUSTOMERDATA.name : ((result.CUSTOMERDATA.firstName)?result.CUSTOMERDATA.firstName+' '+result.CUSTOMERDATA.lastName:((result.CUSTOMERDATA.customer_id) ? result.CUSTOMERDATA.customer_id:'')) } ` : '',
                "Location":(result.midType !== null && result.midType !== "" && result.hasOwnProperty('midType')) ? result.location + '(' + result.midType + ')' : result.location,
                "Invoice":(result.ORDERID !== null)?result.ORDERID :'',
                "Card Number": result.MASKCARDNUMBER?result.MASKCARDNUMBER:result.CUSTOMERDATA.masked_card_number?result.CUSTOMERDATA.masked_card_number.toString():'',
                "MID": (result.pay_mId && result.pay_mId !== '') ? result.pay_mId : ((result.invoices !== null && result.invoices.hasOwnProperty('pay_mId')) ? result.invoices.pay_mId : ""),                
                "Base":result.BASE_AMOUNT != '$0.00' ? result.base_amount : getBaseAmount(result),
                "Tax":result.TAX,
                "Surcharge": result.SURCHARGE_FEE,
                "SVC": result.SVC_FEE,
                "Total": result.calc_total,
                "Date": (new Date(result.createdAt).toLocaleTimeString('en-US', options)),
                "Customer ID":(result.CUSTOMERDATA) ? result.CUSTOMERDATA.customer_id ? result.CUSTOMERDATA.customer_id.substring(0,12)+"..." : "N/A"  : "N/A",
              })
        
        // return ({"Transaction ID":<Tooltip title="View Transaction"><span className={classes.Underline} onClick={(e) => this.handleChangeId(result.TXNID)}  >{result.TXNID}</span></Tooltip>,"Customer Name":result.CUSTOMERDATA.firstName,"Payment Type":(result.CARDTYPE ==='ACH' || result.RESPONSECODE==='120') ? 'ACH' : 'Credit',"Payment Status":(result.FINAL_STATUS === "Paid") ? <button type="button" className="btn paidBtn" >Paid</button> : (result.FINAL_STATUS === "Refunded") ? <button type="button" className="btn RefundedBtn" >Refunded</button> : (result.FINAL_STATUS === "Voided") ? <button type="button" className="btn PenddingBtn" >Voided</button> : '',"Completed By":result.inventedBy,"location":result.location,"Invoice":(result.invoices !== null)?result.invoices.invoice :'',"Base Amount":getBaseAmount(result),"Amount":result.amount,"MaskCard No":result.CUSTOMERDATA.masked_card_number ? "************"+result.CUSTOMERDATA.masked_card_number.substr(-4) :"","Card Type":result.CUSTOMERDATA.cardType,"Date":(date) => (new Date(result.createdAt).toLocaleString('en-US', options))})

      });
  
      
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const { t } = this.props;
    let column=["Action", "TXN ID", "Type", "Status","Name","Customer ID","Location","Invoice","Card Number","MID","Base"]; 
    if(this.state.settingData && this.state.settingData.InvoiceTax){
      column.push('Tax');
    }
    if(this.state.settingData && this.state.settingData.InvoiceDiscount){
      column.push('SVC')
    }    
    if(this.state.settingData && this.state.settingData.InvoiceSurcharge){
      column.push('Surcharge')
    }
    column=[ ...column, ...["Total","Date"] ];
 
    let columns = [
      (this.state.tab === 1) ? {        
        title: 'Capture Amount',
        field: 'CAPTURE_AMOUNT',
        name: 'CAPTURE_AMOUNT',
        editable: 'onUpdate',
        numeric: true,
        headerStyle: { textAlign: 'unset', flexDirection:'unset'},
        cellStyle: {
          paddingRight: "10px",
          minWidth: '240px',
        },
        type:'currency',
        currencySetting:{ locale: 'en-US', currencyCode:'USD', minimumFractionDigits:2, maximumFractionDigits:2},
      } : {
        width: -1,
        hidden: true,
        title: 'Capture amount',
        field: 'CAPTURE_AMOUNT',
        editable: 'onUpdate',
        cellStyle: {         
          textAlign: 'left'
        },        
      },
      (this.state.tab === 1) ?{
        title: 'Action', field: '', editable: 'never', width: -1, hidden: true
      } : {
        title: 'Action', field: '', editable: 'never', render: rowData =>  <Tooltip title="Copy Transaction"><Button  variant="contained" color="primary" onClick={(e) => this.handleTxnCopy(rowData.TXNID)}  > <FileCopyIcon fontSize={"small"} /> </Button></Tooltip>
      }, 
      {
        title: 'TXN ID', field: 'TXNID', editable: 'never', render: rowData => <Tooltip title="View Transaction"><span className={classes.Underline} onClick={(e) => this.handleChangeId(rowData.TXNID)}  >{rowData.TXNID}</span></Tooltip>
      },
      {
        title: 'Type', field: 'payment_type', editable: 'never', render: rowData => (rowData.CARDTYPE === 'ACH' || rowData.RESPONSECODE === '120') ? 'ACH' :  (rowData.payment_type ==="Credit")  ? 'Credit' : "Debit"
      },
      {
        title: 'Status', field: 'FINAL_STATUS', editable: 'never', render: rowData => (rowData.CARDTYPE === 'ACH' || rowData.RESPONSECODE === '120') ? <button type="button" className="btn PenddingBtn" >Requested</button> :
          (rowData.FINAL_STATUS === "Paid") ? <button type="button" className="btn paidBtn" >Paid</button> :
            // (rowData.FINAL_STATUS === "Refunded") ? <button type="button" className="btn RefundedBtn" >Refunded</button> : 
            (rowData.hasOwnProperty('REFUNDED') && rowData.hasOwnProperty('ORDERID') && rowData.FINAL_STATUS !== "Voided" && rowData.FINAL_STATUS !== "Declined" && parseFloat(rowData.REFUNDED.reduce((a, b) => { return (parseFloat(a) + parseFloat(b.amt)).toFixed(2) }, 0)) < parseFloat(rowData.AMOUNT)) && (rowData.FINAL_STATUS !== "authOnly") ? <button type="button" className="btn Refunding" >Partial-Refund</button> :
              (rowData.FINAL_STATUS === "Voided") ? <button type="button" className="btn PenddingBtn" >Voided</button> :
                (rowData.FINAL_STATUS === "Declined") ? <button type="button" className="btn DeclinedBtn" >Declined</button> :
                  (rowData.FINAL_STATUS === "authOnly" && ((new Date().getTime() - (new Date(rowData.createdAt)).getTime()) <= 2592000000)) ? <button type="button" className="btn RefundedBtn" >Auth Only  </button> :
                    (rowData.FINAL_STATUS === "authOnly" && ((new Date().getTime() - (new Date(rowData.createdAt)).getTime()) > 2592000000)) ? <button type="button" className="btn DeclinedBtn" >Expired </button> :
                      <button type="button" className="btn RefundedBtn" >Refunded</button>
      },
      // { title: 'Invented By', field: 'createdBy', render: rowData => (rowData.createdBy)?rowData.createdBy.firstName+' '+rowData.createdBy.lastName:''},
      //  { title: 'Customer Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      //   `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : ((rowData.CUSTOMERDATA.firstName != "")?rowData.CUSTOMERDATA.firstName+' '+rowData.CUSTOMERDATA.lastName:'') } ` : '' },
      { title: 'Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : ((rowData.CUSTOMERDATA.firstName)?rowData.CUSTOMERDATA.firstName+' '+rowData.CUSTOMERDATA.lastName:((rowData.CUSTOMERDATA.customer_id) ? rowData.CUSTOMERDATA.customer_id:'N/A')) } ` : 'N/A' },
      { title: 'Customer ID', field: 'customer_id', editable: 'never' , render: rowData => 
        <span title = {(rowData.CUSTOMERDATA && rowData.CUSTOMERDATA.customer_id)?rowData.CUSTOMERDATA.customer_id:'N/A'}>
          {
          (rowData.CUSTOMERDATA && rowData.CUSTOMERDATA.customer_id) ? rowData.CUSTOMERDATA.customer_id.substring(0,12)+"..." : "N/A"  
          }
        </span>
      },
      { title: 'Location', field: 'location', editable: 'never', render: rowData => (rowData.midType !== null && rowData.midType !== "" && rowData.hasOwnProperty('midType')) ? rowData.location + '(' + rowData.midType + ')' : rowData.location },
      { title: 'Invoice', field: 'ORDERID', editable: 'never', render: rowData => (rowData.ORDERID) ? rowData.ORDERID : 'N/A' },      
      { title: 'Card Number', field: 'MASKCARDNUMBER', editable: 'never', render:  (rowData) => rowData.MASKCARDNUMBER?rowData.MASKCARDNUMBER.toString().replace(/^.{12}/g, '************'): (rowData.CUSTOMERDATA.masked_card_number?rowData.CUSTOMERDATA.masked_card_number.toString().replace(/^.{12}/g, '************'):''),},
      { title: 'MID', field: 'pay_mId', editable: 'never', render: rowData => (rowData.pay_mId && rowData.pay_mId !== '') ? rowData.pay_mId : ((rowData.invoices !== null && rowData.invoices.hasOwnProperty('pay_mId')) ? rowData.invoices.pay_mId : "") },
      { title: 'Base', field: 'base', editable: 'never' , render: rowData => rowData.base_amount },
      // { title: 'Tax', field: 'TAX', editable: 'never' }
      // { title: 'SVC', field: 'SVC_FEE', editable: 'never' },
      // { title: 'Surcharge', field: 'SURCHARGE_FEE', editable: 'never' },
      
      //  { title: 'Total Amount', field: 'amount', editable: 'never' },
      // { title: 'Card Type', field: 'CARDTYPE', editable: 'never', render: rowData => rowData.CARDTYPE },
      // { title: 'Date', field: 'createdAt', editable: 'never', render: (date) => (new Date(date.createdAt).toLocaleString()) },    
    ];   
    if(this.state.settingData && this.state.settingData.InvoiceTax){
      columns.push({ title: 'Tax', field: 'TAX', editable: 'never' });
    }
    if(this.state.settingData && this.state.settingData.InvoiceDiscount){
      columns.push({ title: this.state.settingData.DiscountFeeName, field: 'SVC_FEE', editable: 'never' })
    }    
    if(this.state.settingData && this.state.settingData.InvoiceSurcharge){
      columns.push({ title: this.state.settingData.SurchargeFeeName, field: 'SURCHARGE_FEE', editable: 'never' })
    }
    // columns.push({ title: 'Total', field: 'AMOUNT', editable: 'never', render: (rowData) => ((rowData.tableData.id === 4) ? (rowData.CAPTURE_AMOUNT?formatCurrency(rowData.CAPTURE_AMOUNT):formatCurrency(rowData.AMOUNT)  ): rowData.REFUND ? formatCurrency(rowData.AMOUNT) : formatCurrency(rowData.AMOUNT))});
    columns.push({ title: 'Total', field: 'AMOUNT', editable: 'never', render: (rowData) => ((rowData.tableData.id === 4) ? formatCurrency(rowData.CAPTURE_AMOUNT) : rowData.REFUND ? rowData.amount : rowData.calc_total)}  );
    
    //  columns.push({ title: 'Total', field: 'AMOUNT', editable: 'never', render: (rowData) => ((rowData.tableData.id === 4) ? formatCurrency(rowData.CAPTURE_AMOUNT) : rowData.REFUND ? getBaseAmount4(rowData) : getBaseAmount2(rowData))});
    // columns.push({ title: 'Total', field: 'AMOUNT', editable: 'never', render: (rowData) => ( getBaseAmount2(rowData))});
    
   // columns.push({ title: 'Card Type', field: 'CARDTYPE', editable: 'never', render: rowData => rowData.CARDTYPE });
    let optionsdate = { year: '2-digit', month: '2-digit', day: '2-digit' };
    columns.push({ title: 'Date', field: 'createdAt', editable: 'never', render: (date) => (new Date(date.createdAt).toLocaleTimeString('en-US', optionsdate)) });

    // columns.push({ title: 'Customer ID', field: 'customer_id', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
    //    rowData.CUSTOMERDATA.customer_id ? rowData.CUSTOMERDATA.customer_id : ""  : "" });
    

    // columns.push({ title: 'MID', field: 'pay_mId', editable: 'never', render: rowData => (rowData.pay_mId && rowData.pay_mId !== '') ? rowData.pay_mId : ((rowData.invoices !== null && rowData.invoices.hasOwnProperty('pay_mId')) ? rowData.invoices.pay_mId : "") })
    
    
    // columns.push ({title: 'Action', field: '', editable: 'never', render: rowData => (rowData.status_code === "V") ? <Tooltip title="Copy Transaction"><Button  variant="contained" color="primary" onClick={(e) => this.handleTxnCopy(rowData.TXNID)}  > <FileCopyIcon fontSize={"small"} /> </Button></Tooltip> :"" })

    if (this.props.UserDetail.userId === "5f5f650f3beb36386c57bee3") {
      columns.push(
        { title: "Base amount", field: "Base amount", render: (rowData) => ((rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT) ? "$" + rowData.invoices.FIRST_PENALTY_AMOUNT : "") },
        {
          title: "Penalty amount", field: "Penalty amount", render: (rowData) => (
            (rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT) ? ((parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)) > 0) ?
              `$ ${parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)}` : parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT) : ""
          )
        },
        { title: "Amount charged", field: "amount" });
    }

    const colorStatus = [
      { 'Voided': '#f5aa02' },
      { 'Paid': '#03af38' },
      { 'Refunded': '#b38d54' },
      { 'Partial Refund': '#808080' },
      { 'Requested': '#f5aa02' },
      { 'Declined': '#e45e5e' },
      { 'authOnly': '#03af00' },
    ];
    
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <div className={classes.root}>
            <Tabs
              variant="scrollable"
              value={this.state.tab}
              onChange={(e, val) => this.handleChangeTab(e, val)}
              aria-label="Horizontal tabs example"
              className={classes.tabs}
            >
              <Tab className={`${classes.tabBtn, classes.MuiTabwidth}`} label="Transactions" {...a11yProps(1)} />
              <Tab className={`${classes.tabBtn, classes.MuiTabwidth}`} label="PreAuth" {...a11yProps(2)} />
            </Tabs>
            <div className="tp-tabcustom">

              {this.state.misMatchError.map((e, i) => (< ErrorMessage key={i} errors={[e]} />))}

              {this.state.successMessages ? <SuccessMessage successes={[this.state.msg]} /> : ''}

              <TabPanel value={this.state.tab} index={0} className={classes.mT1} > 


                
              <LoadingOverlay
                  active={this.state.isLoading}
                  spinner={
                  <BarLoader 
                  cssOverride={spinnerStyle}
                  />}
                  text='Syncing Transactions'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: 'transparent',
                      color:"black",
                    })
                  }}
                  >
               

                  { 
                  // this.props.UserDetail.permissions.viewAllTransactions ?<div>
                  //   <Grid container spacing={3} >
                  //     <Grid item xs={12} sm={6}>
                  //     <label>
                  //     {/* <FormControl> */}
                  //       <Checkbox id="viewAllTransactions" onChange={(e) => {
    
                  //           this.handleChangeCheckbox(e);
                  //         }} value={this.state.viewAllTransactions} color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
                  //       <span>{t("viewAllTransactions")}</span>
                  //       {/* </FormControl> */}
                  //     </label>
                  //   </Grid>
                  // </Grid></div> :''
                  }
                      
                  
                     <MuiPickersUtilsProvider  utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant="inline"
												autoOk
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline1"
												label={'From Date'}
												value={this.state.fromDate}
												onChange={(date) => { this.handleDateChange(date, 'fromDate') }}
                        datefilter={true}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant="inline"
												autoOk
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline1"
												label={'To Date'}
												value={this.state.toDate}
												onChange={(date) => { this.handleDateChange(date, 'toDate') }}
                        datefilter={true}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
                    {<MUIDataTable 
                      title={'Transaction'}
                      data={res} 
                      columns={column} 
                      options={optionsmui}
                      
                   />}
                </LoadingOverlay>
                <Colorstatus title="Transcations Color Status" filteredArray={colorStatus} />
                {(this.props.UserDetail.companyId._id === "5f4fdbe29bfe9c0e243a7598") ? <NyTaxReports /> : ''}
              </TabPanel>


              <TabPanel value={this.state.tab} index={1} className={classes.mT1} >
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner={
                  <BarLoader 
                  cssOverride={spinnerStyle}
                  />}
                  text='Syncing Transactions'
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: 'transparent',
                      color:"black",
                      height: "13%"
                      
                    })
                  }}
                  >
                    
                  <div>
                    <FormControl style={{ width: 200, marginLeft: 15 }} className={classes.formControl}>
                      <InputLabel id="demo-mutiple-checkbox-label">Pre Authorize Status</InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={(this.state.preAuthActiveData) ? "Active" : "Expire"}
                        onChange={(e) => this.handleChangeSelect(e)}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {[{ id: 1, value: "Active" }, { id: 2, value: "Expire" }].map((items) => (
                          <MenuItem key={items.id} value={items.value}>
                            {/* <Checkbox
                              checked={true}
                            /> */}
                            <ListItemText primary={items.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Table
                    tableReload={(e) => this.tableReload(e)}
                    captureAuthPayment={(e, data) => this.captureAuthPayment(e, data)}
                    voidAuthPayment={(e, data) => this.voidAuthPayment(e, data)}
                    permissions={userPermissions.permissions}
                    captureAmountModify={(newValue, oldValue, rowData) => this.captureAmountModify(newValue, oldValue, rowData)}
                    options={{
                      exportButton: true,
                      selection: true
                    }}
                    columns={columns}
                    createNew={false}
                    data={query => this.getTableDataAuth(query)}
                    title={'Auth Only'}
                    search={true}
                    exports={this.props.UserDetail.permissions.exportRecord}
                    paging={true}
                    toDateFilter={(e) => this.toDateFilter(this.state.toDate)}
                    fromDateFilter={(e) => this.fromDateFilter(this.state.fromDate)}
                    hardRefresh={(e) => this.hardRefresh(e)}
                    tablesName="PREAUTH"
                  // datefilter={true}
                  />
                </LoadingOverlay>
                <Colorstatus title="Transcations Color Status" filteredArray={colorStatus} />
                {(this.props.UserDetail.companyId._id === "5f4fdbe29bfe9c0e243a7598") ? <NyTaxReports /> : ''}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { capturePreAuth, voidPreAuth, updatePreAuth, settingDataByCompanyIdmId, getCsrfToken })(withTranslation() (Transactions));