import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { addCustomer, editCustomer } from '../../actions/CustomerAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  firstName: '',
  lastName: '',
  createdBy: '',
  companyId: '',
  email: '',
  phone: '',
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  error: {
    firstNameError: false,
    emailError: false,
    amountError: false,
    phoneError: false,
  }
};

class AddClient extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    if (this.props.editData) {
      let allCustomer = this.props.info.customerInfo.data.response;
      let EditCustomer = allCustomer.filter((item) => {
        if (this.props.editData === item._id) {
          return item
        }
      })
      this.setState({ ...EditCustomer[0], editClient: true, isLoading: false })

    }

    let userData = JSON.parse(localStorage.getItem('user'))
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false })
  }

  handleChange(event, t) {
    let successMessages = false;
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (this.state.createdBy === "") {
      let userData = JSON.parse(localStorage.getItem('user'))
      this.setState({ createdBy: userData._id, companyId: userData.company._id })
    }

    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;


    this.setState({ isSubmit: true })

    Object.keys(state).map((key, value) => {

      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      console.log(error)
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.setState({ isLoading: true })
      if (this.state.editClient) {
        this.props.editCustomer(state).then(result => {
          if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true })
          }
          if (result !== undefined && result.type === 'CUSTOMER_ERROR') {
            this.setState({ isLoading: false,misMatchError: this.props.info.customerInfo.data.message, successMessages });

          }
        });
      } else {
        this.props.addCustomer(state).then(result => {
          if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
            this.setState({ ...initialState, isLoading: false, successMessages: true })

          }
          if (result !== undefined && result.type === 'CUSTOMER_ERROR') {
            this.setState({ isLoading: false,misMatchError: this.props.info.customerInfo.data.message, successMessages });

          }
        });
      }

    }

  }

  render() {

    const { classes, t } = this.props
    return (
      <><LoadingOverlay
      // className={classes.tpTableFullW}
      active={this.state.isLoading}
      spinner
      text='Fetching your response ...'>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {this.state.editClient ? 'Edit Client' : 'Client'}
            </Typography>

          </Toolbar>
        </AppBar>
        {this.state.misMatchError.length > 0 ?
          < ErrorMessage errors={[this.state.misMatchError]} /> : ''
        }
        {this.state.successMessages ?
          <SuccessMessage successes={[this.props.info.customerInfo.data.message]} /> : ''
        }
      
        <form className={classes.form} noValidate ref={this.myRef}>
          <Grid container spacing={1}  >
            <Grid container item xs={12}>
              <Textfield
                required={true}
                error={this.state.error.firstNameError}
                id="firstName"
                type="text"
                labels={t("FirstName")}
                value={this.state.firstName}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={true}
              />
            </Grid>
            <Grid container item xs={12}>
              <Textfield
                required={true}
                error={this.state.error.lastNameError}
                id="lastName"
                type="text"
                labels={t("LastName")}
                value={this.state.lastName}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={true}
              />
            </Grid>
            <Grid container item xs={12}>
              <Textfield
                required={true}
                error={this.state.error.emailError}
                id="email"
                type="text"
                labels={t("Email")}
                value={this.state.email}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={true}
              />
            </Grid>
            <Grid container item xs={12}>
              <Textfield
                required={true}
                error={this.state.error.phoneError}
                id="phone"
                type="text"
                labels={t("Phone")}
                value={this.state.phone}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={true}
              />
            </Grid>
          </Grid>
          <Buttons
            id="signUp"
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => this.handleSubmit(e, t)}
            text={t("Save")} />
        </form>
        </LoadingOverlay>
      </>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<AddClient theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {

  return {
    info: state.CustomerReducer
  };
}

export default connect(mapStateToProps, { addCustomer, editCustomer })(withTranslation()(withRouter(ApplyingStyles)))