import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
/**
 * User info Begin Action
 */
export const signinStart = () => ({
    type: ActionType.SIGNIN_START
});

/**
* User info Success Action
*/
export const signInSuccess = signinData => {
    return {
        type: ActionType.SIGNIN_SUCCESS,
        payload: { signinData }
    }
};

/**
* User info Failure Action
*/
export const signinError = error => ({
    type: ActionType.SIGNIN_ERROR,
    payload: { error }
});

/**
 * User driver info
 */
export const signIn = (data) => {
    return dispatch => {
        dispatch(signinStart());
        return DataApi(data, config.BASEURL + 'signUp').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(signInSuccess(response))
            } else {
                return dispatch(signinError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
