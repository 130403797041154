import React, {useEffect} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { find as _find , first } from "lodash"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
    width: 180
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const methodTypes = [
    "Debit",
    "Credit"   
];


function MethodFilter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [methodType, setMethodType] = React.useState([]);

  const handleChange = (event) => {
    // console.log(event.target.value, "============type");  
    setPersonName(event.target.value);  
    props.handleMethodChange(event.target.value)
  };

  // console.log(props.selectedType, " <<<<<<<<<<<<<<<<<<<<<<<< prop methods");

  

  useEffect(() => {
    if(typeof props.selectedType !== 'undefined'){
      // console.log(props.selectedType.data[0], " <<<<<<<<<<<<<<<<<<<<<<<< prop methods 2");
      if(props.selectedType && props.selectedType.data[0]) {
        setPersonName(props.selectedType.data); 
      }
    }   
  },[props.selectedType])

  return (
    <div >
      <FormControl className={classes.formControl}>
        {/* {console.log("personNamepersonName",personName)} */}
        <InputLabel id="methodFilters-label"> Payment Type </InputLabel>
        <Select
          labelId="methodFilters"
          style={{ padding: '2px 0'}}
          id="methodFilters"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {methodTypes.map((title) => (
            <MenuItem key={title} value={title}>
              <Checkbox checked={personName.indexOf(title) > -1} />
              <ListItemText primary={title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );


}


function mapStateToProps(state) {
  return {
    
  };
}
  
const ApplyingStylesOnClasses = (props) => {
  return <MethodFilter {...props} />;
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(ApplyingStylesOnClasses));
