import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { locationAdd } from '../../actions/LocationAction'
import { countryDataList, CompanyDetailById } from '../../actions/CompanyAction'
import TypableSelect from './../../helpers/TypableSelect';
import validator from './../../helpers/checkValidations';
import FormControl from '@material-ui/core/FormControl';
import config from './../../constants/config'
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { set } from 'date-fns/fp';
const filter = createFilterOptions();

const mapToFormatCountry = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  return Object.keys(state).sort().map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, title: name }
  ))
};

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px 0px 0px' };
  } else {
    return { width: '100%' };
  }
};
class AddLocationsCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      sideShow: true,
      isSubmit: false,
      locationSameAsDba: false,
      mId2Credentials: true,
      isLoading: true,
      locationName: '',
      email: '',
      phone: '',
      mId: '',
      mId2: '',
      contactName: '',
      address1: '',
      address2: "",
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      timeZone: new Date(),
      merchantId: '',
      notes: '',
      misMatchError: [],
      successMessages: false,
      user: JSON.parse(localStorage.getItem('user')),
      companyDetails: { country: '', city: '', state: '', address1: '', address2: '', zip: '' },
      paytraceKey: [],
      paytracePassword: "",
      paytraceUserName: "",
      paytraceKey2: [],
      paytracePassword2: "",
      paytraceUserName2: "",
      error: {
        nameError: false,
        paytracePasswordError: false,
        paytraceUserNameError: false,
        paytracePassword2Error: false,
        paytraceUserName2Error: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      }
    }
  }

  getcountryDataList() {
    this.setState({ isLoading: true })
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }

  componentDidMount() {
    if (this.state.user.company) {
      this.setState({ merchantId: this.state.user.company._id });
    }
    this.getcountryDataList();
    const { match: { params } } = this.props;
    let merchantId = params.id;
    this.setState({ merchantId: merchantId });
    this.getCompanyData();
  }
  getCompanyData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true });
    this.props.CompanyDetailById(params.id).then((res) => {
      if (res.payload.companyData) {
        this.setState({ companyDetails: { ...this.state, ...res.payload.companyData.data.response } }, () => {
          this.setState({ isLoading: false });
        })
      }
    });
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleDateChange = (date) => {
    this.setState({ timeZone: date })
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    if (this.state.address2 === "") {
      delete this.state.address2;
    }
    if (this.state.notes === "") {
      delete this.state.notes;
    }
    if (this.state.phone === "") {
      delete this.state.phone;
    }
    if (this.state.mId && this.state.mId2 && this.state.mId.toUpperCase() == this.state.mId2.toUpperCase()) {
      misMatchError.push('MID1 and MID2 can not be same.');
      this.setState({ misMatchError });
      errorFlag = true;
      return false;
    }
    this.setState({ isSubmit: true }); 
    Object.keys(state).map((key, value) => {
      if ((this.state.mId2.trim() == '' && key == 'mId2') || (this.state.mId2.trim() == '' && key == 'paytraceUserName2') || (this.state.mId2.trim() == '' && key == 'paytracePassword2')) {
        return '';
      } else {
        if ((validator(key, state[key]))) {
          return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false);
        } else {
          return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : '';
        }
      }
    });
    if (this.state.mId2Credentials && this.state.mId2.trim() != '') {
      this.state.paytraceKey[1] = this.state.paytraceKey[0];
      this.setState({ paytracePassword2: this.state.paytracePassword, paytraceUserName2: this.state.paytraceUserName });
    }     
    if ((!this.state.paytraceKey[0] || this.state.paytraceKey[0] == "") || ((!this.state.paytraceKey[1] || this.state.paytraceKey[1] == "") && this.state.mId2.trim() != '')) {
      misMatchError.push('Paytrace key required for payment..');
      this.setState({ misMatchError });
      errorFlag = true;
    }
    if (errorFlag) {
      console.log('error', error);
      window.scrollTo(0, 0, 0);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      let data = new FormData();
      const { paytraceKey } = this.state;
      for (const key in this.state) {
        if ("paytraceKey" === key) {
          continue;
        }
        data.append(key, this.state[key]);
      }
      for (const keys in paytraceKey) {
        data.append("paytraceKey", paytraceKey[keys]);
      }       
      this.setState({ isLoading: true });
      this.props.locationAdd(data).then(result => {
        if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState({ successMessages: true, isLoading: false, misMatchError }, () => {
            setTimeout(() => {
              this.goBack(e);
            }, 2000);
          });
        }
        if (result !== undefined && result.type === 'LOCATION_ERROR') {
          this.setState({ misMatchError: this.props.info.locationInfo.data.message, successMessages, isLoading: false });
        }
      });
    }
  }
  setCountryState(val) {
    if (val !== null) {
      this.setState({
        stateList: this.state.Countrydata.filter(obj => (obj.Country === val.label))[0].State, country: val.label
      });
    } else {
      this.setState({ stateList: [], cityList: [], state: '', city: '' });
    }
  }
  setStatesSet(val) {
    if (val !== null) {
      this.setState({
        cityList: this.state.stateList[val.label], state: val.label
      });
    } else {
      this.setState({ cityList: [], state: '', city: '' });
    }
  }
  /*  setCitySet(val) {
     if (val !== null){
       this.setState({ city: val.label })
     }
   } */


  setCitySet(event, val) {
    if (val !== null && val.hasOwnProperty('value')) {
      this.setState({ city: val.title })
    } else if (val && val.inputValue) {
      this.setState({ city: val.inputValue })
    } else {
      this.setState({ city: val })
    }
  }

  goBack(e) {
    this.props.handleChangeTab(e, 1);
  }
  onCloseState() {
     
  }
  //upload paytace key
  uploadKey = (event, t) => {
    let file = event.target.files[0];
    this.state.paytraceKey[0] = file ? file : '';
    this.setState({ paytraceKey: this.state.paytraceKey });
    if (this.state.mId2Credentials && this.state.mId2.trim() != '') {
      this.state.paytraceKey[1] = this.state.paytraceKey[0];
      this.setState({ paytracePassword2: this.state.paytracePassword, paytraceUserName2: this.state.paytraceUserName });
    }
    
  }
  uploadKey2 = (event, t) => {
    let file2 = event.target.files[0];
    this.state.paytraceKey[1] = file2 ? file2 : '';
    this.setState({ paytraceKey: this.state.paytraceKey });
    
  }
  handleCheckboxDba(event) {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      if (this.state.locationSameAsDba) {
        this.setState({
          'email': this.state.companyDetails.email, 'contactName': this.state.companyDetails.contactName, 'city': this.state.companyDetails.city, 'state': this.state.companyDetails.state, 'country': this.state.companyDetails.country, 'zip': this.state.companyDetails.zip, 'address1': this.state.companyDetails.address1, 'address2': this.state.companyDetails.address2
        })
      } else {
        this.setState({ 'city': '', 'state': '', 'country': '', 'address1': '', 'zip': '', 'address2': '' });
      }
    });
  }
  handleCheckboxCredentials(event) {
    this.setState({ [event.target.id]: event.target.checked });
    if (event.target.checked === false) {
      this.state.paytraceKey[1] = '';
      this.setState({ paytracePassword2: '', paytraceUserName2: '' });
    } else {
      this.state.paytraceKey[1] = this.state.paytraceKey[0] ? this.state.paytraceKey[0] : this.state.paytraceKey[0];
      this.setState({ paytracePassword2: this.state.paytracePassword, paytraceUserName2: this.state.paytraceUserName });
    }
     
  }
  render() {
    const { t } = this.props;
    const { Countrydata } = this.state;
    return (
      <LoadingOverlay
        className={classes.tpTableFullW}
        active={this.state.isLoading}
        spinner
        text='Fetching your response ...'>
        <Container component="main" maxWidth="md">
          {
            this.state.misMatchError.length > 0 ? < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {
            this.state.successMessages ? <SuccessMessage successes={[this.props.info.locationInfo.data.message]} /> : ''
          }
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.locationNameError}
                  id="locationName"
                  type="text"
                  labels={t("Location Name")}
                  value={this.state.locationName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.contactNameError}
                  id="contactName"
                  type="text"
                  labels={t("Contact Name")}
                  value={this.state.contactName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="phone format xxx-xxx-xxxx"
                  id="phone"
                  type={"tel"}
                  pattern="[789][0-9]{9}"
                  labels={t("Phone")}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="Unique mId 1"
                  required={true}
                  error={this.state.error.mIdError}
                  id="mId"
                  type={"text"}
                  labels={t("mId 1")}
                  value={this.state.mId}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="Unique mId 2"
                  id="mId2"
                  type={"text"}
                  labels={t("mId 2")}
                  value={this.state.mId2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              {/* f<Grid item xs={12}> <h3>Location info same as DBA</h3></Grid> */}
              <Grid item xs={12} sm={12}>
                <label>
                  <Checkbox
                    id="locationSameAsDba"
                    labels={t("Location Same As DBA")}
                    checked={this.state.locationSameAsDba}
                    onChange={(e) => { this.handleCheckboxDba(e) }}
                    value={this.state.locationSameAsDba}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <span>Location info same as DBA</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.address1Error}
                  id="address1"
                  type="text"
                  labels={t("Address1")}
                  value={this.state.address1}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  id="address2"
                  type="text"
                  labels={t("Address2")}
                  value={this.state.address2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select country name'} suggestions={mapToFormatCountry(Countrydata)}
                    onChange={val => this.setCountryState(val)}
                    isClearable={true}
                    isSelected={(this.state.country) ? this.state.country : ''}
                  // MultiValue={(e)=>{this.onCloseState(val)}}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select state name'} suggestions={mapToFormatStates(this.state.stateList)}
                    onChange={val => this.setStatesSet(val)}
                    isClearable={true}
                    isSelected={(this.state.state) ? this.state.state : ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} style={{ marginTop: '2.3rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)} >
                  <Autocomplete
                    value={this.state.city}
                    onChange={(e, value) => this.setCitySet(e, value)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="city"
                    options={mapToFormatCity(this.state.cityList)}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderOption={(option) => option.title}
                    style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="city" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.zipError}
                  id="zip"
                  type="text"
                  labels={t("ZIP")}
                  value={this.state.zip}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={12}> <h3>Paytrace Api Credentials </h3></Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.paytraceUserNameError}
                  id="paytraceUserName"
                  type="text"
                  labels={t("User Name")}
                  value={this.state.paytraceUserName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.paytracePasswordError}
                  id="paytracePassword"
                  type="password"
                  labels={t("Password")}
                  value={this.state.paytracePassword}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                <label>Paytrace key file    {(this.state.paytraceKey && (this.state.paytraceKey.length) >= 1 && this.state.paytraceKey[0] != '' && this.state.paytraceKey[0] != 'undefined' && this.state.paytraceKey[0] != null) ? <img alt="enable" className={classes.rightImg} src={config.APPURL + '/assets/images/checkmark-24.png'} /> : ''}
                </label>
                <input type="file" name="paytraceKey" onChange={(e) => this.uploadKey(e, t)} />
              </Grid>
              <Grid item xs={6} sm={3}>
              </Grid>
              {(this.state.mId2.trim() != '') ?
                <Grid item xs={12} sm={12}>
                  <label>
                    <Checkbox
                      id="mId2Credentials"
                      labels={t("mId2 Credentials")}
                      checked={this.state.mId2Credentials}
                      onChange={(e) => { this.handleCheckboxCredentials(e) }}
                      value={this.state.mId2Credentials}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Use Same User Credentials for MID 2 </span>
                  </label>
                </Grid>
                : ''
              }
              {(!this.state.mId2Credentials && this.state.mId2.trim() != '') ?
                <>
                  <Grid item xs={12}> <h3>Paytrace Api Credentials for MID 2</h3></Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={this.state.mId2.trim() != '' ? true : false}
                      id="paytraceUserName2"
                      error={this.state.error.paytraceUserName2Error}
                      type="text"
                      labels={t("User Name")}
                      value={this.state.paytraceUserName2}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={this.state.mId2.trim() != '' ? true : false}
                      id="paytracePassword2"
                      error={this.state.error.paytracePassword2Error}
                      type="password"
                      labels={t("Password")}
                      value={this.state.paytracePassword2}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                    <label>Paytrace key file    {(this.state.paytraceKey && this.state.paytraceKey.length == 2 && this.state.paytraceKey[1] != '') ? <img alt="enable" className={classes.rightImg} src={config.APPURL + '/assets/images/checkmark-24.png'} /> : ''}
                    </label>
                    <input type="file" name="paytraceKey2" onChange={(e) => this.uploadKey2(e, t)} />
                  </Grid>
                  <Grid item xs={6} sm={3}></Grid>
                </> : ''
              }

              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.merchantIdError}
                  id="merchantId"
                  type="text"
                  labels={t("Titanium Merchant Id")}
                  value={this.state.merchantId}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: '1.3rem' }}>
                <Textfield
                  id="notes"
                  type="text"
                  labels={t("Notes")}
                  value={this.state.notes}
                  width={true}
                  rowsMax={"2"}
                  multiline={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Save")} />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={(e) => this.goBack(e)}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.LocationReducer
  };
}
export default connect(mapStateToProps, { CompanyDetailById, locationAdd, countryDataList })(withTranslation()(withRouter(AddLocationsCompanyDetails)));
