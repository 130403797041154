import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from './ForgotPassword.module.scss';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { DataApi } from './../../helpers/PostDataApi';
import { config } from './../../constants';
import validator from '../../helpers/checkValidations';

class CheckEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            successMessages: false,
            successDisplay: false,
            email: '',
            emailError:'',
            error: {
                emailError: false
            },
        }
    }


    handleChange(event,t) {
        let error = {};
        let emailError = false;
        let { id, value } = event.target;
        let successMessages = false;
        (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false) 
        this.setState({ [id] : value ,emailError,successMessages,error});
    }
    handleSubmit(e, t) {
        let misMatchError = [];
        let successMessages = false;
        let state = Object.assign({}, this.state);
        if (!state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            misMatchError[0] = t("EmailNotValid");
            this.setState({ emailError: true, misMatchError, successMessages });
        } else {
            DataApi(state, config.BASEURL + 'verifyEmail').then((response) => {
                
                if (response.status === 200 && response.data.statusCode === 200 && response.data.message !="") {
                    let { status } = response;
                    if (status === 200) {
                        this.setState({ successMessages: true, successDisplay: t("ResetConfirmLink") });
                    }
                    this.props.onChange(true, state.email);
                } else {
                    misMatchError[0] = t("EmailNotExist");
                    this.setState({ emailError: true, misMatchError, successMessages });
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div >
                {this.state.successMessages ?
                    <SuccessMessage successes={[this.state.successDisplay]} /> : ''}
                {/* <Container component="main" maxWidth="sm" > */}
                {/* <div className={classes.paper}> */}
                <div>
                    <form className={classes.form} noValidate>
                        <Textfield
                            required={true}
                            error={this.state.error.emailError}
                            id="email"
                            type="email"
                            labels={t("Email")}
                            value={this.state.email}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            focus={true} />
                        <Buttons
                            id="checkEmail"
                            type="button"
                            width={true}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => this.handleSubmit(e, t)}
                            text={t("ResetPassword")} />
                    </form>
                </div>
                {/* </Container> */}
                {this.state.emailError ?
                    < ErrorMessage errors={[this.state.misMatchError]} /> : ''
                }
            </div>
        );
    }
}

CheckEmail.propTypes = {
    t: PropTypes.func
}
export default withTranslation()(CheckEmail);

