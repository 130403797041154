import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import classes from './ForgotPassword.module.scss';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { DataApi } from './../../helpers/PostDataApi';
import { config } from './../../constants';
import { connect } from 'react-redux';
import { Empty} from '../../actions/ForgotPasswordAction';
import {withRouter} from "react-router";
// import { login } from './../../actions/LoginAction';
// import { forget } from './../../actions/ForgotPasswordAction';
// import { CallEnd, CasinoOutlined } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';

class ResetPassword extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            email: props.email,
            TimeOutdiff: props.TimeOutdiff,
            token:props.token,
            password: '',
            confirmPassword: '',
            misMatchError: [],
            successMessages: false,
			successDisplay:false,
            ResetClickbuttonChange:false,            
            pswStrength: {status:'', noPresent: false, symbolPresent: false, upCasePresent: false, lowCasePresent: false, pswLength: 0, show: false},
            error: {
                emailError: false,
                passwordError: false,
                confirmPasswordError: false
            }
        }
    }
    componentDidMount() {
        const password = document.querySelector('#password');
        password.addEventListener('focusout', (event) => {
          this.setState({pswStrength: {...this.state.pswStrength,  show: false} });
        });
    }
       
       
      
    handleChange(event, t) {
        let { id, value } = event.target;
        let { password, confirmPassword } = this.state;
        this.setState({ [id]: value });
        let misMatchError = [];
        let successMessages = false;

        let pswStrength = this.state.pswStrength;
        pswStrength.noPresent = /\d/.test(value);
        pswStrength.symbolPresent = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);;
        pswStrength.upCasePresent = /[A-Z]/.test(value);
        pswStrength.lowCasePresent = /[a-z]/.test(value);
        pswStrength.pswLength = value.length ;
    
        console.log(value, 'Here is ========>',pswStrength, /\d/.test(value));
        console.log(pswStrength.noPresent && pswStrength.symbolPresent && pswStrength.upCasePresent && pswStrength.lowCasePresent && pswStrength.pswLength > 7, " pswStrength.noPresent && pswStrength.symbolPresent && pswStrength.upCasePresent && pswStrength.lowCasePresent && pswStrength.pswLength > 7 ");
        console.log(pswStrength.noPresent && pswStrength.lowCasePresent && pswStrength.pswLength > 5, " pswStrength.noPresent && pswStrength.lowCasePresent && pswStrength.pswLength > 5 ");
         
        if (pswStrength.noPresent && pswStrength.symbolPresent && pswStrength.upCasePresent && pswStrength.lowCasePresent && pswStrength.pswLength > 7) {
          pswStrength =  {...this.state.pswStrength,  status: 'Strong', show: false };
        }else if(pswStrength.noPresent && pswStrength.lowCasePresent && pswStrength.pswLength > 5){
          pswStrength =  {...this.state.pswStrength,  status: 'Weak', show: true }; 
        } else {
          pswStrength =  {...this.state.pswStrength,  status: 'Very Weak', show: true };  
        }

        if ((id === 'confirmPassword' && password !== event.target.value) || (confirmPassword &&  id === 'password' && confirmPassword !== value)) {
            misMatchError[0] = t("MisMatchPassword");
            this.setState({ pswStrength, misMatchError, successMessages });
        } else {
            this.setState({ pswStrength, misMatchError, successMessages });
        }
    }

    handleChangeConfirm(event, t) {
        let { id, value } = event.target;
        let { password, confirmPassword } = this.state;
        this.setState({ [id]: value });
        let misMatchError = [];
        let successMessages = false;

        if ((id === 'confirmPassword' && password !== event.target.value) || (confirmPassword && id === 'password' && confirmPassword !== value)) {
            misMatchError[0] = t("MisMatchPassword");
            this.setState({ misMatchError, successMessages });
        } else {
            this.setState({ misMatchError, successMessages });
        }
    }
    
   
    handleLoginPageRedirect(e,t){  
        this.props.history.push('/login',this.state.email);
        this.setState({email:this.state.email});     
    }

    handleSubmit(e, t) {
        let misMatchError = [];
        let successMessages = false;
        let state = Object.assign({},this.state);         
        let error = {};
        let errorFlag = false;

        if(this.state.pswStrength.status !== 'Strong'){
            misMatchError[0] = t("Password Should be Strong!");
            this.setState({ misMatchError, error, successMessages });
            return false;
        } 

		Object.keys(state).map((key, value) => state[key] === '' ? (error[key + 'Error'] = true, errorFlag = true) : '');
        
        if (errorFlag) {
            this.setState({ misMatchError, error, successMessages });
            return false;
        } else {
              DataApi(state, config.BASEURL + 'resetPassword').then((response) => {
				let {status, data} = response;
				if(status === 200 ){
				 this.setState({ successMessages:true, ResetClickbuttonChange:true, successDisplay:data.message});
                }              
            }).catch((err) => {
                console.log(err);
            });  
        }
    }

    render() { 
        const { t } = this.props;
        
        return (
            <div>
              
                <Container component="main" maxWidth="sm" >
                  
                    <div >
                    {(this.state.TimeOutdiff>5) ? <span>link has been expired in 5 minutes  </span> :  
                       <form className={classes.form} noValidate>
                        
                            <Textfield
                                required={true}
                                error={this.state.error.emailError}
                                id="email"
                                type="email"
                                labels={t("Email")}
								disabled={true}
                                value={this.state.email}
                                width={true}
                                onChange={(e) => this.handleChange(e,t)}
                                focus={false} />
                            <Textfield
                                required={true}
                                error={this.state.error.passwordError}
                                id="password"
                                type="password"
                                labels={t("Password")}
                                value={this.state.password}
                                width={true}
                                onChange={(e) => this.handleChange(e, t)}
                                focus={false} />
                            <div className='pswfield'>
                                {(this.state.password.length > 0 )? <div className='instruction-section'>
                                    {this.state.pswStrength.status === 'Very Weak' ?
                                    <b className='red'>Very Weak</b> : ''
                                    }
                                    {this.state.pswStrength.status === 'Weak' ?
                                    <b className='red'>Weak</b> : ''
                                    }
                                    {this.state.pswStrength.status === 'Strong' ?
                                    <b className='green'>Strong</b> : ''
                                    }

                                    { this.state.pswStrength.show === true ?
                                    <>
                                    <h4>Strong Password Tips : </h4>
                                    <div className="instructionsview">
                                        {this.state.pswStrength.pswLength > 8 ? <CheckIcon className='check-icon'  /> :'-'} At Least 8 Characters</div>
                                    <div className="instructionsview">{this.state.pswStrength.noPresent == true? <CheckIcon className='check-icon' /> :'-'} At Least One Number
                                    </div>
                                    <div className="instructionsview">
                                        {this.state.pswStrength.symbolPresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Special Character
                                    </div>
                                    <div className="instructionsview">
                                        {this.state.pswStrength.upCasePresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Upper Case Letter
                                    </div>
                                    <div className="instructionsview">
                                        {this.state.pswStrength.lowCasePresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Lower Case Letter
                                    </div>                          
                                </>
                                : ''}

                                </div>  
                                : ''}
                            </div>

                            <Textfield
                                required={true}
                                error={this.state.error.confirmPasswordError}
                                id="confirmPassword"
                                type="password"
                                labels={t("Confirm Password")}
                                value={this.state.confirmPassword}
                                width={true}
                                onChange={(e) => this.handleChangeConfirm(e, t)}
                                focus={false} />

                      {this.state.ResetClickbuttonChange===true?
                              <Buttons
                                id="resetPassword"
                                type="button"
                                width={true}
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                // onClick={event =>  window.location.href= 'http://localhost:3006/login'}
                                 onClick={event => this.handleLoginPageRedirect(event,t) }
                                // text={t("ResetPassword")}
                                text= { t("ClickHereTOLogin") }
                                
                                 /> :
                                 <Buttons
                                id="resetPassword"
                                type="button"
                                width={true}
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => this.handleSubmit(e, t)}
                                // text={t("ResetPassword")}
                                text= { t("ResetPassword") }
                                
                                 />
}

                            
                        </form>}
                    </div>
                </Container>
                {this.state.misMatchError.length > 0 ?
                    < ErrorMessage errors={[this.state.misMatchError]} /> : ''
                }
				{this.state.successMessages ?
			  <SuccessMessage successes={[this.state.successDisplay]} /> : ''
			}
            </div>
        );
    }
}

ResetPassword.propTypes = {
    t: PropTypes.func

}
const mapStateToProps = state => { return state};
 
export default connect(mapStateToProps, { Empty})(withTranslation()(withRouter(ResetPassword)));
 
