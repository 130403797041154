const getStringTextFromComponent = (obj)=>{ 
    switch(typeof obj){
      case 'string': return obj; 
      case 'number': return obj;
      case 'boolean': return obj;
      case 'object': return obj.props && obj.props.children &&  getStringTextFromComponent(obj.props.children) 
      break;
      case 'function': //console.log('obj',typeof obj()); return obj(); break; 
      return getStringTextFromComponent(obj());
      default : return '';
    }
  }

  export const MuiDataTableCustomeSearch = (searchQuery,currentRow, columns)=>{
    let found = false;
    columns.forEach((column,i)=>{
      console.log('typeof ', column.name,  getStringTextFromComponent(currentRow[i]));
      if(!found && searchQuery){ 
        if(String(getStringTextFromComponent(currentRow[i])).trim().match(searchQuery)) found = true;
      }
    });  

    return found;
  }