import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import FormControl from '@material-ui/core/FormControl';
import TypableSelect from './../../helpers/TypableSelect';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { addUser } from '../../actions/AdminUserAction'
import { AllCompany ,countryDataList} from '../../actions/CompanyAction'
import config from './../../constants/config'


const mapToFormatCountry = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  
  return Object.sort().keys(state).map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, label: name }
  ))
};

 
const mapToFormat = (values) => {
  const res = [];
  for (let i = 0; i < values.length; i++) {
    res.push({ value: values[i]['_id'], label: values[i]['dbaName'] });
  }
  return res;
};
const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};

class AddAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      role: config.ADMIN_ROLE,
      sideShow: true,
      isLoading: true,
      isLocation: false,
      company: '',
      companyData: '',
      misMatchError: [],
      creatNewUser: false,
      allFeatures: false,
      refunds: false,
      allLocaton: false,
      isSubmit: false,
      location: {
        locationName: '',
        email: '',
        phone: '',
        contactName: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        timeZone: new Date(),
        merchantId: '',
        terminalId: '',
        terminalNumber: '',
        batchItem: '',
      },
      stateList: {},
      cityList: [],
      Countrydata: [],
      locationError: {
        nameError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      },
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
    }
  }


  componentDidMount() {
    this.getCompanyData()
    this.getcountryDataList()
  }
  getcountryDataList() {
    this.setState({ isLoading: true })
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }
  getCompanyData() {

    this.setState({ isLoading: true })
    this.props.AllCompany().then((res) => {
      if (res.payload.companyData.status === 200)
        this.setState({ companyData: res.payload.companyData.data.response, isLoading: false })
    });
  }
  addNewLocation(e) {
    if (this.state.isLocation)
      this.setState({ isLocation: false });
    else
      this.setState({ isLocation: true });
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }
  setCompanyState(val) {
    if (val !== null)
      this.setState({ company: val, location: { ...this.state.location, merchantId: val.value } })
  }
  handleChangeLocation(event, t) {

    let error = {}
    let locationError = {}
    let misMatchError = [];
    let state = this.state.location
    let { id, value } = event.target;
    this.setState({ isSubmit: false, locationError })

    if (event.target.id === "email") {
      if (state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
        Object.keys(state).map((key, value) => (state['email'] !== '') ? (locationError['emailError'] = true) : '');
      } else {
        Object.keys(state).map((key, value) => (state['email'] !== '') ? (locationError['emailError'] = false) : '');
      }
    }
    this.setState({ location: { ...this.state.location, [id]: value }, error, misMatchError });
  }
  handleChange(event, t) {

    let error = {}
    let misMatchError = [];
    let state = this.state;
    let { id, value } = event.target;
    this.setState({ isSubmit: false })
    if (event.target.id === "email") {
      if (state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
        Object.keys(state).map((key, value) => (state['email'] !== '') ? (error['emailError'] = true) : '');
      } else {
        Object.keys(state).map((key, value) => (state['email'] !== '') ? (error['emailError'] = false) : '');
      }
    }
    this.setState({ [id]: value, error, misMatchError });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let locationState = this.state.location
    let state = this.state;
    let error = {};
    let locationError = {};
    let errorFlag = false;
    this.setState({ isSubmit: true })
    if (locationState.address2 === '') {
      delete locationState.address2;
    }
    if (this.state.isLocation) {
      // console.log(this.state);
      Object.keys(locationState).map((key, value) =>
        locationState[key] === '' ? (locationError[key + 'Error'] = true, errorFlag = true) : ''
      );
    }
    Object.keys(state).map((key, value) => state[key] === '' ? (error[key + 'Error'] = true, errorFlag = true) : '');

    if (state.email !== '' && !state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      misMatchError[0] = t("EmailNotValid");
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, locationError, successMessages });
      return false;
    } else {
      this.props.addUser(this.state).then(result => {
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true },()=>{
            this.props.history.goBack();
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });

    }

  }
  setCountryState(val) {
    if (val !== null)
      this.setState({ stateList: this.state.Countrydata.filter(obj=>(obj.Country === val.label))[0].State,
        location: { ...this.state.location, country: val.label }})
  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({ cityList: this.state.stateList[val.label] ,
        location: { ...this.state.location, state: val.label }})
  }
  setCitySet(val) {
    if (val !== null)

      this.setState({ location: { ...this.state.location, city: val.label }})
  }

  render() {
    const { t } = this.props;
    const { companyData,Countrydata } = this.state;
    
    return (

      <div className="rightPanel">
        <Topheader />
        <Container component="main" maxWidth="xl" >
          {this.state.misMatchError.length > 0 ?
            < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {this.state.successMessages ?
            <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
          }

          <form className={classes.form} noValidate>
            <Grid container spacing={3} >
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t("Username")}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t("First Name")}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t("Last Name")}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={3}>

              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '2.8rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.company && this.state.isSubmit)} >

                  <TypableSelect
                    placeholder={'DBA Name *'} suggestions={mapToFormat(companyData)}
                    onChange={val => this.setCompanyState(val)}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>


              {this.state.isLocation ?
                <>
                  <Grid item xs={12}><b>Location Details</b></Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.locationNameError}
                      id="locationName"
                      type="text"
                      labels={t("Location Name")}
                      value={this.state.location.locationName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.contactNameError}
                      id="contactName"
                      type="text"
                      labels={t("Contact Name")}
                      value={this.state.location.contactName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.emailError}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.location.email}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.phoneError}
                      id="phone"
                      type="number"
                      labels={t("Phone")}
                      value={this.state.location.phone}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.address1Error}
                      id="address1"
                      type="text"
                      labels={t("Address1")}
                      value={this.state.location.address1}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield

                      id="address2"
                      type="text"
                      labels={t("Address2")}
                      value={this.state.location.address2}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>

                  <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.location.country && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select country name'} suggestions={mapToFormatCountry(Countrydata)}
                    onChange={val => this.setCountryState(val)}
                    isClearable={true}
                  />
                </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.location.state && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select state name'} suggestions={mapToFormatStates(this.state.stateList)}
                    onChange={val => this.setStatesSet(val)}
                    isClearable={true}
                  />
                </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.location.city && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select city name'} suggestions={mapToFormatCity(this.state.cityList)}
                    onChange={val => this.setCitySet(val)}
                    isClearable={true}
                  />
                </FormControl>
                  </Grid>
                  
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.zipError}
                      id="zip"
                      type="text"
                      labels={t("Zip")}
                      value={this.state.location.zip}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.merchantIdError}
                      id="merchantId"
                      type="text"
                      labels={t("Merchant Id")}
                      value={this.state.location.merchantId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalIdError}
                      id="terminalId"
                      type="text"
                      labels={t("Terminal Id")}
                      value={this.state.location.terminalId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalNumberError}
                      id="terminalNumber"
                      type="text"
                      labels={t("Terminal Number")}
                      value={this.state.location.terminalNumber}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.batchItemError}
                      id="batchItem"
                      type="text"
                      labels={t("Batch Item")}
                      value={this.state.location.batchItem}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    
                  </Grid>
                </>
                : ''}

              <Grid item xs={12} >

                <Grid item xs={6} sm={3} justify="space-evenly">
                  <Checkbox
                    id="creatNewUser"
                    labels="Create New User"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.creatNewUser}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Create New User</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    id="allFeatures"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.allFeatures}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>All Features</span>

                  </label>
                </Grid>
                <Grid >
                  <Checkbox
                    id="refunds"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.refunds}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Refunds</span>
                  </label>
                </Grid>
                <Grid >
                  <Checkbox
                    id="allLocaton"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.allLocaton}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>All Location</span>

                  </label>
                </Grid>
              </Grid>
              <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t("Save")} />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="secondary"
                text={t("Back")}
                onClick={this.props.history.goBack} />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color={(!this.state.isLocation) ? "primary" : "secondary"}
                text={(!this.state.isLocation) ? t("AddNewLocation") : t("RemoveNewLocation")}
                onClick={(e) => this.addNewLocation(e, t)} />
            </Grid>
          </form>
        </Container>
      </div>


    )
  }
}
AddAdmin.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { addUser, AllCompany,countryDataList })(withTranslation()(AddAdmin));
