import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Home.module.scss';
import { getContentByPage } from '../../actions/ContentAction';
import { config } from '../../constants';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            responseError: false,
            customFields: [],
            display: false,
            displayEnt: false,
            displayPro: false,
            displayFour: false,
        }
    }
    componentDidMount() {
        this.getContentData();
        // localStorage.removeItem('token');
    }

    getContentData() {
        const params = { 'pageName': 'Home' };
        this.setState({ isLoading: true });
        this.props.getContentByPage(params).then((res) => {
            this.setState({ ...res.payload.contentData.data.response, isLoading: false }, () => {
            })
        });
    }
    toggleLi(e) {
        if (this.state.display) {
            this.setState({ display: false });
        } else {
            this.setState({ display: true });
        }
    }

    toggleLiTwo(e) {
        if (this.state.displayEnt) {
            this.setState({ displayEnt: false });
        } else {
            this.setState({ displayEnt: true });
        }
    }
    
    toggleLiThree(e) {
        if (this.state.displayPro) {
            this.setState({ displayPro: false });
        } else {
            this.setState({ displayPro: true });
        }
    }

    render() {
        const newCate = [];
        const newContent = [];
        this.state.customFields.map((item) => {
            item.map((vv) => {
                if (vv.label === "CategoryName") {
                   let aa = [vv.value];
                   return newCate.push(aa);
                }
                if (vv.label === "CategoryContents") {
                    return newContent.push(vv.value);
                }
            });
        })
        return (
            <div className={classes.mainWrapper}>
                <div className={classes.container}>
                    <header className={classes.headerPanel}>
                        <div className="logo">
                            <img src={config.APPURL + '/assets/images/home-logo.png'} />
                        </div>
                        <div className={classes.topmenu}>
                            <ul>
                                <li>
                                    <Link component={RouterLink} to={'/'}>Home</Link>
                                </li>
                                <li>
                                    <Link component={RouterLink} to={'/Login'}>Login</Link>
                                </li>
                            </ul>
                        </div>
                    </header>
                </div>
                <div className={classes.bannerWrapper}>

                    <div className={classes.videobox} >
                        <video id="vid" loop autoPlay muted controls>
                            <source src={config.APPURL + "/assets/video/1025610638-hd.mp4"} type="video/mp4" />
                        </video>
                    </div>
                    <div className={classes.container} >
                        <div className={classes.bannerContent}>
                            <h1 className={classNames([classes.bannerHeading, 'banner-heading'])}>
                                {(this.state.pageTitle) ? this.state.pageTitle.split(',').map((res,i) => {
                                    return (<span key={i}>{res}<br></br></span>)
                                }) : ''}
                            </h1>
                        </div>

                    </div>
                </div>
                <div className={classes.infoWrapper}>
                    <div className={classes.container}>
                        <div className={classes.infoContentBox}>
                            <div className={classes.infoLeftPanel}>
                                <div className={classes.infoImg}>
                                    <img src={config.APPURL + '/assets/images/ACH credit options.png'} style={{height:'263px'}}/>
                                </div>
                                <div className={classes.infoDetails}>
                                    <h2>Seamless Payment Options</h2>
                                    <p>With integrated Credit and ACH payment methods, your customers can choose their preferred way to pay with the click of a button. Save time and money by passing the credit card fee and offering ACH as a way to save.</p>
                                </div>
                            </div>
                            <div className={classes.infoRightPanel}>
                                <div className={classes.infoImg}>
                                    <img src={config.APPURL + '/assets/images/custom invoice SS.PNG'} />
                                </div>
                                <div className={classes.infoDetails}>
                                    <h2>Speed & Simplicity</h2>
                                    <p>Allow Titanium Flex to streamline your complex quoting and invoicing. Embed your current invoicing system or build something from scratch. We can connect to countless APIs, quoting systems and data sources to make your work flow optimized for growth!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.flexOptionWrapper}>
                    <h1>{this.state.categoryTitle}</h1>
                    <div className={classes.container}>
                        <div className={classes.choosePlanWrapper}>
                            {this.state.customFields.map((field, idx) => {
                                return (
                                    <div className={classNames([classes.block, classes.one])} key={idx}>
                                        <div key={`${field}-${idx}`}>
                                            {field.map((data, id) => {
                                                return (
                                                    <div key={id} >
                                                        {(data.label === "CategoryName") ? <div className={classNames([classes.headSection, (idx === 0) ? classes.blueBg : (idx === 1) ? classes.darkBlue : classes.skyBlue])}>
                                                            <h2>{(data.label === "CategoryName") ? data.value : ''}</h2>
                                                        </div> : ''}
                                                        {(data.label === "CategoryAmount") ? <div className={classes.paymentDetails}>
                                                            <h2> <span className={classes.pricetxt}>{(data.label === "CategoryAmount") ? '$' + data.value : ''}</span></h2>
                                                            <p className={classes.permonthtxt}>per month</p>
                                                        </div> : ''}

                                                        {(data.label === "CategoryContents") ? <ul className={classes.listItem}>
                                                            {(data.label === "CategoryContents") ? data.value.split(',').map((row,i) => {
                                                                return (<li key={i}>{row}</li>
                                                                )
                                                            }) : ''}
                                                            {(idx==1)?
                                                                     <li className={classes.expendColor} onClick={(e) => this.toggleLi(e)}><a >+{newCate[idx-1]}</a>
                                                                        <ul className={(this.state.display) ? classes.listItemInnerBlock : classes.listItemInner}>
                                                                        {(newContent[idx-1])?newContent[idx-1].split(',').map((lOne,k1)=>{
                                                                            return(<li key={k1}>{lOne}</li>)
                                                                        }):''}
                                                                        </ul>
                                                                    </li>:(idx>1)?<><li className={classes.expendColor} onClick={(e) => this.toggleLiTwo(e)}><a >+{newCate[idx-1]}</a>
                                                                        <ul className={(this.state.displayEnt) ? classes.listItemInnerBlock : classes.listItemInner}>
                                                                        {(newContent[idx-1])?newContent[idx-1].split(',').map((lOne,k2)=>{
                                                                            return(<li key={k2}>{lOne}</li>)
                                                                        }):''}
                                                                        </ul>
                                                                    </li>
                                                                    <li className={classes.expendColor} onClick={(e) => this.toggleLiThree(e)}><a >+{newCate[0]}</a>
                                                                        <ul className={(this.state.displayPro) ? classes.listItemInnerBlock : classes.listItemInner}>
                                                                        {(newContent[0])?newContent[0].split(',').map((lOne,k3)=>{
                                                                            return(<li key={k3}>{lOne}</li>)
                                                                        }):''}
                                                                        </ul>
                                                                    </li>
                                                                    </>:''}
                                                        </ul> : ''}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={classes.footerWrapper}>
                    <a href="#" >Copyright by Titanium Payment</a>
                </div>

            </div>
        );
    }
}

export default connect(null, { getContentByPage })(withTranslation()(Home));