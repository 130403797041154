import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Customer info Begin Action
 */
export const ticketStart = () => ({
    type: ActionType.TICKET_START
});

/**
* Customer info Success Action
*/
export const ticketSuccess = ticketData => {
    return {
        type: ActionType.TICKET_SUCCESS,
        payload: { ticketData }
    }
};

/**
* Customer info Failure Action
*/
export const ticketError = error => ({
    type: ActionType.TICKET_ERROR,
    payload: { error }
});

/**
 * customer driver info
 */
export const addTicket = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(ticketStart());
        return DataApi(data, config.BASEURL + 'addTicket',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(ticketSuccess(response))
            } else {
                return dispatch(ticketError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const editTicket = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(ticketStart());
        return DataApi(data, config.BASEURL + 'editTicket',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(ticketSuccess(response))
            } else {
                return dispatch(ticketError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const ticketList = (data) => {

    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(ticketStart());
        return DataGetApi(config.BASEURL + 'ticketList',data,token).then((response) => {
            if (response.status === 200) {
                return dispatch(ticketSuccess(response))
            } else {
                return dispatch(ticketError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const ticketDetailsId = (data) => {

    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(ticketStart());
        return DataGetApi(config.BASEURL + 'ticketDetailsId',data,token).then((response) => {
            if (response.status === 200) {
                return dispatch(ticketSuccess(response))
            } else {
                return dispatch(ticketError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
