import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';
/**
 * user info Begin Action
 */
export const userStart = () => ({
    type: ActionType.USER_START
});
/**
* user info Success Action
*/
export const userSuccess = userData => {
    return {
        type: ActionType.USER_SUCCESS,
        payload: { userData }
    }
};
/**
* user info Success Action
*/
export const userEditData = userData => {
    return {
        type: ActionType.USER_EDIT_DATA,
        payload: { userData }
    }
};
/**
* fetch info Success Action
*/
export const fetchSuccess = (userData) => {
    return {
        type: ActionType.FETCH_SUCCESS,
        payload: { userData }
    }
};
/**
* user info Failure Action
*/
export const userError = error => ({
    type: ActionType.USER_ERROR,
    payload: { error }
});
/**
 * user driver info
 */
export const addUser = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'userAdd').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const addSubAdmin = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'addSubAdmin').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const regularUserAdd = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'regularUserAdd').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const adminUserList = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'adminUserList', data, token).then((response) => {
            if (response.status === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const getUserList = (data) => {
   
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'getUserList', data, token).then((response) => {
            if (response.status === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const adminUserTableList = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'adminUserTableList', data, token).then((response) => {
            if (response.status === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const subAdminUserList = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'subAdminUserList', data, token).then((response) => {
            if (response.status === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response.response.data));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const UserDetailById = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    let locationId = '';
    let midType = "";
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    if(localStorage.getItem('locationPaydetails')!== null){
        midType = JSON.parse(localStorage.getItem('locationPaydetails')).midType;
    }
    return dispatch => {
        dispatch(userStart());
        // return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, 'locationId': locationId }, token).then((response) => {
        return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, 'locationId': locationId, 'midType': midType }, token).then((response) => {
            if (response.status === 200) {
                return dispatch(userSuccess(response));
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const UserDetailForCustomMiddleWare = (data) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    return dispatch => {
        dispatch(userStart());
    };
}

export const fetchDetails = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    let locationId = '';
    let midType = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    if(localStorage.getItem('locationPaydetails')!== null){
        midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
    }
    // return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, 'locationId': locationId }, token).then((response) => {
    return DataGetApi(config.BASEURL + 'adminUserDetailById', { ...data, 'locationId': locationId , 'midType': midType }, token).then((response) => {
         
        if (response.status === 200) {
            return fetchSuccess(response.data);
        } else {
            return userError(response);
        }
    }).catch((err) => {
        console.log(err);
    });
}
export const adminUserProfile = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'adminUserProfile').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const UserEdit = (data) => {
    return dispatch => {
        return DataApi(data, config.BASEURL + 'adminUserEdit').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userEditData(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const UserResendActivationLink = (email) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi({ 'email': email }, config.BASEURL + 'resendUserActivationLink').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userEditData(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const adminUserDelete = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'adminUserDelete').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const adminUserStatus = (data) => {
    return dispatch => {
        // dispatch(userStart());
        return DataApi(data, config.BASEURL + 'adminUserStatus').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const UserDetailByLink = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'adminUserDetailByLink', data).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const userSettings = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    return dispatch => {
        dispatch(userStart());
        return DataApi({ ...data, 'locationid': locationId }, config.BASEURL + 'userSettings', token, true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const settingDataByCompanyId = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'settingDataByCompanyId', data, token).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const settingDataByCompanyIdmId = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(userStart());
        return DataGetApi(config.BASEURL + 'settingDataByCompanyIdmId', data, token).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
/**
* update default location
 * 
 * @param {Object} data
 */
export const updateDefaultLocation = (data) => {
    return dispatch => {
        dispatch(userStart());
        return DataApi(data, config.BASEURL + 'updateDefaultLocation').then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(userSuccess(response))
            } else {
                return dispatch(userError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
