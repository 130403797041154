import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { addTicket, ticketDetailsId, editTicket } from '../../actions/TicketAction'
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Tickets.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import config from './../../constants/config'
import validator from '../../helpers/checkValidations';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Redirect } from 'react-router-dom';
class AddTickets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      DATETIME: new Date(),
      misMatchError: [],
      firstName: '',
      lastName: '',
      createdBy: '',
      companyId: '',
      email: '',
      phone: '',
      finalStatus: 'Pending',
      issue: '',
      redirectTickets: false,
      error: {
        phoneError: false,
        issueError: false,
        firstNameError: false,
        emailError: false,
      },
    }
  }

  componentDidMount() {
    let ticket = this.props.location.search.split('=')
    if (ticket.length === 2) {
      this.setState({ 'update': true })
      this.getTicketData()
    }
    let userData = JSON.parse(localStorage.getItem('user'))
    this.setState({ createdBy: userData._id, companyId: (userData.company) ? userData.company._id : '' })
  }
  getTicketData() {
    let ticket = this.props.location.search.split('=')
    const params = { 'id': ticket[1] }
    this.setState({ isLoading: true })
    this.props.ticketDetailsId(params).then((res) => {
      this.setState({ ...res.payload.ticketData.data.response, isLoading: false }, () => {
      })
    });
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {

    e.preventDefault();
    let misMatchError = [];
    let error = {}
    let state = this.state
    let successMessages = false;
    let errorFlag = false;
    if (this.state.lastName == "") {
      delete this.state.lastName
    }    
    this.setState({ isSubmit: true })
    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      console.log('err', errorFlag, error)
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to raise the ticket?')) {

        this.setState({ isLoading: true })
        this.props.addTicket(this.state).then(result => {

          if (result !== undefined && result.type === 'TICKET_SUCCESS') {

            this.setState({ isLoading: false, successMessages: true, misMatchError, redirectTickets: true }, () => {
              // setTimeout(() => {
              //   this.props.history.goBack()
              // }, 2000)
              // setTimeout(function () {
              //   window.location.href = config.APPURL + "/Tickets"
              // }, 2000)
            });
          }

          if (result !== undefined && result.type === 'TICKET_ERROR') {
            this.setState({ misMatchError: this.props.info.ticketInfo.data.message, successMessages });
          }
        });
      }
    }

  }
  handleSubmitUpdate(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let error = {}
    let state = this.state
    let successMessages = false;
    let errorFlag = false;

    this.setState({ isSubmit: true })
    Object.keys(state).map((key, value) => {
      if (key === "lastName" && state['lastName'] === "") {
        return false;
      }
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to update the ticket?')) {

        this.setState({ isLoading: true })
        this.props.editTicket(this.state).then(result => {

          if (result !== undefined && result.type === 'TICKET_SUCCESS') {

            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(function () {
                window.location.href = config.APPURL + "/Tickets"
              }, 2000)
            });
          }
          if (result !== undefined && result.type === 'TICKET_ERROR') {
            if (this.props.info.ticketInfo.error.data.response !== undefined) {
              let responseErrors = JSON.parse(this.props.info.ticketInfo.error.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            } else {
              window.location.reload()
            }
          }
        });
      }
    }

  }

  onChangeStatus(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.finalStatus === "Resolved") {
        this.setState({ status: true })
      }
      if (this.state.finalStatus === "Pending") {
        this.setState({ status: false })
      }
    })

  }

  render() {
    const { t } = this.props;

    return (
      <ErrorBoundary>
        <div className="rightPanel">
        {(this.state.redirectTickets) ? <Redirect to='/Tickets' /> : ''}
          <Topheader />
          <div className="contentPart">
            <LoadingOverlay
              active={this.state.isLoading}
              spinner
              text='Loading your content...'>
              <Container component="main" maxWidth="xl" >
                {this.state.misMatchError.length > 0 ?
                  < ErrorMessage errors={[this.state.misMatchError]} /> : ''
                }
                {this.state.successMessages ?
                  <SuccessMessage successes={[this.props.info.ticketInfo.data.message]} /> : ''
                }

                <form className={classes.form} noValidate>
                  {(this.state.update && this.state.ticketNumber !== 0) ? <h3 className=""><span>Ticket Number : {(this.state.ticketNumber) ? this.state.ticketNumber : ''}</span></h3> : ''}
                  <Grid container spacing={3} className="ViewTxnFormBox" >
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        required={true}
                        error={this.state.error.firstNameError}
                        id="firstName"
                        type="text"
                        labels={t("First Name")}
                        value={this.state.firstName}
                        onChange={(e) => this.handleChange(e, t)}
                        width={true} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="lastName"
                        type="text"
                        labels={t("Last Name")}
                        value={this.state.lastName}
                        onChange={(e) => this.handleChange(e, t)}
                        width={true} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        required={true}
                        error={this.state.error.emailError}
                        id="email"
                        type="email"
                        labels={t("Email")}
                        onChange={(e) => this.handleChange(e, t)}
                        value={this.state.email}
                        width={true} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        required={true}
                        error={this.state.error.phoneError}
                        id="phone"
                        type="text"
                        labels={t("Phone")}
                        value={this.state.phone}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Textfield
                        required={true}

                        error={this.state.error.issueError}
                        id="issue"
                        type="text"
                        labels={t("Issue Description")}
                        value={this.state.issue}
                        width={true}
                        multiline={true}
                        rows={"2"}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>

                    <Grid item xs={12} >
                      {(this.state.update && this.props.user.role === 1) ?
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Textfield
                              id="remarks"
                              type="text"
                              labels={t("Remarks")}
                              value={this.state.remarks}
                              width={true}
                              multiline={true}
                              rows={"2"}
                              onChange={(e) => this.handleChange(e, t)}
                            />
                            <Grid item xs={8} sm={6} className="taxSelect">
                              <label>Status</label>
                              <select id="finalStatus" onChange={(e) => { this.onChangeStatus(e) }}>
                                <option selected={this.state.finalStatus === "Resolved"} value={"Resolved"}>Resolved</option>
                                <option selected={this.state.finalStatus === "Pending"} value={"Pending"}>Pending</option>
                              </select>
                            </Grid>
                          </Grid>
                          <Grid item xs={8} sm={12}>
                            <Buttons
                              variant="contained"
                              color="primary"
                              text={t("Resolved")}
                              onClick={(e) => this.handleSubmitUpdate(e, t)} />
                            <Buttons
                              className={classes.ml_2}
                              variant="contained"
                              color="secondary"
                              text={t("Back")}
                              onClick={this.props.history.goBack} />
                          </Grid>
                        </Grid>
                        : <Grid item xs={12}>
                          {this.state.update ?
                            <Buttons
                              variant="contained"
                              color="primary"
                              text={t("Update")}
                              onClick={(e) => this.handleSubmitUpdate(e, t)} /> :
                            <Buttons
                              variant="contained"
                              color="primary"
                              text={t("Save")}
                              onClick={(e) => this.handleSubmit(e, t)} />
                          }
                          <Buttons
                            className={classes.ml_2}
                            variant="contained"
                            color="secondary"
                            text={t("Back")}
                            onClick={this.props.history.goBack} />
                        </Grid>}
                    </Grid>


                  </Grid>
                </form>
              </Container>

            </LoadingOverlay>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.TicketReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { ticketDetailsId, addTicket, editTicket })(withTranslation()(AddTickets));