import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { companyGetTermCondition } from '../../actions/CompanyAction'
import LoadingOverlay from 'react-loading-overlay';
import config from './../../constants/config'
import classes from './TermCondition.module.scss';
import classNames from 'classnames'

class TermCondition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      termCondition:'',
      image:'',
      dbaName:'',
    }
  }

   componentDidMount(){
    this.getTermConditions()
  }

  getText() {
    let data = { __html: this.state.termCondition }
    return data;
  }

  getTermConditions() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.companyGetTermCondition(params).then((res) => {
      let companyData = res.payload.companyData.data;
      if (companyData.success) {
        this.setState({ ...companyData.response, isLoading: false })
      }
    });
  }
 
  render() {
    return (
      <Container component="main" maxWidth={false} className="terminal-container-term">
        <LoadingOverlay
              active={this.state.isLoading}
              spinner
              text='Fetching your response ...'>
        <Grid item md={3}><img className={classNames([classes.termLogo,'imgtermLogo'])} alt="logo" src={(this.state.image) ? config.IMGURL + this.state.image : config.APPURL + '/assets/images/logo.png'} /></Grid>
        <Grid container  justify="center">
                            <div className={classes.companyName}>
                              {(this.state.dbaName) ? this.state.dbaName : ''}
                            </div>
                          </Grid>
                          <Grid container  justify="center"> <div className="termContentsPage" dangerouslySetInnerHTML={this.getText()} /></Grid>
      </LoadingOverlay>
      </Container>
    );
  }
}


export default connect(null,{ companyGetTermCondition })(TermCondition); 