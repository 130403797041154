import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './locales/en/translation';
import EN_US from './locales/en-US/translation';
import ES from './locales/es/translation';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // debug: process.env.NODE_ENV === 'development',
    debug: false,

    interpolation: {
      escapeValue: false,
      format: function(value, format) {
        if (format === 'bold') return `<b>${value}</b>`
        return value;
      }
    },

    react: {
      useSuspense: false,
    },

    resources: {
      en: {
        translation: EN
      },
      "en-US": {
        translation: EN_US
      },
      es: {
        translation: ES
      }
    }
  });

export default i18n;