import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Textfield } from './../../components/Textfield';
import { InvoiceDetailById } from '../../actions/PaymentAction'
import { addInvoice } from '../../actions/RequestFormAction'
import { customerList } from '../../actions/CustomerAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { formatAmount, formatCurrency, userRole } from '../../helpers/commonFunction';
import TextField from '@material-ui/core/TextField';
import { Table } from '../../components/Table'
import { Typography } from '@material-ui/core';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
  },
});

function createData(Item, Description, UnitCost, Quantity, LineUnit) {
  return { Item, Description, UnitCost, Quantity, LineUnit };
}

const rows = [
  createData(<Textfield labels="Product" />, <Textfield labels="Description" />, <Textfield labels="Amount" />, <Textfield labels="Quantity" />),
];


class EditInvoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      isOpen: false,
      amount: '',
      invoice: '',
      isSubmit: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      transactions: '',
      successMessages: false,
      tabValue: 0,
      indexValue: 0,
      transitionDuration: {
        enter: this.props.theme.transitions.duration.enteringScreen,
        exit: this.props.theme.transitions.duration.leavingScreen,
      },
      error: {
        firstNameError: false,
        emailError: false,
        amountError: false,
        phoneError: false,
      }
    }
    this.paymentRef = React.createRef();
  }


  componentDidMount() {
    this.getInvoiceData();
    // this.getCustomerList();
  }





  getInvoiceData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.InvoiceDetailById(params).then((res) => {
      this.setState({ ...res.payload.paymentData.data.response, isLoading: false }, () => {
      })
    });
  }

  // getCustomerList() {
  //   this.setState({ isLoading: true })
  //   this.props.customerList().then((res) => {
  //     this.setState({ CoustmerData: res.payload.customerData.data.response, isLoading: false })
  //   })
  // }

  handleChangeTab(e, val) {
    this.setState({ tabValue: val })
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date })
  }

  a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  render() {
    const { classes, t } = this.props
    const { CardData, CoustmerData, transactions , partialPayments } = this.state;

  

    if (transactions !== "") {
      var refundNotes = transactions.hasOwnProperty('REFUNDED') ? transactions.REFUNDED : [];
    }

    const columns = [
      { title: 'Transaction Id', field: 'Transaction Id' },
      { title: 'Amount', field: 'amount' },
      { title: 'Date', field: 'createdAt', render: (date) => (new Date(date.createdAt).toLocaleString()) },
      { title: 'Card No.', field: 'card_no' },
    ];
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          className={classes.tpTableFullW}
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <Container component="main" maxWidth="xl">
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''
            }

            <form className={classes.form} noValidate ref={this.myRef}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12} md={12}> /}
                <Grid item xs={12} sm={12} md={12}>

                  {this.state.STATUS === "PAID" ? <Alert severity="success">Paid invoice details.</Alert> : (this.state.STATUS === "UNPAID") ? <Alert severity="warning">Un-Paid invoice details.</Alert> : this.state.STATUS}
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="invoice"
                    type="text"
                    labels={t("Invoice")}
                    value={this.state.invoice}
                    width={true}
                    // onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    error={this.state.error.amountError}
                    id="amount"
                    type="text"
                    labels={t("Amount")}
                    value={ (this.state.totalAmount) ?  formatCurrency(this.state.totalAmount).replace("$", "") :   formatCurrency(this.state.total).replace("$", "")}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="firstName"
                    type="text"
                    labels={t("First Name")}
                    value={this.state.customerId ? this.state.customerId.firstName : ''}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="lastName"
                    type="text"
                    labels={t("Last Name")}
                    value={this.state.customerId ? this.state.customerId.lastName : ''}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="email"
                    type="text"
                    labels={t("Email")}
                    value={this.state.customerId ? this.state.customerId.email : ''}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="cardType"
                    type="text"
                    labels={t("Card Type")}
                    value={this.state.cardType}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="public_notes"
                    type="text"
                    labels={t("Public Notes")}
                    value={(refundNotes !== undefined) ? refundNotes.map(item => (
                      item.REFUND_BY.firstName + "   " + item.REFUND_BY.lastName + "   " + userRole(item.REFUND_BY.role) + "   " + new Date(item.REFUND_DATE).toLocaleDateString() + "   $" + parseFloat(item.amt).toFixed(2)
                    )).join('\n') + '\n' + (this.state.publicNotes !== undefined ? this.state.publicNotes : " ") : ' '}
                    width={true}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="private_notes"
                    type="text"
                    labels={t("Private Notes")}
                    value={this.state.privateNotes}
                    width={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="term_notes"
                    type="text"
                    labels={t("Term Notes")}
                    value={this.state.termNotes}
                    width={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="invoiceDate"
                    type="text"
                    labels={t("Invoice Date")}
                    value={this.state.invoiceDate}
                    width={true}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="timestamp_PAID"
                    type="text"
                    labels={t("Paid Date")}
                    value={this.state.timestamp_PAID}
                    width={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
             

                { typeof partialPayments !== "undefined" &&  partialPayments.length > 0 ? 
                  <Grid item xs={12} sm={12}>
                     <Typography variant="h6" style={{fontWeight:"400", fontSize: "1.05rem"}} > Partial  Invoices</Typography> 
                  </Grid> : "" }
                  {( typeof partialPayments !== "undefined" && partialPayments.length > 0 ) ? partialPayments.map((item, index)=>
                  
                   ( <div>
                      <Grid container spacing={3} >
                          <Grid item xs={12} sm={2}>
                            <Textfield
                              id="transactionId"
                              type="text"
                              labels={t("Transaction Number")}
                              value={ item.hasOwnProperty('transactionId') ? item.transactionId   : ""}
                              disabled={true}
                              width={true} />
                          </Grid>
                         
                      
                       
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="amount"
                            type="text"
                            labels={t("Amount")}
                            value={ item.hasOwnProperty('amount') ?  formatCurrency(item.amount) : "$0.00"}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="method"
                            type="text"
                            labels={t("Payment Method")}
                            value={ item.hasOwnProperty('method') ?  item.method : ""}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="maskCardNumber"
                            type="text"
                            labels={t("Mask Card Number")}
                            value={ item.hasOwnProperty('cardNumber') ?  item.cardNumber : ""}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="cardType"
                            type="text"
                            labels={t("Card Type")}
                            value={ item.hasOwnProperty('cardType') ? item.cardType : ""}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Rdate"
                            type="text"
                            labels={t("Date")}
                            value={ item.hasOwnProperty('createdAt') ?  new Date(item.createdAt).toLocaleString() : ""}
                            disabled={true}
                            width={true} />
                        </Grid>
                      </Grid>
                  </div>)
                  ):'' } 



              
            </Grid>
                <Grid item xs={6} sm={6} md={12}>
                  <Buttons
                    // className={classesI.ml_2}
                    className={'emailInvoiceTerminal'}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack} />
                </Grid>
                {/* </Grid> */}
              </Grid>
            </form>

          </Container>
        </LoadingOverlay>
      </div>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<EditInvoice theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer
  };
}

export default connect(mapStateToProps, { addInvoice, customerList, InvoiceDetailById })(withTranslation()(ApplyingStyles));