import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import fetch from 'isomorphic-unfetch';
import cookie from 'react-cookies'
import { getCost } from './getCost';
import { success, error } from './Common';
import { stat } from 'fs';
import {DataGetApi } from './../helpers/PostDataApi'


/**
 * user info Begin Action
 */
export const invoiceStart = () => ({
    type: ActionType.INVOICE_START
});

/**
* user info Success Action
*/
export const invoiceSuccess = invoiceData => {
    return {
        type: ActionType.INVOICE_SUCCESS,
        payload: { invoiceData }
    }
};

/**
* user info Failure Action
*/
export const invoiceError = error => ({
    type: ActionType.INVOICE_ERROR,
    payload: { error }
});

/**
 * user driver info
 */

export const csvFilesImport = (data,csrf = null,paytraceCredentials=null) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'csvFilesImport', token, true,csrf,paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const addInvoice = (data,csrf = null,paytraceCredentials=null) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'addInvoice', token, true,csrf,paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const emailInvoice = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let emailSent = (data.emailSent=== true) ? data.emailSent:false;
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi({...data, 'emailSent':emailSent}, config.BASEURL + 'emailInvoice', token, true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const intakeInvoiceLink = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'intakeInvoiceLink', token, true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const updateInvoice = (data,csrf=null,paytraceCredentials=null) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        
        return DataApi(data, config.BASEURL + 'updateInvoice', token, true, csrf, paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const capturePreAuth = (data, csrf) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    let locationPaydetails =JSON.parse(localStorage.getItem('locationPaydetails'))!==null? JSON.parse(localStorage.getItem('locationPaydetails')):'';    
    
    const paytraceCredentials = { 
    'userName': locationPaydetails.paytraceUserName, 
    'password': locationPaydetails.paytracePassword, 
    'integratorId': locationPaydetails.paytraceIntegratorId 
    }
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'capturePreAuth', token, true, csrf, paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200 && response.data.response.data.success) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}


export const updatePreAuth = (data) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'updatePreAuth', token, true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const voidPreAuth = (data, csrf) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    let locationPaydetails =JSON.parse(localStorage.getItem('locationPaydetails'))!==null? JSON.parse(localStorage.getItem('locationPaydetails')):'' ;
    // console.log("paytraceCredentials location =====>",locationPaydetails)
    const paytraceCredentials = { 
        'userName': locationPaydetails.paytraceUserName, 
        'password': locationPaydetails.paytracePassword, 
        'integratorId': locationPaydetails.paytraceIntegratorId 
        }
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'voidPreAuth', token, true, csrf, paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const payByLink = (data,csrf,paytraceCredentials=null) => {
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'payByLink',null,true,csrf,paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

 
export const fetchHashDetails = (token) => (
    dispatch => {
        fetch(config.BASEURL + `fetchHashDetails?hash=${token}`)
            .then(response => response.json())
            .then(myJson => {
                dispatch({
                    type: 'HASH', payload: { property: 'users', value: myJson.response }
                });
            })
            .catch((err) => {
                // console.log('Error', err);
                //error(dispatch, err);
            });
    }
);

export const addressAutoSuggest = (address) => (
	dispatch => {
		fetch(config.BASEURL+`addressAutocomplete?input=${address}`)
			.then(response => response.json())
			.then(myJson => {
				dispatch({
					type: 'SET_STATE', payload: {property: 'suggestionsData', value: myJson}
				});
			})
			.catch((err) => {
				// console.log('Error', err);
				//error(dispatch, err);
			});
	}
);
export const appendToNotes = (value) => ({
	type: 'APPEND_STATE',
	payload: { value }
});

export const fetchDetails = (token,history) => (
	
	(dispatch) => {
        // let user = cookie.load('user')
        let user = JSON.parse(localStorage.getItem('user'))
		fetch(config.BASEURL + `userDetails`, {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				// 'Authorization': 'bearer ' + user.token
			},

			body: JSON.stringify({ token })
		})
			.then(response => response.json())
			.then(
				response => {
					const users = response;
					if (users.status === 401) {
						//alert('Auth time out');
                        // cookie.remove('token')
                        
					}
					dispatch({
						type: 'USERS_DETAILS', payload: { property: 'user', value: users.data }
					});
				},
				errorV => { error(errorV); }
			)
			.catch(err => { error("User not found", err); });
	}
);


export const getDrivingDetails = (origin, destination, zipCode, serviceType, pickUpLocation, newPrice) => (
	(dispatch) => {
		if (origin && destination) {
			fetch(config.BASEURL+`getDistance?origin=${origin}&destination=${destination}`, {method: 'GET'})
				.then((response) => response.json())
				.then((myJson) => {
					if (myJson.distance && zipCode && serviceType) {
						dispatch(getCost(myJson.distance, zipCode, serviceType, pickUpLocation, newPrice));
					}else{
						dispatch(getCost(-1, zipCode, serviceType, pickUpLocation, newPrice));
					}
					dispatch({
						type: 'SET_STATE', payload: {property: 'distanceDetails', value: myJson}
					});
				})
				.catch((err) => {
					 console.log('Error', err);
					//error(dispatch, err);
				});
		} else if (origin) {
			if (zipCode && serviceType) {
				dispatch(getCost(-1, zipCode, serviceType, pickUpLocation, newPrice));
			} else {
				if (!zipCode)
					alert('zip code not found');
			}
		}
	}
);



const generateInvoice = () => Math.floor(Math.random() * 1000000000);
export const invoiceGenerate = () => (
	async dispatch => {
		let rand = generateInvoice(), count = 0;
		while (true) {
			const resp = await fetch(config.BASEURL + `invoiceCheck?invoice=${rand}`);
			const jsonV = await resp.json();
			if (jsonV.validity) {
				dispatch(setInputState('invoice', `${rand}`));
				break;
			}
			count++;
			if (count > 25) { break; }
		}
	}
);
// export const reset = () => (
// 	async dispatch => {
// 		console.log('<<<<','RESET')
// 		await dispatch({ type: 'RESET' });
// 		//dispatch();
// 	}
// );
export const reset = () => (
	async dispatch => {
        await dispatch({ type: 'RESET' });
        dispatch(invoiceGenerate());
    }
);



export const setInputState = (property, value) => ({
	type: 'SET_STATE',
	payload: { property, value }
});
export const setInputErrorState = (property, value) => ({
	type: 'SET_STATE_ERROR',
	payload: { property, value }
});

export const saveFormData = (state) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    let paytraceCredentials =state.paytraceCredentials
	let data = JSON.stringify({
		invoice: state.invoice,
		firstName: state.firstName,	lastName: state.lastName, phoneNumber: state.phoneNumber,
		year: state.year, make: state.make.value,	model: state.model,	color: state.color.value,
		serviceType: state.serviceType,	problemType: state.problemType,
		pickUpLocation: state.pickUpLocation, pickUpNotes: state.pickUpNotes,
		startAddress: state.startAddress, endAddress: state.endAddress,
		email: state.email,	notes: state.notes,
		sendTo: state.sendTo, zipForCalc: state.zipForCalc,	error: state.error,
		distanceDetails: state.distanceDetails, userAccount: state.userAccount, system: state.system,
		amount: ((parseFloat(state.amount) * 3.50 / 100) + parseFloat(state.amount)).toFixed(2),
		type: state.type,saveAsDraft:state.saveAsDraft ,companyId:state.companyId,createdBy:state.createdBy	})
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'saveFormData', token, true,null,paytraceCredentials).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const updateFormData = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
	let token = user.token;
    return dispatch => {
        dispatch(invoiceStart());
        return DataApi(data, config.BASEURL + 'updateFormData', token, true,null).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const InvoiceDetailById = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    
    return dispatch => {
        dispatch(invoiceSuccess());
        return DataGetApi(config.BASEURL + 'InvoiceDetailById', data,token).then((response) => {
             
            if (response.status === 200) {
                return dispatch({'type': 'INVOICE_DETAILS',
					payload: {value:response.data.response} })
            } else {
                return dispatch(invoiceError(response.response.data));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const getNyTaxReports = (data) => {
    return dispatch => {
        dispatch(invoiceStart());
        return DataGetApi(config.BASEURL + 'getCutomizeRecordsOfTax',data).then((response) => {
            if (response.status === 200) {
                return dispatch(invoiceSuccess(response))
            } else {
                return dispatch(invoiceError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}