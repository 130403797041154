import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from 'prop-types';
import { SideMenu } from '../components/SideMenu'
import { Footer } from '../components/Footer'
import { ErrorBoundary } from '../components/ErrorBoundary'
import decode  from 'jwt-decode'

const checkAuth = () => {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  if (!token || !refreshToken) {
    return false
  }
  try {
    const { exp } = decode(token)
    if(exp < new Date().getTime()/1000){
      localStorage.removeItem('user')
      localStorage.removeItem('locationId', { path: '/' })
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      return false
    }
  } catch (e) {
    return false
  }
  return true
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        checkAuth() ? (
          <>
            <ErrorBoundary>
              <div className="wrapper">
                <SideMenu />
                <Component {...props} />
              </div>
              <Footer />
            </ErrorBoundary>
          </>
        ) : (
            <ErrorBoundary>
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            </ErrorBoundary>
          )
      }
    />
  )
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object
}
export default PrivateRoute;