import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Table } from '../../components/Table'
import { Topheader } from './../../components/Topheader';
import { subAdminUserList, adminUserDelete, adminUserStatus } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import cookie from 'react-cookies'
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';

class SubAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      sideShow: true,
      reload: false,
      user: JSON.parse(localStorage.getItem('user'))
    }
  }
  tableReload(status){
    this.setState({reload:status})
  }
  getTable(query){
    if(this.state.reload){
      query.search=""
      this.setState({reload:false})
    }
  return   new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': config.SUB_ADMIN_ROLE,'per_page': query.pageSize, 'page': + (query.page + 1),'searchAll':(typeof query.search==="undefined" || query.search==="")?false:true
      };
      DataGetApi(url + 'subAdminUserList', param, token).then(result => {
        resolve({
          data: (typeof query.search ==="undefined" || query.search==="")?result.data.response:result.data.response.filter((row=>{
            return (row.userName.toLowerCase().indexOf(String(query.search)) !== -1 || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !==-1 ||  row.email.toLowerCase().indexOf(String(query.search)) !== -1)
          })),
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
      }).catch(error => {
        console.log('fetching clients error : ', error);
        // toast.error('There was an error trying to fetch clients');
        reject(error);
      })
    })
  }

  companyDeleteStatus(e, data) {
    this.props.adminUserDelete(data).then((res) => {
      // this.getData()
      window.location.reload(false)
    })
  }

  userStatusToggle(e, data) {
    this.props.adminUserStatus(data).then((res) => {
      // this.getData()
      window.location.reload(false)
    })
  }

  getData() {
    this.setState({ isLoading: true });
    let role = { "role": 0 }
    this.props.subAdminUserList(role).then((res) => {
      if (res.payload.userData) {
        this.setState({ data: res.payload.userData.data, isLoading: false })
      } else {
        alert('Auth failed')
        cookie.remove('user', { path: '/' })
        this.props.history.push('/');
      }
    })
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getData();
  }

  render() {
    const columns = [
      { title: 'UserName', field: 'userName' },
      { title: 'Role', field: 'role', lookup: { 0: 'Sub-Admin', 3: 'Manager', 4: 'Regular User' } },
      { title: 'Active Status', field: 'status', lookup: { true: 'Active', false: 'In Active' } },
      { title: 'Email', field: 'email' }];
      let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
            tableReload={(e)=>this.tableReload(e)}
              companyDeleteAction={(e, data) => this.companyDeleteStatus(e, data)}
              companyActiveAction={(e, data) => this.userStatusToggle(e, data)}
              permissions={userPermissions.permissions}
              options={{
                exportButton: true
              }}
              columns={columns}
              data={(query)=>this.getTable(query)}
              title={'SubAdmin'}
              search={true}
              exports={true}
              paging={true}
            />
          </LoadingOverlay>
        </div>
      </div>

    )
  }
}
function mapStateToProps(state) {

  return {
    info: state.AdminUserReducer,
    currentLocation: state.LocationReducer.currentLocation
  };
}

export default connect(mapStateToProps, { subAdminUserList, adminUserDelete, adminUserStatus })(withTranslation()(SubAdmin));
