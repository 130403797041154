import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { CompanyDetailById, companyEdit, countryDataList } from '../../actions/CompanyAction'
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import { config } from '../../constants'
import validator from '../../helpers/checkValidations';
import Resizer from 'react-image-file-resizer';
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

const mapToFormat = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  return Object.keys(state).sort().map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, title: name }
  ))
};

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};

class EditCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      sideShow: true,
      ACH: true,
      isLoading: true,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      timeZone: new Date(),
      misMatchError: [],
      editData: [],
      logoError: [],
      successMessages: false,
      image: null,
      selectedFile: null,
      imageUrl: null,
      loaded: false,
      error: {
        nameError: false,
        dbaNameError: false,
        emailError: false,
        contactNameError: false,
        websiteError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
        fileError: false,
      }
    }
  }
  getCompanyData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.CompanyDetailById(params.id).then((res) => {
      if (res.payload.companyData.data.response.image !== "null" && res.payload.companyData.data.response.image) {
        const uploadImageUrl = config.PAYTRACE_AWS_PATH + res.payload.companyData.data.response.image
        this.setState({ imageUrl: uploadImageUrl })
      }
      this.setState({ ...res.payload.companyData.data.response, isLoading: false })
    });
  }
  componentDidMount() {
    this.getCompanyData()
    this.getcountryDataList();
  }
  getcountryDataList() {
    this.setState({ isLoading: true })
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }

  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getCompanyData()
  }
  handleDateChange = (date) => {
    this.setState({ timeZone: date })
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError });
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
     
    Object.keys(state).map((key, value) => {
      if (key === "address2" && state['address2'] === "") {
        return false;
      }
      if (key === "website" && state['website'] === "") {
        return false;
      }
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    // if (state.error.fileError) {
    //   logoError[0] = t("CompanyLogo");
    //   this.setState({ logoError });
    //   return false;
    // }
    if (errorFlag) {
      console.log('error ',error)
      this.setState({ misMatchError, error, logoError, successMessages });
      return false;
    } else {
      delete this.state._id;
      const data = new FormData()
      for (const key in this.state) {
        data.append(key, this.state[key])
      }
      if (window.confirm('Are you sure want to update?')) {
        this.setState({ isLoading: true })
        this.props.companyEdit(data).then(result => {
          if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
            var uploadImageUrl = config.PAYTRACE_AWS_PATH + result.payload.companyData.data.response.image
           if(result.payload.companyData.data.response.image !=="null"){
             this.setState({ imageUrl: uploadImageUrl, successMessages: true, isLoading: false, loaded: false })
           }
           this.setState({ successMessages: true, isLoading: false, loaded: false })
           
          }
          if (result !== undefined && result.type === 'COMPANY_ERROR') {
            this.setState({ misMatchError: this.props.info.companyInfo.data.message, successMessages });
          }
        });
      }
    }

  }
  setCountryState(val) {
    if (val !== null) {
      this.setState({ stateList: this.state.Countrydata.filter(obj => (obj.Country === val.label))[0].State, country: val.label })
    }

  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({ cityList: this.state.stateList[val.label], state: val.label , city : '' })
  }
  /* setCitySet(val) {
    if (val !== null)
      this.setState({ city: val.label })
  } */
  setCitySet(event , val) {
    if (val !== null && val.hasOwnProperty('value')) {
      this.setState({ city: val.title })
    }else if (val && val.inputValue)
    {
      this.setState({ city: val.inputValue })
    } else{
      this.setState({ city: val })
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : ''
    let image = event.target.files[0]
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (image && !allowedExtensions.exec(image.name)) {
      logoError.push(t("CompanyLogoFileTypeNotCorrect"));
      error = true;
      this.setState({ error: { ...this.state.error, fileError: true, }, logoError, image: null, loaded: false })
    }
    var size = 5 * 1048576;
    if (image) {
      var imgName = image.name.replace(/\s+/g, '-');
      // var imgNameWithOutExt = imgName.split('.')[0] + Date.now() + '.' + imgName.split('.')[1];
      if (image.size > size) {
        logoError.push(t("CompanyLogoFileSizeNotCorrect"));
        this.setState({ error: { ...this.state.error, fileError: true, }, logoError, image: null })
      }
    }
    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        uri => {
          let file = new File([uri], imgName)
          this.setState({ selectedFile: selectedFile, image: file, loaded: true, logoError })
        },
        'blob'
      );

    }
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      // console.log('>>>',this.state.ACH)
    });
  }
  render() {

    const { t } = this.props;
    const { Countrydata } = this.state;
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          // className={classes.tpTableFullW}
          active={this.state.isLoading}
          spinner
          text='Fetching your response ...'>
          <Container component="main" maxWidth="xl" >
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[this.props.info.companyInfo.data.message]} /> : ''
            }
            <form className={classes.form} noValidate>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                  <Textfield
                    required={true}
                    error={this.state.error.dbaNameError}
                    id="dbaName"
                    type="text"
                    labels={t("DBAN ame")}
                    value={this.state.dbaName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Textfield
                    required={true}
                    error={this.state.error.contactNameError}
                    id="contactName"
                    type="text"
                    labels={t("Contact Name")}
                    value={this.state.contactName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.emailError}
                    id="email"
                    type="email"
                    labels={t("Email")}
                    value={this.state.email}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    // required={true}
                    // error={this.state.error.websiteError}
                    id="website"
                    type="text"
                    labels={t("Website")}
                    value={this.state.website}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.mccError}
                    id="mcc"
                    type="text"
                    labels={t("MCC")}
                    value={this.state.mcc}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>

                  <Textfield
                    required={true}
                    error={this.state.error.address1Error}
                    id="address1"
                    type="text"
                    labels={t("Address1")}
                    value={this.state.address1}
                    width={true}
                    rowsMax={"2"}
                    onChange={(e) => this.handleChange(e, t)}
                    multiline={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Textfield

                    id="address2"
                    type="text"
                    labels={t("Address2")}
                    value={this.state.address2}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    rowsMax={"2"}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)} >
                    <TypableSelect
                      placeholder={'Select country name'} suggestions={mapToFormat(Countrydata)}
                      onChange={val => this.setCountryState(val)}
                      isSelected={this.state.country}
                      isClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)} >
                    <TypableSelect
                      placeholder={'Select state name'} suggestions={mapToFormatStates(this.state.stateList)}
                      onChange={val => this.setStatesSet(val)}
                      isSelected={this.state.state}
                      isClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '1.0rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)} >
                   {/*  <TypableSelect
                      placeholder={'Select city name'} suggestions={mapToFormatCity(this.state.cityList)}
                      onChange={val => this.setCitySet(val)}
                      isSelected={this.state.city}
                      isClearable={true}
                    /> */}

                    

<Autocomplete
      value={this.state.city}
      onChange= { (e ,value) => this.setCitySet (e , value)  }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="city"
     options={mapToFormatCity(this.state.cityList)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="city" />
      )}
    />


                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.zipError}
                    id="zip"
                    type="text"
                    labels={t("Zip")}
                    value={this.state.zip}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={12}> <h3>Merchant Permission</h3>
                  <label>
                    <Checkbox
                      id="ACH"
                      checked={this.state.ACH}
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.ACH}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>ACH</span>
                  </label>
                </Grid>
                
                <Grid item xs={6} sm={3}>
                  <label>Company Logo
              <input type="file" name="file" onChange={(e) => this.onChangeHandler(e, t)} />
                  </label>
                  {this.state.logoError.length > 0 ?
                    < ErrorMessage errors={[this.state.logoError]} /> : ''
                  }
                </Grid>
                <Grid item xs={6} sm={3} >
                  {(this.state.imageUrl ? <img alt="profile" src={this.state.imageUrl} className={classes.preview} /> :
                    '')}
                </Grid>
                <Grid item xs={6} sm={3} >
                  <div className={(this.state.loaded) ? classes.show : classes.hide}>

                    {((this.state.selectedFile) ? <img alt="preview" className={classes.preview} src={this.state.selectedFile} /> :
                      <img className={classes.preview} alt="blank" src={config.APPURL + '/assets/images/blank.png'} />)}
                  </div>

                </Grid>
                <Grid item xs={12} >
                  {((this.props.userInfo.permissions && this.props.userInfo.permissions.editCompany) || this.state.user.role === config.SUPER_ADMIN_ROLE) ?
                    <Buttons
                      id="signUp"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t("Update")} />
                    : ''}
                  <Buttons
                    className={classes.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack} />
                </Grid>
              </Grid>

            </form>
          </Container>
        </LoadingOverlay>
      </div>

    )
  }
}
function mapStateToProps(state) {

  return {
    info: state.CompanyReducer,
    userInfo: state.AdminUserReducer,
  };
}
export default connect(mapStateToProps, { CompanyDetailById, companyEdit, countryDataList })(withTranslation()(EditCompany));
