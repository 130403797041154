import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { companyAdd, countryDataList } from '../../actions/CompanyAction'
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import validator from '../../helpers/checkValidations';
import Resizer from 'react-image-file-resizer';
import config from './../../constants/config'
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from '@material-ui/core/Checkbox';
import { POINT_CONVERSION_COMPRESSED } from 'constants';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

const mapToFormat = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  return Object.keys(state).sort().map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, title: name }
  ))
};

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};
class AddCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      ACH: true,
      isLoading: true,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      isSubmit: false,
      zip: '',
      timeZone: new Date(),
      misMatchError: [],
      logoError: [],
      successMessages: false,
      redirectCompany: false,
      image: null,
      selectedFile: null,
      imageUrl: null,
      preview: false,
      user: JSON.parse(localStorage.getItem('user')),
      error: {
        nameError: false,
        dbaNameError: false,
        emailError: false,
        contactNameError: false,
        websiteError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      }
    }
  }

  componentDidMount() {
    this.getcountryDataList();
    this.setState({ createdBy: this.state.user._id })
  }
  getcountryDataList() {
    this.setState({ isLoading: true })
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }

  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let logoError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, logoError, isSubmit: false });
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (state.address2 === "") {
      delete state.address2;
    }
    if (state.website === "") {
      delete state.website;
    }

    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });
    // if (state.image == null) {
    //   logoError[0] = t("CompanyLogo");
    //   errorFlag = true;
    // }


    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {

      const data = new FormData();
      for (const key in this.state) {
        data.append(key, this.state[key])
      }

      this.setState({ isLoading: true })
      window.scrollTo(0, 0, 0)
      this.props.companyAdd(data).then(result => {
        if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
          this.setState({ successMessages: true, isLoading: false }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false })
              this.setState({ redirectCompany: true })
            }, 2000)

          });
        }
        if (result !== undefined && result.type === 'COMPANY_ERROR') {
          this.setState({ misMatchError: this.props.info.companyInfo.data.message, successMessages });
        }
      });
    }
  }

  setCountryState(val) {
    if (val !== null) {
      this.setState({ stateList: this.state.Countrydata.filter(obj => (obj.Country === val.label))[0].State, country: val.label })
    } else {
      this.setState({ stateList: [], cityList: [], state: '', city: '' })
    }
  }
  setStatesSet(val) {
    if (val !== null) {
      this.setState({ cityList: (val.label) ? this.state.stateList[val.label] : [], state: val.label, city: '' })
    } else {
      this.setState({ cityList: [], state: '', city: '' })
    }
  }
  setCitySet(event, val) {
    if (val !== null && val.hasOwnProperty('value')) {
      this.setState({ city: val.title })
    } else if (val && val.inputValue) {
      this.setState({ city: val.inputValue })
    } else {
      this.setState({ city: val })
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let logoError = [];
    let error = false
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : ''
    let image = event.target.files[0]
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (image && !allowedExtensions.exec(image.name)) {
      logoError.push(t("CompanyLogoFileTypeNotCorrect"));
      error = true;
      this.setState({ logoError, image: null, preview: false })
    }
    if (image) {
      let imgName = image.name.replace(/\s+/g, '-');
      var imgNameWithOutExt = imgName.split('.')[0] + Date.now() + '.' + imgName.split('.')[1];

      var size = 5 * 1048576;
      if (image.size > size) {
        logoError.push(t("CompanyLogoFileSizeNotCorrect"));
        this.setState({ logoError, image: null })
      }
    }
    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        uri => {
          let file = new File([uri], imgNameWithOutExt)
          this.setState({ selectedFile: selectedFile, image: file, preview: true, logoError })
        },
        'blob'
      );
    }
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      // console.log('>>>',this.state.ACH)
    });

  }


  handleAutocompteEvent(event) {

  }

  render() {
    const { t } = this.props;
    const { Countrydata, redirectCompany } = this.state;
    return (

      <div className="rightPanel">
        {(redirectCompany) ? <Redirect to='/Company' /> : ''}
        <Topheader />

        <Container component="main" maxWidth="xl" >
          {this.state.misMatchError.length > 0 ?
            < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {this.state.successMessages ?
            <SuccessMessage successes={[this.props.info.companyInfo.data.message]} /> : ''
          }
          {/* <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text='Fetching your response ...'> */}
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>

                <Textfield
                  required={true}
                  error={this.state.error.dbaNameError}
                  id="dbaName"
                  type="text"
                  labels={t("DBA Name")}
                  value={this.state.dbaName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.contactNameError}
                  id="contactName"
                  type="text"
                  labels={t("Contact Name")}
                  value={this.state.contactName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  // required={true}
                  // error={this.state.error.websiteError}
                  id="website"
                  type="text"
                  labels={t("Website")}
                  value={this.state.website}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.mccError}
                  id="mcc"
                  type="text"
                  labels={t("MCC")}
                  value={this.state.mcc}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>

                <Textfield
                  required={true}
                  error={this.state.error.address1Error}
                  id="address1"
                  type="text"
                  rowsMax={"2"}
                  labels={t("Address1")}
                  value={this.state.address1}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  multiline={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  id="address2"
                  type="text"
                  rowsMax={"2"}
                  labels={t("Address2")}
                  value={this.state.address2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}

                  multiline={true}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '1.8rem' }} className="custom-select">
                <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select country name'} suggestions={mapToFormat(Countrydata)}
                    onChange={val => this.setCountryState(val)}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '1.8rem' }} className="custom-select">
                <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select state name'} suggestions={mapToFormatStates(this.state.stateList)}
                    onChange={val => this.setStatesSet(val)}
                    isClearable={true}
                    isSelected={(this.state.state) ? this.state.state : ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} className="custom-select">
                <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)} >
                  {/*  <TypableSelect
                    placeholder={'Select city name'} 
                    suggestions={mapToFormatCity(this.state.cityList)}
                    onChange={val => this.setCitySet(val)}
                    isSelected={(this.state.state)?this.state.city:''}
                    isClearable={true}
                  /> */}


                  <Autocomplete
                    value={this.state.city}
                    onChange={(e, value) => this.setCitySet(e, value)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="city"
                    options={mapToFormatCity(this.state.cityList)}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderOption={(option) => option.title}
                    style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="city" />
                    )}
                  />





                </FormControl>
              </Grid>

              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.zipError}
                  id="zip"
                  type="text"
                  labels={t("Zip")}
                  value={this.state.zip}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={12}> <h3>Merchant Permission</h3>
                <label>
                  <Checkbox
                    id="ACH"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.ACH}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <span>ACH</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={3}>
                <label>Company Logo
                <input type="file" name="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => this.onChangeHandler(e, t)} />
                </label>
                {this.state.logoError.length > 0 ?
                  < ErrorMessage errors={[this.state.logoError]} /> : ''
                }
              </Grid>
              <Grid item xs={6} sm={3} >
                <div className={(this.state.preview) ? classes.show : classes.hide}>

                  {((this.state.selectedFile) ? <img alt="preview" className={classes.preview} src={this.state.selectedFile} /> :
                    '')}
                </div>
              </Grid>
              <Grid item xs={6} sm={3} >
                {(this.state.imageUrl ? <img alt="profile" src={this.state.imageUrl} className={classes.preview} /> :
                  '')}
              </Grid>


              <Grid item xs={12} >
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Save")} />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={this.props.history.goBack} />
              </Grid>
            </Grid>

          </form>
          {/* </LoadingOverlay> */}
        </Container>
      </div>


    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.CompanyReducer
  };
}
export default connect(mapStateToProps, { companyAdd, countryDataList })(withTranslation()(AddCompany));
