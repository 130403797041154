import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Textfield } from './../../components/Textfield';
import { fetchDetails, settingDataByCompanyId } from '../../actions/AdminUserAction'
import { getInvoiceNumber, getTransactionListTodayData, getCsrfToken } from '../../actions/PaymentAction'
import { addInvoice } from '../../actions/RequestFormAction'
import { customerDetailsByCompany } from '../../actions/CustomerAction'
import { productList } from '../../actions/ProductAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DialogBox } from './../../components/DialogBox/DialogBox'
import AddClient from './AddClient'
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Button, CardActions, Collapse, IconButton, TextField } from '@material-ui/core';
import MaskedInput from 'react-maskedinput'
import config from './../../constants/config'
import { Checkbox } from '@material-ui/core';
import classesI from './Invoice.module.scss';
import ProductTable from './ProductTable'
import { doesFileExist, formatCurrency } from '../../helpers/commonFunction'
import binlookup from "binlookup"; 
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import Notification from '../../components/Notifications/Notification';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
const credit_or_debit = binlookup();

const mapToFormat = (values) => {
  return values.map(Obj => (
    { value: Obj._id, label: Obj.firstName + ' ' + Obj.lastName }
  ))
};


const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    width: '100%',
    // overflow: 'auto',
  },
  formControl: {
    marginTop: '2rem'
  }
});

class AddInvoice extends Component {
  constructor(props) {
    super(props)
    this.state = {

      isDisableInvoice: true,
      // start binLookUp
      isCreditCard: null,
      mIdType: "",
      locationDetails: JSON.parse(localStorage.getItem('locationPaydetails')),
      userDetails: JSON.parse(localStorage.getItem('user')),
      functionToCallInTopHeader: null,
      showAlert: false,
      timer: 30,
      //End binLookUp
      redirect: false,
      isPaid: false,
      redirectTransaction: false,
      cardPanelShow: false,
      americanExpress: false,
      isLoading: false,
      isEditClient: false,
      isOpen: false,
      amount: '',
      total: 0,
      tax: 0,
      svcFee: 0,
      taxType: '%',
      svcType: '%',
      invoice: '',
      createdBy: '',
      companyId: '',
      discountType: 1,
      discount: 0,
      isSubmit: false,
      addNewCard: false,
      saveDraft: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      ProductData: [],
      Products: [],
      CardData: [],
      TransactionData: [],
      productFields: [{ name: '', description: '', amount: '', id: 0 }],
      successMessages: false,
      discountErr: false,
      updateCard: false,
      tabValue: 0,
      indexValue: 0,
      customerId: null,
      settings: {},
      paytraceCredentials: {},
      card: {
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      userData: { permissions: {} },
      feeSettings: { InvoiceDiscount: false, InvoiceTax: false },
      error: {
        firstNameError: false,
        emailError: false,
        amountError: false,
        phoneError: false,
        svcFeeError: false,
      },
      limit: {
        perTransactionLimit: false,
        dailyTransactionLimit: false,
        perRefundLimit: false,
        dailyRefundLimit: false,
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      }
    }
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
  }

  UNSAFE_componentWillMount() {

    //redirect to intake form for road assitence client
    if (this.props.UserDetail.companyId.id === '5ee85adef500853860700888') {
      this.props.history.push('/RequestForm')

    }
  }

  componentDidMount() {
    this.getCsrfTokens()
    let misMatchError = []
    let user = JSON.parse(localStorage.getItem('user'))
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'))
    if (locationPaydetails) {

      /** 
     * Bin lookup
     */
      if (locationPaydetails.midType) {
        this.setState({ mIdType: locationPaydetails.midType });
        let mIdobj = locationPaydetails.midType == 'Surcharge' ? { mId: (locationPaydetails.mId2) ? locationPaydetails.mId2 : locationPaydetails.mId } : { mId: locationPaydetails.mId };
        this.setState(mIdobj);
      } else if (locationPaydetails.midType == "") {
        this.setState({ mIdType: "" });
      }
      /** End  */

      setTimeout(() => {
        const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword };
        this.setState({ 'paytraceCredentials': paytraceCredentials, 'locationId': locationPaydetails._id }, () => {
          let file = doesFileExist(config.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
          if (window.paytrace && file) {
            window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
          } else {
            let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.'
            // alert('Paytrace file not exist.Kindly contact to super admin to re-upload the key.')
            misMatchError.push(msg);
            this.setState({ misMatchError });
          }
        })
      }, 3000)
    } else {
      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
    }

    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS") {
        this.setState({ userData: { ...res.payload.userData } }, () => {
          if (this.state.userData.permissions && !this.state.userData.permissions.createInvoice) {
            alert('Access denied!');
            this.props.history.push('/Dashboard');
          }
        });
      }
    });
    this.getUniqueInvoiceNumber();
    this.getTransactionListDailyData();
    this.getCustomerList();
    this.getProductList();
    if (window.paytrace) {
      let userData = JSON.parse(localStorage.getItem('user'));
      this.setState({ createdBy: userData._id, companyId: userData.company._id }, () => {
        this.settingDataByCompanyId();
      })

    }

    /**
     * Disable invoice
     */
    let userData = JSON.parse(localStorage.getItem('user'))
    const isDisableInvoice = userData.company._id === '5ee8646bf50085386070089a' ? false : true
    this.setState({ isDisableInvoice: isDisableInvoice })





  }
  settingDataByCompanyId() {
    this.setState({ isLoading: true })
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
      locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let param = { 'id': this.state.companyId, 'locationId': locationId }
    this.props.settingDataByCompanyId(param).then((res) => {
      if (res.payload.error) {
        //svc disabled
        this.setState({ feeSettings: { InvoiceDiscount: false, InvoiceTax: false, DiscountFeeName: '' } }, () => {
        })
      } else {
        this.setState({ feeSettings: { ...(res.payload.userData.data) ? res.payload.userData.data.response : {} } }, () => {
          if (this.state.amount > 0) {
            if (this.state.feeSettings.InvoiceTax) {
              if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
                this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
                })

              } else {
                this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * this.state.amount, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
                })
              }
            }

            if (this.state.feeSettings.InvoiceDiscount) {
              if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee })
                })
              } else {
                let svc = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount
                let svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2))
                this.setState({ svcFee: svcVal }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee })
                })
              }
            }

          }
          this.setState({ isLoading: false })
        })
      }

    })
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } })
    })
  }
  getTransactionListDailyData() {
    this.setState({ isLoading: true })
    let userData = JSON.parse(localStorage.getItem('user'))
    let data = { 'date': new Date(), 'companyId': userData.company ? userData.company._id : '', 'role': userData.role, 'userId': userData._id }
    this.props.getTransactionListTodayData(data).then((res) => {
      if (res) {
        this.setState({ TransactionData: res.payload.paymentData.data.response, isLoading: false })
      }
    })
  }
  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return 'ACH'
    }
  }

  hitLoc(e) {
    let curloc = this.state.locationDetails;
    let loclist = this.state.userDetails;
    let dataloc = {};
    let indexloc = 0;
    this.setState({ isLoading: true });
    if (curloc.midType == 'Surcharge') {
      dataloc = loclist.locationSelectedwithmid.filter((item) => ((item._id === curloc._id) && (item.midType === 'Traditional')));
      indexloc = loclist.locationSelectedwithmid.findIndex((item) => ((item._id === curloc._id) && (item.midType === 'Surcharge')));
    } else if (curloc.midType == 'Traditional') {
      dataloc = loclist.locationSelectedwithmid.filter((item) => ((item._id === curloc._id) && (item.midType === 'Surcharge')));
      indexloc = loclist.locationSelectedwithmid.findIndex((item) => ((item._id === curloc._id) && (item.midType === 'Surcharge')));
    }

    if (dataloc[0]) {
      localStorage.setItem("binLook", JSON.stringify({ index: indexloc, item: dataloc[0] }))
      this.state.functionToCallInTopHeader();
      this.setState({ isLoading: false, expiresIn: 30 });
    }
  }



  getCardDebitCredit(e) {
    var cardNo = this.state.card.cardNumber.replaceAll("_", "");
    if (cardNo.length > 5) {
      credit_or_debit(cardNo)
        .then(data => {
          if (typeof data.type !== "undefined") {
            this.setState({ ...this.state, isCreditCard: (data.type === "credit") ? true : false },
              () => {
                const isCreditCard = this.state.isCreditCard;
                const { mId, mId2, midType } = this.state.locationDetails;
                var setState = isCreditCard ? { mId: (mId2) ? mId2 : mId } : { mId: mId };
                this.setState(setState);
                if (midType === "Traditional" && isCreditCard === true) {
                  this.setState({ showAlert: true });
                } else if (midType === "Surcharge" && isCreditCard === false) {
                  this.setState({ showAlert: true });
                }
                setTimeout(() => {
                  this.setState({ showAlert: false });
                }, 30000)
                return false;
              })
          } else {
            alert("Enter valid debit or credit card.");
            this.setState({ card: { ...this.state.card, cardNumber: '_________________' }, isCreditCard: null });
            return false;
          }
        });
    }
  }




  handleChangeCard(event, t) {
    /** bin loopUp */
    let { id, value } = event.target;
    if (this.state.card.cardNumber.replaceAll("_", "") != '' && id === "cardNumber") {
      this.getCardDebitCredit();
    }
    /** end binLookUp */

    let error = {}
    let misMatchError = [];

    if (this.state.customerId == null || this.state.customerId === undefined) {
      alert('Select a client first.')
      document.getElementById("cardNumber").value = "___________";
    } else {
      (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
      this.setState({ card: { ...this.state.card, [id]: value }, errorCard: { ...this.state.errorCard, [id]: false }, error, misMatchError, isSubmit: false }, () => {
        let firstNumber = this.state.card.cardNumber;
        this.setState({ 'cardType': this.getCardType(firstNumber.charAt(0)) }, () => {

        })
        if (firstNumber[0] === "3") {
          this.setState({ americanExpress: true })
        } else {
          this.setState({ americanExpress: false })
        }

      });

    }
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false, errorCard: { ...this.state.errorCard, [id]: false } });
    if (!this.state.feeSettings.InvoiceDiscount && !this.state.feeSettings.InvoiceTax && id === "amount") {
      this.setState({ total: parseFloat(value) })
    }
    else if (id === "amount" && value !== '0') {
      if (this.state.feeSettings.InvoiceTax) {
        if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
          this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
          })

        } else {
          this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * value, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
          })
        }
      }

      if (this.state.feeSettings.InvoiceDiscount) {
        if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
          this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee })
          })
        } else {
          let svc = (this.state.feeSettings.InvoiceSVCFee / 100) * value
          let svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2))
          this.setState({ svcFee: svcVal }, () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee })
          })
        }
      }

    }
  }
  handleCardSubmit(e, t) {
    e.preventDefault();
  }

  checkMaxTransactionLimit(val) {
    let maxTransactionLimit = (this.props.UserDetail.permissions.perTransactionLimit) ? this.props.UserDetail.permissions.perTransactionLimit : 0
    let maxDailyTransactionLimit = (this.props.UserDetail.permissions.dailyTransactionLimit) ? this.props.UserDetail.permissions.dailyTransactionLimit : 0
    let maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0)
    if (val > (maxDailyTransactionLimit - maxDailyAmount) && maxDailyAmount > 0 && maxDailyTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: false } })
        }, 5000)
        return false;
      })
    }
    else if (val > maxTransactionLimit && maxTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, perTransactionLimit: false } })
        }, 5000)
        return false;
      })

    } else {
      return true;
    }
  }


  handleSubmit(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    // let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.PO === "") {
      delete this.state.PO;
    }
    if (this.state.customerId === null) {
      this.openAddclient();
      return false;
    }
    if (this.state.isPaid || this.checkMaxTransactionLimit(this.state.total) && this.checkCardValues(this.state.card)) {
      if (this.state.name === "" || this.state.mask_number) {
        delete this.state.name;
        delete this.state.mask_number;
      }
      Object.keys(this.state).map((key, value) => {
        if (!this.state.mId2 && this.state.mIdType == "" && key == 'mIdType') {
          return "";
        } else if ((validator(key, this.state[key]))) {
          return (validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
        } else {
          return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
        }
      });
      if (!this.state.paytraceCredentials.hasOwnProperty('password')) {
        window.scrollTo(0, 0, 0);
        let msg = 'Select the payment location.';
        misMatchError.push(msg);
        this.setState({ misMatchError });
        errorFlag = true;
      }
      if (errorFlag) {
        this.setState({ misMatchError, logoError, error, successMessages });
        console.log("misMatchError, logoError, error, successMessages ", misMatchError, logoError, error, successMessages);
        return false;
      } else {
        window.paytrace.submitEncrypted('#DemoFormInvoice');
        this.setState({
          card: { ...this.state.card, cardNumber: '', cardCsc: '' },
          isLoading: true,
          encrypted_number: localStorage.getItem('0'),
          encrypted_csc: localStorage.getItem('1')
        }, () => {
          localStorage.removeItem('0');
          localStorage.removeItem('1');
          this.props.addInvoice(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {
            window.scrollTo(0, 0);

            if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
              this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                setTimeout(() => {
                  this.setState({ successMessages: false });
                  if (this.state.isPaid) {
                    this.setState({ redirect: true });
                  } else {
                    this.setState({ redirectTransaction: true });
                  }
                }, 2000)
              });
            }

            if (result !== undefined && result.type === 'INVOICE_ERROR') {
              if (result.payload.error.data) {
                let message = result.payload.error.data.message;
                misMatchError.push(message);
                if (result.payload.error.data.success === false) {
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }
                if (result.payload.error.data.response.length > 0) {
                  let responseErrors = JSON.parse(result.payload.error.data.response);
                  if (responseErrors.response_code === 1) {
                    for (let key in responseErrors.errors) {
                      let i = 0;
                      misMatchError.push(responseErrors.errors[key][i] + '\n');
                    }
                    // this.setState({ isLoading: false, misMatchError, successMessages });
                  }
                  if (responseErrors.response_code === 1001) {
                    misMatchError.push(responseErrors.status_message);
                  }
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              }
            }
          });
        });
      }
    }
  }
  handleSubmitEmailInvoice(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.PO === "") {
      delete this.state.PO;
    }
    if (this.state.customerId === null) {
      this.openAddclient();
      return false;
    }
    if (this.state.name === "" || this.state.mask_number) {
      delete this.state.name;
      delete this.state.mask_number;
    }
    Object.keys(this.state).map((key, value) => {
      if (!this.state.mId2 && this.state.mIdType == "" && key == 'mIdType') {
        return "";
      } else if ((validator(key, this.state[key]))) {
        return (validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false);
      } else {
        return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : '';
      }
    });
    if (!this.state.paytraceCredentials.hasOwnProperty('password')) {
      window.scrollTo(0, 0, 0);
      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      console.log("misMatchError, logoError, error, successMessages ", misMatchError, logoError, error, successMessages);
      return false;
    } else {
      this.setState({ saveDraft: true, emailSent: true, isLoading: true }, () => {
        this.props.addInvoice(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {
          window.scrollTo(0, 0)
          if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false });
                this.setState({ redirect: true });
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'INVOICE_ERROR') {
            if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
              let message = result.payload.error.data.message
              misMatchError.push(message)
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (result.payload.error.data.success === false) {
              let message = result.payload.error.data.message
              misMatchError.push(message)
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (this.props.info.invoiceInfo.data.response.length > 0 && this.props.info.invoiceInfo.data.statusCode !== 401 && this.props.info.invoiceInfo.data.statusCode !== 500) {
              let responseErrors = JSON.parse(this.props.info.invoiceInfo.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n');
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message);
              }
            }

            this.setState({ isLoading: false, misMatchError, successMessages });
          }
        });
      })

    }
  }
  handleSubmitSaveDraft(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true })
    if (this.state.PO === "") {
      delete this.state.PO;
    }
    if (this.state.customerId === null) {
      this.openAddclient();
      return false;
    }
    if (this.state.name === "" || this.state.mask_number) {
      delete this.state.name;
      delete this.state.mask_number;
    }
    Object.keys(this.state).map((key, value) => {
      if (!this.state.mId2 && this.state.mIdType == "" && key == 'mIdType') {
        return "";
      } else if ((validator(key, this.state[key]))) {
        return (validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false);
      } else {
        return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : '';
      }
    });
    if (!this.state.paytraceCredentials.hasOwnProperty('password')) {
      window.scrollTo(0, 0, 0);
      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      console.log("misMatchError, logoError, error, successMessages ", misMatchError, logoError, error, successMessages);
      return false;
    } else {
      this.setState({ saveDraft: true, isLoading: true }, () => {
        this.props.addInvoice(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {
          window.scrollTo(0, 0)
          if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false });
                this.setState({ redirect: true });
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'INVOICE_ERROR') {
            if (result.payload.error.data) {
              let message = result.payload.error.data.message;
              misMatchError.push(message);
              if (result.payload.error.data.success === false) {
                this.setState({ isLoading: false, misMatchError, successMessages });
              }
              if (result.payload.error.data.response.length > 0) {
                let responseErrors = JSON.parse(result.payload.error.data.response);
                if (responseErrors.response_code === 1) {
                  for (let key in responseErrors.errors) {
                    let i = 0;
                    misMatchError.push(responseErrors.errors[key][i] + '\n');
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }
                if (responseErrors.response_code === 1001) {
                  misMatchError.push(responseErrors.status_message);
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }
              }
            }
          }
        });
      })

    }
  }
  checkCardValues(state) {
    if (this.state.name === undefined) {
      // return false
    } else if (this.state.name !== "") {
      return true;
    }
    if ((state.cardNumber.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } })
      window.scrollTo(0, 0);
      return false;
    } else if ((state.cardExpiryDate.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } })
      window.scrollTo(0, 0);
      return false;
    } else if ((state.cardCsc.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } })
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }
  getUniqueInvoiceNumber() {
    this.setState({ isLoading: true })
    this.props.getInvoiceNumber().then((res) => {
      this.setState({ invoice: res.payload.paymentData.data.invoice, isLoading: false });
    })
  }

  getCustomerList() {
    this.setState({ isLoading: true })
    let userData = JSON.parse(localStorage.getItem('user'))
    let data = { 'role': userData.role, 'userId': userData._id, 'companyId': userData.company._id }
    this.props.customerDetailsByCompany(data).then((res) => {
      this.setState({ CoustmerData: res.payload.customerData.data.response, isLoading: false })
    })
  }
  getProductList() {
    this.setState({ isLoading: true })
    let userData = JSON.parse(localStorage.getItem('user'))
    this.props.productList({ 'companyId': userData.company._id }).then((res) => {
      this.setState({ ProductData: res.payload.productData.data.response, isLoading: false })
    })
  }

  handleChangeTab(e, val) {
    this.setState({ tabValue: val })
  }

  handleDateChange = (date, key) => {
    if (date) {
      this.setState({ [key]: date })
    } else {
      alert('Date can not be empty!')
      this.setState({ [key]: new Date() })
    }
  }

  openAddclient() {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.getCustomerList();
    this.setState({ isOpen: false, isEditClient: false, isLoading: true })
  }

  a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }
  handleSubmitAddNewCard(e, t) {

    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____'
    }

    this.setState({ card: { ...cardDataInital }, mask_number: '', name: '', updateCard: true, addNewCard: true })
  }
  setClientValue(val) {
    document.getElementById("cardNumber").value = "___________";
    if (val !== null) {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      }
      const cardDataInital = {
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false
      }

      this.setState({ card: { ...cardDataInital }, errorCard: { ...errCard }, misMatchError, error: { ...this.state.error }, customerId: val.value, cardPanelShow: true, addNewCard: false });

      //For set mask number
      const data = this.state.CoustmerData.filter((item) => item._id === val.value);
      if (data[0].customer_id) {
        this.setState({ cardType: data[0].cardType, mask_number: data[0].masked_card_number, cardPanelShow: true, name: data[0].firstName + ' ' + data[0].lastName });
      } else {
        this.setState({ mask_number: '', name: '', updateCard: false });
      }
    } else {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      }
      document.getElementById("cardNumber").value = "___________";
      const cardDataInital = {
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false
      }
      this.setState({ card: { ...cardDataInital }, misMatchError, errorCard: { ...errCard }, customerId: null, cardPanelShow: false, addNewCard: false }, () => {
      })
    }
  }

  fetchDiscountAmount = () => {
    let discount = { 'message': '', 'total': '' };
    if ((this.state.amount) > 0 || (this.state.discount) > 0) {
      const total = (this.state.amount - this.state.discount);
      discount.total = total;
      switch (total) {
        case 0:
          discount.message = 'Amount can not be zero';
          return discount;
        case total < 0:
          discount.message = 'Amount can not be negative';
          return discount;
        default:
          return discount;
      }
    }
    return discount;
  }

  dicountTabHandle(e) {
    let { value } = e.target;
    this.setState({ 'discountType': value })
  }

  handleCheckAsPaid(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.checked })
  }

  handleChangeCheckbox(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  handleChangeCheckboxUpdate(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  backToMaskCard(e, t) {
    //For set mask number
    const errCard = {
      cardNumber: false,
      cardHolderName: false,
      cardExpiryDate: false,
      cardCsc: false
    }
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____'
    }
    this.setState({ card: { ...cardDataInital }, errorCard: { ...errCard }, cardPanelShow: true, updateCard: false })
    const data = this.state.CoustmerData.filter((item) => {
      if (item._id === this.state.customerId) {
        return item
      }

    })
    if (data[0].customer_id) {
      this.setState({ mask_number: data[0].masked_card_number, cardPanelShow: true, name: data[0].firstName + ' ' + data[0].lastName })
    }
  }

  setEditClient() {
    this.setState({ isEditClient: true })
  }

  handleRemove(i) {
    const values = [...this.state.productFields];
    values.splice(i, 1);
    this.setState({ productFields: values });
  }

  changeValue(key, value, projects) {
    for (var i in projects) {
      if (projects[i].key === 'description') {
        projects[i].value = value;
        break; //Stop this loop, we found it!
      }
      if (projects[i].key === 'unitCost') {
        projects[i].value = value;
        break; //Stop this loop, we found it!
      }
    }
  }

  productFieldsAmount(data) {
    if (data.length > 1) {
      let values = [...data]
      let prodictData = values.filter((item, i) => {
        if (item.name !== "") {
          return item
        }
      });
      this.setState({ Products: [...prodictData] })
      let amountData = data.reduce((sum, { amount }) => sum + Number(amount), 0)
      this.setState({ 'amount': Number(amountData).toFixed(2) }, () => {
        if (!this.state.feeSettings.InvoiceTax && !this.state.feeSettings.InvoiceDiscount) {
          this.setState({ total: parseFloat(amountData) })
        }
        else if (amountData !== '0') {
          if (this.state.feeSettings.InvoiceTax) {

            if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
              this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
              })

            } else {
              this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * amountData, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                this.setState({ total: parseFloat(this.state.amount) + this.state.tax })
              })
            }
          }

          if (this.state.feeSettings.InvoiceDiscount) {
            if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
              this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType }, () => {
                this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee })
              })
            } else {
              let svc = (this.state.feeSettings.InvoiceSVCFee / 100) * amountData
              let svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2))
              this.setState({ svcFee: svcVal }, () => {
                this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee })
              })
            }
          }
        }

      })
    } else {
      this.setState({ amount: 0, total: 0, tax: 0, svcFee: 0 })
    }
  }

  handleChangeSvcFee(e) {
    let { id, value } = e.target;
    if (value !== "" && id === "svcFee") {
      this.setState({ svcFee: value }, () => {
        this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) })
      })
    } else {
      this.setState({ svcFee: 0, }, () => {
        this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) })
      })
    }
  }

  render() {
    const renderTime = ({ remainingTime }) => {
      if (remainingTime === 0) {
        return <div className="timer">Closing</div>;
      }
      return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
      );
    };

    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0);

    const { classes, t } = this.props
    const { CoustmerData, ProductData, redirect, redirectTransaction } = this.state;
    return (
      <div className="rightPanel">
        {(redirect) ? <Redirect to='/Invoices' /> : ''}
        {(redirectTransaction) ? <Redirect to='/Transactions' /> : ''}
        <Topheader setCallable={callable => this.setState({ functionToCallInTopHeader: callable })} />



        <Container component="main" maxWidth={false}>

          {this.state.misMatchError.map((e) => (
            < ErrorMessage errors={[e]} />
          ))}

          {this.state.successMessages ?
            <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''
          }

          {(this.state.limit.perTransactionLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your per transaction limit is ${(this.props.UserDetail.permissions.perTransactionLimit) ? this.props.UserDetail.permissions.perTransactionLimit : 0} </h3> : ''}
          {(this.state.limit.dailyTransactionLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your daily transaction limit is ${(this.props.UserDetail.permissions.dailyTransactionLimit) ? this.props.UserDetail.permissions.dailyTransactionLimit : 0}.You have already made the payment of ${maxDailyAmount}, Make a paymeny of ${(this.props.UserDetail.permissions.dailyTransactionLimit - maxDailyAmount)}.</h3> : ''}
          <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<AddClient />} />
          <DialogBox open={this.state.isEditClient} handleClose={this.handleClose} message={<AddClient editData={this.state.customerId} />} />
          <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text='Fetching your response ...'>
            <Grid container className="warning-display">
              <Grid item xs={10} sm={10}>
                {this.state.showAlert && !this.state.isSubmit ? <Notification callHitLoc={this.hitLoc.bind(this)} card={this.state.isDebitCard} /> : ''}
              </Grid>
              <Grid item xs={2} sm={23}>
                {this.state.showAlert && !this.state.isSubmit ?
                  <div className="timer-wrapper">
                    <CountdownCircleTimer
                      isPlaying size={90}
                      duration={30}
                      colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                      onComplete={() => [true, 1000]}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                    <a href="#" onClick={(e) => { this.setState({ showAlert: false }) }} className="cancel-icon"><span class="material-icons">cancel</span></a>
                  </div>
                  : ''}
              </Grid>
            </Grid>


            <form className={classes.form} id="DemoFormInvoice" ref={this.msgRef}>
              <Grid container spacing={3} className="addInvoiceCustomePage">
                <Grid item md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} >
                      <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                      <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                      <FormControl className="frequencyInput" fullWidth style={getValidationStyle(this.state.customerId == null && this.state.isSubmit)} >
                        <TypableSelect
                          placeholder={'Client Name'} suggestions={mapToFormat(CoustmerData)}
                          onChange={val => this.setClientValue(val)}
                          isClearable={true}
                        />
                      </FormControl>
                      <Grid className="addClientLink">
                        {(this.state.customerId !== null && this.state.customerId._id !== '') ? <Buttons
                          className={classesI.addNewClient}
                          variant="contained"
                          color="primary"
                          text={t("Edit Client")}
                          onClick={(e) => { this.setEditClient(e) }} /> : <Buttons
                          className={classesI.addNewClient}
                          variant="contained"
                          color="primary"
                          text={t("Add New Client")}
                          onClick={() => { this.openAddclient() }} />}
                      </Grid></Grid>
                    <Grid item xs={12}>
                      <Card className={classNames([(this.state.cardPanelShow) ? 'cardData' : 'blur', 'customCardWraper', (this.state.cardPanelShow && this.state.mask_number) ? 'none' : 'block'])}>
                        <div className="Card_header">
                          {(!this.state.updateCard) ? <Typography variant="h5" component="h2">
                            Card Details
                        </Typography> : <><Typography variant="h5" component="h2">
                            Card Details
                            </Typography>
                            <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>back</a></>}
                        </div>
                        <CardContent ref={this.cardRef} className="cardContents">
                          {(this.state.errorCard.cardNumber) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number")}</label>}
                          <MaskedInput type="text" value={this.state.card.cardNumber} className="pt-encrypt" mask={(this.state.americanExpress ? '111111111111111' : '1111111111111111')} labels={t("Card Number")} id="cardNumber" name="encrypted_number" size="20" onChange={(e) => this.handleChangeCard(e, t)} />
                          <Grid container item xs={12}>
                            <Grid item md={6} xs={3} >
                              {(this.state.errorCard.cardExpiryDate) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Card Expiry Date Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Expiry Date")}</label>}
                              <MaskedInput mask="11/1111" value={this.state.card.cardExpiryDate} name="cardExpiryDate" id="cardExpiryDate" placeholder="mm/yyyy" onChange={(e) => this.handleChangeCard(e, t)} />
                            </Grid>
                            <Grid item md={6} xs={3} >
                              {(this.state.errorCard.cardCsc) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("CSC")}</label>}
                              <MaskedInput type="text" value={this.state.card.cardCsc} mask={this.state.americanExpress ? "1111" : "111"} className="pt-encrypt" id="cardCsc" name="encrypted_csc" onChange={(e) => this.handleChangeCard(e, t)} />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          {(!this.state.updateCard) ? <> <Checkbox
                            id="customerToken"
                            labels="Create New User"
                            checked={this.state.card.customerToken}
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.card.customerToken}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }} />
                            <label>Save card for future payments</label></> : <> <Checkbox
                              id="customerTokenUpdate"
                              labels="Create New User"
                              checked={this.state.card.customerTokenUpdate}
                              onChange={(e) => { this.handleChangeCheckboxUpdate(e) }}
                              value={this.state.card.customerTokenUpdate}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }} /><label>Update card for future payments</label></>}
                        </CardActions>
                      </Card>

                      {/* for mask mask number */}
                      < div xs={12} className={(this.state.cardPanelShow && this.state.mask_number) ? 'block' : 'none'}>

                        <Card className={classNames([(this.state.cardPanelShow) ? 'maskData' : 'blur', 'customCardWraper'])}>
                          <div className="Card_header">

                            <Typography variant="h5" component="h2">
                              Name : {this.state.name}
                            </Typography>

                          </div>
                          <CardContent ref={this.cardRef} className="cardContents">
                            <label className="MuiTypography-gutterBottom">{t("Mask Card Number")}</label>
                            <Grid container item xs={12}>
                              {(this.state.mask_number) ? this.state.mask_number : ''}
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Buttons
                              id="AddNewCard"
                              type="button"
                              variant="contained"
                              color="secondary"
                              className={"addNewCardBtn"}
                              onClick={(e) => this.handleSubmitAddNewCard(e, t)}
                              text={t("Add Another Card")} />
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>

                  </Grid>

                </Grid>
                <Grid item md={8}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline1"
                          minDate={new Date()}
                          label={t('Invoice Date')}
                          value={(this.state.invoiceDate) ? this.state.invoiceDate : null}
                          onChange={(date) => { this.handleDateChange(date, 'invoiceDate') }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                      <Textfield
                        required={true}
                        error={this.state.error.invoiceError}
                        id="invoice"
                        type="text"
                        labels={t("Invoice")}
                        value={this.state.invoice}
                        disabled={this.state.isDisableInvoice}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          minDateMessage={'Due date should not be before invoice date'}
                          minDate={this.state.invoiceDate}
                          id="date-picker-inline"
                          label={t("Due Date")}
                          value={this.state.dueDate}
                          onChange={(date) => { this.handleDateChange(date, 'dueDate') }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                      <Textfield
                        id="PO"
                        type="text"
                        labels={t("PO")}
                        value={this.state.PO}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={6}>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <FormControl className={classes.formControl}>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Textfield
                        required={true}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        error={this.state.error.amountError}
                        id="amount"
                        type="text"
                        labels={t("Amount")}
                        value={this.state.amount}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}  >
                <div className={classes.tableWrapper}>
                  <ProductTable productFieldsAmount={(data) => this.productFieldsAmount(data)} />

                </div>
                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item md={6} xs={12}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={this.state.tabValue}
                          onChange={(e, val) => { this.handleChangeTab(e, val) }}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="action tabs example"
                        >
                          <Tab label="Public Notes" {...this.a11yProps(0)} />
                          <Tab label="Private Notes" {...this.a11yProps(1)} />
                          <Tab label="Terms" {...this.a11yProps(2)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.tabValue} index={0}>
                        <Textfield
                          error={this.state.error.publicNotesError}
                          id="publicNotes"
                          type="text"
                          labels={t("Public Notes")}
                          value={this.state.publicNotes}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                          rowsMax={'4'}
                          multiline={true}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={1}>
                        <Textfield
                          error={this.state.error.privateNotesError}
                          id="privateNotes"
                          type="text"
                          labels={t("Private Notes")}
                          value={this.state.privateNotes}
                          width={true}
                          rowsMax={'4'}
                          onChange={(e) => this.handleChange(e, t)}
                          multiline={true}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={2}>
                        <Textfield
                          error={this.state.error.termNotesError}
                          id="termNotes"
                          type="text"
                          labels={t("Term Notes")}
                          value={this.state.termNotes}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                          rowsMax={'4'}
                          multiline={true}
                        />
                      </TabPanel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container>
                        <Grid container item xs={12}>
                          <Grid item sm={4} xs={12}>

                          </Grid>
                          <Grid item sm={8} xs={12}>
                            <div className="sub-total">
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={7}>
                                  Mark As Paid
                                </Grid>
                                <Grid item xs={5}>
                                  <Checkbox
                                    id="isPaid"
                                    labels="Mark As Paid"
                                    checked={this.state.isPaid}
                                    onChange={(e) => { this.handleCheckAsPaid(e) }}
                                    value={this.state.isPaid}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }} />
                                </Grid>
                                {(this.state.feeSettings.InvoiceDiscount && this.state.feeSettings.DiscountFeeName !== "") ? <><Grid item xs={7}>
                                  {this.state.feeSettings.DiscountFeeName} ({(this.state.feeSettings.InvoiceSVCFeeType === '$') ? this.state.feeSettings.InvoiceSVCFeeType + '' + this.state.feeSettings.InvoiceSVCFee : this.state.feeSettings.InvoiceSVCFee + ' ' + this.state.feeSettings.InvoiceSVCFeeType})
                                </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      disabled={!this.props.UserDetail.permissions.svcFeeEdit}
                                      error={this.state.error.svcFeeError}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      id="svcFee"
                                      type="text"
                                      labels={t("SVC Fee")}
                                      onChange={(e) => this.handleChangeSvcFee(e, t)}
                                      value={(Number(this.state.svcFee) > 0) ? this.state.svcFee : ''}
                                    />

                                  </Grid>
                                  <Grid item xs={12}>
                                    <hr />
                                  </Grid></> : ''}

                                {this.state.feeSettings.InvoiceTax ? <> <Grid item xs={8} className="taxSelect">
                                  <label>Tax</label>
                                  <select>
                                    {this.state.feeSettings.InvoiceTax ? <option>Sales Tax {(this.state.feeSettings.InvoiceSalesTaxFeeType === '$') ? this.state.feeSettings.InvoiceSalesTaxFeeType + '' + this.state.feeSettings.InvoiceSalesTaxFee.toFixed(2) : this.state.feeSettings.InvoiceSalesTaxFee.toFixed(2) + ' ' + this.state.feeSettings.InvoiceSalesTaxFeeType}</option> : <option></option>}
                                  </select>
                                </Grid>
                                  <Grid item xs={4} className="textRight">
                                    {(this.state.tax) ? formatCurrency(this.state.tax) : '$0.00'}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <hr />
                                  </Grid></> : ''}

                                <Grid item xs={7}>
                                  Total amount
                            </Grid>
                                <Grid item xs={5}>
                                  {(this.state.total) ? formatCurrency(this.state.total) : '$0.00'}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

              <Grid item md={8} xs={12} sm={12} >

                <Buttons
                  id="emailInvoice"
                  type="submit"
                  variant="contained"
                  className={'emailInvoiceTerminal email-invoice'}
                  // className={classesI.emailInvoice}
                  onClick={(e) => this.handleSubmitEmailInvoice(e, t)}
                  startIcon={<Icon>send</Icon>}
                  text={t("Email Invoice")} />
                <Buttons
                  id="signUp"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={'emailInvoiceTerminal'}
                  // className={classesI.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  startIcon={<Icon>payment</Icon>}
                  text={t("Payment")} />
                <Buttons
                  id="signUp"
                  type="submit"
                  variant="contained"
                  className={'emailInvoiceTerminal'}
                  // className={classesI.saveDraft}
                  onClick={(e) => this.handleSubmitSaveDraft(e, t)}
                  startIcon={<SaveIcon />}
                  text={t("Save Draft")} />
                <Buttons
                  className={classesI.ml_2}
                  className={'emailInvoiceTerminal'}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={this.props.history.goBack} />
              </Grid>
            </form>

          </LoadingOverlay>
        </Container>
      </div>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<AddInvoice theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {

  return {
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { settingDataByCompanyId, getCsrfToken, getTransactionListTodayData, productList, addInvoice, customerDetailsByCompany, getInvoiceNumber })(withTranslation()(ApplyingStyles));