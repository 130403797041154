import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {  setInputState } from './../../actions/RequestFormAction';
import classes from './ServiceInfo.module.scss';
 
import {RenderMenuItem} from './../../components/RenderMenuItem';

class ServiceInfo extends React.Component {
	render () {
		const { 
			serviceType, problemType, touched,error,  neutral, doAllWheelsTurn,
			willAnyoneBeWithTheVehicle, doYouHaveKeysForVehicle, regularGasOrDiesel, doesFrontWheelsTurn,
			doesBackWheelsTurn, amount
		} = this.props.input;
		const {onChange, setInputState } = this.props
		const SERVICE_TYPE = ['Fuel/Fluids', 'Jump Start', 'Lockout', 'Towing', 'Tire Change'];
		const PROBLEM_TYPE = [
			`Won't Start`, `Belt Broken`, `Brakes`, `Engine Fire`, `Engine Problem`, `Fuel System Problem`,
			`Head / Brake Lights`, `Ignition Problems`, `Items Hanging`, `Key Stuck in Ignition`,
			`Multiple Tire / No Spare`, `Oil / Fuel Leak`, `Overheating`, `	Stuck in Park / Gear`,
			`Tire Pressure Low`, `Transmission Problem`, `Vandalism`, `Windshield (Cracked / Broken)`, `Other`
		];
		const NEW_DATA = [`YES`, `NO`];
		const FUEL_DATA = [`REGULAR GAS`, `DIESEL GAS`];
		return (
			<div>
			<strong> Service Info. </strong> <br />
			<i> Pricing may increase due to additional equipment needs </i>
			<div className={classes.service_container} style={{ marginTop: '1rem', marginBottom: '1.25rem' }}>
				<FormControl fullWidth>
					<Grid container align="center">
						<Grid item xs={1} sm={2} md={2} />

						<Grid item xs={5} sm={4} md={4}>
							<FormControl style={{width: '100%'}} error={error['serviceType']} required>
								<InputLabel htmlFor="serviceType-required">{'Service Type'}</InputLabel>
								<Select
									value={serviceType} name="serviceType" inputProps={{ id: 'serviceType-required' }}
									style={{ marginRight: '1rem' }}	
									onChange={
										event => {
											setInputState(event.target.name, event.target.value);

											if (parseFloat(amount) > 0) { setInputState('amount', ''); }
										}
									}
								>
									<MenuItem value=''> <em>{''}</em> </MenuItem>
									{ RenderMenuItem(SERVICE_TYPE) }
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={5} sm={4} md={4}>
							{
								(serviceType === 'Towing') ?
									(<FormControl style={{width: '100%'}} error={error['problemType']} required>
										<InputLabel htmlFor="problemType-required">{'Problem Type'}</InputLabel>
										<Select
											value={problemType} name={'problemType'} inputProps={{ id: 'problemType-required' }}
											onChange={onChange}
										>
											<MenuItem value=''> <em>{''}</em> </MenuItem>
											{ RenderMenuItem(PROBLEM_TYPE) }
										</Select>
									</FormControl>)
									: ''
							}
						</Grid>

						<Grid item xs={1} sm={2} md={2} />

						<Grid item xs={1} sm={2} md={2} />

						<Grid item xs={5} sm={4} md={4}>
							<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['willAnyoneBeWithTheVehicle']} required>
								<InputLabel htmlFor="willAnyoneBeWithTheVehicle-required">{'Will anyone be with the vehicle'}</InputLabel>
								<Select
									value={willAnyoneBeWithTheVehicle} name="willAnyoneBeWithTheVehicle" 
									inputProps={{ id: 'willAnyoneBeWithTheVehicle-required' }}
									style={{ marginRight: '1rem' }}	
									onChange={
										event => {
											if (event.target.name === 'willAnyoneBeWithTheVehicle' && event.target.value !== 'YES') 
												alert('Service will not be performed on unattended vehicles');

											setInputState(event.target.name, event.target.value);
										}
									}
								>
									<MenuItem value=''> <em>{''}</em> </MenuItem>
									{ RenderMenuItem(NEW_DATA) }
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={5} sm={4} md={4}>
							<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['doYouHaveKeysForVehicle']} required>
								<InputLabel htmlFor="doYouHaveKeysForVehicle-required">
									{'Do you have keys for the vehicle'}
								</InputLabel>
								<Select
									value={doYouHaveKeysForVehicle} name="doYouHaveKeysForVehicle" 
									inputProps={{ id: 'doYouHaveKeysForVehicle-required' }}
									style={{ marginRight: '1rem' }}	
									onChange={ 
										(event) => {
											setInputState(event.target.name, event.target.value);
											if (parseFloat(amount) > 0) { setInputState('amount', ''); }
										} 
									}
								>
									<MenuItem value=''> <em>{''}</em> </MenuItem>
									{ RenderMenuItem(NEW_DATA) }
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={1} sm={2} md={2} />

						{(serviceType === 'Fuel/Fluids') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}

						{(serviceType === 'Fuel/Fluids') ? (					
							<Grid item xs={5} sm={4} md={4}>
								<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['regularGasOrDiesel']} required>
									<InputLabel htmlFor="regularGasOrDiesel-required">
										{'Do you need regular gas or diesel?'}
									</InputLabel>
									<Select
										value={regularGasOrDiesel} name="regularGasOrDiesel" 
										inputProps={{ id: 'regularGasOrDiesel-required' }}
										style={{ marginRight: '1rem' }}	
										onChange={
											event => {
												if (event.target.name === 'regularGasOrDiesel' && event.target.value !== 'REGULAR GAS') {
													alert('Service will not be performed, we cannot service diesel engines');
													
													// const option = confirm("Press OK button to convert the service type into Towing!");
													// if (option) { setInputState('serviceType', 'Towing'); }
												} 

												setInputState(event.target.name, event.target.value);
											}
										}
									>
										<MenuItem value=''> <em>{''}</em> </MenuItem>
										{ RenderMenuItem(FUEL_DATA) }
									</Select>
								</FormControl>
							</Grid>
						 ) : ''}

						{(serviceType === 'Fuel/Fluids') ? (
							<Grid item xs={5} sm={4} md={4} />
						) : ''}

						{(serviceType === 'Fuel/Fluids') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}

						{(serviceType === 'Towing') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}

						{(serviceType === 'Towing') ? (
							<Grid item xs={5} sm={4} md={4}>
								<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['neutral']} required>
									<InputLabel htmlFor="neutral-required">
										{'Will the vehicle go in neutral?'}
									</InputLabel>
									<Select
										value={neutral} name="neutral" 
										inputProps={{ id: 'neutral-required' }}
										style={{ marginRight: '1rem' }}	
										onChange={ 
											(event) => {
												setInputState(event.target.name, event.target.value);
												if (parseFloat(amount) > 0)
													setInputState('amount', '');
											}	
										}
									>
										<MenuItem value=''> <em>{''}</em> </MenuItem>
										{ RenderMenuItem(NEW_DATA) }
									</Select>
								</FormControl>
							</Grid>
						) : ''}

						{(serviceType === 'Towing') ? (
							<Grid item xs={5} sm={4} md={4}>
								<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['doAllWheelsTurn']} required>
									<InputLabel htmlFor="doAllWheelsTurn-required">
										{'Do all four wheels on the vehicle turn?'}
									</InputLabel>
									<Select
										value={doAllWheelsTurn} name="doAllWheelsTurn" 
										inputProps={{ id: 'doAllWheelsTurn-required' }}
										style={{ marginRight: '1rem' }}
										onChange={ onChange }
									>
										<MenuItem value=''> <em>{''}</em> </MenuItem>
										{ RenderMenuItem(NEW_DATA) }
									</Select>
								</FormControl>
							</Grid>
						) : ''}

						{(serviceType === 'Towing') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}

						{(doAllWheelsTurn === 'NO') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}

						{(doAllWheelsTurn === 'NO') ? (
						<Grid item xs={5} sm={4} md={4}>
							<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['doesFrontWheelsTurn']} required>
								<InputLabel htmlFor="doesFrontWheelsTurn-required">
									{'Will both front wheels turn?'}
								</InputLabel>
								<Select
									value={doesFrontWheelsTurn} name="doesFrontWheelsTurn" 
									inputProps={{ id: 'doesFrontWheelsTurn-required' }}
									style={{ marginRight: '1rem' }}	
									onChange={ 
										(event) => {
											setInputState(event.target.name, event.target.value);
											if (parseFloat(amount) > 0)
												setInputState('amount', '');
										} 
									}
								>
									<MenuItem value=''> <em>{''}</em> </MenuItem>
									{ RenderMenuItem(NEW_DATA) }
								</Select>
							</FormControl>
						</Grid>
						) : ''}

						{(doAllWheelsTurn === 'NO') ? (
							<Grid item xs={5} sm={4} md={4}>
								<FormControl style={{width: '100%', marginTop: '1rem'}} error={error['doesBackWheelsTurn']} required>
									<InputLabel htmlFor="doesBackWheelsTurn-required">
										{'Will both back wheels turn?'}
									</InputLabel>
									<Select
										value={doesBackWheelsTurn} name="doesBackWheelsTurn" 
										inputProps={{ id: 'doesBackWheelsTurn-required' }}
										style={{ marginRight: '1rem' }}	
										onChange={
											(event) => {
												setInputState(event.target.name, event.target.value);
												if (parseFloat(amount) > 0)
													setInputState('amount', '');
											}
										}
									>
										<MenuItem value=''> <em>{''}</em> </MenuItem>
										{ RenderMenuItem(NEW_DATA) }
									</Select>
								</FormControl>
							</Grid>
						) : ''}

						{(doAllWheelsTurn === 'NO') ? (
							<Grid item xs={1} sm={2} md={2} />
						) : ''}
					</Grid>
				</FormControl>
			</div>
		</div>
		);
	}
}

// const mapStateToProps = ({ input }) => (
// 	{ 
// 		serviceType: input.serviceType, problemType: input.problemType, touched: input.touched, regularGasOrDiesel: input.regularGasOrDiesel,
// 		willAnyoneBeWithTheVehicle: input.willAnyoneBeWithTheVehicle, doYouHaveKeysForVehicle: input.doYouHaveKeysForVehicle, neutral: input.neutral,
// 		doAllWheelsTurn: input.doAllWheelsTurn, doesFrontWheelsTurn: input.doesFrontWheelsTurn, doesBackWheelsTurn: input.doesBackWheelsTurn,
// 		amount: input.amount
// 	}
// );
function mapStateToProps(state) {
	return {
	  input: state.InputReducer,
	};
  }
  

  
  export default  connect(mapStateToProps,{setInputState})(withTranslation()(ServiceInfo));
 