import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Textfield } from './../../components/Textfield';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

class CallerInfo extends Component {
 

  render() {  
 
    const {error, firstName, lastName, phoneNumber, touched  } = this.props.input;
    const { t ,onChange} = this.props;
    return (
      <div>
      <strong> Caller Info. </strong>
      <FormControl fullWidth>
        <Grid container align="center">
          <Grid item xs={1} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container align='center'>
              <Grid item xs={12} sm={4} md={4}>
                <Textfield
                    required={true} 
                    error={error['firstName']}
                    id={'firstName'}
                    type="text"
                    labels={t("First Name")}
                    value={firstName}
                    width={true}
                    onChange={onChange}
                    focus={true} 
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Textfield
                     required={true}
                     error={error['lastName']}
                     id="lastName"
                     type="text"
                     labels={t("Last Name")}
                     value={lastName}
                     width={true}
                     onChange={onChange}
                    //  focus={true} 
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Textfield
                    required={true}
                    error={error['phoneNumber']}
                    id="phoneNumber"
                    type="text"
                    labels={t("Phone Number")}
                    value={phoneNumber}
                    width={true}
                    onChange={onChange}
                    // focus={true} 
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={2} md={2} />
        </Grid>
      </FormControl>
    </div>
    );
  }
}

CallerInfo.propTypes = {
  t: PropTypes.func
}
// const mapStateToProps = ({input}) => (
// 	{ firstName: input.firstName, lastName: input.lastName, phoneNumber: input.phoneNumber, touched: input.touched }
// );
 
function mapStateToProps(state) {
  return {
    input: state.InputReducer,
  };
}

export default  connect(mapStateToProps)(withTranslation()(CallerInfo));