import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import classes from './SecureUserCard.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';  
import { Textfield } from './../../components/Textfield';
import { hashSecureCard ,secureCardAdd ,getPaymentVaribale} from '../../actions/PaymentAction'
import { UserCardForm } from './../../helpers/UserCardForm'
import queryString from 'query-string'; 
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import LoadingOverlay from 'react-loading-overlay';


class SecureUserCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      isLoading: true,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: null,
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      CardData: [],
      isSubmit: false,
      zip: '',
      timeZone: new Date(),
      misMatchError: [],
      successMessages: false,
      error: {
        nameError: false,
        dbaNameError: false,
        emailError: false,
        contactNameError: false,
        websiteError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      }
    }
    this.paymentRef= React.createRef();
  }

  UNSAFE_componentWillMount(){
    let params = queryString.parse(this.props.location.search)
    if(params && Object.keys(params).length>0)
    {
      this.props.secureCardAdd(params).then((res)=>{
        
        this.setState({ data: res.payload.paymentData.data,  successMessages:true,isLoading: false })
      }).catch((err)=>{ 
        
        this.setState({errorMsg:this.props.info.secureCardInfo.data.message,isError:true})
      })
    }
  }
  componentDidMount() {
    this.getSecureCardDetails();
    
  }

  getSecureCardDetails() {
    this.setState({ isLoading: true })
    this.props.hashSecureCard().then((res) => {
      
      this.setState({ CardData: res.payload.paymentData.data,isLoading: false })
    })
  }
  handleSubmit = () => {
    this.paymentRef.current.submit()
 }

  
 
  handleChange(event) {
    let error= []
    this.setState({ [event.target.id]: event.target.value, responseError: false,error:error });
  }
  
  render() {
    const { t } = this.props;
    const { CardData } = this.state;
  
    return (
      <div className="rightPanel">
        <Topheader />
        <Container component="main">
        {this.state.successMessages ?
            <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} /> : ''
          }
        <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
          <form className={classes.form} noValidate>
            <Grid container spacing={3} justify="flex-start"
              alignItems="center">
              
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.dbaNameError}
                  id="firstName"
                  type="text"
                  labels={t("First Name")}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.contactNameError}
                  id="contactName"
                  type="text"
                  labels={t("Last Name")}
                  value={this.state.contactName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={6}>
              <Textfield
                  required={true}
                  error={this.state.error.mccError}
                  id="mcc"
                  type="text"
                  labels={t("Email")}
                  value={this.state.mcc}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.websiteError}
                  id="website"
                  type="number"
                  labels={t("Phone")}
                  value={this.state.website}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={6}>
              <Textfield
                  required={true}
                  error={this.state.error.amountError}
                  id="amount"
                  type="amount"
                  labels={t("Amount")}
                  value={this.state.amount}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
                
              </Grid>
              <Grid item xs={12} >
              <UserCardForm merchantRef={CardData.merchantref} hash={CardData.hash} date={CardData.date} />
              
                {/* <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Save")} /> */}
                {/* <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={this.props.history.goBack} /> */}
              </Grid>
            </Grid>

          </form>

        </LoadingOverlay>
        </Container>
      </div>
    )
  }
}
function mapStateToProps(state) {
  
  return {
    info: state.PaymentReducer
  };
}

export default connect(mapStateToProps, { hashSecureCard ,secureCardAdd ,getPaymentVaribale})(withTranslation()(SecureUserCard));