import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserEdit,UserResendActivationLink } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      role: 2,
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      permissions:{
        creatNewUser: false,
        allFeatures: false,
        refunds: false,
        allLocaton: false,
      },
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
    }
  }
  componentDidMount() {
    this.getUserData()
  }
  getUserData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.UserDetailById(params).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false })
    });
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData()
  }
  handleChangeCheckbox(event) {
    this.setState({permissions:{...this.state.permissions, [event.target.id]: event.target.checked }});

  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    this.setState({ [id]: value, error, misMatchError });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    Object.keys(state).map((key, value) => state[key] === '' ? (error[key + 'Error'] = true, errorFlag = true) : '');

    if (!state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      misMatchError[0] = t("EmailNotValid");
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {

      this.props.UserEdit(this.state).then(result => {
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });
    }
  }
  handleSubmitResendLink(e,t){
    let successMessages = false;
    this.props.UserResendActivationLink(this.state.email).then(result => {
      if (result !== undefined && result.type === 'USER_SUCCESS') {
        this.setState({ successMessages: true });
      }
      if (result !== undefined && result.type === 'USER_ERROR') {
        this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
      }
    });
  }

  render() {
    const { t } = this.props;

    return (

      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <Container component="main" maxWidth="xl" >
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[(this.props.info.userInfo)?this.props.info.userInfo.data.message:'']} /> : ''
            }
            <form className={classes.form} noValidate>
              <Grid container spacing={3} >
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.userNameError}
                    id="userName"
                    type="text"
                    labels={t("User Name")}
                    value={this.state.userName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.firstNameError}
                    id="firstName"
                    type="text"
                    labels={t("First Name")}
                    value={this.state.firstName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid item xs={6} sm={3}>
                </Grid>
                <Grid item xs={6} sm={3}>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.lastNameError}
                    id="lastName"
                    type="text"
                    labels={t("Last Name")}
                    value={this.state.lastName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.emailError}
                    id="email"
                    type="email"
                    labels={t("Email")}
                    value={this.state.email}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid xs={12} >

                  <Grid item xs={6} sm={3} justify="space-evenly">
                    <label>
                      <span>Create New User</span>
                      <Checkbox
                        id="creatNewUser"
                        labels="Create New User"
                        checked={this.state.permissions.creatNewUser}
                        onChange={(e) => { this.handleChangeCheckbox(e) }}
                        value={this.state.creatNewUser}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <label>
                      <span>All Features</span>
                      <Checkbox
                        id="allFeatures"
                        checked={this.state.permissions.allFeatures}
                        onChange={(e) => { this.handleChangeCheckbox(e) }}
                        value={this.state.allFeatures}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />

                    </label>
                  </Grid>
                  <Grid >
                    <label>
                      <span>Refunds</span>
                      <Checkbox
                        id="refunds"
                        checked={this.state.permissions.refunds}
                        onChange={(e) => { this.handleChangeCheckbox(e) }}
                        value={this.state.refunds}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                    </label>
                  </Grid>
                  <Grid >
                    <label>
                      <span>All Location</span>
                      <Checkbox
                        id="allLocaton"
                        checked={this.state.permissions.allLocaton}
                        onChange={(e) => { this.handleChangeCheckbox(e) }}
                        value={this.state.allLocaton}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />

                    </label>
                  </Grid>
                </Grid>

                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Save")} />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={this.props.history.goBack} />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="primary"
                  text={t("Resend Link")}
                  onClick={(e) => this.handleSubmitResendLink(e, t)} />
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>

    )
  }
}
EditUser.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { UserDetailById, UserEdit ,UserResendActivationLink})(withTranslation()(EditUser));
