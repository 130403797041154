import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Textfield } from './../../components/Textfield';
import TypableSelect from './TypableSelect';
import { setInputState } from './../../actions/RequestFormAction';
 
const mapToFormat = (values) => {
	
	const res = [];

	for (let i = 0; i < values.length; i++) {
		res.push({ value: values[i], label: values[i] });
	}

	return res;
};

const getValidationStyle = cond => {
	if (cond) {
		return { width: '100%', marginTop: '1.6rem', border: '2px solid red' };
	} else {
		return { width: '100%', marginTop: '2.80rem' };
	}
};


class VehicleInfo extends React.Component {
	OnchangeDropDown(val){
		this.props.setInputState('make',val)
	} 
	render () {
		const { year, make, model, color,  touched,error,  } = this.props.input;
		const { onChange,t,setInputState} = this.props;
		

		const MAKE = [
			`AM General`, `AMC`, `Acura`, `Alfa Romeo`, `Aston Martin`, `Audi`, `BACKDRAFT`, `BMW`, `Bentley`, `Buick`,
			`Cadillac`, `Chevrolet`, `Chrysler`, `Daewoo`, `Daihatsu`, `Datsun`, `Dodge`, `EAGLE`, `EXCALIBUR`, `FIAT`,
			`Ferrari`, `Ford`, `GEO`, `GMC`, `GRUMMAN`, `Honda`, `Hummer`, `Hyundai`, `INTERNATIONAL`, `Infiniti`, `Isuzu`,
			`Jaguar`, `Jeep`, `Kia`, `Lamborghini`, `Land Rover`, `Lexus`, `Lincoln`, `Lotus`, `MARUTI`, `MG`, `Maserati`,
			`Maybach`, `Mazda`, `Mercedes Benz`, `Mercury`, `Merkur`, `Mini`, `Mitsubishi`, `Nissan`, `OTHER`, `Oldsmobile`,
			`Peugeot`, `Plymouth`, `Pontiac`, `Porsche`, `Rolls Royce`, `Saab`, `Saturn`, `Scion`, `Smart`, `Sterling`,
			`Subaru`, `Suzuki`, `Tesla`, `Toyota`, `Volkswagen`, `Volvo`, `WHEEGO`, `WILLYS`, `Yugo`
		];

		const COLOR = [
			`BEIGE`, `BLACK`, `BLUE`, `BROWN`, `BURGUNDY`, `CHAMPAGNE`, `GOLD`, `GRAY`, `GRAY-VIOLET`, `GREEN`,
			`LIGHT BLUE`, `LIGHT BROWN`, `LIGHT GRAY`, `LIGHT GREEN`, `MAROON`, `OLIVE-GREEN`, `ORANGE`, `PEARL-WHITE`,
			`PEWTER`, `PINK`, `PURPLE`, `RED`, `SAND`, `SILVER`, `TAN`, `TEAL`, `WHITE`, `YELLOW`
		];
		return (
			<div>
				<strong> Vehicle Info. </strong>
				<FormControl fullWidth>
					<Grid container align="center">
						<Grid item xs={1} sm={2} md={2} />
						<Grid item xs={10} sm={8} md={8}>
							<Grid container align='center'>
								<Grid item xs={12} sm={4} md={4}>
									<Textfield
										required={true}
										error={error['year']}
										id="year"
										type="text" 
										labels={t("Year")}
										value={year}
										width={true}
										onChange={onChange}
									/>
								</Grid>
								<Grid className='custom-liststyle' item xs={12} sm={4} md={4}>
								<FormControl 
										// style={getValidationStyle(!make && touched['make'])}
										style={getValidationStyle(error['make'])} error={error['make']} required
									>

										<TypableSelect  
											value={make} placeholder={'Make *'}	suggestions={mapToFormat(MAKE)} 
											onChange={val => this.OnchangeDropDown(val)} // isInvalid={!make && touched['make']}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4} md={4}>
									<Textfield
									required={true}
									error={error['model']}
									id="model"
									type="text"
									labels={t("Model")}
									value={model}
									width={true}
									onChange={onChange}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1} sm={2} md={2} />
					</Grid>
					<Grid container align="center">
						<Grid item xs={1} sm={2} md={2} />

						<Grid className='custom-liststyle' item xs={10} sm={2} md={2}>
						<FormControl  error={error['color']}
						// style={{width: '100%', marginTop: '1rem'} }
								style={getValidationStyle(error['color'])} 
							>
								<TypableSelect 
									value={color} placeholder={'Color *'} suggestions={mapToFormat(COLOR)} 
									onChange={val => setInputState('color', val)} // isInvalid={!make && touched['make']}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={1} sm={2} md={2} />
					</Grid>
				</FormControl>
			</div>
		);
	}
}
 
VehicleInfo.propTypes = {
	t: PropTypes.func
  }
//   const mapStateToProps = ({ input: { year, make, model, color, touched } }) => ({ year, make, model, color, touched });
function mapStateToProps(state) {
	return {
	  input: state.InputReducer,
	};
  }
  

export default  connect(mapStateToProps,{setInputState})(withTranslation()(VehicleInfo));