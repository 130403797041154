import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Work from '@material-ui/icons/Work';
import Face from '@material-ui/icons/Face';
import LocationCity from '@material-ui/icons/LocationCity';
import { config } from '../../constants'
import MenuList from '@material-ui/core/MenuList';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PagesIcon from '@material-ui/icons/Pages';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { UserDetailById } from '../../actions/AdminUserAction'
import ListAltIcon from '@material-ui/icons/ListAlt';

const menuItemsSuperAdmin = [
	'Dashboard',
	'Company',
	'SubAdmin',
	'Tickets',
	'Pages',
	'Reporting'
];
const menuItemsSubAdmin = [
	'Dashboard',
	'Company',
	'Reporting'
];
const menuItemsAdmin = [
	'Dashboard',
	'Terminal',
	// 'Invoices',
	// 'Recurring',
	'Transactions',
	'Customers',
	'Users',
	'Locations',
	'Products',
	'Settings',
	'Tickets',
	'Demo',
	'Reporting'
];
const menuItemsManager = [
	'Dashboard',
	'Terminal',
	// 'Invoices',
	// 'Recurring',
	'Transactions',
	'Customers',
	'Users',
	'Locations', 
	'Tickets',
	'Demo',
	'Reporting'
];



const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			position: 'relative',
		},
		menuitem: {
			color: '#aaa',
		},
		menuicon: {
			marginRight: theme.spacing(1.5),
		},

	}),
);


class SideMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			user: JSON.parse(localStorage.getItem('user')),
			active: 'Dashboard',
			location:''
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot){	 
		let menuItem = this.props.location.pathname.split("/")
		if( menuItem[1]){
			if (this.state.active !== menuItem[1]) {
				this.setState({ active: menuItem[1] ? menuItem[1] : "" });
			}
		}
	}

	componentDidMount() {
		let Userdata = JSON.parse(localStorage.getItem('user'));
		let data = Userdata.location.length > 0 ? Userdata.location: '';
		if (data && data.length > 0) {
			this.setState({ locationSelected: Object.keys(data).map((k, v) => data[k].id) })
		}
		let menuItem = this.props.location.pathname.split("/")
		this.setState({ active: menuItem[1] ? menuItem[1] :"" });
	}

	handleClick = (menuItem) => {
		this.setState({ active: menuItem });
	}

	render() {
		const { classes } = this.props;
		const activeStyle = { background: 'rgb(68, 56, 56)' };
		const permission = this.state.user.permissions.virtualTerminal;
		const modiFiedAdmin = menuItemsAdmin.filter((item) => {
			if (item === 'Reporting' || item === 'Products' || item === 'Tickets' || item === 'Settings' || item === 'Locations' || item === 'Users' || item === 'Customers' || item === 'Transactions' || (permission && item === 'Terminal')  || item === 'Dashboard') {
				return item;
			}
		})
		const modiFiedManager = menuItemsManager.filter((item) => {
			if (item === 'Reporting' || item === 'Tickets' || item === 'Settings' || item === 'Locations' || (this.state.user.role === config.MANAGER_ROLE && item === 'Users') || item === 'Customers' || 
			 item === 'Transactions' || (permission && item === 'Terminal')  || item === 'Dashboard') {
				return item;
			}
		})
		return (
			<div className="sidebar">
				<div className="logoBox cbr-logo-round">
					<Link component={RouterLink} to={'/Dashboard'}><img alt="logo" src={(this.state.user.hasOwnProperty('company') && this.state.user.company.image) ? config.PAYTRACE_AWS_PATH + this.state.user.company.image : config.APPURL + '/assets/images/logo.png'} /></Link>
				</div>
				<div className="sidebarMenu">
					{(this.state.user.role === config.ADMIN_ROLE) ? <MenuList>
						{modiFiedAdmin.map((menuItem, i) =>
							<Link key={menuItem + i} component={RouterLink} onClick={() => this.handleClick(menuItem)} to={'/' + menuItem} >
								<MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
									{(menuItem === 'Dashboard') ? <Face className={classes.menuicon} /> : ''}
									{menuItem === 'Terminal' ? <LocalAtmIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Recurring' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Transactions' ? <CreditCardIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Customers' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
									{menuItem === 'Users' ? <SupervisorAccount className={classes.menuicon} /> : ''}
									{menuItem === 'Locations' ? <LocationCity className={classes.menuicon} /> : ''}
									{menuItem === 'Products' ? <BusinessCenterIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Settings' ? <SettingsIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Reporting' ? <ListAltIcon className={classes.menuicon} /> : ''}
									{menuItem}</MenuItem>

							</Link>
						)}
					</MenuList> : ''}
					{(this.state.user.role === config.MANAGER_ROLE || this.state.user.role === config.REGULAR_USER_ROLE) ? <MenuList>
						{modiFiedManager.map((menuItem, i) =>
							<Link key={menuItem + i} component={RouterLink} onClick={() => this.handleClick(menuItem)} to={'/' + menuItem} >
								<MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
									{menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
									{menuItem === 'Terminal' ? <LocalAtmIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Recurring' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Transactions' ? <CreditCardIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Customers' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Users' ? <SupervisorAccount className={classes.menuicon} /> : ''}
									{menuItem === 'Locations' ? <LocationCity className={classes.menuicon} /> : ''}
									{menuItem === 'Settings' ? <SettingsIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Reporting' ? <ListAltIcon className={classes.menuicon} /> : ''}
									{menuItem}</MenuItem>
							</Link>
						)}
					</MenuList> : ''}

					{this.state.user.role === config.SUPER_ADMIN_ROLE ? <MenuList>
						{menuItemsSuperAdmin.map((menuItem, i) =>
							<Link key={menuItem + i} component={RouterLink} onClick={() => this.handleClick(menuItem)} to={'/' + menuItem}>
								<MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}  >
									{menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
									{menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
									{menuItem === 'SubAdmin' ? <SupervisorAccount className={classes.menuicon} /> : ''}
									{menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Pages' ? <PagesIcon className={classes.menuicon} /> : ''}
									{menuItem === 'Reporting' ? <ListAltIcon className={classes.menuicon} /> : ''}
									{menuItem}</MenuItem>
							</Link>
						)}

					</MenuList> : ''}
					{this.state.user.role === config.SUB_ADMIN_ROLE ? <MenuList>
						{menuItemsSubAdmin.map((menuItem, i) =>
							<Link key={menuItem + i} component={RouterLink} onClick={() => this.handleClick(menuItem)} to={'/' + menuItem}>
								<MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}  >
									{menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
									{menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
									{menuItem === 'Reporting' ? <ListAltIcon className={classes.menuicon} /> : ''}
									{menuItem}</MenuItem>
							</Link>
						)}
					</MenuList> : ''}
				</div>
				<div className="bottomLogo">
					<img alt="logo" src={config.PAYTRACE_AWS_PATH + 'bottomLogo.png'} />
					<p style={{color:'#ffff'}}>Beta version {config.VERSION} </p>
				</div>
				
			</div>

		);
	}
}
const ApplyingStylesOnClasses = (props) => {
	const classes = useStyles()
	return (<SideMenu classes={classes} {...props} />)
}

function mapStateToProps(state) {
	return {
		info: state.AdminUserReducer
	};
}
export default connect(mapStateToProps, { UserDetailById })(withTranslation()(withRouter(ApplyingStylesOnClasses)));