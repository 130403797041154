import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Textfield } from './../../components/Textfield';
import Button from '@material-ui/core/Button';
import classes from './Login.module.scss';
import { connect } from 'react-redux';
import { login } from './../../actions/LoginAction';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LoadingOverlay from 'react-loading-overlay';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // email: props.email,
      userName: '',
      password: '',
      responseError: false,
      isLoading: false
    }
  }
  handleChange(event) {
    let error = []
    this.setState({ [event.target.id]: event.target.value, responseError: false, error: error });
  }
  componentDidMount() {  
    if(!this.state.userName)   
    this.setState({userName:this.props.location.state})
    let user = JSON.parse(localStorage.getItem('token'));
    let userses = JSON.parse(sessionStorage.getItem('token'));
    // console.log(user, userses, "user, userses");
    if (userses !== null) {
      window.location.href = "/Dashboard";
    }   
  }
  onLoginSubmit = e => {
    e.preventDefault();
    this.setState({ responseError: false , isLoading: true});
    let state = this.state;
    let error = {};
    let errorFlag = false;
    Object.keys(state).map((key, value) => state[key] === '' ? (error[key + 'Error'] = true, errorFlag = true) : '');
    if (errorFlag) {
      this.setState({ error });
      this.setState({isLoading: false});
      return false;
    } else {
      this.props.dispatch(login(this.state, this.props)).then(response => {
        if (response !== undefined && response.type === 'LOGIN_ERROR') {           
          this.setState({isLoading: false});
          this.setState({ responseError: true });
        }
        this.setState({isLoading: false});
      });
    }
  }
  render() {
    const { t } = this.props;
    const { imageStyle } = styles;
    return (

      <div className={classes.login} >
        <LoadingOverlay  active={this.state.isLoading} spinner  styles={{wrapper: {} }} text='Logging...'>
        <Container component="main" maxWidth="xs" >
          <div className={classes.paper}>
            <Link component={RouterLink} to={'/'}><img src="/assets/images/logo.png" style={imageStyle} height={'100rem'} alt="logo" /></Link>
            

            <form className={classes.form} noValidate onSubmit={this.onLoginSubmit}>
              <Textfield
                required={true}
                error={this.state.error ? this.state.error.userNameError : null}
                id="userName"
                type="text"
                labels={t("Username Or Email")}
                // value={this.state.userName?this.state.userName:this.state.login}
                // value={this.state.userName?this.state.userName:this.state.login?this.state.login:this.props.location.state}
                value={this.state.userName?this.state.userName:this.props.location.state}
                width={true}
                onChange={(e) => this.handleChange(e)}
                focus={true} />
              <Textfield
                required={true}
                error={this.state.error ? this.state.error.passwordError : null}
                id="password"
                type="password"
                labels={t("Password")}
                value={this.state.password}
                width={true}
                onChange={(e) => this.handleChange(e)}
                focus={false} />
              <Grid item xs={12}>
                <Button
                  id="signIn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >{t("Sign In")}</Button>
              </Grid>
              <Grid item xs>
                <Link className={classes.forget} component={RouterLink} to={'/ForgotPassword'}>Forgot Password?</Link>
                <Link className={classes.back} component={RouterLink} to={'/'}>Back</Link>
              </Grid>

            </form>
            

          </div>

        </Container>
        {
          this.state.responseError ?

            <ErrorMessage errors={[this.props.info.loginInfo.data.message]} className="error" />
            : ''
        }
      </LoadingOverlay>
      </div>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func
}
const styles = {
  imageStyle: { maxWidth: '95%', marginLeft: '36%' },
};
function mapStateToProps(state) {
  return {
    info: state.LoginReducer
  };
}

export default connect(mapStateToProps)(withTranslation()(Login));