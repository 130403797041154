import React from 'react';


const QuoteExpired = ({data}) => (
    <div style={{ backgroundColor: '#FFF' }}>
        <div style={styles.awwwSnapContainerStyle}>
            <b style={{ fontSize: '1.3rem', ...styles.bold }}> Awww snap! </b> <br />
            <img src={'/assets/images/traffic_cones_3.png'} alt='Three cones image' />
        </div>

        <div style={styles.errorTextContainerStyle}>
            <b style={{ color: '#F00', fontSize: '1.5rem' }}> This quote has expired. </b>
            <p style={styles.bold} className="error-quote-message">
               {data.phoneNumber} Please call as soon as possible, we may be able to still honor the rate and find a new driver for your service call
            </p>
        </div>

        {
              (data.phoneNumber ? 
                (
                    <span style={styles.clickToCallContainerStyle}>
                        <a href={`tel: ${data.phoneNumber}`} style={styles.linkStyle}>
                            {`Click to Call ${data.phoneNumber}`}
                        </a>
                    </span>
                )
                : '') 
             
        }
        
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <img src={'/assets/images/traffic_cone_UP.png'} alt='UPRIGHT CONE' />
            <img src={'/assets/images/traffic_cone_LEFT.png'} alt='LEFT POSITIONED CONE' />
            <img src={'/assets/images/traffic_cone_RIGHT.png'} alt='RIGHT POSITIONED CONE' />
        </div>
        <hr />
    </div>
);

const styles = {
    awwwSnapContainerStyle: {
        display: 'flex', alignItems: 'flex-start', 
        justifyContent: 'space-around', margin: '0.6rem'
    },
    bold: { fontWeight: 'bold' },
    errorTextContainerStyle: {
        backgroundColor: '#F8FE4D', padding: '0.7rem', margin: '0.5rem'
    },
    clickToCallContainerStyle: {
        display: 'flex', justifyContent: 'center'
    },
    linkStyle: { 
        backgroundColor: '#F9C218',	color: '#000', padding: '0.5rem', borderRadius: '10px',
        textDecoration: 'none', fontWeight: 'bold', border: '5px solid black'
    }
};

export default QuoteExpired;
