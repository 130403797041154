import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl';

class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps.data[0].length !== this.props.data[0].length) {
      this.setState({ data: this.props.data })
     
    }
  }

  render() {
    return (
      <FormControl>
        <InputLabel shrink htmlFor="age-label-placeholder">History</InputLabel>
        <Select style={{ marginRight: '1rem' }} value="">
          {this.state.data[0].length ? this.state.data[0].map(item => {
 
            return <MenuItem key={item._id} value={item._id}>
              {item.name} {Date(item.updatedAt)}</MenuItem>
          }) : null}
        </Select>
      </FormControl>

    )

  }
}



export default History; 