import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Topheader } from "../../components/Topheader";
import LoadingOverlay from "react-loading-overlay";
import { find as _find } from "lodash";
import { Buttons } from './../../components/Buttons';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  createMuiTheme,
  Grid,
  MuiThemeProvider,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import { SuccessMessage } from "../../components/Message/SuccessMessage";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { config } from "../../constants";
import { DataGetApi } from "../../helpers/PostDataApi";
import { formatCurrency } from "../../helpers/commonFunction";
import { map as _map } from "lodash";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";



const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rootBatch: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));


function BatchDetails(props) {
  // console.log(props, " <<<<<<<<<<<<<<<<<< props");
  const { data } = props.batchInfo.batchInfo;
  const { batchData } = props;
  const batchDetails = typeof data !== "undefined" ? data.data.batches : [];
  let batchDetailsArray = _find(batchDetails, { number: +props.batchId });
  return (
    <Box
      boxShadow={3}
      bgcolor="background.paper"
      m={1}
      p={1}
      style={{ "marginLeft": "1px", "marginRight": 0 }}
    >
      <Container fixed>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={3}>
            Batch number : {batchDetailsArray ? batchDetailsArray.number : (( batchData && batchData.number)? batchData.number:"")}
          </Grid>
          <Grid item xs={3}>
            Transaction Count :{" "}
            {batchDetailsArray ? batchDetailsArray.transaction_count :  (( batchData &&  batchData.transaction_count)? batchData.transaction_count:"")}
          </Grid>
          <Grid item xs={3}>
            Net Amount :{" "}
            {batchDetailsArray
              ? formatCurrency(batchDetailsArray.net_amount)
              :  (( batchData && batchData.net_amount)?formatCurrency( batchData.net_amount):"")}
          </Grid>
          <Grid item xs={3}>
            Sales Count :{" "}
            {batchDetailsArray ? batchDetailsArray.sales_count :  (( batchData && batchData.sales_count)? batchData.sales_count:"")}
          </Grid>
          <Grid item xs={3}>
            Sales Amount :{" "}
            {batchDetailsArray
              ? formatCurrency(batchDetailsArray.sales_amount)
              : (( batchData &&  batchData.sales_amount)?formatCurrency( batchData.sales_amount):"")}
          </Grid>
          <Grid item xs={3}>
            Refund Count :{" "}
            {batchDetailsArray ? batchDetailsArray.refund_count : (( batchData &&  batchData.refund_count)? batchData.refund_count:"")}
          </Grid>
          <Grid item xs={3}>
            Refund Amount :{" "}
            {batchDetailsArray
              ? formatCurrency(batchDetailsArray.refund_amount)
              :  (( batchData &&  batchData.refund_amount)?formatCurrency( batchData.refund_amount):"")}
          </Grid>
          <Grid item xs={3}>
            Created At : {batchDetailsArray ? batchDetailsArray.created.at : (( batchData && batchData.created)?batchData.created.at:"")}
          </Grid>
        </Grid>
        
      </Container>
    </Box>
  );
}

class Batch extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      isLoading: true,
      reload: false,
      misMatchError: [],
      transactionsList: [],
      title: [],
      page: 10,
      userData: JSON.parse(localStorage.getItem("user")),
      error: {},
      batchData: {}
    };
  }



  changePage =  (page , offset , sorting , searching)  =>{
    this.setState({
      isLoading: true
    });
    this.getTransactionListByBatchId(page , offset , sorting , searching )
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTablePagination: {
          toolbar: {
            width: "160px !important ",
          },
          root: {
            width: "497px !important",
          },
        },
      },
    });
  componentDidMount() {    
    this.getTransactionListByBatchId();    
  }

  getTransactionListByBatchId(pages = 0 , offset = 10 , sorting = false , searching = false ) {
    const {
      match: { params },
    } = this.props;
    let user = JSON.parse(localStorage.getItem("user"));
    let locationPaydetails = JSON.parse(localStorage.getItem("locationPaydetails")) ;
    // let locationMid= locationPaydetails ? locationPaydetails.locationMid : "" ;
    // let locationId= locationPaydetails ? locationPaydetails._id : "" ;
    
    let token = user.token;
    const paytraceCredentials = {
      'locationMid': locationPaydetails  ? locationPaydetails.locationMid : '', 
      'paytraceKey': locationPaydetails  ? locationPaydetails.paytraceKey : '', 
      'userName': locationPaydetails  ? locationPaydetails.paytraceUserName : '', 
      'password': locationPaydetails  ? locationPaydetails.paytracePassword : ''
    };
    let companyId = (user.role !== config.SUPER_ADMIN_ROLE && user.company._id) ? user.company._id : "" ;
    let url = config.BASEURL;
    DataGetApi(url + "getBatchById", { ...{...params,  ...JSON.parse( localStorage.getItem('batchParams') ), ...{companyId: companyId} } , pages , offset , sorting  ,searching  }, token, paytraceCredentials).then((result) => {    
      if (result.data.success) {
        const transactionListArray = result.data.data.map(
          (items) => {
            let itemsSet = {
              transaction_id: items.transaction_id,
              amount: items.amount ? formatCurrency(items.amount) : "$0.00",
              batchNumber: items.batchNumber,
              invoice_id: items.invoice_id,
              paymentType : items.paymentType,
              paymentStatus : items.paymentStatus,
              CardType : items.CardType,
              // location : locationPaydetails ? locationPaydetails.locationName+'('+ locationPaydetails.midType+')' : '',   
              Location: items.location,
              approval_code: items.approval_code,
              transaction_type: items.transaction_type,
              credit_card: items.credit_card
                ? items.credit_card.masked_number
                : "",
              created: items.created.at,
              settled: items.settled,
            };
            return Object.values(itemsSet);
          }
        );

        this.setState({
          data: Object.values(transactionListArray),
          batchData: result.data.batchData[0],
          isLoading: false,
          page: pages,
          count:result.data.total
        });
        return Object.values(transactionListArray);
      }

      //this.setState()
    });
  }

  findBatchDetail() {}

  render() {
    const {
      classes,
      batchInfo,
      match: {
        params: { id, locationId, locationMid },        
      },
    } = this.props;


    const { data, page, count, isLoading, batchData } = this.state;
    // console.log(this.state, " this.state ");
    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "vertical",
      tableBodyHeight: "400px",
      tableBodyMaxHeight: "",
      serverSide: true,
      customSearchRender: debounceSearchRender(1000),    
      count :count,
      page,
      selectableRows: false,
      onTableChange: (action, tableState) => {        // console.log(action, tableState);      
        if ( ["changeRowsPerPage" ,"changePage","sort","search"].indexOf(action) !== -1) {        
          this.changePage(tableState.page , tableState.rowsPerPage , tableState.sortOrder , tableState.searchText );
        }
      }
    };

    const columns = [
      
      {
      
        name: "TXN ID",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Tooltip title="">
                <span
                  className={this.props.classes.Underline}
                  //  onClick={(ev) => this.props.history.push("/batch/" + value)}
                >
                  {value}
                </span>
              </Tooltip>
            );
          },
        },
      }, 
      "Amount",
      "Batch Number",
      "Invoice Id",
      // "Payment Type",
      {
        name: "Payment Type",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (             
              value? value : 'N/A'            
            );
          },
        },
      },    
      {
        name: "Status",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (             
              (value === "Paid") ? <button type="button" className="btn paidBtn" >Paid</button> : 
              (value === "Refunded") ? <button type="button" className="btn RefundedBtn" >Refunded</button> : 
              (value === "Voided") ? <button type="button" className="btn PenddingBtn" >Voided</button> : 
              (value === "Declined") ?  <button type="button" className="btn DeclinedBtn" >Declined</button> :  
              (value === "") ?  'N/A' :  ''
            );
          },
        },
      },
      // "Card Type",
      {
        name: "Card Type",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (             
              value? value : 'N/A'            
            );
          },
        },
      }, 
      // "Location",
      {
        name: "Location",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (             
              value? value : 'N/A'            
            );
          },
        },
      }, 
      "Approval Code",
      "Transaction Type",     
      {
        name: "Credit Card",
        options: {
          filter: false,
          sort: false
      }},
      {
        name: "Created At",
        options: {
          filter: false,
          sort: false
      }},
      {
        name: "Settled Date",
        options: {
          filter: false,
          sort: false
      }},
     
    ];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading your content..."
          >
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? (
                <ErrorMessage errors={[this.state.misMatchError]} />
              ) : (
                ""
              )}
              {this.state.successMessages ? (
                <SuccessMessage
                  successes={[this.props.info.paymentInfo.data.message]}
                />
              ) : (
                ""
              )}
              <BatchDetails
                classes={classes}
                batchInfo={batchInfo}
                batchId={id}
                batchData={batchData}
              />
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={"Transaction Lists"}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Container>
          </LoadingOverlay>
        </div>
        <Grid item xs={6} sm={6} md={6}>
        <Buttons
       // className={classesI.ml_2}
       className={'emailInvoiceTerminal'}
       variant="contained"
       color="secondary"
       text="Back"
       onClick={this.props.history.goBack} />
       </Grid>
      </div>
    
     
     
    );
  }
}

function mapStateToProps(state) {
  return {
    batchInfo: state.BatchReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <Batch classes={classes} {...props} />;
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(ApplyingStylesOnClasses));
