import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { CustomerDetailsId } from '../../actions/CustomerAction'
import { getCsrfToken } from '../../actions/PaymentAction'
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Customers.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import TypableSelect from './../../helpers/TypableSelect';
import validator from '../../helpers/checkValidations';
import FormControl from '@material-ui/core/FormControl';
import { editCustomer } from '../../actions/CustomerAction'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import { Checkbox, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import config from './../../constants/config'
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import binlookup from "binlookup";
import { truncate } from 'lodash';
const credit_or_debit = binlookup();

class EditCustomers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      updateCard: true,
      firstName: "",
      lastName: "",
      name:'',
      isNewCustomerId:false,
      createdBy: '',
      companyId: '',
      email: "",
      phone: "",
      customerCompanyName: '',
      customer_id: '',
      paytrace_added:false,
      newCustomer_id: '',
      customerAddress:'',
      customerCity:'',
      zip: '',
      zipRequired: false,
      customFields: [],
      StartDate: new Date(),
      misMatchError: [],
      cardPanelShow: true,
      addNewCard: false,
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      paytraceCredentials: {},
      userDetails: JSON.parse(localStorage.getItem('user')),
      card: {
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      error: {
        customer_idError: false,
        amountError: false,
        phoneError: false,
        customerCompanyNameError: false,
        customerAccountNumberError: false,
        customerAddressError: false,
        customerCityError: false,
        zipError: false
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      }
    }
  }

  componentDidMount() {
    this.getCustomerDetails();
    this.getCsrfTokens();
    let userData = JSON.parse(localStorage.getItem('user'));
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails')) !== null ? JSON.parse(localStorage.getItem('locationPaydetails')) : '';
    const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword };
    this.setState({ 'paytraceCredentials': paytraceCredentials });
    if (window.paytrace && locationPaydetails) {
      window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + locationPaydetails.paytraceKey);
    }
    this.setState({ createdBy: userData._id, companyId: userData.company._id });     
  }

  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    })
  }
  
  handleChange(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'amount' && value === '0') {
      alert('Amount should be greater than zero.');
      return false;
    }
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (id === 'zip' && value.toString().length === 6) {
      return false;
    }
    if(id === "customer_id"){
       id = 'newCustomer_id'
      this.setState({'isNewCustomerId':true })
    }

    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false });
  }

  getCustomerDetails() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true });

    this.props.CustomerDetailsId(params).then((res) => {
      // console.log(res.payload.customerData.data,"res.payload.customerData.data");
      let transaction = res.payload.customerData.data;
      if (transaction.success) {
        let month=transaction.response.expiration_month ? transaction.response.expiration_month :"__/"
        let year=transaction.response.expiration_year ? transaction.response.expiration_year :"____"
        this.setState({ card:{...this.state.card, cardExpiryDate:month+'/'+year}, ...transaction.response, isLoading: false, });
      }
      if (this.state.masked_card_number) {
        this.setState({ addNewCard: true });
      }
    });
  }

  handleSubmitUpdate(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let state = this.state;
    let error = {};
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.customerAddress === "") {
        delete this.state.customerAddress;
    }
    if (this.state.customerCity === "") {
         delete this.state.customerCity;
    }
    if (this.state.newCustomer_id === "") {
        delete this.state.newCustomer_id;
    }
    // if (this.state.expiration_year === "") {
    //   delete this.state.expiration_year;
    // }
    if (this.state.lastName === "") {
      delete this.state.lastName;
    }
    if (this.state.customerCompanyName === "") {
      delete this.state.customerCompanyName;
    }
    if (this.state.firstName === "") {
      delete this.state.firstName;
    }
    if (this.state.customerAccountNumber === "") {
      delete this.state.customerAccountNumber;
    }
    // if (this.state.expiration_month === "") {
    //   delete this.state.expiration_month;
    // }
    
    if (this.checkCardValues(this.state.card)) {
      Object.keys(state).map((key, value) => {
        if (key === "creditOrDebit") {
          return false;
        }if (key === "customerCity" && state.customerCity === "") {
          return false;
        }else if (key === "customerAddress" && state.customerAddress === "") {
          return false;
        }else if (key === "email" && state.email === "") {
          return false;
        }else if (key === "phone" && state.phone === "") {
          return false;
        }else if (key === "zip" && state.zip === "") {
          return false;
        }else if (key === "cardType" && state.cardType === "") {
          return false;
        } else if (key === "name" && state.name === "") {
          return false;
        }else if ((validator(key, state[key]))) {
          return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false);
        } else {
          return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : '';
        }
      });
      if (errorFlag) {
        this.setState({ misMatchError, error, successMessages });
        console.log(misMatchError, error, successMessages, " misMatchError, error, successMessages ");
        return false;
      } else {
        if (window.confirm('Are you sure you want to update customer details?')) {
          this.setState({ isLoading: true });
          window.paytrace.submitEncrypted('#EditFormCustomer');
          let userMaskNumber = this.state.card.cardNumber.replaceAll('_', '') ? this.state.card.cardNumber.substring(0,6)+'******'+this.state.card.cardNumber.substr(-4) : ''
          this.setState({
            card: { ...this.state.card, cardNumber: userMaskNumber },
            isLoading: true,
            encrypted_number: localStorage.getItem('0'),
            encrypted_csc: localStorage.getItem('1')
          }, () => {
            localStorage.removeItem('0');
            localStorage.removeItem('1');
            window.scrollTo(0, 0);
            console.log(this.state, " <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ");
            this.props.editCustomer(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {
              if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
                this.setState({ isLoading: false, successMessages: true }, () => {
                  setTimeout(() => {
                    this.setState({ redirect: true });
                  }, 2000);
                })
              }
              if (result !== undefined && result.type === 'CUSTOMER_ERROR') {

                if(JSON.parse(result.payload.error.data.response).errors && JSON.parse(result.payload.error.data.response).errors[122][0] == "Please provide a valid Billing ZIP"){
                  this.setState({misMatchError: JSON.parse(result.payload.error.data.response).errors[122][0], zipRequired: true, error:{zipError: true} });
                }else{
                  this.setState({misMatchError: this.props.info.customerInfo.data.message, zipRequired: false});   
                }

                this.setState({
                  isLoading: false, successMessages,
                  card: {
                    cardHolderName: '',
                    cardNumber: '_______________',
                    cardExpiryDate: '__/____',
                    cardCsc: '___',
                    customerToken: false,
                    customerTokenUpdate: false,
                  }
                },()=>{
                  setTimeout(() => {
                    this.setState({ redirect: true });
                  }, 3000);
                });

              }
            });
          });
        }
      }
    }
  }

  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }

  handleSubmitAddNewCard(e, t) {
    this.setState({ updateCard: true, addNewCard: false });
  }

  backToMaskCard(e, t) {
    this.setState({ addNewCard: true });
  }

  handleChangeCard(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;


    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ card: { ...this.state.card, [id]: value }, errorCard: { ...this.state.errorCard, [id]: false }, error, misMatchError, isSubmit: false }, () => {
      let firstNumber = this.state.card.cardNumber;
      let creditOrDebit = '';
      credit_or_debit(firstNumber).then(data => {
        if (typeof data.type !== "undefined") {
          creditOrDebit = data.type;
        }
        this.setState({ card: { ...this.state.card, customerToken: true, 'cardType': this.getCardType(firstNumber.charAt(0)), 'creditOrDebit': creditOrDebit } });
      });

      this.setState({ card: { ...this.state.card, customerToken: true, 'cardType': this.getCardType(firstNumber.charAt(0)), 'creditOrDebit': creditOrDebit } })
      if (firstNumber[0] === "3") {
        this.setState({ americanExpress: true });
      } else {
        this.setState({ americanExpress: false });
      }
    });
  }

  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return 'CreditCard'
    }
  }

  checkCardValues(state) {
    // if ( (state.cardNumber == '_______________' || state.cardNumber == '')  && (state.cardExpiryDate == '__/____' || state.cardExpiryDate == '') && (state.cardCsc == '___' || state.cardCsc == '') ) {
    //   return true;
    // }
    if ( state.cardNumber == '_______________' || state.cardNumber == '' ) {
      return true;
    }
    if ((state.cardNumber.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } })
      window.scrollTo(0, 0);
      return false;
    } else if ((state.cardExpiryDate.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } })
      window.scrollTo(0, 0);
      return false;
    } else if ((state.cardCsc.indexOf('_') > -1)) {
      this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } })
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { t } = this.props;
    const { redirect } = this.state;
    return (
      <div className="rightPanel">        
          {(redirect) ? <Redirect to='/Customers' /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.customerInfo.data.message]} /> : ''
              }
              <form className={classes.form} noValidate id={"EditFormCustomer"}>
                <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                    <Textfield
                      id="name"
                      error={this.state.error.nameError}
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("Name")}
                      // value={(this.state.name) ? this.state.name : ''}
                      value= {this.state.firstName || this.state.lastName || this.state.name ? this.state.name || this.state.firstName +" "+ this.state.lastName: ""} 
                      focus={true}
                      width={true} />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Textfield
                      id="firstName"
                      error={this.state.error.firstNameError}
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("First Name")}
                      value={(this.state.firstName) ? this.state.firstName : ' '}
                      // disabled={true}
                      width={true} />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <Textfield
                      id="lastName"
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("Last Name")}
                      value={(this.state.lastName) ? this.state.lastName : ' '}
                      // disabled={true}
                      width={true} />
                  </Grid> */}
                  {/* <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.customerCompanyNameError}
                      id="customerCompanyName"
                      type="text"
                      labels={t("Company Name")}
                      value={this.state.customerCompanyName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      // required={true}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.email}
                      // disabled={true}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      // required={true}
                      error={this.state.error.phoneError}
                      id="phone"
                      type="text"
                      labels={t("Phone")}
                      value={this.state.phone}
                      // disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container item sm={5} xs={10}>
                    <Textfield
                      required={true}
                      error={this.state.error.customer_idError}
                      id="customer_id"
                      type="text"
                      labels={t("CustomerId")}
                      value={this.state.isNewCustomerId ? this.state.newCustomer_id : this.state.customer_id}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                      // disabled={true}
                    />
                  </Grid>
                  {/* <Grid container item sm={5} xs={10}>
                    <Textfield
                      id="newCustomer_id"
                      type="text"
                      labels={t("NewCustomerId")}
                      value={this.state.newCustomer_id}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid> */}
                  <Grid container item sm={1} xs={2}>
                    <Tooltip title="Here Costumer Account Number is same as Costumer Id" aria-label="add" style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow>
                      <InfoTwoToneIcon style={{ marginTop: '30px' }} />
                    </Tooltip>
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                  <Textfield
                      id="customerCity"
                      type="text"
                      labels={t("City")}
                      value={this.state.customerCity}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                  <Textfield
                      id="customerAddress"
                      type="text"
                      placeholder="Address"
                      multiline={true}
                      rows={4}
                      value={this.state.customerAddress}
                      width={true}
                      variant="outlined"
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  
                  <Grid container item sm={6} xs={12}  >
                    <Textfield
                      error={this.state.error.zipError}
                      required={this.state.zipRequired}
                      id="zip"
                      type="text"
                      labels={t("Zip Code")}
                      value={this.state.zip}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={4} className={'CustomerPage'}>

                    <Card className={classNames([(this.state.cardPanelShow) ? 'cardData' : 'blur', 'customCardWraper', (this.state.cardPanelShow && this.state.masked_card_number && this.state.addNewCard) ? 'none' : 'block'])}>
                      <div className="Card_header">
                        {(!this.state.updateCard) ? <Typography variant="h5" component="h2">
                          Card Details
                        </Typography> : <><Typography variant="h5" component="h2">
                            Card Details
                            </Typography>
                            <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>back</a></>}
                      </div>
                      <CardContent ref={this.cardRef} className="cardContents">
                        {(this.state.errorCard.cardNumber) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number")}</label>}
                        <MaskedInput type="text" value={this.state.card.cardNumber} className="pt-encrypt" mask={(this.state.americanExpress ? '111111111111111' : '1111111111111111')} labels={t("Card Number")} id="cardNumber" name="encrypted_number" size="20" onChange={(e) => this.handleChangeCard(e, t)} />
                        <Grid container item xs={12}>
                          <Grid item md={6} xs={3} >
                            {(this.state.errorCard.cardExpiryDate) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Card Expiry Date Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Expiry Date")}</label>}
                            <MaskedInput mask="11/1111" value={this.state.card.cardExpiryDate} name="cardExpiryDate" id="cardExpiryDate" placeholder="mm/yyyy" onChange={(e) => this.handleChangeCard(e, t)} />
                          </Grid>
                          <Grid item md={6} xs={3} >
                            {(this.state.errorCard.cardCsc) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC")}</label>}
                            <MaskedInput type="text" value={this.state.card.cardCsc} mask={this.state.americanExpress ? "1111" : "111"} className="pt-encrypt" id="cardCsc" name="encrypted_csc" onChange={(e) => this.handleChangeCard(e, t)} />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        {(this.state.updateCard) ? <> <Checkbox
                          id="customerToken"
                          checked={this.state.card.customerToken}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.card.customerToken}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }} />
                          <label>Save card for future payments</label></> : <> <Checkbox
                            id="customerTokenUpdate"
                            checked={this.state.card.customerTokenUpdate}
                            onChange={(e) => { this.handleChangeCheckboxUpdate(e) }}
                            value={this.state.card.customerTokenUpdate}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }} /><label>Update card for future payments</label></>}
                      </CardActions>
                    </Card>

                    {/* for mask mask number */}
                    < div xs={12} className={(this.state.addNewCard && this.state.masked_card_number) ? 'block' : 'none'}>

                      <Card className={classNames([(this.state.cardPanelShow) ? 'maskData' : 'blur', 'customCardWraper'])}>
                        <div className="Card_header">

                          <Typography variant="h5" component="h2">
                            {/* Name : {this.state.name ? this.state.name: ""}   firstName */}
                            Name : {this.state.firstName || this.state.lastName || this.state.name ? this.state.name || this.state.firstName +" "+ this.state.lastName: ""} 
                          </Typography>

                        </div>
                        <CardContent ref={this.cardRef} className="cardContents">
                          <labels className="MuiTypography-gutterBottom">{t("Mask Card Number")}</labels>
                          <Grid container item xs={12}>
                            {(this.state.masked_card_number) ? this.state.masked_card_number : ''}
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Buttons
                            id="AddNewCard"
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={"addNewCardBtn"}
                            onClick={(e) => this.handleSubmitAddNewCard(e, t)}
                            text={t("Add Another Card")} />
                        </CardActions>
                      </Card>
                    </div>
                  </Grid>
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6}>
                        <div key={`${field}-${idx}`}>
                          <Textfield
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ""}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            focus={true}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={12}>
                    {this.state.userDetails.permissions.editCustomer ?
                      <Buttons
                        className={classes.ml_2}
                        variant="contained"
                        color="primary"
                        text={t("Update")}
                        onClick={(e) => this.handleSubmitUpdate(e, t)} /> : ''}
                    <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t("Back")}
                      onClick={this.props.history.goBack} />
                  </Grid>
                </Grid>

              </form>
            </Container>

          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { editCustomer, getCsrfToken, CustomerDetailsId })(withTranslation()(EditCustomers));