const initialState = () => ({
    invoice: '',
    firstName: '', lastName: '', phoneNumber: '',
    year: '', make: '', model: '', color: '',
    serviceType: '', problemType: '',
    willAnyoneBeWithTheVehicle: '',
    doYouHaveKeysForVehicle: '',
    regularGasOrDiesel: '',
    neutral: '',
    doAllWheelsTurn: '', doesFrontWheelsTurn: '', doesBackWheelsTurn: '',
    pickUpLocation: '', pickUpNotes: '',
    startAddress: '', endAddress: '',
    email: '',
    amount: '', basePrice: '', notes: '',
    sendTo: 'phone',
    zipForCalc: '',
    system: '',
    error: '', success: '',
    distanceDetails: {},

    open: false,
    userAccount: '',

    touched: {
        firstName: false, lastName: false, phoneNumber: false,
        year: false, make: false, model: false, color: false,
        serviceType: false, problemType: false,
        willAnyoneBeWithTheVehicle: false, doYouHaveKeysForVehicle: false,
        regularGasOrDiesel: false, neutral: false,
        doAllWheelsTurn: false, doesFrontWheelsTurn: false, doesBackWheelsTurn: false,
        pickUpLocation: false,
        email: false, amount: false
    },
    error: {
        firstName: false, lastName: false, phoneNumber: false,
        year: false, make: false, model: false, color: false,
        serviceType: false, problemType: false,
        willAnyoneBeWithTheVehicle: false, doYouHaveKeysForVehicle: false,
        regularGasOrDiesel: false, neutral: false,
        doAllWheelsTurn: false, doesFrontWheelsTurn: false, doesBackWheelsTurn: false,
        pickUpLocation: false,
        email: false, amount: false
    }
});

const InputReducer = (state = initialState(), { type, payload }) => {
    switch (type) {
        case 'SET_STATE':
            if ((Object.keys(state.touched).indexOf(payload.property)) >= 0)
            {
                return { ...state, [payload.property]: payload.value, touched: { ...state.touched, [payload.property]: true }, error: { ...state.error, [payload.property]: false } };
            }
            return { ...state, [payload.property]: payload.value };
            case 'SET_STATE_ERROR':
            if ((Object.keys(state.error).indexOf(payload.property)) >= 0)
            {
                return { ...state, [payload.property]: payload.value, error: { ...state.error, [payload.property]: true } };
            }
        case 'INVOICE_DETAILS':
            return { ...state, ...payload.value };
        case 'APPEND_STATE':
            return { ...state, notes: state.notes + '\n' + payload.value };
        case 'RESET':
            return { ...initialState(), invoice: Math.floor(Math.random() * 1000000000).toString() };
        case 'SUCCESS': return { ...state, success: payload, open: payload };
        case 'ERROR': alert(payload); return { ...state, error: payload };
        default: return state;
    }
};

export { InputReducer }