import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserDetailForCustomMiddleWare, adminUserProfile } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import { config } from '../../constants'
import validator from '../../helpers/checkValidations';
import Resizer from 'react-image-file-resizer';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '&+$track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      param: {
        isEmailNotification:false,
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        image: null,
        selectedFile: null
      },
      imageUrl: null,
      loaded: false,
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      logoError: [],
      userData: JSON.parse(localStorage.getItem('user')),
      response:{success:'',message:''},
      error: {
        userNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        fileError: false,
      },
    }
  }


  componentDidMount() {
    this.getUserData()
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.info !== prevProps.info) {
      this.setState({ param: { ...this.props.info.userDataValue }, isLoading: false });
      if (this.props.info.userDataValue.image === null) {
        this.setState({ imageUrl: null })
      } else {
        var uploadImageUrl = config.PAYTRACE_AWS_PATH + this.props.info.userDataValue.image
        this.setState({ imageUrl: uploadImageUrl })
      }      
    }
  }

  getUserData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.UserDetailForCustomMiddleWare();
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData()
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });

  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ param: { ...this.state.param, [id]: value }, error, misMatchError });
  }

  handleChangeID(e, name) {
    let successMessages = false;
    let successStr = '';
    this.setState({param: {...this.state.param, [name]: e.target.checked }, successStr, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    Object.keys(this.state.param).map((key, value) => {
      if ((validator(key, this.state.param[key]))) {
        return (validator(key, this.state.param[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (this.state.param[key] === '' || (typeof this.state.param[key] === 'string' && this.state.param[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });


    if (state.error.fileError) {
      logoError[0] = t("UserLogo");
      this.setState({ logoError });
      return false;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {

      const data = new FormData()
      for (const key in this.state.param) {
        data.append(key, this.state.param[key])
      }
      this.setState({ isLoading: true })
      this.props.adminUserProfile(data).then(result => {
        window.scrollTo(0, 0, 0)
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          if (result.payload.userData.data.response.response.image === undefined || result.payload.userData.data.response.response.image == null) {
            this.setState({ param:{ isEmailNotification:result.payload.userData.data.response.response.isEmailNotification}, successMessages: true, isLoading: false },()=>{
            });
          } else {
            const uploadImageUrl = config.PAYTRACE_AWS_PATH + result.payload.userData.data.response.response.image
            this.setState({ param: { ...this.state.param, isEmailNotification:result.payload.userData.data.response.response.isEmailNotification, selectedFile: null }, isLoading: false, successMessages: true, imageUrl: uploadImageUrl, loaded: false },()=>{
            });
          }
          let user = JSON.parse(localStorage.getItem('user'))
          user.firstName = result.payload.userData.data.response.response.firstName;
          localStorage.setItem("user", JSON.stringify(user));
          this.setState({response:{...this.state.response,message:result.payload.userData.data.message}
          },()=>{
            setTimeout(()=>{
              this.setState({ successMessages: false})
            },2000) })
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, isLoading: false, successMessages });
        }
      });
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let successMessages = false;
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : ''
    let image = event.target.files[0]
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(image.name)) {
      logoError.push(t("UserFileTypeNotCorrect"));
      error = true;
      this.setState({ error: { ...this.state.error, fileError: true, }, logoError, image: null, loaded: false, successMessages })
    }
    var size = 5 * 1048576;

    if (image.size > size) {
      logoError.push(t("UserFileSizeNotCorrect"));
      this.setState({ error: { ...this.state.error, fileError: true, }, logoError, image: null, successMessages })
    }

    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        uri => {
          let file = new File([uri], image.name)
          this.setState({ param: { ...this.state.param, image: file, selectedFile: selectedFile }, loaded: true, successMessages })
        },
        'blob'
      );
    }

  }

  render() {
    const { t } = this.props;
    let user = JSON.parse(localStorage.getItem('user'));
    return (
      
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {(this.state.successMessages ) ?
                <SuccessMessage successes={[this.state.response.message]} /> : ''
              }
              <form className={classes.form} noValidate >
                <Grid container spacing={3} >
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.userNameError}
                      id="userName"
                      type="text"
                      labels={t("Username")}
                      value={this.state.param.userName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.firstNameError}
                      id="firstName"
                      type="text"
                      labels={t("First Name")}
                      value={this.state.param.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={3}>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.lastNameError}
                      id="lastName"
                      type="text"
                      labels={t("Last Name")}
                      value={this.state.param.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.param.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={3}>
                    <label><b></b></label>
                  </Grid> */}
                   <Grid item xs={6} sm={6} >
                    {((this.state.imageUrl !== null) ? <img alt="profile" src={this.state.imageUrl} className={classes.preview} /> :
                      <img className={classes.preview} alt="blank" src={config.PAYTRACE_AWS_PATH + 'blank.png'} />)}
                  </Grid>
                  <Grid item xs={6} sm={6} >
                    <div className={(this.state.loaded) ? classes.show : classes.hide}>

                      {((this.state.param.selectedFile) ? <img alt="preview" className={classes.preview} src={this.state.param.selectedFile} /> :
                        <img className={classes.preview} alt="blank" src={config.PAYTRACE_AWS_PATH + 'blank.png'} />)}
                    </div>
                    <span className={(this.state.loaded) ? classes.show : classes.hide}>Preview</span>
                  </Grid>
                 
                  <Grid item xs={6} sm={6}>
                    <input type="file" name="file" placeholder="Add profile picture" onChange={(e) => { this.onChangeHandler(e, t) }} />
                    {this.state.logoError.length > 0 ?
                      < ErrorMessage errors={[this.state.logoError]} /> : ''
                    }
                  </Grid>
                </Grid>
                {(this.state.userData.role > 1) ? <h2>Permissions</h2> : ''}
                <Grid container item xs={12}>
                   
                  <Grid item xs={6} sm={6}>
                    {this.props.info.permissions.refunds ? <><label>
                      <span>Refunds</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {this.props.info.permissions.editCustomer ? <><label>
                      <span>Edit Customer</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  {/* <Grid item xs={6} sm={6}>
                    {this.props.info.permissions.textToInvoice ? <><label>
                      <span>Text To Invoice</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.createInvoice ? <><label>
                      <span>Invoicing</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.ach ? <><label>
                      <span>ACH</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  {/* <Grid item xs={12} sm={6}></Grid> */}
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.virtualTerminal ? <><label>
                      <span>Virtual Terminal</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.recurring ? <><label>
                      <span>Recurring billing</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.allLocaton ? <><label>
                      <span>All Location</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.svcFeeEdit ? <><label>
                      <span>Svc Fee Edit</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.creatNewUser ? <><label>
                      <span>Create New User</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.exportRecord ? <><label>
                      <span>Export Record</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.refundsManual ? <><label>
                      <span>Manual Refunds</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.void ? <><label>
                      <span>Void</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.props.info.permissions.preAuthorize ? <><label>
                      <span>preAuthorize</span></label><img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} />
                    </> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <div>&nbsp;</div>
                  { user.permissions.emailNotification ?
                  <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          <Grid item xs={12} className="discount-label-field">
                            <label className="mr-2">Get email notifications for paid invoices</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.param.isEmailNotification}
                                  onChange={(e) => { this.handleChangeID(e, 'isEmailNotification') }}
                                  value={this.state.param.isEmailNotification}
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid> : " " }
                </Grid>
                </Grid>
                <Grid container item xs={12}>
                <Grid item xs={12} sm={12}>
                  <div>&nbsp;</div>
                </Grid>
                  <Grid item xs={12} sm={6}>
                    <Buttons
                      id="signUp"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t("Update")} />
                    <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t("Back")}
                      onClick={this.props.history.goBack} />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
Profile.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { UserDetailById,UserDetailForCustomMiddleWare, adminUserProfile })(withTranslation()(Profile));
