import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * user info Begin Action
 */
export const batchStart = () => ({
    type: ActionType.BATCH_START
});

/**
* user info Success Action
*/
export const batchSuccess = batchData => {
    return {
        type: ActionType.BATCH_SUCCESS,
        payload: { batchData }
    }
};

export const LastbatchSuccess = data => {
    return {
        type: ActionType.BATCH_LAST_UPDATED_DATE,
        payload: { data }
    }
};

/**
* user info Failure Action
*/
export const batchError = error => ({
    type: ActionType.BATCH_ERROR,
    payload: { error }
});


export const getBatchData = (data) => {    
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user.token;
    // let locationId = '';
    // let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
   
    // if (localStorage.getItem("locationArrIds") !== null) {
    //     locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    // }
    return dispatch => {
        dispatch(batchStart());
        return  DataGetApi(config.BASEURL + 'getBatchReporting', { ...data }, token).then((response) => {
            if (response.status === 200 && response.data.success) {
               return dispatch(batchSuccess(response))
           } else {
               return dispatch(batchError(response));
           }
       }).catch((err) => {
           console.log(err);
       });
    };
}
export const getLastUpdatedBatchData = (data) => {    
    let user = JSON.parse(localStorage.getItem("user"));
    let companyId = user.company._id ? user.company._id : ""
    let token = user.token;
    // let locationId = '';
    // if (localStorage.getItem("locationArrIds") !== null) {
    //     locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    // }
    // let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    return dispatch => {
        dispatch(batchStart());
        return  DataGetApi(config.BASEURL + 'getLastUpdatedBatchInfo', {...data,'companyId':companyId}, token).then((response) => {
            if (response.status === 200 && response.data.success) {
               return dispatch(LastbatchSuccess(response))
           } else {
               return dispatch(batchError(response));
           }
       }).catch((err) => {
           console.log(err);
       });
    };
}

export const getBatchFundData = (data) => {    
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user.token;
    return dispatch => {
        dispatch(batchStart());
        return  DataGetApi(config.BASEURL + 'getBatchFundData', data, token).then((response) => {
            // console.log(response, " response ");
           if (response.status === 200 && response.data.success) {
               return dispatch(batchSuccess(response))
           } else {
               return dispatch(batchError(response));
           }
       }).catch((err) => {
           console.log(err);
       });
    };
}



