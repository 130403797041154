import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import cookie from 'react-cookies';
/**
 * User info Begin Action
 */
export const loginStart = () => ({
    type: ActionType.LOGIN_START
});

/**
* User info Success Action
*/
export const loginSuccess = loginData => { 
    return {
        type: ActionType.LOGIN_FETCH_DATA,
        payload: { loginData }
    }
};

/**
* User info Failure Action
*/
export const loginError = error => ({
    type: ActionType.LOGIN_ERROR,
    payload: { error }
});

/**
 * User driver info
 */
export const login = (data, props) => {
    return dispatch => {
        dispatch(loginStart());
        return DataApi(data, config.BASEURL + 'logIn').then((response) => {          
            if (response.status === 200 && response.data.statusCode === 200) {
                dispatch(loginSuccess(response.data.response));
                let locations = response.data.response.hasOwnProperty('defaultLocation') ? response.data.response.defaultLocation : (response.data.response.location.length > 0) ? response.data.response.location[0]._id : null ;
                localStorage.setItem('localDefaultLocation', locations);
                localStorage.setItem('user', JSON.stringify(response.data.response));
                localStorage.setItem('token', JSON.stringify(response.data.response.token)); 
                sessionStorage.setItem('token', JSON.stringify(response.data.response.token));                
                localStorage.setItem('refreshToken', JSON.stringify(response.data.response.refreshToken));
                cookie.save('user', response.data.response, { path: '/' });
                props.history.push('/Dashboard');
            } else {
                return dispatch(loginError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
