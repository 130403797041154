import { ActionType } from './../constants';

const initialState = {
  
  reset: [],
  loading: false,
  error: null,
  msg: null
};


const ResetReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case ActionType.RESET_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionType.RESET_FETCH_DATA:
      return {
        ...state,
        loading: false,
        resetInfo: action.payload.loginData,
        
      };

    case ActionType.RESET_ERROR:
      return {
        ...state,
        loading: false,
        resetInfo: action.payload.error
      };

    default:
      return state;
  }
}

export { ResetReducer };
