import { ActionType } from './../constants';
const initialState = () => ({
	card: '',
	CVC: '',
	expiry: '',
	zip: '',
	rating: 0,
	successPage: false,
	loading: false,
	comment: '',
	latitude: '',
	longitude: '',
	address: '',
	style: false
});

const PaymentReducer = (state = initialState, action) => {
 
	switch (action.type) {
		case ActionType.PAYMENT_START:
			return {
				...state,
				loading: true,
			}
		case ActionType.PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				paymentInfo: action.payload.paymentData
			}
		case ActionType.PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				paymentInfo: action.payload.error
			};


		default:
			return state;
	}
};
export { PaymentReducer };