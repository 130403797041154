import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Textfield } from './../../components/Textfield';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Buttons } from './../../components/Buttons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Topheader } from './../../components/Topheader';
import { userSettings, UserDetailForCustomMiddleWare } from '../../actions/AdminUserAction'
import { companySettings, companyTermCondition } from '../../actions/CompanyAction'
import LoadingOverlay from 'react-loading-overlay';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import classesSe from './Settings.module.scss';
import { Grid } from '@material-ui/core';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import validator from '../../helpers/checkValidations';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { DialogBox } from './../../components/DialogBox/DialogBox'
import TextfieldLable from './TextfieldLable'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { stat } from 'fs';
import { Checkbox } from '@material-ui/core';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '@media (max-width:768px)': {
      display: 'block',
      alignItems: 'center',
    },

  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mT1: {
    marginTop: `-1.5rem`,
    '@media (max-width:768px)': {
      marginTop: `0`,
    },

  },
  tab4: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },
  tab3: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },

  tabBtn: {
    '@media (max-width:768px)': {
      maxWidth: 'inherit',
    },
  },
  tpTableFullW: {
    width: '100%',
  }
}));


class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      termCondition: "",
      InvoiceDiscount: false,
      InvoiceSurcharge: false,
      isZip: false,
      isMultiPayInvoice: false,
      InvoiceTax: false,
      checkedInvoice: true,
      InvoiceSalesTaxFee: 0,
      InvoiceSalesTaxFeeType: '%',
      InvoiceSVCFee: 0,
      InvoiceSVCFeeType: '%',
      InvoiceSurchargeFee: 0,
      InvoiceSurchargeFeeType: '%',
      EditPanel: '',
      DiscountFeeName: '',
      SurchargeFeeName: 'Surcharge',
      locationid: (JSON.parse(localStorage.getItem('locationArrIds'))[0] !== null) ? JSON.parse(localStorage.getItem('locationArrIds'))[0] : null,
      usersData: [],
      receiptSetting: { 'email': '', 'phone': '', 'isPhone': false, 'isEmail': false },
      misMatchError: [],
      customFields: [],
      tab: 0,
      tabSelected: 'Transaction',
      title: 'Company',
      sideShow: true,
      isLoading: true,
      userDetails: JSON.parse(localStorage.getItem('user')),
      locDetails: JSON.parse(localStorage.getItem('locationPaydetails')),
      midType: '',
      error: {
        InvoiceSalesTaxFeeError: false,
        InvoiceSVCFeeError: false,
        InvoiceSurchargeFeeError: false,
        amountError: false,
        phoneError: false,
      }
    }
    this.handleModelChange = this.handleModelChange.bind(this);
    this.config = {
      placeholderText: "Type here...",
      charCounterCount: false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info !== prevProps.info) {
      this.setState({ 'customFields': this.props.info.companyId.customFields })
      this.setState({ 'termCondition': (this.props.info.companyId.termCondition) ? this.props.info.companyId.termCondition : '' })
      this.setState({ ...this.props.info.settings, isLoading: false })
      this.setState({ 'user': this.state.userDetails._id })
      if (this.props.info.companyId.receiptSetting) {
        this.setState({ receiptSetting: { ...this.state.receiptSetting, ...this.props.info.companyId.receiptSetting } })
      } else {
        this.setState({ receiptSetting: { ...this.state.receiptSetting, "email": this.props.info.locationIds[0].email, "phone": this.props.info.locationIds[0].phone } })
      }
    }
  }
  componentDidMount() {
    this.fetchDetailsData();

    // if(localStorage.getItem('locationPaydetails') != null && localStorage.getItem('locationPaydetails') != undefined && localStorage.getItem('locationPaydetails') && localStorage.getItem('locationPaydetails') != ''){
    //   this.setState({
    //     // userDetails: JSON.parse(localStorage.getItem('user')),
    //     locDetails: JSON.parse(localStorage.getItem('locationPaydetails'))
    //   });
    // }else{
    //   let msg = 'Select the payment location.';
    //   alert(msg);
    // }

    if (!this.state.locDetails) {
      let msg = 'Select the payment location.';
      alert(msg);
    } else {
      this.setState({ midType: this.state.locDetails.midType });
    }

  }
  fetchDetailsData() {
    this.props.UserDetailForCustomMiddleWare({ 'id': this.state.userDetails._id }).then((res) => {
      // this.setState({ isLoading: false })
    });
  }

  renderSwitch(param) {
    switch (param) {
      case 0:
        return 'Transactions';
      case 1:
        return 'User';
      case 3:
        return 'User';
      case 4:
        return 'User';
      case 2:
        return 'Locations';
      case 5:
        return 'Locations';
      case 6:
        return 'Locations';
      default:
        return '';
    }
  }
  handleChange(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, successStr: '', misMatchError, isSubmit: false });
  }


  handleChangeTab = (e, val, Id = null) => {
    if (this.props.location.search) {
      // delete this.props.location.search;
      this.props.history.push(this.props.location.pathname);
    }
    let tabSelected = this.renderSwitch(val);
    switch (val) {
      case 3:
        this.setState({ successStr: '', AddUser: false, EditUser: true, tab: 1, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname + '?users=' + Id);
        break;
      case 4:
        this.setState({ successStr: '', AddUser: true, EditUser: false, tab: 1, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      case 5:
        this.setState({ successStr: '', AddLocation: false, EditLocation: true, tab: 2, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname + '?locations=' + Id);
        break;
      case 6:
        this.setState({ successStr: '', AddLocation: true, EditLocation: false, tab: 2, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      default:
        this.setState({ successStr: '', tab: val, AddLocation: false, EditLocation: false, AddUser: false, EditUser: false, tabSelected: tabSelected });
        break;
    }
  }


  handleChangeID(e, name) {
    let successMessages = false;
    let successStr = '';
    // console.log(name,this.state.InvoiceDiscount);
    if (name == 'InvoiceDiscount' && this.state.locDetails && this.state.locDetails.midType != '') {
      if (this.state.InvoiceSurcharge) {
        this.setState({ ...this.state, successStr, successMessages, ['InvoiceDiscount']: e.target.checked, ['InvoiceSurcharge']: !e.target.checked  });
      } else {
        this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked, });
      }
    } else if (name == 'InvoiceSurcharge' && this.state.locDetails && this.state.locDetails.midType != '') {
      if (this.state.InvoiceDiscount) {
        this.setState({ ...this.state, successStr, successMessages, ['InvoiceSurcharge']: e.target.checked, ['InvoiceDiscount']: !e.target.checked   });
      } else {
        this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
      }
    } else {
      // if (e.target.checked === false && name === 'InvoiceDiscount') {
      //   this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked, InvoiceSVCFee: 0 });
      // } else if (e.target.checked === false && name === 'InvoiceSurcharge') {
      //   this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked, InvoiceSurchargeFee: 0 });
      // } else if (e.target.checked === false && name === 'InvoiceTax') {
      //   this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked, InvoiceSalesTaxFee: 0 });
      // } else {
        this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
      // }
    }

  }

  handleSubmit(e, t) {
    let location = JSON.parse(localStorage.getItem('selected'));
    let misMatchError = [];
    if (location === 0) {
      let msg = 'Select the location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
      window.scrollTo(0, 0);
      return false;
    }
    if (this.state.tab === 1) {
      let successMessages = false;
      this.setState({ isSubmit: true });
      let data = { 'user': this.state.user, 'company': this.state.company, 'termCondition': this.state.termCondition }
      this.setState({ isSubmit: true });
      this.props.companyTermCondition(data).then(result => {
        if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
          window.scrollTo(0, 0)
          this.setState({ successMessages: true, successStr: (result.payload.companyData) ? result.payload.companyData.data.message : '' }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false, successStr: '' })

            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'COMPANY_ERROR') {
          this.setState({ misMatchError: this.props.info.infoCompany.data.message, successMessages });
        }
      });
    }
    if (this.state.tab === 2) {
      let successMessages = false;
      this.setState({ isSubmit: true })
      let data = { 'user': this.state.user, 'company': this.state.company, 'customFields': this.state.customFields, 'receiptSetting': this.state.receiptSetting }
      this.setState({ isSubmit: true })
      this.props.companySettings(data).then(result => {
        if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
          window.scrollTo(0, 0)
          // this.setState({ ...this.props.info.settings })
          this.setState({ successMessages: true, successStr: (result.payload.companyData) ? result.payload.companyData.data.message : '' }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false, successStr: '' })
            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'COMPANY_ERROR') {
          this.setState({ misMatchError: this.props.info.infoCompany.data.message, successMessages });
        }
      });
    } if (this.state.tab === 0) {
      let successMessages = false;
      let state = this.state;
      if (state.InvoiceDiscount && state.DiscountFeeName === "") {
        alert('Enter discount fee name.')
        return false;
      } if (state.InvoiceSurcharge && state.SurchargeFeeName === "" && state.midType === 'Surcharge') {
        alert('Enter surcharge fee name.')
        return false;
      }
      else {
        this.setState({ isSubmit: true });
        this.setState({ isLoading: true });
      }
      this.props.userSettings(state).then(result => {
        this.setState({ isLoading: false });
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState({ successMessages: true, successStr: (this.props.info.userInfo) ? this.props.info.userInfo.data.message : '' }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false, successStr: '' });
              window.location.reload();
            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });
    }
  }

  onchangeType(e, type) {
    let successMessages = false;
    this.setState({ [type]: e.target.value, successMessages })
  }
  handleClose = () => {
    this.setState({ isOpen: false })
  }
  handleChangeCustom(event, t, i) {
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields: values }, () => {
    });
  }
  handleAdd() {
    this.setState({ isOpen: true })
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields];
    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  }
  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  handleModelChange = (model) => {
    this.setState({
      termCondition: model
    }
    );
  }
  handleChangeReceipt(e) {
    let error = {}
    let misMatchError = [];
    let { id, value } = e.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    if (id === 'phone' || id === 'email') {
      this.setState({ receiptSetting: { ...this.state.receiptSetting, [id]: value }, error, successStr: '', misMatchError, isSubmit: false })
      // this.setState({ [id]: value, error, successStr: '', misMatchError, isSubmit: false });
    } else {
      this.setState({ [id]: value, error, successStr: '', misMatchError, isSubmit: false });
    }

  }
  handleChangeCheckbox(event) {
    this.setState({ receiptSetting: { ...this.state.receiptSetting, [event.target.id]: event.target.checked } });
  }
  render() {
    const { t } = this.props;
    const { classes } = this.props;
    let defaultEmails = this.props.info.locationIds;
    return (
      <div className="rightPanel">
        <Topheader />
        <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<TextfieldLable handleClose={this.handleClose} handleLabels={this.handleLabels} />} />
        <div className="contentPart">
        <div className="settings-main">
        <h2 className="page-title">Settings </h2>
          {this.state.misMatchError.length > 0 ?
            < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {(this.state.successMessages && this.state.successStr) ?
            <SuccessMessage successes={[this.state.successStr]} /> : ''
          }
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={this.state.tab}
              onChange={(e, val) => this.handleChangeTab(e, val)}
              aria-label="Vertical tabs example"
              className={classes.tabs}>
              <Tab className={classes.tabBtn} label="Invoice/VT settings" {...a11yProps(0)} />
              {/* <Tab className={classes.tabBtn} label="Users Settings" {...a11yProps(1)} /> */}
              <Tab className={classes.tabBtn} label="Term & Conditions" {...a11yProps(1)} />
              <Tab className={classes.tabBtn} label="Receipt settings" {...a11yProps(2)} />

            </Tabs>
            <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text='Loading your content...'>
              <div className="tp-tabcustom">
                <TabPanel value={this.state.tab} index={0} className={classes.mT1}>
                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          <Grid item xs={12} className="discount-label-field">
                            <label className="mr-2">Zip Code Enable</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.isZip}
                                  onChange={(e) => { this.handleChangeID(e, 'isZip') }}
                                  value={this.state.isZip}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} className="discount-label-field">
                            <label className="mr-2">Allow multiple payments to invoices</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.isMultiPayInvoice}
                                  onChange={(e) => { this.handleChangeID(e, 'isMultiPayInvoice') }}
                                  value={this.state.isMultiPayInvoice}
                                />
                              }
                            />
                          </Grid>
                          {/* <Grid item xs={12} className="discount-label-field">
                            <label className="mr-2">Discount Enable</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.InvoiceDiscount}
                                  onChange={(e) => { this.handleChangeID(e, 'InvoiceDiscount') }}
                                  value={this.state.InvoiceDiscount}
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={12} className="fee-name-field">
                            <Grid item sm={8} className="tax-select-field taxSelect">
                              <label className="fee-name-label-settings">Fee Name: </label>
                              <Textfield
                                required={true}
                                error={this.state.error.DiscountFeeNameError}
                                id="DiscountFeeName"
                                type="text"
                                // labels={t("FeeName")}
                                value={this.state.DiscountFeeName}
                                width={true}
                                onChange={(e) => this.handleChange(e, t)}
                                focus={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                            <label>Fee Type</label>
                            <select value={this.state.InvoiceSVCFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSVCFeeType') }}>
                              <option value='%'>%</option>
                              <option value='$'>$</option>
                            </select>
                          </Grid>
                          <Grid item sm={12} md={6} className="invoice-svc-fee-field">
                            <Textfield
                              required={true}
                              error={this.state.error.InvoiceSVCFeeError}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">{this.state.InvoiceSVCFeeType}</InputAdornment>,
                              }}
                              id="InvoiceSVCFee"
                              type="text"
                              labels={t("SVC Fee")}
                              value={this.state.InvoiceSVCFee}
                              width={true}
                              onChange={(e) => this.handleChange(e, t)}
                            />
                          </Grid> */}
                        </Grid>
                      </div>
                    </Grid>
                    {(this.state.locDetails && this.state.locDetails.midType && this.state.locDetails.midType == 'Surcharge') ?
                      <>
                        <hr />
                        <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                          <div className="vt-settings-inner">
                            <Grid container spacing={3}>
                              <Grid item xs={12} className="discount-label-field">
                                <label>Surcharge Enable</label>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      checked={this.state.InvoiceSurcharge}
                                      onChange={(e) => { this.handleChangeID(e, 'InvoiceSurcharge') }}
                                      value={this.state.InvoiceSurcharge}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} className="tax-select-field taxSelect">
                                <label>Surcharge Fee Name: </label>
                                <Textfield
                                  required={true}
                                  error={this.state.error.SurchargeFeeNameError}
                                  id="SurchargeFeeName"
                                  type="text"
                                  // labels={t("FeeName")}
                                  value={this.state.SurchargeFeeName}
                                  width={true}
                                  onChange={(e) => this.handleChange(e, t)}
                                  focus={true}
                                />
                              </Grid>
                              <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                                <label>Fee Type</label>
                                <select value={this.state.InvoiceSurchargeFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSurchargeFeeType') }}>
                                  <option value='%'>%</option>
                                  <option value='$'>$</option>
                                </select>
                              </Grid>
                              <Grid item sm={12} md={6} className="invoice-svc-fee-field">
                                <Textfield
                                  required={true}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{this.state.InvoiceSurchargeFeeType}</InputAdornment>,
                                  }}
                                  error={this.state.error.InvoiceSurchargeFeeError}
                                  id="InvoiceSurchargeFee"
                                  type="text"
                                  labels={t("Surcharge Fee")}
                                  value={this.state.InvoiceSurchargeFee}
                                  width={true}
                                  onChange={(e) => this.handleChange(e, t)}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </>
                      : ''}

                    <hr />
                    <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          <Grid item xs={12} className="discount-label-field">
                            <label>Tax Enable</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.InvoiceTax}
                                  onChange={(e) => { this.handleChangeID(e, 'InvoiceTax') }}
                                  value={this.state.InvoiceTax}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} className="fee-name-field">
                            <label>Fee Name: </label>
                            <span>Sales Tax </span>
                          </Grid>
                          <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                            <label>Fee Type</label>
                            <select value={this.state.InvoiceSalesTaxFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSalesTaxFeeType') }}>
                              <option value='%'>%</option>
                              <option value='$'>$</option>
                            </select>
                          </Grid>
                          <Grid item sm={12} md={6} className="invoice-svc-fee-field">
                            <Textfield
                              required={true}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">{this.state.InvoiceSalesTaxFeeType}</InputAdornment>,
                              }}
                              error={this.state.error.InvoiceSalesTaxFeeError}
                              id="InvoiceSalesTaxFee"
                              type="text"
                              labels={t("Sales Tax Fee")}
                              value={this.state.InvoiceSalesTaxFee}
                              width={true}
                              onChange={(e) => this.handleChange(e, t)}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>


                  </Grid>
                </TabPanel>

                <TabPanel value={this.state.tab} index={1} className={classes.mT1}>
                  <ReactQuill value={this.state.termCondition}
                    onChange={this.handleModelChange} />
                </TabPanel>
                <TabPanel value={this.state.tab} index={2} className={classes.mT1}>

                  <Grid container item xs={12}>

                    <Grid container spacing={3} >

                      <Grid item xs={6} sm={6}>
                        <Textfield
                          required={true}
                          error={this.state.error.phoneError}
                          id="phone"
                          type="text"
                          labels={t("Phone")}
                          value={this.state.receiptSetting ? this.state.receiptSetting.phone : ''}
                          width={true}
                          onChange={(e) => this.handleChangeReceipt(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Textfield
                          required={true}
                          error={this.state.error.emailError}
                          id="email"
                          type="text"
                          labels={t("Email")}
                          value={this.state.receiptSetting ? this.state.receiptSetting.email : ''}
                          width={true}
                          onChange={(e) => this.handleChangeReceipt(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6}  >
                        <label>Receipt Phone Number</label>
                        <Checkbox
                          id="isPhone"
                          labels="Create New User"
                          checked={this.state.receiptSetting.isPhone}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.receiptSetting.isPhone}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }} />
                      </Grid>
                      <Grid item xs={6}  >
                        <label>Receipt Email</label>
                        <Checkbox
                          id="isEmail"
                          labels="Create New User"
                          checked={this.state.receiptSetting.isEmail}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.receiptSetting.isEmail}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }} />
                      </Grid>
                    </Grid>
                  </Grid>

                </TabPanel>
              </div>
            </LoadingOverlay>
          </div>
          <div className="mt-4">
            <Buttons
              id="signUp"
              type="button"
              variant="contained"
              color="primary"
              className={classesSe.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text={t("Save")} />
            <Buttons
              className={classesSe.ml_2}
              variant="contained"
              color="secondary"
              text={t("Back")}
              onClick={this.props.history.goBack} />
          </div>
        </div>
           </div>               
      </div >
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    infoCompany: state.CompanyReducer,
  }
}
const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles()
  return (<Settings classes={classes} {...props} />)
}

export default connect(mapStateToProps, { UserDetailForCustomMiddleWare, companySettings, companyTermCondition, userSettings })(withTranslation()(ApplyingStylesOnClasses));
