import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LoadingOverlay from 'react-loading-overlay';
import { Textfield } from './../../components/Textfield';
import { addCustomer, editCustomer, addDuplicateCustomer } from '../../actions/CustomerAction'
import { UserDetailById } from '../../actions/AdminUserAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import config from './../../constants/config'
import classesCus from './Customers.module.scss';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import TextfieldLable from './TextfieldLable';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import { Checkbox, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import binlookup from "binlookup";
const credit_or_debit = binlookup();
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
const initialState = {
  sideShow: true,
  isLoading: true,
  isOpen: false,
  firstName: '',
  lastName: '',
  name: '',
  customerAddress: '',
  customerCity: '',
  createdBy: '',
  companyId: '',
  customerCompanyName: '',
  customer_id: '',
  zip: '',
  email: '',
  phone: '',
  customFields: [],
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  cardPanelShow: true,
  paytraceCredentials: {},
  card: {
    cardHolderName: '',
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
  },
  error: {
    firstNameError: false,
    emailError: false,
    amountError: false,
    phoneError: false,
    customerCompanyNameError: false,
    customerAccountNumberError: false,
    zipError: false
  },
  errorCard: {
    cardNumber: false,
    cardHolderName: false,
    cardExpiryDate: false,
    cardCsc: false
  }
};

class AddCustomers extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.fetchDetailsData();
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword };
    this.setState({ 'paytraceCredentials': paytraceCredentials });
    if (window.paytrace && locationPaydetails) {
      window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + locationPaydetails.paytraceKey);
    }
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false });
  }

  fetchDetailsData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ 'id': userData._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        let data = res.payload.userData.data.company.customFields;
        let dataArr = data.filter((el) => {
          return el !== "";
        })
        this.setState({ 'customFields': dataArr, isLoading: false });
      }
    })
  }

  handleChange(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (id === 'zip' && value.toString().length === 6) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {    
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    if (this.state.customerCompanyName === "") {
      delete this.state.customerCompanyName;
    }
    if (this.state.firstName === "") {
      delete this.state.firstName;
    }
    if (this.state.lastName === "") {
      delete this.state.lastName;
    }
   
    this.setState({ isSubmit: true });
    //check cutom fields values
    const values = [...this.state.customFields];
    values.map((key, val) => {
      return ((key['value'] === null || (typeof key['value'] === 'string' && key['value'].trim() === '')) ? (error[key.label + 'Error'] = true, errorFlag = true) : error[key.label + 'Error'] = false);
    });
    if (this.checkCardValues(this.state.card)) {
      Object.keys(state).map((key, value) => {
        if (key === "name" && state.name === "") {
          return false;
        }else if (key === "customerCity" && state.customerCity === "") {
          return false;
        }else if (key === "customerAddress" && state.customerAddress === "") {
          return false
        }else if (key === "email" && state.email === "") {
          return false
        }else if (key === "phone" && state.phone === "") {
          return false
        }else if (key === "zip" && state.zip === "") {
          return false
        } else if ((validator(key, state[key]))) {
          return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
        } else {
          return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
        }
      });
      if (errorFlag) {
        this.setState({ misMatchError, logoError, error, successMessages });
        return false;
      } else {
        this.setState({ isLoading: true });
        window.paytrace.submitEncrypted('#DemoFormCustomer');
        // console.log(this.state.card.cardNumber);
        // console.log(this.state.card.cardNumber.substr(-4));
       // let userMaskNumber = this.state.card.cardNumber.replaceAll('_', '') ? '************ '+this.state.card.cardNumber.substr(-4) : ''
        let userMaskNumber = this.state.card.cardNumber.replaceAll('_', '') ? this.state.card.cardNumber.substring(0, 6) + '******' + this.state.card.cardNumber.substr(-4) : ''

        this.setState({
          card: { ...this.state.card, cardNumber: userMaskNumber, cardCsc: '' },        
          isLoading: true,
          encrypted_number: localStorage.getItem('0'),
          encrypted_csc: localStorage.getItem('1')
        }, () => {
          localStorage.removeItem('0');
          localStorage.removeItem('1');
          window.scrollTo(0, 0, 0);
          this.props.addCustomer(this.state, this.state.paytraceCredentials).then(result => {
            if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
              this.setState({ ...initialState, isLoading: false, successMessages: true }, () => {
                setTimeout(() => {
                  this.setState({ redirect: true })
                }, 2000);
              });
            }
            if (result !== undefined && result.type === 'CUSTOMER_ERROR') {

              // if (window.confirm("Warning: Customer ID already exists. Do you wish to proceed? Yes or No")) {
                // this.props.addDuplicateCustomer(this.state, this.state.paytraceCredentials).then(result => {
                  var initialState = {...this.state.initialState}
                initialState.customer_id = ''
                  if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
                    var initialState = {...this.state.initialState}
                     initialState.customer_id = ''
                    this.setState({ ...initialState, isLoading: false, successMessages: true }, () => {
                      setTimeout(() => {
                        this.setState({ redirect: true })
                      }, 2000);
                    });
                  }
                // })
              // }
              
              var initialState = {...this.state.initialState}
              initialState.customer_id = ''

              this.setState({ misMatchError: this.props.info.customerInfo.data.message, isLoading: false, successMessages, initialState });
            }
          });
        })
        
      }
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleChangeCustom(event, t, i) {
    let error = {}
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields: values, error }, () => {
    });
  }

  handleAdd() {
    this.setState({ isOpen: true })
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields];
    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  }

  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  handleChangeCard(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value.length===16 && value.slice(-1)==="_"? value.slice(0,-1):value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ card: { ...this.state.card, [id]: value.slice(-1)==="_"? value.slice(0,-1):value }, errorCard: { ...this.state.errorCard, [id]: false }, error, misMatchError, isSubmit: false }, () => {
      let firstNumber = this.state.card.cardNumber;
      let creditOrDebit = '';
      credit_or_debit(firstNumber).then(data => {
        if (typeof data.type !== "undefined"  || data.scheme === "mastercard" ) {
          creditOrDebit = (data.type && data.type === "debit") ? 'debit' :  'credit';
        }
        this.setState({ card: { ...this.state.card, customerToken: true, 'cardType': this.getCardType(firstNumber.charAt(0)), 'creditOrDebit': creditOrDebit } });
      });
      this.setState({ card: { ...this.state.card, customerToken: true, 'cardType': this.getCardType(firstNumber.charAt(0)), 'creditOrDebit': creditOrDebit } });
      // console.log(this.getCardType(firstNumber.charAt(0)), " this.getCardType(firstNumber.charAt(0)) ");
      if (firstNumber[0] === "3") {
        this.setState({ americanExpress: true});
      } else {
        this.setState({ americanExpress: false });
      }
    });
  }

  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return 'CreditCard'
    }
  }
  
  checkCardValues(state) {
    if(this.state.card.customerToken){
      if (this.state.card.cardNumber === "_______________") {
        this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } })
        window.scrollTo(0, 0)
        return false;
      }else if ((state.cardNumber.indexOf('_') > -1)) {
        this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } })
        window.scrollTo(0, 0)
        return false;
      } else if ((state.cardExpiryDate.indexOf('_') > -1)) {
        this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } })
        window.scrollTo(0, 0)
        return false;
      } else if ((state.cardCsc.indexOf('_') > -1)) {
        this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } })
        window.scrollTo(0, 0)
        return false;
      } else {
        return true;
      }
    }else{
      return true;
    }
  }

  handleChangeCheckbox(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  handleChangeCheckboxUpdate(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  render() {

    const { classes, t } = this.props
    const { redirect } = this.state;
    return (
      <div className="rightPanel">
        {(redirect) ? <Redirect to='/Customers' /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >

              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.customerInfo.data.message]} /> : ''
              }
              <h3>Add Customer</h3>
              <form className={classes.form} noValidate ref={this.myRef} id={"DemoFormCustomer"}>
                <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<TextfieldLable handleClose={this.handleClose} handleLabels={this.handleLabels} />} />
                <Grid container item xs={12} spacing={1}  >
                <Grid item xs={12} sm={6}>
                    <Textfield
                    // required={true}
                      id="name"
                      error={this.state.error.nameError}
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("Name")}
                      // value={(this.state.name) ? this.state.name : ' '}
                      value = {this.state.name}
                      focus={true}
                      width={true} />
     
                  </Grid>
                  {/* <Grid container item sm={6} xs={12}> */}
                    {/* <Textfield
                      id="firstName"
                      // error={this.state.error.firstNameError}
                      type="text"
                      labels={t("First Name")}
                      value={this.state.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      id="lastName"
                      type="text"
                      labels={t("Last Name")}
                      value={this.state.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.customerCompanyNameError}
                      id="customerCompanyName"
                      type="text"
                      labels={t("Company Name")}
                      value={this.state.customerCompanyName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      
                    />
                  </Grid> */}
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      id="email"
                      type="text"
                      // labels={t("Email")}
                      labels={"Email"}
                      value={this.state.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container item sm={4} xs={12}>
                    <Textfield
                      id="phone"
                      type="number"
                      pattern="[789][0-9]{9}"
                      // labels={t("Phone")}
                      labels={"Phone"}
                      value={this.state.phone}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      
                    />
                  </Grid>
                  <Grid container item sm={4} xs={10}>
                    <Textfield
                      required={true}
                      error={this.state.error.customer_idError}
                      id="customer_id"
                      type="text"
                      labels={t("CustomerId")}
                      value={this.state.customer_id}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                     
                    />
                  </Grid>
                  <Grid container item sm={1} xs={2}>
                    <Tooltip title="Here Costumer Account Number is same as Costumer Id" aria-label="add" style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow>
                      <InfoTwoToneIcon style={{ marginTop: '30px' }} />
                    </Tooltip>
                  </Grid>
                  <Grid container item sm={3} xs={12}  >
                    <Textfield
                      id="zip"
                      type="number"
                      labels={t("Zip Code")}
                      value={this.state.zip}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                  
                    />
                  </Grid>
                  <Grid container item sm={4} xs={12}>
                  <Textfield
                      id="customerCity"
                      type="text"
                      labels={t("City")}
                      value={this.state.customerCity}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container item sm={1} xs={12}></Grid>
                  <Grid container item sm={4} xs={12}>
                  <Textfield
                      id="customerAddress"
                      type="text"
                      placeholder="Address"
                      multiline={true}
                      rows={4}
                      value={this.state.customerAddress}
                      width={true}
                      variant="outlined"
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  
                  </Grid>
                  <Grid container item sm={3} xs={12}></Grid>

                  <Grid item sm={12} xs={12} md={5}>
                    <Card className={classNames([(this.state.cardPanelShow) ? 'cardData' : 'blur', 'customCardWraper', 'block'])}>
                      <div className="Card_header">
                        {(!this.state.updateCard) ? <Typography variant="h5" component="h2">
                          Card Details
                        </Typography> : <><Typography variant="h5" component="h2">
                            Card Details
                            </Typography>
                            <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>back</a></>}
                      </div>
                      <CardContent ref={this.cardRef} className="cardContents">
                        {(this.state.errorCard.cardNumber) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number")}</label>}
                        <MaskedInput type="text" value={this.state.card.cardNumber} className="pt-encrypt" mask={(this.state.americanExpress ? '111111111111111' : '1111111111111111')} labels={t("Card Number")} id="cardNumber" name="encrypted_number" size="20" onChange={(e) => this.handleChangeCard(e, t)} />
                        <Grid container item xs={12}>
                          <Grid item md={6} xs={12} >
                            {(this.state.errorCard.cardExpiryDate) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Card Expiry Date Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t(" Expiry Date")}</label>}
                            <MaskedInput mask="11/1111" value={this.state.card.cardExpiryDate} name="cardExpiryDate" id="cardExpiryDate" placeholder="mm/yyyy" onChange={(e) => this.handleChangeCard(e, t)} />
                          </Grid> 
                          <Grid className="custom-spacing" item md={6} xs={12} >
                            {(this.state.errorCard.cardCsc) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC")}</label>}
                            <MaskedInput type="text" value={this.state.card.cardCsc} mask={this.state.americanExpress ? "1111" : "111"} className="pt-encrypt" id="cardCsc" name="encrypted_csc" onChange={(e) => this.handleChangeCard(e, t)} />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        {(!this.state.updateCard) ? <> <Checkbox
                          id="customerToken"
                          labels="Create New User"
                          checked={this.state.card.customerToken}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.card.customerToken}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }} />
                          <label>Save card for future payments</label></> : <> <Checkbox
                            id="customerTokenUpdate"
                            labels="Create New User"
                            checked={this.state.card.customerTokenUpdate}
                            onChange={(e) => { this.handleChangeCheckboxUpdate(e) }}
                            value={this.state.card.customerTokenUpdate}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }} /><label>Update card for future payments</label></>}
                      </CardActions>
                    </Card>

                    {/* for mask mask number */}
                    < div xs={12} className={(this.state.cardPanelShow && this.state.mask_number) ? 'block' : 'none'}>

                      <Card className={classNames([(this.state.cardPanelShow) ? 'maskData' : 'blur', 'customCardWraper'])}>
                        <div className="Card_header">

                          <Typography variant="h5" component="h2">
                            Name : {this.state.name}
                          </Typography>

                        </div>
                        <CardContent ref={this.cardRef} className="cardContents">
                          <labels className="MuiTypography-gutterBottom">{t("Mask Card Number")}</labels>
                          <Grid container item xs={12}>
                            {(this.state.mask_number) ? this.state.mask_number : ''}
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Buttons
                            id="AddNewCard"
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={"addNewCardBtn"}
                            onClick={(e) => this.handleSubmitAddNewCard(e, t)}
                            text={t("Add Another Card")} />
                        </CardActions>
                      </Card>
                    </div>
                  </Grid>
                  <Grid container item sm={12} xs={12} justify="flex-end">
                    <Buttons
                      className={classesCus.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t("Add More")}
                      onClick={(e) => this.handleAdd(e)} />

                  </Grid>
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6} key={idx}>
                        <div key={`${field}-${idx}`}>
                          <CloseIcon onClick={() => this.handleRemove(idx)} />

                          <Textfield
                            error={this.state.error[field.label + 'Error']}
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ""}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            
                          />
                        </div>
                      </Grid>
                    );
                  })}

                </Grid>
                <Grid item xs={12} sm={12} className="mt-2">
                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t("Save")} />
                  <Buttons
                    className={classesCus.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack} />
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<AddCustomers theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    infoUser: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { UserDetailById, addCustomer, editCustomer, addDuplicateCustomer })(withTranslation()(withRouter(ApplyingStyles)))