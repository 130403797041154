import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

const SpinnerLoader = (props) => {
  const loaderClasses = useStylesFacebook();
  const loaderSize = 25;
  return (
    <div className={loaderClasses.root}>
      <CircularProgress
        variant="determinate"
        className={loaderClasses.bottom}
        size={loaderSize}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={loaderClasses.top}
        classes={{
          circle: loaderClasses.circle,
        }}
        size={loaderSize}
        thickness={4}
        {...props}
      />
    </div>
  );
};

export default SpinnerLoader;
