import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Customer info Begin Action
 */
export const contentStart = () => ({
    type: ActionType.CONTENT_START
});

/**
* content info Success Action
*/
export const contentSuccess = contentData => {
    return {
        type: ActionType.CONTENT_SUCCESS,
        payload: { contentData }
    }
};

/**
* content info Failure Action
*/
export const contentError = error => ({
    type: ActionType.CONTENT_ERROR,
    payload: { error }
});

/**
 * content driver info
 */
export const addContent = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(contentStart());
        return DataApi(data, config.BASEURL + 'addContent',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(contentSuccess(response))
            } else {
                return dispatch(contentError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const editContent = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(contentStart());
        return DataApi(data, config.BASEURL + 'editContent',token,true).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                return dispatch(contentSuccess(response))
            } else {
                return dispatch(contentError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

export const contentList = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(contentStart());
        return DataGetApi(config.BASEURL + 'contentList',data,token).then((response) => {
            if (response.status === 200) {
                return dispatch(contentSuccess(response))
            } else {
                return dispatch(contentError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const getContentByPage = (data) => {
    return dispatch => {
        dispatch(contentStart());
        return DataGetApi(config.BASEURL + 'getContentByPage',data,null).then((response) => {
            if (response.status === 200) {
                return dispatch(contentSuccess(response))
            } else {
                return dispatch(contentError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}
export const contentDetailsId = (data) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token;
    return dispatch => {
        dispatch(contentStart());
        return DataGetApi(config.BASEURL + 'contentDetailsId',data,token).then((response) => {
            if (response.status === 200) {
                return dispatch(contentSuccess(response))
            } else {
                return dispatch(contentError(response));
            }
        }).catch((err) => {
            console.log(err);
        });
    };
}

// export const contentStatus = (data) => { 
//     let user = JSON.parse(localStorage.getItem('user'))
//     let token = user.token;
//     return dispatch => {
//         dispatch(contentStart());
//         return DataApi(data, config.BASEURL + 'contentStatus',token,true).then((response) => {
//             if (response.status === 200 && response.data.statusCode === 200) {
//                 return dispatch(contentSuccess(response))
//             } else {
//                 return dispatch(contentError(response));
//             }
//         }).catch((err) => {
//             console.log(err);
//         });
//     };
// }