import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { productList ,productStatus} from '../../actions/ProductAction'
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi'; 
import { formatCurrency } from '../../helpers/commonFunction';

class Products extends Component {
  constructor(props) {
    const date = new Date();
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),  
    }
  }
  toDateFilter(date) {
    this.setState({ toDate: date })
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date })
  }
  tableReload(status){
    this.setState({reload:status})
  }
  getTable(query){
    if(this.state.reload){
      query.search=""
      this.setState({reload:false})
    }
  return   new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1),'searchAll':(typeof query.search=="undefined" || query.search=="")?false:true
        , 'toDate': this.state.toDate, 'fromDate': this.state.fromDate
      };
      DataGetApi(url + 'productList', param, token).then(result => {
        resolve({
          data: (typeof query.search=="undefined" || query.search=="")?result.data.response:result.data.response.filter((row=>{
            return (row.name.toLowerCase().indexOf(String(query.search)) !== -1  ||  row.description.toLowerCase().indexOf(String(query.search)) !== -1 || String(row.amount).toLowerCase().indexOf(query.search) !== -1)
          })),
          page: result.data.pagination.page - 1,
          // totalCount: result.data.pagination.totalCount,
          totalCount: (typeof query.search=="undefined" || query.search=="")?result.data.pagination.totalCount:result.data.response.filter((row=>{
            return (row.name.toLowerCase().indexOf(String(query.search)) !== -1  ||  row.description.toLowerCase().indexOf(String(query.search)) !== -1 || String(row.amount).toLowerCase().indexOf(query.search) !== -1)
          })).length,
        })
      }).catch(error => {
        console.log('fetching clients error : ', error);
        // toast.error('There was an error trying to fetch clients');
        reject(error);
      })
    })
  }


  productStatusToggle(e, data) {
    this.props.productStatus(data).then((res) => {
      //this.productListData()
    })
  }
   
handleChangeId(id){
  this.props.history.push('/CustomerDetails/'+id)
}

  render() {
    const columns = [
      { title: 'Name', field: 'name'},
      { title: 'Description', field: 'description',  render:(rowData)=>rowData.description?rowData.description:'N/A' },
      { title: 'Amount', field: 'amount',render : rowData=>(formatCurrency(rowData.amount))},
      { title: 'Notes', field: 'notes', render:(rowData)=>rowData.notes?rowData.notes:'N/A'},
   ];
 
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
            companyActiveAction={(e,data)=>this.productStatusToggle(e,data)}
            tableReload={(e)=>this.tableReload(e)}
              options={{
                exportButton: true
              }}
              columns={columns}
              createNew={false}
              data={(query)=>this.getTable(query)}
              title={'Products'}
              search={true}
              // exports={true}
              permissions={this.props.UserDetail.permissions}
              exports={this.props.UserDetail.permissions.exportRecord}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              // datefilter={true}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.ProductReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { productList ,productStatus})(withTranslation()(Products));