import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import FormControl from '@material-ui/core/FormControl';
import TypableSelect from './../../helpers/TypableSelect';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { addUser } from '../../actions/AdminUserAction'
import { AllCompany, countryDataList } from '../../actions/CompanyAction'
import config from './../../constants/config'
import IntegrationDownshift from './../../helpers/multiSelect'
import { locationList } from '../../actions/LocationAction'
import validator from './../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import classes from './Company.module.scss';
const filter = createFilterOptions();

const mapToFormatCountry = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  return Object.keys(state).sort().map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, title: name }
  ))
};
const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};

class AddUserCompanyDetails extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this);
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: config.ADMIN_ROLE,
      sideShow: true,
      isLoading: true,
      isLocation: false,
      company: {},
      companyData: '',
      misMatchError: [],
      isSubmit: false,
      locationSelected: [],
      locationSelectedwithmid: [],
      data: [],
      permissions: {
        creatNewUser: true,
        editCustomer: true,
        exportRecord: true,
        refunds: true,
        refundsManual: true,
        allLocaton: true,
        createInvoice: true,
        textToInvoice: true,
        ach: true,
        virtualTerminal: true,
        recurring: true,
        svcFeeEdit: true,
        void: true,
        preAuthorize: true,
        creditSale: true,
        emailNotification : true,
        viewAllTransactions: true
      },
      location: {
        locationName: '',
        email: '',
        // secretKey: '',
        phone: '',
        mId: '',
        mId2: '',
        contactName: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        timeZone: new Date(),
        merchantId: '',
        // gatewayMerchantId: '',
        notes: '',
        // terminalId: '',
        // terminalNumber: '',
        // batchItem: '',
        mId2Credentials: true,
        paytraceKey: [],
        paytracePassword: "",
        paytraceUserName: "",
        paytraceKey2: [],
        paytracePassword2: "",
        paytraceUserName2: "",
      },
      stateList: {},
      cityList: [],
      Countrydata: [],
      locationError: {
        nameError: false,
        // secretKeyError: false,
        // gatewayMerchantIdError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
        paytracePasswordError: false,
        paytraceUserNameError: false,
        paytracePassword2Error: false,
        paytraceUserName2Error: false,
      },
      error: {
        UserNameError: false,
        phoneError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
    }
  }


  componentDidMount() {
    this.getLocationData();
    this.getCompanyData();
    this.getcountryDataList();
    let dbaName = this.props.dbaName;
    const { match: { params } } = this.props.props;
    this.setState({ company: { ...this.state.company, value: params.id, label: dbaName }, location: { ...this.state.location, merchantId: params.id } });
  }
  getcountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }
  getCompanyData() {
    this.setState({ isLoading: true });
    this.props.AllCompany().then((res) => {
      if (res.payload.companyData.status === 200)
        this.setState({ companyData: res.payload.companyData.data.response, isLoading: false })
    });
  }
  getLocationData() {
    const { match: { params } } = this.props.props;
    let merchant = params.id;
    let data = { 'companyId': merchant }
    let midArray = [];
    this.props.locationList(data).then((res) => {
      // this.setState({ data: res.payload.locationData.data.response, isLoading: false })
      midArray = res.payload.locationData.data.response;

      let midArray2 = [];
      let val = {};
      let val1 = {};

      for (var x = 0; x < midArray.length; x++) {
        val = midArray[x];

        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey2;
            val1.paytracePassword = val.paytracePassword2;
            val1.paytraceUserName = val.paytraceUserName2;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            midArray2.push(val1);
            val1 = {};
          }
        } else {

          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {

            if (midArray[x].mId) {

              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId";
              val1.address1 = val.address1;
              val1.address2 = val.address2;
              val1.contactName = val.contactName;
              val1.company = val.company;
              val1.city = val.city;
              val1.country = val.country;
              val1.createdAt = val.createdAt;
              val1.email = val.email;
              val1.id = val.id;
              val1.isDeleted = val.isDeleted;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.notes = val.notes;
              val1.paytraceKey = val.paytraceKey;
              val1.paytracePassword = val.paytracePassword;
              val1.paytraceUserName = val.paytraceUserName;
              val1.phone = val.phone;
              val1.state = val.state;
              val1.status = val.status;
              val1.timeZone = val.timeZone;
              val1.zip = val.zip;
              val1._v = val._v;
              midArray2.push(val1);
              val1 = {};
            }
          }
        }
      }

      // if (midArray2 && midArray2.length > 0) {
      this.setState({ data: midArray2, isLoading: false });
      this.setState({ locationSelected: Object.keys(midArray2).map((k, v) => midArray2[k].id), locationSelectedwithmid: midArray2 });

      this.setState({
        locationsData: midArray2,
        isLoading: false,
      });
      // }
    })
  }
  //upload paytace key
  uploadKey = (event, t) => {
    let file = event.target.files[0];
    this.state.location.paytraceKey[0] = file;
    this.setState({ location: { ...this.state.location, paytraceKey: this.state.location.paytraceKey } });
    if (this.state.location.mId2Credentials && this.state.location.mId2 && this.state.location.mId2.trim() != '') {
      this.state.location.paytraceKey[1] = this.state.location.paytraceKey[0];
      // this.setState({ location: { paytracePassword2: this.state.location.paytracePassword, paytraceUserName2: this.state.location.paytraceUserName } });
    }

  }
  uploadKey2 = (event, t) => {
    let file2 = event.target.files[0];
    this.state.location.paytraceKey[1] = file2;
    this.setState({ location: { ...this.state.location, paytraceKey: this.state.location.paytraceKey } });

  }
  addNewLocation(e) {
    if (this.state.isLocation)
      this.setState({ isLocation: false });
    else
      this.setState({ isLocation: true });
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleChangeCheckbox(event) {
    if (event.target.id === "allLocaton") {
      var locationSelectedValue = [];
      var previousSelectedLocationValue = [];
      var setData = []
      if (event.target.checked) {
        let locationsIds = this.state.data.map((items) => items._id);
        previousSelectedLocationValue = this.state.locationSelected
        locationSelectedValue = locationsIds
        setData = this.state.data.map((items) => items);
      } else {
        locationSelectedValue = this.state.previousSelectedLocation
        setData = this.state.data.map((items) => items);
      }
      this.setState({
        locationSelected: locationSelectedValue,
        previousSelectedLocation: previousSelectedLocationValue,
        data: setData,
      })
    }
    this.setState({ permissions: { ...this.state.permissions, [event.target.id]: event.target.checked } });
  }

  handleChangeLocation(event, t) {
    let error = {}
    let successMessages = false;
    let locationError = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (id === 'zip' && value.toString().length === 6) {
      return false;
    }
    (validator(id, value)) ? (locationError[id + 'Error'] = true) : (locationError[id + 'Error'] = false)
    this.setState({ location: { ...this.state.location, [id]: value }, error, successMessages, misMatchError, locationError, isSubmit: false });
  }
  handleChange = (event, t) => {
    let successMessages = false;
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, successMessages, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let locationState = this.state.location;
    let state = this.state;
    let error = {};
    let locationError = {};
    let errorFlag = false;
    this.setState({ isSubmit: true, })
    if (locationState.address2 === '') {
      delete locationState.address2;
    }
    if (locationState.notes === '') {
      delete locationState.notes;
    }
    if (this.state.isLocation) {
      if (locationState.mId2Credentials && locationState.mId2.trim() != '') {
        locationState.paytraceKey[1] = locationState.paytraceKey[0];
        this.setState({ location: { ...locationState, paytracePassword2: this.state.paytracePassword, paytraceUserName2: this.state.paytraceUserName } });
      }
      Object.keys(locationState).map((key, value) => {
        if ((locationState.mId2.trim() == '' && key == 'mId2') || (locationState.mId2.trim() == '' && key == 'paytraceUserName2') || (locationState.mId2.trim() == '' && key == 'paytracePassword2')) {
          return '';
        } else {
          if ((validator(key, locationState[key]))) {
            return (validator(key, locationState[key])) ? (locationError[key + 'Error'] = true, errorFlag = true) : (locationError[key + 'Error'] = false)
          } else {
            return (locationState[key] === '' || (typeof locationState[key] === 'string' && locationState[key].trim() === '')) ? (locationError[key + 'Error'] = true, errorFlag = true) : ''
          }
        }
      }
      );
    }
    Object.keys(state).map((key, value) => {
      if (key === "phone" && !this.state.permissions.textToInvoice) {
        return false;
      }
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });
    if (errorFlag) {
      window.scrollTo(0, 0)
      this.setState({ misMatchError, error, locationError, successMessages });
      console.log("misMatchError, error, locationError, successMessages ", misMatchError, error, locationError, successMessages);
      return false;
    } else {
      const data = new FormData();
      const { paytraceKey } = this.state;
      for (const key in this.state) {
        if ("paytraceKey" === key) {
          continue;
        }
        data.append(key, this.state[key]);
      }
      if (this.state.isLocation) {
        for (const keys in paytraceKey) {
          data.append("paytraceKey", paytraceKey[keys]);
        }
      }
      this.setState({ isLoading: true });
      this.props.addUser(this.state).then(result => {
        window.scrollTo(0, 0);
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true, isLoading: false })
          setTimeout(() => {
            this.goBack(e);
          }, 3000)
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, isLoading: false, successMessages });
        }
      });
    }
  }
  setCountryState(val) {
    if (val !== null)
      this.setState({
        stateList: this.state.Countrydata.filter(obj => (obj.Country === val.label))[0].State,
        location: { ...this.state.location, country: val.label }
      })
  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({
        cityList: this.state.stateList[val.label],
        location: { ...this.state.location, state: val.label }
      })
  }
  /*  setCitySet(val) {
     if (val !== null)
   
       this.setState({ location: { ...this.state.location, city: val.label } })
   } */

  setCitySet(event, val) {
    if (val !== null && val.hasOwnProperty('value')) {
      this.setState({ location: { ...this.state.location, city: val.title } })
    } else if (val && val.inputValue) {
      this.setState({ location: { ...this.state.location, city: val.inputValue } })
    } else {
      this.setState({ location: { ...this.state.location, city: val } })
    }
  }

  goBack(e) {
    this.props.handleChangeTab(e, 0);
  }

  addLocation = (arr) => {
    let misMatchError = [];
    let allLocationChceked =
      this.state.data.length === arr.length ? true : false;
    this.setState({
      locationSelected: arr, misMatchError,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      }
    })
  }

  addLocation2 = (arr) => {
    let allLocationChceked = this.state.locationSelectedwithmid.length === arr.length ? true : false;
    this.setState({
      locationSelectedwithmid: arr,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      },
    });
  };

  handleCheckboxCredentials1(event) {

    if (event.target.checked === false) {
      this.state.location.paytraceKey[1] = '';
      this.setState({ location: { ...this.state.location, paytracePassword2: '', paytraceUserName2: '', mId2Credentials: event.target.checked } });
    } else {
      this.state.location.paytraceKey[1] = this.state.location.paytraceKey[0] ? this.state.location.paytraceKey[0] : '';
      this.setState({ location: { ...this.state.location, paytracePassword2: this.state.location.paytracePassword, paytraceUserName2: this.state.location.paytraceUserName, mId2Credentials: event.target.checked } });
    }
  }

  render() {

    const { t } = this.props;
    const { Countrydata, data } = this.state;

    return (
      <LoadingOverlay
        className={classes.tpTableFullW}
        active={this.state.isLoading}
        spinner
        text='Fetching your response ...'>
        <Container component="main" maxWidth="md" >
          {this.state.misMatchError.length > 0 ?
            < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {/* {this.state.successMessages ?
          <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
        } */}
          {(this.state.successMessages && this.props.info) ?
            <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
          }

          <form className={classes.form} noValidate>
            <Grid container spacing={3} >
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t("Username")}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t("First Name")}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t("Last Name")}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Textfield
                  required={this.state.permissions.textToInvoice}
                  error={this.state.permissions.textToInvoice ? this.state.error.phoneError : false}
                  id="phone"
                  type="number"
                  labels={t("Phone")}
                  pattern="[789][0-9]{9}"
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth  >
                  <IntegrationDownshift
                    isLocationExists={true}
                    selectedLocations={data}
                    locationData={data}
                    addLocation={this.addLocation}
                    addLocation2={this.addLocation2}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} >
                <Textfield
                  className='Redcursor'
                  required={true}
                  id="dabName"
                  type="text"
                  labels={t("DBA Name")}
                  value={this.state.company.label}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  disabled={true} />
              </Grid>
              <Grid item xs={6}>
              </Grid>
              {this.state.isLocation ?
                <>
                  <Grid item xs={12}><b>Location Details</b></Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.locationNameError}
                      id="locationName"
                      type="text"
                      labels={t("Location Name")}
                      value={this.state.location.locationName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.contactNameError}
                      id="contactName"
                      type="text"
                      labels={t("Contact Name")}
                      value={this.state.location.contactName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.emailError}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.location.email}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      helperText="phone format xxx-xxx-xxxx"
                      error={this.state.locationError.phoneError}
                      id="phone"
                      type="number"
                      pattern="[789][0-9]{9}"
                      labels={t("Phone")}
                      value={this.state.location.phone}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      helperText="Traditional MID"
                      required={true}
                      error={this.state.locationError.mIdError}
                      id="mId"
                      type={"text"}
                      labels={t("Traditional")}
                      value={this.state.location.mId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      helperText="Surcharge MID"
                      id="mId2"
                      type={"text"}
                      labels={t("Surcharge")}
                      value={this.state.location.mId2}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.address1Error}
                      id="address1"
                      type="text"
                      labels={t("Address1")}
                      value={this.state.location.address1}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      id="address2"
                      type="text"
                      labels={t("Address2")}
                      value={this.state.location.address2}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                    <FormControl fullWidth style={getValidationStyle(!this.state.location.country && this.state.isSubmit)} >
                      <TypableSelect
                        placeholder={'Select country name'} suggestions={mapToFormatCountry(Countrydata)}
                        onChange={val => this.setCountryState(val)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                    <FormControl fullWidth style={getValidationStyle(!this.state.location.state && this.state.isSubmit)} >
                      <TypableSelect
                        placeholder={'Select state name'} suggestions={mapToFormatStates(this.state.stateList)}
                        onChange={val => this.setStatesSet(val)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '-0.2rem' }}>
                    <FormControl fullWidth style={getValidationStyle(!this.state.location.city && this.state.isSubmit)} >
                      {/*    <TypableSelect
                      placeholder={'Select city name'} suggestions={mapToFormatCity(this.state.cityList)}
                      onChange={val => this.setCitySet(val)}
                    /> */}
                      <Autocomplete
                        required={true}
                        value={this.state.city}
                        onChange={(e, value) => this.setCitySet(e, value)}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              title: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="city"
                        options={mapToFormatCity(this.state.cityList)}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        renderOption={(option) => option.title}
                        style={{ width: 200 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} label="city" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '-2.5rem' }}   >
                    <Textfield
                      required={true}
                      error={this.state.locationError.zipError}
                      id="zip"
                      type="number"
                      labels={t("ZIP")}
                      value={this.state.location.zip}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>


                  <Grid item xs={12}> <h3>Paytrace Api Credentials </h3></Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.paytraceUserNameError}
                      id="paytraceUserName"
                      type="text"
                      labels={t("User Name")}
                      value={this.state.location.paytraceUserName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.paytracePasswordError}
                      id="paytracePassword"
                      type="password"
                      labels={t("Password")}
                      value={this.state.location.paytracePassword}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                    <label>Paytrace key file    {(this.state.location.paytraceKey && (this.state.location.paytraceKey.length) >= 1 && this.state.location.paytraceKey[0] != '' && this.state.location.paytraceKey[0] != 'undefined' && this.state.location.paytraceKey[0] != null) ? <img alt="enable" className={classes.rightImg} src={config.APPURL + '/assets/images/checkmark-24.png'} /> : ''}
                    </label>
                    <input type="file" name="paytraceKey" onChange={(e) => this.uploadKey(e, t)} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                  </Grid>
                  {(this.state.location.mId2 && this.state.location.mId2.trim() != '') ?
                    <Grid item xs={12} sm={12}>
                      <label>
                        <Checkbox
                          id="mId2Credentials"
                          labels={t("mId2 Credentials")}
                          checked={this.state.location.mId2Credentials}
                          onChange={(e) => { this.handleCheckboxCredentials1(e) }}
                          // value={this.state.location.mId2Credentials}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Use Same User Credentials for MID 2 </span>
                      </label>
                    </Grid>
                    : ''
                  }

                  {(!this.state.location.mId2Credentials && this.state.location.mId2 && this.state.location.mId2.trim() !== '') ?
                    <>
                      <Grid item xs={12}><h3>Paytrace Api Credentials for MID 2</h3></Grid>
                      <Grid item xs={6} sm={3}>
                        <Textfield
                          required={this.state.location.mId2 && this.state.location.mId2.trim() != '' ? true : false}
                          id="paytraceUserName2"
                          error={this.state.locationError.paytraceUserName2Error}
                          type="text"
                          labels={t("User Name")}
                          value={this.state.location.paytraceUserName2}
                          width={true}
                          onChange={(e) => this.handleChangeLocation(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Textfield
                          required={this.state.location.mId2 && this.state.location.mId2.trim() != '' ? true : false}
                          id="paytracePassword2"
                          error={this.state.locationError.paytracePassword2Error}
                          type="password"
                          labels={t("Password")}
                          value={this.state.location.paytracePassword2}
                          width={true}
                          onChange={(e) => this.handleChangeLocation(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                        <label>Paytrace key file    {(this.state.location.paytraceKey && this.state.location.paytraceKey.length == 2 && this.state.location.paytraceKey[1] != '') ? <img alt="enable" className={classes.rightImg} src={config.APPURL + '/assets/images/checkmark-24.png'} /> : ''}
                        </label>
                        <input type="file" name="paytraceKey2" onChange={(e) => this.uploadKey2(e, t)} />
                      </Grid>
                      <Grid item xs={6} sm={3}></Grid>
                    </> : ''
                  }
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.merchantIdError}
                      id="merchantId"
                      type="text"
                      labels={t("Titanium Merchant Id")}
                      value={this.state.location.merchantId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: '1.3rem' }}>
                    <Textfield
                      id="notes"
                      type="text"
                      labels={t("Notes")}
                      value={this.state.location.notes}
                      width={true}
                      rowsMax={"2"}
                      multiline={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.gatewayMerchantIdError}
                      id="gatewayMerchantId"
                      type="text"
                      labels={t("GatewayMerchantId")}
                      value={this.state.location.gatewayMerchantId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalIdError}
                      id="terminalId"
                      type="text"
                      labels={t("Terminal Id")}
                      value={this.state.location.terminalId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalNumberError}
                      id="terminalNumber"
                      type="text"
                      labels={t("Terminal Number")}
                      value={this.state.location.terminalNumber}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid> */}
                  {/* <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.batchItemError}
                      id="batchItem"
                      type="text"
                      labels={t("Batch Item")}
                      value={this.state.location.batchItem}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      helperText="it must contain at least one uppercase, one lower case, one special character and a number,length 8-20"
                      required={true}
                      error={this.state.locationError.secretKeyError}
                      id="secretKey"
                      type="text"
                      labels={t("Secret Key")}
                      value={this.state.location.secretKey}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid> */}


                </>
                : ''}
              <Grid item xs={6}>
              </Grid>
              <Grid container item xs={12}>
                {(this.state.role < 4) ? <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="creatNewUser"
                      labels="Create New User"
                      checked={this.state.permissions.creatNewUser}
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.creatNewUser}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Create New User</span>
                  </label>
                </Grid> : ''}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="refunds"
                      checked={this.state.permissions.refunds}
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.refunds}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Refunds General</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="editCustomer"
                      checked={this.state.permissions.editCustomer}
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.editCustomer}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Edit Customer</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="textToInvoice"
                      checked={this.state.permissions.textToInvoice}
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.textToInvoice}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Text To Invoice</span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.refundsManual}
                      id="refundsManual"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.refundsManual}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Refunds Manual</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.allLocaton}
                      id="allLocaton"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.allLocaton}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>All Location</span>
                  </label>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.createInvoice}
                      id="createInvoice"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.createInvoice}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Invoicing</span>
                  </label>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.ach}
                      id="ach"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.ach}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>ACH </span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.virtualTerminal}
                      id="virtualTerminal"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.virtualTerminal}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Virtual Terminal</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.recurring}
                      id="recurring"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.recurring}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Recurring billing</span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.svcFeeEdit}
                      id="svcFeeEdit"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.svcFeeEdit}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Svc Fee Edit</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.exportRecord}
                      id="exportRecord"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.exportRecord}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Export Records</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.void}
                      id="void"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.void}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Void</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                    checked={this.state.permissions.emailNotification}
                      id="emailNotification"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.emailNotification}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>{t("emailNotifications")}</span>

                  </label>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.preAuthorize}
                      id="preAuthorize"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.preAuthorize}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Pre-Authorization</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.creditSale}
                      id="creditSale"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.creditSale}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Credit Sale</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.viewAllTransactions}
                      id="viewAllTransactions"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.viewAllTransactions}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>View All Transactions</span>
                  </label>
                </Grid>
              </Grid>
              <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t("Save")} />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="secondary"
                text={t("Back")}
                onClick={(e) => this.goBack(e)}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color={(!this.state.isLocation) ? "primary" : "secondary"}
                text={(!this.state.isLocation) ? t("Add New Location") : t("Remove New Location")}
                onClick={(e) => this.addNewLocation(e, t)} />
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    )
  }
}
AddUserCompanyDetails.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { addUser, AllCompany, countryDataList, locationList })(withTranslation()(AddUserCompanyDetails));
