import { ActionType } from './../constants';

const initialState = {
  signin: [],
  loading: false,
  error: null,
  msg: null
};


const TicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.TICKET_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionType.TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketInfo: action.payload.ticketData
      };

    case ActionType.TICKET_ERROR:
      return {
        ...state,
        loading: false,
        ticketInfo: action.payload.error
      };

    default:
      return state;
  }
}

export { TicketReducer };
