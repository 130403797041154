import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getTransactionList, getTransactionOverview, getTransactionDailyHours, getTransactionMonthlyOverview ,getDeclinedTransactions } from '../../actions/PaymentAction'
import { Topheader } from './../../components/Topheader';
import { Line } from 'react-chartjs-2/dist/react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import classes from './Dashboard.module.scss';
import { Table } from '../../components/Table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency } from '../../helpers/commonFunction';
import css from './dashboard.css';
const options= {
    scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Amount($)'
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Month'
          }
        }],
      }   ,
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function(tooltipItems, data) {
                return formatCurrency(tooltipItems.yLabel) ;
            }
        }
    },
}     
const optionsForDaily= {
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function(tooltipItems, data) { 
              return formatCurrency( tooltipItems.yLabel) ;
               // return '$'+tooltipItems.yLabel ;
            }
        }
    },
    scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Amount($)'
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Hours'
          }
        }],
      } 
}   
class Dashboard extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      reload: false,
      isLoading: true,
      TransactionData: [],
      TransactionHourData: [],
      TransactionMonthlyData: [],
      TransactionOverViewData: [],
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
    };
  }

  componentDidMount() {
    this.getTransactionOverViewData();
    this.getTransactionOverViewMonthlyData();
    this.getTransactionDailyHoursData(); 
  }


  /**
   * Get declined transaction data
   */
  getDeclinedTransactionsData(query) {
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false });
    }
    return new Promise((resolve,) => {
      let data = {
        start_date: new Date(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        ),
        end_date: new Date(),
        transaction_type: "Declined",
      };
      this.props.getDeclinedTransactions(data).then((res) => {
        if (res) {
          this.setState({ isLoading: false });
          resolve({
            data: res.payload.paymentData.data.response,
          });
        }
      });
    });
  }

  getTransactionDailyHoursData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : "",
      role: userData.role,
      userId: userData._id,
    };
    this.props.getTransactionDailyHours(data).then((res) => {
      if (res) {
        this.setState({
          TransactionHourData: res.payload.paymentData.data.response,
          isLoading: false,
        });
      }
    });
  }
  getTransactionOverViewMonthlyData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    let data = {
      companyId: userData.company ? userData.company._id : "",
      role: userData.role,
      userId: userData._id,
    };
    this.props.getTransactionMonthlyOverview(data).then((res) => {
        if (res) {
        this.setState({
          TransactionMonthlyData: res.payload.paymentData.data.response,
          isLoading: false,
        });
      }
    });
  }

  getTransactionOverViewData() {
    this.setState({ isLoading: true });
    const userData = JSON.parse(localStorage.getItem("user"));
    const locationDetails = JSON.parse(localStorage.getItem("locationPaydetails"));
    let data = {
      companyId: userData.company ? userData?.company?._id : "",
      role: userData?.role,
      userId: userData?._id,
      locationId: locationDetails?._id
    };
    this.props.getTransactionOverview(data).then((res) => {
      if (res && res.type === "PAYMENT_SUCCESS") {
        this.setState({
          TransactionOverViewData: res.payload.paymentData.data
            ? res.payload.paymentData.data.response
            : [],
          isLoading: false,
        });
      }
    });
  }

  getLinearData() {
    let hourArr = [];
    let amountArr = [];
    if (this.state.TransactionHourData.length > 0) {
      this.state.TransactionHourData.map((item) => {
        if (item.hour) {
          hourArr.push(new Date(item.date).getHours());
        }
        if (item.amount) {
          amountArr.push(item.amount);
        }
        return item;
      });
    }
    return {
      labels: hourArr,
      datasets: [
        {
          label: "Daily Transaction Activity",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: amountArr,
        },
      ],
    };
  }

  getBlockData() {
    let monthArr = [];
    let amountArr = [];
    const monthNames = [
      "January",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (this.state.TransactionMonthlyData.length > 0) {
      this.state.TransactionMonthlyData.map((item) => {
        if (item._id ) {
          monthArr.push(monthNames[item._id]);
          amountArr.push(item.total);
        }
        return item;
      });
    }
    return {
      labels: monthArr,
    datasets: [
        {
          label: "Monthly Revenue",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",         
          data: amountArr,          
          options: {
            legend: {
              display: false,
            },           
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  return (
                    data.labels[tooltipItem.index] +
                    " : " +
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ] +
                    "jjhj€"
                  );
                },
              },
            },
          },
        },
      ],
    };
  }

  // getTableData(query) {
  //     if (this.state.reload) {
  //       query.search = ""
  //       this.setState({ reload: false })
  //     }    
  //     return new Promise((resolve, reject) => {
  //         setTimeout(() => {
  //             let user = JSON.parse(localStorage.getItem('user'))
  //             let locationId = localStorage.getItem('locationArrIds');
  //             console.log(locationId, " locationIds ");
  //             let token = user.token;
  //             let url = config.BASEURL;
  //             let midType = '';
  //             if(localStorage.getItem('locationPaydetails') && localStorage.getItem('locationPaydetails')!== null){
  //               midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
  //             }
  //             let param = {
  //               'locationIds':locationId,
  //               'midType': midType,
  //               'role': user.role,
  //               'userId': user._id,
  //               'companyId':(user.role>1)?user.company._id:'',
  //               'per_page': query.pageSize, 'page': + (query.page + 1), 
  //               'searchAll': (typeof query.search === undefined || query.search === "") ? false : true, 
  //               'toDate': this.state.toDate, 
  //               'fromDate': this.state.fromDate
  //             };
  //             DataGetApi(url + 'getTransactionList', param, token).then(result => {
  //               resolve({
  //                 data: (typeof query.search === "undefined" || query.search === "") ? result.data.response :
  //                   result.data.response.filter((row) => {
  //                     return (String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || String(row.ORDERID).indexOf((query.search)) !== -1 || String(row.TXNID).indexOf((query.search)) !== -1)
  //                   }),
  //                 page: result.data.pagination.page - 1,
  //                 totalCount: result.data.pagination.totalCount,
  //               })
  //             })
            
  //         }, 1500 );
  //       });
  // }

  tableReload(status) {
    this.setState({ reload: status })
  } 

  handleChangeId(id) {
    this.props.history.push("/Viewtransaction/" + id);
  }

  getTableData(query) {
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false });
    }
    const locationDetails = this.props.CurrentLocation?.currentLocation;

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        let locationId = localStorage.getItem("locationArrIds");
        let orderBy = (query.orderBy!==undefined) ? query.orderBy.field : "";
        let orderDirection = (query.orderDirection!=="") ? query.orderDirection : "";
        let token = user.token;
        let url = config.BASEURL;
        let midType = '';
        if(locationDetails !== null){
          midType = (locationDetails && locationDetails?.midType) ? locationDetails.midType : '';
        }
        let param = {
          locationIds: locationId,
          midType: midType,
          role: user.role,
          userId: user._id,
          companyId: user.role > 1 ? user.company._id : "",
          per_page: query.pageSize,
          page: +(query.page + 1),
          searchAll:
            typeof query.search === "undefined" || query.search === ""
              ? false
              : true,
          toDate: this.state.toDate,
          fromDate: this.state.fromDate,
          orderBy:orderBy,
          orderDirection:orderDirection
        };
        DataGetApi(url + "getTransactionList", param, token).then((result) => {
          resolve({
            data:
              typeof query.search === "undefined" || query.search === ""
                ? result.data.response
                : result.data.response.filter((row) => {
                    return (
                      String(new Date(row.createdAt).toLocaleString()).indexOf(
                        String(query.search)
                      ) !== -1 ||
                      String(row.ORDERID).indexOf(query.search) !== -1 ||
                      String(row.TXNID).indexOf(query.search) !== -1
                    );
                  }),
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        });
      }, 1500);
    });
  }
  
  tableReload(status) {
    this.setState({ reload: status });
  }

  render() {
    const { TransactionOverViewData } = this.state;
    const tnxOverViewPaid = TransactionOverViewData.filter(
      (item) => item._id === "Paid"
    );
    const tnxOverViewReund = TransactionOverViewData.filter(
      (item) => item._id === "Refunded"
    );
    const tnxOverVoid = TransactionOverViewData.filter(
      (item) => item._id === "Voided"
    );

    const columns = [
      {
        title: "Payment Status",
        field: "FINAL_STATUS",
        render: (rowData) =>
          rowData.FINAL_STATUS === "Paid" ? (
            <button type="button" className="btn paidBtn">
              Paid
            </button>
          ) : rowData.FINAL_STATUS === "Refunded" ? (
            <button type="button" className="btn RefundedBtn">
              Refunded
            </button>
          ) : rowData.FINAL_STATUS === "Voided" ? (
            <button type="button" className="btn PenddingBtn">
              Voided
            </button>
          ) : rowData.FINAL_STATUS === "Declined" ?  (
            <button type="button" className="btn DeclinedBtn">
            Declined
          </button>
          ) : "",
      },
      {
        title: "Payment Type",
        field: "RESPONSECODE",
        render: (rowData) =>
          rowData.CARDTYPE === "ACH" || rowData.RESPONSECODE === "120"
            ? "ACH"
            : "Credit",
      },
      {
        title: "Transaction ID",
        field: "TXNID",
        render: (rowData) => (
          <Tooltip title="View Details">
            <span
              className={classes.Underline}
              onClick={(e) => this.handleChangeId(rowData.TXNID)}
            >
              {rowData.TXNID}
            </span>
          </Tooltip>
        ),
      },
      { title: 'Amount',
       field: 'amount',
       render: rowData => rowData.AMOUNT ? <span> { formatCurrency( rowData.AMOUNT) } </span> : ''  },
      // { title: 'Customer Name', field: 'CustomerName' },
    ];

    const columns2 = [
      {
        title: "DBA",
        field: "dbaName",
        render: (rowData) =>
          rowData._id ?  rowData._id.dbaName : ''
      },

      {
        title: "Number of Declines",
        field: "count",
        render: (rowData) =>   rowData.count ?  rowData.count : '' ,
      },
      {
        title: "Amount",
        field: "totalAmount",
        render: (rowData) =>
          String(rowData.totalAmount ? formatCurrency(rowData.totalAmount) : ""),
      },
    ];
    const  userRole = JSON.parse(localStorage.getItem("user"));
    const  superAdminRole = userRole.role == 1 ? true : false
    return (
      <div className="rightPanel">
        <Topheader />

          <div className="contentPart">
            <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading your content..."
            >
              <Grid container spacing={3} className="dashboard-cards-container">
                <Grid
                  container
                  className={classes.dashboard_panel1}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <Card className={classes.customCard}>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h4">
                        Transaction Overview
                      </Typography>
                      <div className={classes.cardContentBox}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          className={classes.totalGreen}
                        >
                          <span className={classes.total_span}>
                            Total Revenue
                          </span>
                          <span>
                            {" "}
                              <span className={classes.rightAlign} > {tnxOverViewPaid[0] ? formatCurrency(tnxOverViewPaid[0].total) : '0.00'}</span>
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          className={classes.totalYellow}
                        >
                        <span style={ {marginRight : 40} } className={classes.total_span}>Refunded</span>                        
                            <span className={classes.rightAlign}> {tnxOverViewReund[0]
                            ? formatCurrency(tnxOverViewPaid[0]?.total - tnxOverViewReund[0]?.total)
                            : "0.00"} </span>
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          className={classes.totalGray}
                        >
                          <span style={ {marginRight : 70} } className={classes.total_span}>Voided</span>                      
                            <span className={classes.rightAlign}>   {tnxOverVoid[0]
                            ? formatCurrency(tnxOverViewPaid[0].total - tnxOverVoid[0].total)
                            : "0.00"} </span>
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid  className="no-display"
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Card className={classes.customCard}>
                    <div className="no-display">
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h4">
                        Monthly Revenue
                      </Typography>
                      <div className={classes.cardContentBox}>
                        <Bar data={this.getBlockData()} options={options} />
                      </div>
                    </CardContent>
                    </div>
                  </Card>
                </Grid>

                <Grid className="no-display"
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Card className={classes.customCard}>
                  <div className="no-display">
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h4">
                        Daily Activity
                      </Typography>
                      <div className={classes.cardContentBox}>
                        <Line
                          options={optionsForDaily}
                          data={this.getLinearData()}
                        />
                      </div>
                    </CardContent>
                    </div>
                  </Card>
                </Grid>
          { !superAdminRole && 
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Card className={classes.customCard}>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h4">
                        Transaction History
                      </Typography>
                      <div className="Dashboard_cardContentBoxTnx">
                        <Table
                          tableReload={(e) => this.tableReload(e)}
                          title={"Transactions"} // do not remove space end of the title 
                          createNew={false}
                          data={(query) => this.getTableData(query)}
                          disabledAction={true}
                          paging={false}
                          columns={columns}
                          search={false}
                          exports={false}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
          }
          { superAdminRole && 
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Card className={classes.customCard}>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h4">
                      Declined Transaction History
                      </Typography>
                      <div className="Dashboard_cardContentBoxTnx">
                        <Table
                          tableReload={(e) => this.tableReload(e)}
                          title={"Transactions"} // do not remove space end of the title 
                          createNew={false}
                          data={(query) =>
                            this.getDeclinedTransactionsData(query)
                          }
                          disabledAction={true}
                          paging={false}
                          columns={columns2}
                          search={false}
                          exports={false}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
          }
              </Grid>
            </LoadingOverlay>
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    CurrentLocation: state.LocationReducer,
  };
}

Dashboard.propTypes = {
    t: PropTypes.func,
    getDeclinedTransactions : PropTypes.func,
    CurrentLocation: PropTypes.any
}
export default connect(null, { getTransactionDailyHours, getTransactionList, getTransactionOverview, getTransactionMonthlyOverview ,getDeclinedTransactions })(withTranslation()(Dashboard));
