export const success = (dispatch, msg) => {
	dispatch({
		type: 'SUCCESS',
		payload: msg
	});
};

export const error = (dispatch, msg) => {
	dispatch({
		type: 'ERROR',
		payload: msg
	});
};
 