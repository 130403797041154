import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailForCustomMiddleWare, UserEdit, UserResendActivationLink, UserDetailById } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import { CompanyLocationDataById } from '../../actions/CompanyAction'
import validator from './../../helpers/checkValidations';
import IntegrationDownshift from './../../helpers/multiSelect'
import FormControl from '@material-ui/core/FormControl';
import { locationList } from '../../actions/LocationAction'


const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', height: '4rem' };
  } else {
    return { width: '100%' };
  }
}


class EditUserCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      role: "",
      locationsData: [],
      locationSelected: "",
      locationSelectedwithmid: [],
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      permissions: {
        creatNewUser: false,
        editCustomer: false,
        refundsManual: false,
        exportRecord: false,
        refunds: false,
        allLocaton: false,
        createInvoice: false,
        textToInvoice: false,
        ach: false,
        virtualTerminal: false,
        recurring: false,
        svcFeeEdit: false,
        void: false,
        preAuthorize:false,
        creditSale:false,
        emailNotification : false,
        viewAllTransactions: true
      },
      error: {
        UserNameError: false,
        phoneError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
    };
  }
  componentDidMount() {
    this.getUserData();
    this.getLocationData();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info !== prevProps.info) {
      // this.setState({ ...this.props.info.userDataValue, isLoading: false })
      // let data = this.state.location;
      let data = this.state.locationSelectedwithmid;
      if (data && data.length > 0) {
        this.setState({ locationSelected: Object.keys(data).map((k, v) => data[k].id) });
      }
    }
  }
  getUserData() {
    let search = this.props.location.search;
    let param = queryString.parse(search);
    let data = { id: param.users };
    this.setState({ isLoading: true });
    this.props.UserDetailForCustomMiddleWare();
    this.props.UserDetailById(data).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false });
 
      let data = this.state.locationSelectedwithmid;
 
      if (data && data.length > 0) {
        // this.setState({
        //   locationSelected: Object.keys(data).map((k, v) => data[k].id),
        // });
        this.setState({
          location: data,
        });
      }
    });
  }

  getLocationData() {
    const {
      match: { params },
    } = this.props.props;
    let merchant = params.id;
    let data = { companyId: merchant };
    let midArray = [];
    this.props.locationList(data).then((res) => {      
      this.setState({ locationsData: res.payload.locationData.data.response })
      midArray = res.payload.locationData.data.response;        
      let midArray2 = [];
      let val = {};
      let val1 = {};
      for (var x = 0; x < midArray.length; x++) {

        val = midArray[x];
        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey2;
            val1.paytracePassword = val.paytracePassword2;
            val1.paytraceUserName = val.paytraceUserName2;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            midArray2.push(val1);
            val1 = {};
          }
        } else {
          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {
            if (midArray[x].mId) {
              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId";
              val1.address1 = val.address1;
              val1.address2 = val.address2;
              val1.contactName = val.contactName;
              val1.company = val.company;
              val1.city = val.city;
              val1.country = val.country;
              val1.createdAt = val.createdAt;
              val1.email = val.email;
              val1.id = val.id;
              val1.isDeleted = val.isDeleted;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.notes = val.notes;
              val1.paytraceKey = val.paytraceKey;
              val1.paytracePassword = val.paytracePassword;
              val1.paytraceUserName = val.paytraceUserName;
              val1.phone = val.phone;
              val1.state = val.state;
              val1.status = val.status;
              val1.timeZone = val.timeZone;
              val1.zip = val.zip;
              val1._v = val._v;
              midArray2.push(val1);
              val1 = {};
            }
          }
        }

      }
      this.setState({
        locationsData: midArray2,
        isLoading: false,
      });
 
    });
  }
  handleClickMenu(e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData();
  }
  handleChangeCheckbox(event) {
    if (event.target.id === "allLocaton") {
      var locationSelectedValue = [];
      var previousSelectedLocationValue = [];
      var setData = [];
      if (event.target.checked) {
        let locationsIds = this.state.locationsData.map((items) => items._id);
        previousSelectedLocationValue = this.state.locationSelected;
        locationSelectedValue = locationsIds;
        setData = this.state.locationsData.map((items) => items);
      } else {
        locationSelectedValue = this.state.previousSelectedLocation;
        setData = this.state.locationsData.map((items) => items);
      }
      this.setState({
        locationSelected: locationSelectedValue,
        locationSelectedwithmid: setData,
        location: setData,
        previousSelectedLocation: previousSelectedLocationValue,
        locationsData: setData, 
      });
    }
    this.setState({
      permissions: {
        ...this.state.permissions,
        [event.target.id]: event.target.checked,
      },
    });
  }
  handleChange(event, t) {
    let successMessages = false;
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    validator(id, value)
      ? (error[id + "Error"] = true)
      : (error[id + "Error"] = false);
    this.setState({ [id]: value, error, misMatchError, successMessages });
  }
  goBack(e) {
    this.props.handleChangeTab(e, 0);
  }
  handleSubmitResendLink(e, t) {
    let successMessages = false;
    if (window.confirm("Are you sure you want to resend the link?")) {
      this.setState({ isLoading: true });
      this.props.UserResendActivationLink(this.state.email).then((result) => {
        if (result !== undefined && result.type === "USER_EDIT_DATA") {
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.setState({ successMessages: true, isLoading: false });
            // this.goBack(e);
          }, 2000);
        }
        if (result !== undefined && result.type === "USER_ERROR") {
          this.setState({
            misMatchError: this.props.info.userInfo.data.message,
            successMessages,
            isLoading: false,
          });
        }
      });
    }
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    Object.keys(state).map((key, value) => {
   
      if (key === "phone" && !this.state.permissions.textToInvoice) {
        return false;
      }
      if(key === "hash" && this.state.hash === ''){
        delete this.state.hash
      }
      if(key === "defaultmidType"){
        return false;
      }
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : (error[key + "Error"] = false);
      } else {
        return state[key] === "" ||
          (typeof state[key] === "string" && state[key].trim() === "")
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : "";
      }
    });
    if (state.locationSelectedwithmid.length === 0) {
      misMatchError.push(t("LocationRequired"));
      errorFlag = true;
      this.setState({ misMatchError });
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm("Are you sure want to update?")) {
        this.setState({ isLoading: true });
 
        this.props.UserEdit(this.state).then((result) => {
          if (result !== undefined && result.type === "USER_EDIT_DATA") {
            window.scrollTo(0, 0);
            this.setState(
              { successMessages: true, misMatchError, isLoading: false },
              () => {
                setTimeout(() => {
                  this.goBack(e);
                });
              },
              3000
            );
          }
          if (result !== undefined && result.type === "USER_ERROR") {
            this.setState({
              misMatchError: this.props.userInfo.data.message,
              isLoading: false,
              successMessages,
            });
          }
        });
      }
    }
  }
  addLocation = (arr) => {
 
    let allLocationChceked = this.state.locationsData.length === arr.length ? true : false;
    this.setState({
      locationSelected: arr,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      },
    });
  };
  addLocation2 = (arr) => {
    
    let allLocationChceked = this.state.locationsData.length === arr.length ? true : false;
    this.setState({
      locationSelectedwithmid: arr,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      },
    });
  };
  render() {
    const { t } = this.props;
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text="Loading your content..."
      >
        <Container component="main" maxWidth="md">
          {this.state.misMatchError.length > 0 ? (
            <ErrorMessage errors={[this.state.misMatchError]} />
          ) : (
              ""
            )}
          {this.state.successMessages && this.props.userInfo ? (
            <SuccessMessage successes={[this.props.userInfo.data.message]} />
          ) : (
              ""
            )}
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t("User Name")}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t("First Name")}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t("Last Name")}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={this.state.permissions.textToInvoice}
                  error={
                    this.state.permissions.textToInvoice
                      ? this.state.error.phoneError
                      : false
                  }
                  id="phone"
                  type="Number"
                  labels={t("Phone")}
                  pattern="[789][0-9]{9}"
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid> 
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  style={getValidationStyle(
                    !this.state.locationSelectedwithmid && this.state.isSubmit
                  )}
                >
                  <IntegrationDownshift
                    isLocationExists={true}
                    selectedLocations={this.state.locationSelectedwithmid }
                    locationData={
                      this.state.locationsData ? this.state.locationsData : ""
                    }
                    addLocation={this.addLocation}
                    addLocation2={this.addLocation2}
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} />
              </Grid>
              <Grid container item xs={12}>
                {this.state.role < 4 ? (
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="creatNewUser"
                        labels="Create New User"
                        checked={this.state.permissions.creatNewUser}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.creatNewUser}
                        color="primary"
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}
                      />
                      <span>Create New User</span>
                    </label>
                  </Grid>
                ) : (
                    ""
                  )}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="refunds"
                      checked={this.state.permissions.refunds}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.refunds}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Refunds General</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="editCustomer"
                      checked={this.state.permissions.editCustomer}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.editCustomer}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Edit Customer</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="textToInvoice"
                      checked={this.state.permissions.textToInvoice}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.textToInvoice}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Text To Invoice</span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="refundsManual"
                      checked={this.state.permissions.refundsManual}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.refundsManual}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Refunds Manual</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="allLocaton"
                      checked={this.state.permissions.allLocaton}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.allLocaton}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>All Location</span>
                  </label>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="createInvoice"
                      checked={this.state.permissions.createInvoice}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.createInvoice}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Invoicing</span>
                  </label>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="ach"
                      checked={this.state.permissions.ach}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.ach}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>ACH </span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="virtualTerminal"
                      checked={this.state.permissions.virtualTerminal}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.virtualTerminal}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Virtual Terminal</span>
                  </label>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="recurring"
                      checked={this.state.permissions.recurring}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.recurring}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Recurring billing</span>
                  </label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="exportRecord"
                      checked={this.state.permissions.exportRecord}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.exportRecord}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Export Records</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="svcFeeEdit"
                      checked={this.state.permissions.svcFeeEdit}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.svcFeeEdit}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>SVC Fee Edit</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="void"
                      checked={this.state.permissions.void}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.void}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                    <span>Void</span>
                  </label>
                </Grid>

                <Grid item xs={12} sm={6}>
                <label>
                  <Checkbox
                  checked={this.state.permissions.emailNotification}
                    id="emailNotification"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.emailNotification}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                    <span>{t("emailNotifications")}</span>

                </label>
              </Grid>
              <Grid item xs={12} sm={6}>                   
                <label>
                  <Checkbox
                  checked={this.state.permissions.viewAllTransactions}
                    id="viewAllTransactions"
                    onChange={(e) => { this.handleChangeCheckbox(e) }}
                    value={this.state.viewAllTransactions}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                    <span>{t("viewAllTransactions")}</span>

                </label>
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>
                  <Checkbox
                    id="preAuthorize"
                    checked={this.state.permissions.preAuthorize}
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.preAuthorize}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                  <span>Pre-Authorization</span>
                </label>
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>
                  <Checkbox
                    id="creditSale"
                    checked={this.state.permissions.creditSale}
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.creditSale}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                  <span>Credit Sale</span>
                </label>
              </Grid>

              </Grid>

              <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t("Update")}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="secondary"
                text={t("Back")}
                onClick={(e) => this.goBack(e)}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="primary"
                text={t("Resend Link")}
                onClick={(e) => this.handleSubmitResendLink(e, t)}
              />
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}
EditUserCompanyDetails.propTypes = {
  t: PropTypes.func
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    userInfo: state.AdminUserReducer.editInfo
  };
}

export default connect(mapStateToProps, { UserDetailById, UserDetailForCustomMiddleWare, locationList, UserEdit, UserResendActivationLink, CompanyLocationDataById })(withTranslation()(withRouter(EditUserCompanyDetails)));
