import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Topheader } from "../../components/Topheader";
import LoadingOverlay from "react-loading-overlay";
import { Table } from "../../components/Table";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, Container, Grid, Tooltip } from "@material-ui/core";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import { SuccessMessage } from "../../components/Message/SuccessMessage";
import { connect } from "react-redux";
import { getAllLocation } from "../../actions/LocationAction";
import { withTranslation } from "react-i18next";
import { config } from "../../constants";
import { DataGetApi } from "../../helpers/PostDataApi";
import { formatCurrency,getBaseAmount } from "../../helpers/commonFunction";
import { map as _map } from "lodash";
import LocationFilter from "./LocationFilter";
import AmountFilter from "./AmountFilter";
import MethodFilter from "./MethodFilter";
import BatchReporting from "./BatchReporting";
import FundReporting from "./FundReporting";
import FundReportingNew from "./FundReportingNew";
import NewFundReporting from "./NewFundReporting";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import classes from './reporting.css';
import IntegrationDownshift from './../../helpers/multiSelect';
import { locationList } from '../../actions/LocationAction'
import { UserDetailById } from '../../actions/AdminUserAction'
import {  settingDataByCompanyIdmId } from '../../actions/AdminUserAction'
// import { formatCurrency, getBaseAmount } from '../../helpers/commonFunction'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  Underline: {
    color : "blue",
    cursor: "pointer"
  }
}));

class Reportinng extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    if(sessionStorage.getItem("reportingtab") && JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).fromDate){
      console.log(JSON.parse(sessionStorage.getItem("reportingtab")).fromDate,JSON.parse(sessionStorage.getItem("reportingtab")).toDate, " <<<<<<<<<<<<<< enter" );
    }
    let sesData = typeof sessionStorage.getItem("reportingtab") !== "undefined" ?JSON.parse(sessionStorage.getItem("reportingtab")):{};
    this.state = {
      exportRecord:false,
      sessionStore: {
        tab: 0,
        toDate: '',
        fromDate : '',
        payType: '',
        locations: '',
        methodFilter:'',
        searchTxt: ''
      },
      value: ( sesData && sesData.tab )? sesData.tab : 0,
      isLoading: false,
      reload: false,
      misMatchError: [],
      // methodFilter:  (sesData && sesData.methodFilter && sesData.methodFilter !== '')? sesData.methodFilter : {},
      title: [],
      userData: JSON.parse(localStorage.getItem("user")),
      toDate: (sesData && sesData.toDate && sesData.toDate !== '')? new Date(sesData.toDate) : new Date(),
      fromDate: (sesData && sesData.fromDate && sesData.fromDate !== '')? new Date(sesData.fromDate) : new Date(date.setDate(date.getDate() - 30)),
      anchorEl: false,
      openAmountFilter: false,
      error: {
        amountError: false,
      },
      locationSelected: [],
      locationSelectedwithmid: (sesData && sesData.locations && sesData.locations !== '' )? sesData.locations : [JSON.parse(localStorage.getItem('locationPaydetails'))],
      data: [],
      searchTxt: (sesData && sesData.searchTxt && sesData.searchTxt !== '')? sesData.searchTxt :'',
    };
    this.reportRef = React.createRef();
  }
  async componentDidMount() {
    this.fetchDetailsData();
    localStorage.setItem("filterLocation", []);
    await this.getAllLocation();
    if (this.state.userData.role !== config.SUPER_ADMIN_ROLE) {
      await this.getLocationData();
    }
    console.log(this.state,  "  <<<<<<<<<<<<<<<<<<<<<<<< state");
  

    if(typeof sessionStorage.getItem("reportingtab") !== "undefined" && JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).methodFilter ){
      // console.log(JSON.parse(sessionStorage.getItem("reportingtab")), " <<<<<<<<<<<<<<<<<<<<<<<<< session data ");
      this.handleMethodChangeEvent(JSON.parse(sessionStorage.getItem("reportingtab")).methodFilter.data);
    }
    this.getSettingData();
    // if(typeof sessionStorage.getItem("reportingtab") !== "undefined" ){
    //   this.setState({sessionStore: {       
    //     toDate: '',
    //     fromDate : '',
    //     methodFilter: '',
    //     // locations: '',
    //     searchTxt:''
    //   }}, () => {  
    //     this.setState({methodFilter: '', searchTxt:''});      
    //     sessionStorage.setItem('reportingtab', JSON.stringify(this.state.sessionStore));
    //   });
    // }
  }

  getSettingData(){
    //for column svc and surcharge
    let user = JSON.parse(localStorage.getItem('user'));
    if(localStorage.getItem('locationArrIds') && JSON.parse(localStorage.getItem('locationArrIds')).length === 1){       
      let param2 = {
        id: user.company.id ? user.company.id : null,
        userId: user._id ? user._id : null,
        locationId: JSON.parse(localStorage.getItem('locationPaydetails'))._id,
        midType: JSON.parse(localStorage.getItem('locationPaydetails')).midType
      };

      this.props.settingDataByCompanyIdmId(param2).then((res) => {         
      let datasetting = (res.payload.hasOwnProperty('userData')) ? res.payload.userData.data.response : "";
      this.setState({settingData: datasetting});         
      });
      
    }
  }

  handleChange = (event, newValue) => {
    // console.log(newValue, ' <<<<<<<<<<<<<<<<<<<< set');
    this.setState({sessionStore:{
      tab : newValue,
      // toDate: '',
      // fromDate : '',
      // payType: '',
      // locations: '',
    }}, () => {      
      sessionStorage.setItem('reportingtab', JSON.stringify(this.state.sessionStore));
      // console.log(this.state.sessionStore, "  <<<<<<<<<<<<<<<<<<  set", sessionStorage.getItem('reportingtab'));
    });
    this.setState({ value: newValue });
  };

  tableReload(status) {
    this.setState({ reload: status, amountFiter: {} });
  }

  handleButtonClick() {
    this.setState({ openAmountFilter: true, anchorEl: true });
  }

  handleButtonBlur() {
    this.setState({ openAmountFilter: false, anchorEl: true });
  }

  getPaymentStatus(val) {
    let value = val.toLowerCase();
    let Credit = "credit";
    let ACH = "ach";
    if (Credit.indexOf(value) !== -1) {
      return "101";
    } else if (ACH.indexOf(value) !== -1) {
      return "120";
    }
  }

  fetchDetailsData() {    
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ 'id': userData._id })
    .then((res) => {
      
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        // let data = res.payload.userData.data.company.customFields;
        let data = res.payload.userData.data.permissions.exportRecord;
        // let dataArr = data.filter((el) => {
        //   return el !== "";
        // })
        // console.log("dataArr------->",res)
        this.setState({ 'exportRecord': data, isLoading: false });
      }
    })
  }

  getTableData(query) {
    //localStorage.setItem('filterLocation',[])
    let orderBy = query.orderBy !== undefined ? query.orderBy.field : "";
    let orderDirection = query.orderDirection !== "" ? query.orderDirection : "";
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false, });
    }
    return new Promise((resolve) => {
      let user = JSON.parse(localStorage.getItem("user"));
      let token = user.token;

      // let alllocationIds  = this.state.userData.locationSelectedwithmid.map( item => item._id );
      // alllocationIds = alllocationIds.filter( function( item, index, inputArray ) {
      //   return inputArray.indexOf(item) == index;
      // });
      // JSON.stringify(alllocationIds) 

      let filterLocation = localStorage.getItem("filterLocation")
        ? JSON.parse(localStorage.getItem("filterLocation"))
        : [];
      // let allmids = [];
      // if(filterLocation.length > 0 ){
      //   allmids = filterLocation.map( item => item.locationMid );        
      // }
        
      let allmids = this.state.locationSelectedwithmid.map( item => ( (item.midType === "Surcharge") ? item.mId2:item.mId ) );
      // console.log(filterLocation, "filterLocation", allmids, this.state.locationSelectedwithmid);
      // let allmids = [];
      // this.state.locationSelectedwithmid.forEach(element => {
      //   allmids.push({locationId: element._id, midType: element.midType});
      // });


      // console.log(filterLocation, "filterLocation", allmids, this.state.locationSelectedwithmid);

      // let locationId = filterLocation.length
      //   ? localStorage.getItem("filterLocation")
      //   : localStorage.getItem('locationArrIds') ;
      
      let locationId = this.state.locationSelectedwithmid.length
        ? this.state.locationSelectedwithmid.map( item => item._id )
        : [] ;
      // console.log(filterLocation, "filterLocation2", locationId);
      // let midType = '';
      // if(localStorage.getItem('locationPaydetails')!== null){
      //   midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
      // }
      let url = config.BASEURL;
      let param = {
        locationIds: JSON.stringify(locationId),
        // midType: filterLocation.length ? '' : midType,
        allmids: JSON.stringify(allmids),
        role: user.role,
        userId: user._id,
        companyId: user.company._id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll:
          typeof query.search === "undefined" || query.search === ""
            ? false
            : true,
        toDate: this.state.toDate,
        fromDate: this.state.fromDate,
        orderBy: orderBy,
        orderDirection: orderDirection,
        amountFilter: this.state.amountFiter,
        methodFilter: this.state.methodFilter,
      };
      DataGetApi(url + "getTransactionList", param, token).then((result) => {
        
        let modifiedData = [];
        modifiedData = result.data.response.map((item) => ({
          ...item,
          amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
          calc_total: item.BASE_AMOUNT ? formatCurrency( ( parseFloat(item.BASE_AMOUNT) + parseFloat(item.SVC_FEE) + parseFloat(item.SURCHARGE_FEE) + parseFloat(item.TAX) ) ) : formatCurrency(item.AMOUNT) ,
          capture_amount: item.CAPTURE_AMOUNT ? formatCurrency(item.CAPTURE_AMOUNT) : '$0.00',
          SVC_FEE: item.SVC_FEE ? formatCurrency(item.SVC_FEE) : '$0.00',
          SURCHARGE_FEE: item.SURCHARGE_FEE ? formatCurrency(item.SURCHARGE_FEE) : '$0.00',
          base_amount: item.BASE_AMOUNT ? formatCurrency(item.BASE_AMOUNT) : getBaseAmount(item),
          TAX: item.TAX ? formatCurrency(item.TAX) : '$0.00',
          inventedBy:
            typeof item.createdBy != "undefined"
              ? item.createdBy.firstName + " " + item.createdBy.lastName
              : "",
          location: item.locationId ? item.locationId.locationName : "",
        }));
        if (typeof query.search === "undefined" || query.search === "") {
          resolve({
            data: modifiedData,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        } else {
          this.setState({ searchTxt: query.search });
          // console.log(this.state.searchTxt, " <<<<<<<<<<<<<<<<<<<<<<<<<<<< ");
          let filterData = modifiedData.filter((row) => {
            return (
              row.RESPONSECODE ===
              this.getPaymentStatus(String(query.search)) ||
              String(new Date(row.createdAt).toLocaleString()).indexOf(
                String(query.search)
              ) !== -1 ||
              String(row.ORDERID).indexOf(query.search) !== -1 ||
              String(row.AMOUNT).indexOf(query.search) !== -1 ||
              String(row.TXNID).indexOf(query.search) !== -1 ||
              String(row.inventedBy)
                .toLowerCase()
                .indexOf(String(query.search).toLowerCase()) !== -1 ||
              String(row.location)
                .toLowerCase()
                .indexOf(String(query.search).toLowerCase()) !== -1 ||
              String(row.CARDTYPE)
                .toLowerCase()
                .indexOf(String(query.search).toLowerCase()) !== -1 ||
              String(row.FINAL_STATUS)
                .toLowerCase()
                .indexOf(query.search) !== -1  ||
              String(row.CUSTOMERDATA.name )
                .toLowerCase()
                .indexOf(String(query.search).toLowerCase()) !== -1  ||
              String(row.CUSTOMERDATA.customer_id )
                  .toLowerCase()
                  .indexOf(query.search) !== -1                
            );
          });
          if (filterData.length > 0) {
            query.filters.push(filterData);
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          });
        }
      });
    });
  }

  getAllLocation() {
    this.props
      .getAllLocation(
        { selected: ["_id", "locationName"] },
        this.state.userData.token
      )
      .then((res) => {
        const locationListArray = res.payload.locationData.data.response.map(
          (items) => {
            return { id: items.id, locationName: items.locationName };
          }
        );
        const allLOcationIds = _map(locationListArray, "id");
        localStorage.setItem("locationArrIds", JSON.stringify(allLOcationIds));
        this.setState({ locationListArray });
      });
  }
 
  getTransaction = () => {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let url = config.BASEURL;
      let allmids = [];
      let locationId = [];

      allmids = this.state.locationSelectedwithmid.map( item => (item.midType === "Surcharge") ? item.mId2 : item.mId  );
      locationId = this.state.locationSelectedwithmid.length ? this.state.locationSelectedwithmid.map( item => item._id ) : [] ;       
      let param = {
        role: user.role,
        userId: user._id,
        'locationIds': JSON.stringify(locationId),
        'allmids':  JSON.stringify(allmids),
        'companyId': user.company._id,
        'toDate': this.state.toDate,
        'fromDate': this.state.fromDate,        
        methodFilter: this.state.methodFilter,
      };     
      this.setState({ isLoading : true })
      DataGetApi(url + 'getAllTransactionsExports', param, {}).then(result => {        
        this.setState({ isLoading : false })
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "Transcactions data";
        let filterData = [];        
        if (typeof this.state.searchTxt === "undefined" || this.state.searchTxt === "") {
          filterData = result.data.response.response;  
        }else{
          filterData = result.data.response.response.filter((row) => {
            return (
              row.RESPONSECODE ===
              this.getPaymentStatus(String(this.state.searchTxt)) ||
              String(new Date(row.createdAt).toLocaleString()).indexOf(
                String(this.state.searchTxt)
              ) !== -1 ||
              String(row.ORDERID).indexOf(this.state.searchTxt) !== -1 ||
              String(row.AMOUNT).indexOf(this.state.searchTxt) !== -1 ||
              String(row.TXNID).indexOf(this.state.searchTxt) !== -1 ||
              String(row.inventedBy)
                .toLowerCase()
                .indexOf(String(this.state.searchTxt).toLowerCase()) !== -1 ||
              String(row.location)
                .toLowerCase()
                .indexOf(String(this.state.searchTxt).toLowerCase()) !== -1 ||
              String(row.CARDTYPE)
                .toLowerCase()
                .indexOf(String(this.state.searchTxt).toLowerCase()) !== -1 ||
              String(row.FINAL_STATUS)
                .toLowerCase()
                .indexOf(this.state.searchTxt) !== -1  ||
              String(row.CUSTOMERDATA.name )
                .toLowerCase()
                .indexOf(this.state.searchTxt) !== -1  ||
              String(row.CUSTOMERDATA.customer_id )
                .toLowerCase()
                .indexOf(this.state.searchTxt) !== -1                
            );
          });
        }
        let newArray = [];
        // let optionsdate = { year: '2-digit', month: '2-digit', day: '2-digit', hour12: false  };
        filterData.map((d, i) => {
          let svcFee = d.SVC_FEE ? formatCurrency(d.SVC_FEE.toString().replace(/\$/g, "")) : 0.00
          let surcharge_fee = d.SURCHARGE_FEE ? formatCurrency(d.SURCHARGE_FEE.toString().replace(/\$/g, "")) : 0.00
          let tax = d.TAX ? formatCurrency(d.TAX.toString().replace(/\$/g, "")) : 0.00
          let base = d.BASE_AMOUNT?formatCurrency(d.BASE_AMOUNT): formatCurrency(parseFloat(d.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax)))
          let total = formatCurrency(d.AMOUNT);
          //let midType= (d.midType)? d.midType :'';
          let location=(d.locationId && d.locationId.locationName) ?`${ d.locationId.locationName }${'(' + d.midType + ')'}`: ''
          let name=`${ d.CUSTOMERDATA && d.CUSTOMERDATA.name ? d.CUSTOMERDATA.name :  d.CUSTOMERDATA.firstName  ? d.CUSTOMERDATA.firstName : ''}`;
          let cardno = d.MASKCARDNUMBER?d.MASKCARDNUMBER.toString().replace(/^.{12}/g, '************'): (d.CUSTOMERDATA.masked_card_number?d.CUSTOMERDATA.masked_card_number.toString().replace(/^.{12}/g, '************'):'');
          let charAtZero = ( d.MASKCARDNUMBER && d.MASKCARDNUMBER.charAt(0) !== 'x' && d.MASKCARDNUMBER.charAt(0) !== '*' )? d.MASKCARDNUMBER.charAt(0) : d.CUSTOMERDATA.masked_card_number ? d.CUSTOMERDATA.masked_card_number.charAt(0): '' ;
          let cardType = d.CARDTYPE ? d.CARDTYPE : ( d.CUSTOMERDATA.cardType ? d.CUSTOMERDATA.cardType : this.getCardType(charAtZero) );
          let crdate = new Date(d.createdAt).toLocaleDateString("en-US");
          let obj = { "TXN ID": d.TXNID, Type: d.payment_type, Status: d.FINAL_STATUS, Name: name, "Customer Id": d.CUSTOMERDATA.customer_id, Location: location, Invoice: d.ORDERID, Base: base, Surcharge: surcharge_fee, SVC: svcFee,Tax: tax, amount: total, Total: total,'Card Number': cardno, "Card Type": cardType, Date: crdate };
          newArray.push(obj);
        });
        // console.log(newArray, " newArray <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ");
        // **********for export to excel************
        const ws = XLSX.utils.json_to_sheet(newArray);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      });
    });
  }

  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return ''
    }
  }

  /**
   * Get all transaction data
   * @param {Object} query
   */
  getAllTransacationData(query) {
    return new Promise((resolve) => {
      setTimeout(() => {
        let orderBy = query.orderBy !== undefined ? query.orderBy.field : "";
        let orderDirection =
          query.orderDirection !== "" ? query.orderDirection : "";
        if (this.state.reload) {
          query.search = "";
          this.setState({ reload: false });
        }

        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.token;
        let filterLocation = localStorage.getItem("filterLocation")
          ? JSON.parse(localStorage.getItem("filterLocation"))
          : [];
        let locationId = filterLocation.length
          ? localStorage.getItem("filterLocation")
          : localStorage.getItem("locationArrIds");

        let url = config.BASEURL;
        
        let param = {
          locationIds: locationId,
          role: user.role,
          userId: user._id,
          //   companyId: user.company._id,
          per_page: query.pageSize,
          page: +(query.page + 1),
          searchAll:
            typeof query.search === "undefined" || query.search === ""
              ? false
              : true,
          toDate: this.state.toDate,
          fromDate: this.state.fromDate,
          orderBy: orderBy,
          orderDirection: orderDirection,
          amountFilter: this.state.amountFiter,
          methodFilter: this.state.methodFilter,
        };

        DataGetApi(url + "getAllTransactionList", param, token).then(
          (result) => {
            let modifiedData = [];
            modifiedData = result.data.response.map((item) => ({
              ...item,
              amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : "$0.00",
              inventedBy:
                typeof item.createdBy != "undefined"
                  ? item.createdBy.firstName + " " + item.createdBy.lastName
                  : "",
              location: item.locationId ? item.locationId.locationName : "",
            }));
            if (typeof query.search === "undefined" || query.search === "") {
              resolve({
                data: modifiedData,
                page: result.data.pagination.page - 1,
                totalCount: result.data.pagination.totalCount,
              });
            } else {
              let filterData = modifiedData.filter((row) => {
                return (
                  row.RESPONSECODE ===
                  this.getPaymentStatus(String(query.search)) ||
                  String(new Date(row.createdAt).toLocaleString()).indexOf(
                    String(query.search)
                  ) !== -1 ||
                  String(row.ORDERID).indexOf(query.search) !== -1 ||
                  String(row.AMOUNT).indexOf(query.search) !== -1 ||
                  String(row.TXNID).indexOf(query.search) !== -1 ||
                  String(row.inventedBy)
                    .toLowerCase()
                    .indexOf(String(query.search).toLowerCase()) !== -1 ||
                  String(row.location)
                    .toLowerCase()
                    .indexOf(String(query.search).toLowerCase()) !== -1 ||
                  String(row.CARDTYPE)
                    .toLowerCase()
                    .indexOf(String(query.search).toLowerCase()) !== -1 ||
                  String(row.FINAL_STATUS)
                    .toLowerCase()
                    .indexOf(query.search) !== -1
                );
              });
              if (filterData.length > 0) {
                query.filters.push(filterData);
              }
              resolve({
                data: filterData,
                page: result.data.pagination.page - 1,
                totalCount: filterData.length,
              });
            }
          }
        );
      }, 2000);
    });
  }
  handleChangeId(id) {
    console.log(this.state.fromDate, this.state.toDate, "<<<<<<<<<<<<<<<<<<<<< dates");
    this.setState({ sessionStore: {tab:0,searchTxt:this.state.searchTxt, methodFilter: this.state.methodFilter, locations: this.state.locationSelectedwithmid, toDate: this.state.toDate, fromDate: this.state.fromDate }}, () => {
      sessionStorage.setItem('reportingtab', JSON.stringify(this.state.sessionStore));
      console.log(this.state.sessionStore, "  <<<<<<<<<<<<<<<<<<  set", sessionStorage.getItem('reportingtab'));
      this.props.history.push("/Viewtransaction/" + id);
    });
  }
  toDateFilter(date) {
    console.log(date, "toDate");
    // (typeof sessionStorage.getItem("reportingtab") !== "undefined" && JSON.parse(sessionStorage.getItem("reportingtab")).toDate != '') ? JSON.parse(sessionStorage.getItem("reportingtab")).toDate :
    this.setState({ toDate:date }, () => {      
    });
  }
  fromDateFilter(date) {
    console.log(date, "fromDate");
    // (typeof sessionStorage.getItem("reportingtab") !== "undefined" && JSON.parse(sessionStorage.getItem("reportingtab")).fromDate != '') ? JSON.parse(sessionStorage.getItem("reportingtab")).fromDate :
    this.setState({ fromDate: date  }, () => {      
    });
  }
  handleChangeInParent(data) {
    localStorage.setItem("filterLocation", JSON.stringify(data));
    // console.log(data, " user filter ");
    this.tableReload(true);
    if (this.state.userData.role === config.SUPER_ADMIN_ROLE) {
      this.getAllTransacationData({});
    } else {
      this.getTableData({});
    }
    this.state.tableObje.onQueryChange();
  }

  hardRefresh(data) {
    this.setState({ tableObje: data.current });
  }

  handleCloseEnvet() {
    this.setState({ openAmountFilter: false, anchorEl: false });
  }

  isSuperAdmin() {
    return this.state.userData.role === config.SUPER_ADMIN_ROLE ? true : false;
  }

  /**
   * Handle method type change event
   * @param {Object} data
   */
  handleMethodChangeEvent(data) {
    this.setState({
      methodFilter: {
        data: data,
      },
    },()=>{
      // console.log(this.state, " <<<<<<<<<<<<<<<<<<<< ");
    });

    this.isSuperAdmin ? this.getAllTransacationData({}) : this.getTableData({});
    this.state.tableObje.onQueryChange();
  }

  handleOkButtonEvent(data) {
    this.tableReload(true);
    this.setState({
      amountFiter: {
        filterType: data.filterType,
        amount: data.amount,
        maxAmount: data.maxAmount,
      },
    });
    if (this.state.userData.role === config.SUPER_ADMIN_ROLE) {
      this.getAllTransacationData({});
    } else {
      this.getTableData({});
    }
    this.state.tableObje.onQueryChange();
  }

  getLocationData() {

    let data = { 'companyId': this.state.userData.company._id }
    let midArray = [];
    this.props.locationList(data).then((res) => {
      // this.setState({ data: res.payload.locationData.data.response, isLoading: false })
      midArray = res.payload.locationData.data.response;

      let midArray2 = [];
      let val = {};
      let val1 = {};
      let selectedMid = [];
      for (var x = 0; x < midArray.length; x++) {
        val = midArray[x];

        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
             }
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey2;
            val1.paytracePassword = val.paytracePassword2;
            val1.paytraceUserName = val.paytraceUserName2;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
            }
            midArray2.push(val1);
            val1 = {};
          }
        } else {

          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {

            if (midArray[x].mId) {

              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId";
              val1.address1 = val.address1;
              val1.address2 = val.address2;
              val1.contactName = val.contactName;
              val1.company = val.company;
              val1.city = val.city;
              val1.country = val.country;
              val1.createdAt = val.createdAt;
              val1.email = val.email;
              val1.id = val.id;
              val1.isDeleted = val.isDeleted;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.notes = val.notes;
              val1.paytraceKey = val.paytraceKey;
              val1.paytracePassword = val.paytracePassword;
              val1.paytraceUserName = val.paytraceUserName;
              val1.phone = val.phone;
              val1.state = val.state;
              val1.status = val.status;
              val1.timeZone = val.timeZone;
              val1.zip = val.zip;
              val1._v = val._v;
              if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
                selectedMid.push(val1)
              }
              midArray2.push(val1);
              val1 = {};
            }
          }
        }
      }

      // if (midArray2 && midArray2.length > 0) {
      this.setState({ data: midArray2, isLoading: false });
      this.setState({ locationSelected: Object.keys(midArray2).map((k, v) => midArray2[k].id), locationSelectedwithmid:  (typeof sessionStorage.getItem("reportingtab") !== "undefined" &&   JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).locations )? JSON.parse(sessionStorage.getItem("reportingtab")).locations : selectedMid });
      this.setState({
        locationsData: midArray2,
        isLoading: false,
      });
      // }
    })
  }

  addLocation = (arr) => {
    let misMatchError = [];   
    this.setState({
      locationSelected: arr, misMatchError,
    })
  }

  addLocation2 = (arr) => {    
    this.setState({
      locationSelectedwithmid: arr,
    });
    this.state.tableObje.onQueryChange();
  };

  hardRefresh(data) {
    this.setState({ tableObje: data.current });
  }


  render() {  
 
    const { classes } = this.props;
    const { data, locationSelectedwithmid, methodFilter } = this.state;
    let optionsdate = { year: '2-digit', month: '2-digit', day: '2-digit' };
    let columns = [
      {
        title: "TXN ID",
        field: "TXNID",
        render: (rowData) => (
          <Tooltip title="View Transaction">
            <span
              className={classes.Underline}
              onClick={() => this.handleChangeId(rowData.TXNID)}
            >
              {rowData.TXNID}
            </span>
          </Tooltip>
        ),
      },
      {        
        title: 'Type', field: 'payment_type', editable: 'never', render: rowData => (rowData.CARDTYPE === 'ACH' || rowData.RESPONSECODE === '120') ? 'ACH' :  (rowData.payment_type ==="Credit")  ? 'Credit' : "Debit"
      },
      {
        title: "Status",
        field: "FINAL_STATUS",
        render: (rowData) =>
          rowData.FINAL_STATUS === "Paid" ? (
            <button type="button" className="btn paidBtn">
              Paid
            </button>
          ) : rowData.FINAL_STATUS === "Refunded" ? (
            <button type="button" className="btn RefundedBtn">
              Refunded
            </button>
          ) : rowData.FINAL_STATUS === "Voided" ? (
            <button type="button" className="btn PenddingBtn">
              Voided
            </button>
          ) : rowData.FINAL_STATUS === "Declined" ? (
            <button type="button" className="btn DeclinedBtn">
              Declined
            </button>
          ) : (
            ""
          ),
      },
      // { title: 'Invented By', field: 'createdBy', render: rowData => (rowData.createdBy)?rowData.createdBy.firstName+' '+rowData.createdBy.lastName:''},
      // { title: "Customer Name", field: "inventedBy", render: rowData => (rowData.createdBy)?rowData.createdBy.firstName+' '+rowData.createdBy.lastName:'' },

      // { title: 'Customer Name', field: 'firstName',  render: rowData => (rowData.CUSTOMERDATA !== null && rowData.CUSTOMERDATA.hasOwnProperty('firstName')) ? rowData.CUSTOMERDATA.firstName : "" },
      // { title: 'Customer Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      // `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : '' } ` : '' },
      { title: 'Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : ((rowData.CUSTOMERDATA.firstName)?rowData.CUSTOMERDATA.firstName+' '+rowData.CUSTOMERDATA.lastName:((rowData.CUSTOMERDATA.customer_id)?rowData.CUSTOMERDATA.customer_id:'N/A')) } ` : 'N/A' },

      { title: 'Customer ID', field: 'customer_id', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
       ( rowData.CUSTOMERDATA.customer_id ? rowData.CUSTOMERDATA.customer_id : "N/A" )  : "N/A" },

      { title: "Location", field: "location", render: (rowData) => (rowData.midType !== null && rowData.midType !== "" && rowData.hasOwnProperty('midType')) ? rowData.location + '(' + rowData.midType + ')' : rowData.location },      
      { title: 'Invoice', field: 'ORDERID', render: rowData => (rowData.ORDERID) ? rowData.ORDERID : 'N/A' },  
      {
        title: "Card Type",
        field: "CARDTYPE",
        render: (rowData) => rowData.CARDTYPE,
      },
      {
        title: "Card Number",
        field: "MASKCARDNUMBER",
        render: (rowData) => rowData.MASKCARDNUMBER?rowData.MASKCARDNUMBER.toString().replace(/^.{12}/g, '************'): (rowData.CUSTOMERDATA.masked_card_number?rowData.CUSTOMERDATA.masked_card_number.toString().replace(/^.{12}/g, '************'):''),
      },
      { title: "MID", field: "pay_mId" },
      { title: "Base", field: "base",  editable: 'never' , render: rowData => rowData.base_amount },

      // data.midType==="surcharge" ? { title: 'Surcharge', field: 'SURCHARGE_FEE', editable: 'never' }:'',      
      // rowData.midType ==='surcharge'? { title: "Surcharge", field: (rowData.midType) }:'',
      // { title: "Base", field: "base",  editable: 'never' , render: rowData =>((parseFloat(rowData.AMOUNT) )- (parseFloat(rowData.TAX))) },
      // let base = formatCurrency(parseFloat(d.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax)))
      // { title: "Base", field: "base" , render: rowData => formatCurrency(parseFloat(rowData.AMOUNT) -( (parseFloat(rowData.svcFee) + parseFloat(rowData.surcharge_fee) + parseFloat(rowData.tax)))) },
      // { title: "Total", field: "amount" },
      // // { title: "Amount", field: "amount" },

      // {
      //   title: "Date",
      //   field: "createdAt",
      //   render: (date) => new Date(date.createdAt).toLocaleString('en-US', optionsdate),
      // },
    ];
    if(this.state.settingData && this.state.settingData.InvoiceTax){
      columns.push({ title: 'Tax', field: 'TAX', editable: 'never' });
    }
    if(this.state.settingData && this.state.settingData.InvoiceDiscount){
      columns.push({ title: this.state.settingData.DiscountFeeName, field: 'SVC_FEE', editable: 'never' })
    }    
    if(this.state.settingData && this.state.settingData.InvoiceSurcharge){
      columns.push({ title: this.state.settingData.SurchargeFeeName, field: 'SURCHARGE_FEE', editable: 'never' })
    }
    columns.push({ title: 'Total', field: 'AMOUNT', editable: 'never', render: (rowData) => ((rowData.tableData.id === 4) ? formatCurrency(rowData.CAPTURE_AMOUNT) : rowData.REFUND ? rowData.amount : rowData.calc_total)}  );
    // let optionsdate = { year: '2-digit', month: '2-digit', day: '2-digit' };
    columns.push({ title: 'Date', field: 'createdAt', editable: 'never', render: (date) => (new Date(date.createdAt).toLocaleString('en-US', optionsdate)) });

    let columns1 = [
      {
        title: "TXN ID",
        field: "TXNID",
        render: (rowData) => (
          <Tooltip title="View Transaction">
            <span
              className={classes.Underline}
              onClick={() => this.handleChangeId(rowData.TXNID)}
            >
              {rowData.TXNID}
            </span>
          </Tooltip>
        ),
      },
      {        
        title: 'Type', field: 'payment_type', editable: 'never', render: rowData => (rowData.CARDTYPE === 'ACH' || rowData.RESPONSECODE === '120') ? 'ACH' :  (rowData.payment_type ==="Credit")  ? 'Credit' : "Debit"
      },
      {
        title: "Status",
        field: "FINAL_STATUS",
        render: (rowData) =>
          rowData.FINAL_STATUS === "Paid" ? (
            <button type="button" className="btn paidBtn">
              Paid
            </button>
          ) : rowData.FINAL_STATUS === "Refunded" ? (
            <button type="button" className="btn RefundedBtn">
              Refunded
            </button>
          ) : rowData.FINAL_STATUS === "Voided" ? (
            <button type="button" className="btn PenddingBtn">
              Voided
            </button>
          ) : rowData.FINAL_STATUS === "Declined" ? (
            <button type="button" className="btn DeclinedBtn">
              Declined
            </button>
          ) : (
            ""
          ),
      },
      // { title: 'Invented By', field: 'createdBy', render: rowData => (rowData.createdBy)?rowData.createdBy.firstName+' '+rowData.createdBy.lastName:''},
      // { title: "Customer Name", field: "inventedBy", render: rowData => (rowData.createdBy)?rowData.createdBy.firstName+' '+rowData.createdBy.lastName:'' },

      // { title: 'Customer Name', field: 'firstName',  render: rowData => (rowData.CUSTOMERDATA !== null && rowData.CUSTOMERDATA.hasOwnProperty('firstName')) ? rowData.CUSTOMERDATA.firstName : "" },
      // { title: 'Customer Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      // `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : '' } ` : '' },
      { title: 'Name', field: 'name', editable: 'never' ,  render: rowData => (rowData.CUSTOMERDATA) ? 
      `${ rowData.CUSTOMERDATA.name ? rowData.CUSTOMERDATA.name : ((rowData.CUSTOMERDATA.firstName)?rowData.CUSTOMERDATA.firstName+' '+rowData.CUSTOMERDATA.lastName:((rowData.CUSTOMERDATA.customer_id)?rowData.CUSTOMERDATA.customer_id:'N/A')) } ` : 'N/A' },

      { title: 'Customer ID', field: 'customer_id', editable: 'never' ,  
      // render: rowData => (rowData.CUSTOMERDATA) ? 
      //  ( rowData.CUSTOMERDATA.customer_id ? rowData.CUSTOMERDATA.customer_id : "N/A" )  : "N/A",
       render: rowData => 
       <span title = {(rowData.CUSTOMERDATA && rowData.CUSTOMERDATA.customer_id)?rowData.CUSTOMERDATA.customer_id:'N/A'} >
         {
         (rowData.CUSTOMERDATA && rowData.CUSTOMERDATA.customer_id.length > 12) ? rowData.CUSTOMERDATA.customer_id.substring(0,12)+"..." : "N/A"  
         }
       </span>
      },

      { title: "Location", field: "location", render: (rowData) => (rowData.midType !== null && rowData.midType !== "" && rowData.hasOwnProperty('midType')) ? rowData.location + '(' + rowData.midType + ')' : rowData.location },      
      { title: 'Invoice', field: 'ORDERID', render: rowData => (rowData.ORDERID) ? rowData.ORDERID : 'N/A' },

      { title: "MID", field: "pay_mId" },
      { title: "Base", field: "base",  editable: 'never' , render: rowData => getBaseAmount(rowData) },      
      { title: 'Surcharge', field: 'SURCHARGE_FEE',  editable: 'never' },
        // data.midType==="surcharge" ? { title: 'Surcharge', field: 'SURCHARGE_FEE', editable: 'never' }:'',
      
      // rowData.midType ==='surcharge'? { title: "Surcharge", field: (rowData.midType) }:'',
      // { title: "Base", field: "base",  editable: 'never' , render: rowData =>((parseFloat(rowData.AMOUNT) )- (parseFloat(rowData.TAX))) },
      // let base = formatCurrency(parseFloat(d.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax)))
      { title: "Total",   render: (rowData) => ((rowData.tableData.id === 4) ? formatCurrency(rowData.CAPTURE_AMOUNT) : rowData.REFUND ? formatCurrency(rowData.AMOUNT) : formatCurrency(rowData.AMOUNT)) },
      // { title: "Total", field: "amount"},
      // { title: "Amount", field: "amount" },
      // {
      //   title: "Card Type",
      //   field: "CARDTYPE",
      //   render: (rowData) => rowData.CARDTYPE,
      // },
      {
        title: "Date",
        field: "createdAt",
        render: (date) => new Date(date.createdAt).toLocaleString('en-US', optionsdate),
      },
    ];
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading your content..."
          >
            <Container component="main" maxWidth="xl">
              { this.state.misMatchError.length > 0 ? (
                <ErrorMessage errors={[this.state.misMatchError]} />
              ) : (
                ""
              ) }
              { this.state.successMessages ? (
                <SuccessMessage
                  successes={[this.props.info.paymentInfo.data.message]}
                />
              ) : (
                ""
              ) }
              
              <AppBar  position="static" color="default">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Transactions" {...a11yProps(0)} />
                  <Tab label="Batches" {...a11yProps(1)} />
                  <Tab label="Funding" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel className="custom-name-classes" value={this.state.value} index={0}>
                {/* <Card className={classes.root} variant="outlined">
                  <CardContent> */}
                    <Grid container direction="row" className="navGrid"  style={{ marginBottom: '10px' }}>
                      {/*  <DateFilter /> */}
                      {/* Older location filter */}
                      {/* <LocationFilter
                        handleChange={(e) => this.handleChangeInParent(e)}
                      /> */}
                      { (this.state.userData.role !== config.SUPER_ADMIN_ROLE) ?                      
                        <Grid item xs={12}>
                          <FormControl fullWidth style={{ zIndex:"11" }}>
                            <IntegrationDownshift
                              isLocationExists={true}
                              selectedLocations={locationSelectedwithmid}
                              locationData={data}
                              addLocation={this.addLocation}
                              addLocation2={this.addLocation2}
                            />
                          </FormControl>
                        </Grid>
                      : '' }
                      {/* <div /> */}
                      {/* <FormControl className={classes.formControl}>
                        <AmountFilter
                          handleCloseEvent={(e) => this.handleCloseEnvet()}
                          anchorEl={this.state.anchorEl}
                          openAmountFilter={this.state.openAmountFilter}
                          handleOkButtonEvent={(e) =>
                            this.handleOkButtonEvent(e)
                          }
                        />
                      </FormControl> */}
                  <Grid item xs={12} style={{ marginTop: '23px' }}>
                    <FormControl fullWidth>
                      <MethodFilter
                        selectedType={methodFilter}
                        handleMethodChange={(e) =>
                          this.handleMethodChangeEvent(e)
                        }
                      />
                    </FormControl>
                  </Grid>

                </Grid>
                {/* </CardContent>
                </Card> */}
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Table
                      tableReload={(e) => this.tableReload(e)}
                      options={{
                        exportButton: false,
                      }}
                      clickHandler={this.getTransaction}
                      //  let allmids = this.state.locationSelectedwithmid.map( item => ( (item.midType === "Surcharge") ? item.mId2:item.mId ) );
                      columns={columns}
                      createNew={false}
                      exports={this.state.exportRecord===true?true:false}
                      data={(query) =>
                        this.state.userData.role === config.SUPER_ADMIN_ROLE
                          ? this.getAllTransacationData(query)
                          : this.getTableData(query)
                      }
                      title={"Transactions"} // do not remove the space end of the title name 
                      search={true}
                      exports={this.state.exportRecord===true?true:false}
                      paging={true}
                      toDateFilter={(e) => this.toDateFilter(e)}
                      fromDateFilter={(e) => this.fromDateFilter(e)}
                      datefilter={true}
                      hardRefresh={(e) => this.hardRefresh(e)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel  className="custom-name-classes" value={this.state.value} index={1}>
                <BatchReporting locationData={locationSelectedwithmid} />
              </TabPanel>
              <TabPanel  className="custom-name-classes" value={this.state.value} index={2}>
                {/* <FundReporting /> */}
                {/* <FundReportingNew /> */}
                <NewFundReporting />
              </TabPanel>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <Reportinng classes={classes} {...props} />;
};

export default connect(
  mapStateToProps,
  { getAllLocation, locationList,UserDetailById,settingDataByCompanyIdmId }
)(withTranslation()(ApplyingStylesOnClasses));
