import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { getInvoiceList } from '../../actions/PaymentAction'
import { fetchDetails } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Transactions.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';
const date = new Date();
class AdminPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      userData: { permissions: {} },
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 3)),
    }
    this.tableRef = React.createRef();
  }
  UNSAFE_componentWillMount() {
    //redirect to intake form for road assitence client
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.company._id !=='5ee85adef500853860700888') {
      this.props.history.push('/Transactions');
    }
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS" && this.props.userInfo.userId === "") {
        this.setState({ userData: { ...res.payload.userData } }, () => {
        })
      }
    })
  }
  toDateFilter(date) {
    this.setState({ toDate: date });
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date });
  }
  handleChangeId(invoice, status, id) {
    if (status) {
      this.props.history.push('/EditInvoice/' + id);
    } else {
      this.props.history.push('/Terminal/?' + id);
    }
  }
  tableReload(status) {
    this.setState({ reload: status });
  }
  rowOnclick(row){
    this.props.history.push('/InvoiceEdit/' + row._id);
  }
  getData(query) {
    if (this.state.reload) {
      query.search = ""
      this.setState({ reload: false })
    }
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true
        , 'toDate': this.state.toDate, 'fromDate': this.state.fromDate
      };
      DataGetApi(url + 'getInvoiceList', param, token).then(result => {
        resolve({
          data: (typeof query.search === "undefined" || query.search === "") ? result.data.response : result.data.response.filter((row => {
            return (String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || (row.customerId != null && (row.customerId['firstName'].toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || row.customerId['lastName'].toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1)) || row.invoice.indexOf(String(query.search)) !== -1 || String(row.amount).indexOf(query.search) !== -1)
          })),
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
      })
    });
  }
  render() {
   const  columns2 = [
      { title: 'Invoice', field: 'invoice' },
      { title: 'First Name', field: 'firstName' }, { title: 'Last Name', field: 'lastName' },
      { title: 'Phone number', field: 'phoneNumber' }, { title: 'Service type', field: 'serviceType' },
      { title: 'Status', field: 'STATUS', render: rowData => (rowData.STATUS === "PAID") ? <button type="button" className="btn paidBtn" >PAID</button> : (rowData.STATUS === "YET_TO_PAY") ? <button type="button" className="btn PenddingBtn" >YET TO PAY</button> : (rowData.STATUS === "Voided") ? <button type="button" className="btn PenddingBtn" >Voided</button> : '' }, 
      // { title: 'Payment-Status', field: 'isPaid', lookup: { 'true': <button type="button" className="btn paidBtn" >Paid</button>, 'false': <button type="button" className="btn PenddingBtn" >Pending</button> } },      
      { title: 'Amount', field: 'amount' },
      {
          title: 'Date opened (in your local time)', field: 'dateOpenedTimeStamp',
          render: ({ dateOpenedTimeStamp }) => {
              const s = new Date(dateOpenedTimeStamp).toString();
              const date = s.slice(0, s.indexOf('('));
              return (<span> {date} </span>);
          },
      },
      {
          title: 'Dispatching System', field: 'system',
          render: ({ system }) => (<span> {system} </span>),
          cellStyle: (syst) => (
              (syst === 'SYSTEM 1') ? { backgroundColor: '#E9454C', color: '#FFF', textAlign: 'center' }
              : (syst === 'SYSTEM 2') ? { backgroundColor: '#157FFB', color: '#FFF', textAlign: 'center' }
              : { }
          ),
      },
      // {
      //     field: '_id', Title: 'Edit',
      //     render: ({_id}) => (
      //         <a
      //             id={_id} href='#' onClick={async event => {
      //             const { id } = event.target;
      //             if (id) location.href = `/adminPortal/${id}`;
      //         }} style={{ color: 'blue' }}
      //         > <EditIcon /> Edit </a>
      //     )
      // }
  ]
    const columns = [
      {
        title: 'Invoice Number', field: 'invoice', customFilterAndSearch: (item, rowData) => item === rowData.invoice, render: rowData => <Tooltip title="View Details"><span
          className={classes.Underline} onClick={(e) => this.handleChangeId(rowData.invoice, rowData.isPaid, rowData._id)}  >{rowData.invoice}</span></Tooltip>
      },
      { title: 'First Name', field: 'customerId', render: rowData => (rowData.customerId && rowData.customerId.firstName)?rowData.customerId.firstName:'' },
      { title: 'Last Name', field: 'customerId', render: rowData => (rowData.customerId && rowData.customerId.lastName)?rowData.customerId.lastName:'' },
      { title: 'Amount', field: 'amount', render: rowData => (String(rowData.amount).indexOf('.') === -1) ? '$' + String(rowData.amount) + '.00' : '$' + String(rowData.amount) },
      { title: 'SVC Fee', field: 'svcFee', render: rowData => (rowData.svcFee) ? '$' + rowData.svcFee.toFixed(2) : '$ 0.00' },
      { title: 'Tax', field: 'tax', render: rowData => rowData.tax ? '$' + rowData.tax.toFixed(2) : '$ 0.00' },
      { title: 'Total', field: 'total', render: rowData => rowData.total ? '$' + rowData.total.toFixed(2) : rowData.amount },
      { title: 'Email-Status', field: 'emailSent', lookup: { 'true': <button type="button" className="btn sentBtn" >Sent</button>, 'false': <button type="button" className="btn ViewedBtn" >Viewed</button> } },
      { title: 'Payment-Status', field: 'isPaid', lookup: { 'true': <button type="button" className="btn paidBtn" >Paid</button>, 'false': <button type="button" className="btn PenddingBtn" >Pending</button> } },
      { title: 'Date', field: 'createdAt', render: (date) => (new Date(date.dateOpened).toLocaleDateString()) }]

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          {(!this.props.userInfo.permissions && this.props.userInfo.userId === "")}
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true,
                search: true,
                tableLayout: 'auto', selection: true,
                cellStyle: { padding: '0.25rem 0.75rem', fontWeight: 400 },
              }}
            //   onRowClick={(event, rowData) => {
            //     window.location.href = `/adminPortal/${rowData._id}`;
            // }}
            onRowClick={(event, rowData) =>this.rowOnclick(rowData) }
              columns={columns2}
              permissions={(this.props.userInfo.userId === "") ? this.state.userData.permissions : this.props.userInfo.permissions}
              data={query => this.getData(query)}
              title={'Invoice'}
              search={true}
              exports={(this.props.userInfo.userId === "") ? this.state.userData.permissions.exportRecord : this.props.userInfo.permissions.exportRecord}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={true}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    userInfo: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { getInvoiceList })(withTranslation()(AdminPortal));