import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { userSettings, settingDataByCompanyId, settingDataByCompanyIdmId } from '../../actions/AdminUserAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Toolbar from '@material-ui/core/Toolbar';
import classesSe from '../Settings/Settings.module.scss';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config'
import { element } from 'prop-types';
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


const initialState = {

  InvoiceDiscount: false,
  InvoiceSurcharge: false,
  isZip: false,
  InvoiceTax: false,
  isMultiPayInvoice: false,
  checkedInvoice: true,
  InvoiceSalesTaxFee: 0,
  InvoiceSalesTaxFeeType: '%',
  InvoiceSVCFee: 0,
  InvoiceSVCFeeType: '%',
  DiscountFeeName: '',
  InvoiceSurchargeFee: 0,
  InvoiceSurchargeFeeType: '%',
  SurchargeFeeName: '',
  usersData: [],
  locationsData: [],
  misMatchError: [],
  tab: 0,
  tabSelected: 'Transaction',
  title: 'Company',
  sideShow: true,
  isLoading: true,
  userDetails: JSON.parse(localStorage.getItem('user')),
  locDetails:   localStorage.getItem('locationPaydetails') !=="undefined" ?  JSON.parse(localStorage.getItem('locationPaydetails')) : {},
  error: {
    InvoiceSalesTaxFeeError: false,
    InvoiceSVCFeeError: false,
    InvoiceSurchargeFeeError: false,
    amountError: false,
    phoneError: false,
  }
};

class AdjustSettings extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
  }

  componentDidMount() {
    // this.settingDataByCompanyId();
    this.settingDataByCompanyIdmId();
    this.setState({ locDetails: localStorage.getItem('locationPaydetails') !=="undefined" ?  JSON.parse(localStorage.getItem('locationPaydetails')) : {}});
    if (!this.state.locDetails) {
      let msg = 'Select the payment location.';
      alert(msg);
    } else {
      this.setState({ midType: this.state.locDetails.midType });
    }    
  }
  settingDataByCompanyId() {
    this.setState({ isLoading: true })
    let param = {
      id: this.props.info.companyId ? this.props.info.companyId._id : null,
      userId: this.props.info.userId ? this.props.info.userId : null,
      locationId: (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0]
    };
    this.props.settingDataByCompanyId(param).then((res) => {
      if (res.payload.userData) {
        this.setState({ ...res.payload.userData.data.response }, () => {
          this.setState({ isLoading: false })
        })
      }
    })
  }

  settingDataByCompanyIdmId() {
    this.setState({ isLoading: true })
    let param = {
      id: this.props.info.companyId ? this.props.info.companyId._id : null,
      userId: this.props.info.userId ? this.props.info.userId : null,
      locationId: (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0],
      midType: this.state.locDetails.midType
    };
    this.props.settingDataByCompanyIdmId(param).then((res) => {
      if (res.payload.userData) {
        this.setState({ ...res.payload.userData.data.response }, () => {
          this.setState({ isLoading: false });
        });
      }
    })
  }

  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {
    let error = {};
    let errorFlag = false;
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;

    // Object.keys(this.state).map((key, value) => {
    //   if ((validator(key, this.state[key]))) {
    //     return (validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
    //   } else {
    //     return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
    //   }
    // });

    if (!this.state.locDetails) {
      let msg = 'Select the payment location.';
      alert(msg);
    } else {
      this.setState({ midType: this.state.locDetails.midType });
    }
    let state = this.state;
    if (this.state.InvoiceSurcharge && (this.state.InvoiceSurchargeFee == 0 || this.state.InvoiceSurchargeFee == '' || this.state.InvoiceSurchargeFee == null)) {
      error['InvoiceSurchargeFeeError'] = true;
      errorFlag = true
    }

    if (this.state.InvoiceDiscount && (this.state.InvoiceSVCFee == 0 || this.state.InvoiceSVCFee == '' || this.state.InvoiceSVCFee == null)) {
      error['InvoiceSVCFeeError'] = true;
      errorFlag = true
    }


    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.setState({ isSubmit: true })
      this.setState({ isLoading: true })
      this.props.userSettings(state).then(result => {
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          window.scrollTo(0, 0)
          this.setState({ isLoading: false, successMessages: true, successStr: (this.props.info.userInfo) ? this.props.info.userInfo.data.message : '' }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false, successStr: '' })
            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {

          this.setState({ isLoading: false, misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });
    }
  }
  onchangeType(e, type) {
    let successMessages = false;
    this.setState({ [type]: e.target.value, successMessages })
  }
  handleChangeID(e, name) {
    let successMessages = false;
    let successStr = '';
    // this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });

    if (name == 'InvoiceDiscount' && this.state.locDetails && this.state.locDetails.midType != '') {
    
      if (this.state.InvoiceSurcharge) {
        this.setState({ ...this.state, successStr, successMessages, ['InvoiceDiscount']: e.target.checked, ['InvoiceSurcharge']: !e.target.checked });
      } else {
        this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
      }
    } else if (name == 'InvoiceSurcharge' && this.state.locDetails && this.state.locDetails.midType != '') {
     
      if (this.state.InvoiceDiscount) {
        this.setState({ ...this.state, successStr, successMessages, ['InvoiceSurcharge']: e.target.checked, ['InvoiceDiscount']: !e.target.checked });
      } else {
        this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
      }
    } else {
      this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
    }
  }
  render() {

    const { classes, t } = this.props
    return (
      <>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>Update Settings </Typography>
          </Toolbar>
        </AppBar>

        { (typeof this.state.misMatchError !== 'undefined' && this.state.misMatchError.length > 0) ?
          < ErrorMessage errors={[this.state.misMatchError]} /> : null
        }
        {(this.state.successMessages) ?
          <SuccessMessage successes={[(this.props.info.userInfo) ? this.props.info.userInfo.data.message : 'Success']} /> : ''
        }
        <LoadingOverlay
          className={classes.tpTableFullW}
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <form className={classes.form} noValidate ref={this.myRef}>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                <div  >
                  <Grid container spacing={3}>
                    <Grid item xs={12} className="discount-label-field">
                      <label className="mr-2">Zip Code Enable</label>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={this.state.isZip}
                            onChange={(e) => { this.handleChangeID(e, 'isZip') }}
                            value={this.state.isZip}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="discount-label-field">
                            <label className="mr-2">Allow multiple payments to invoices</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.isMultiPayInvoice}
                                  onChange={(e) => { this.handleChangeID(e, 'isMultiPayInvoice') }}
                                  value={this.state.isMultiPayInvoice}
                                />
                              }
                            />
                          </Grid>
                    {/* <Grid item xs={12} className="discount-label-field">
                      <label className="mr-2">Discount Enable</label>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={this.state.InvoiceDiscount}
                            onChange={(e) => { this.handleChangeID(e, 'InvoiceDiscount') }}
                            value={this.state.InvoiceDiscount}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="fee-name-field">                      
                      <Grid item sm={12} className="tax-select-field taxSelect">
                        <label className="fee-name-label-settings">Discount Fee Name: </label>
                        <Textfield
                          required={true}
                          error={this.state.error.DiscountFeeNameError}
                          id="DiscountFeeName"
                          type="text"
                          // labels={t("Fee Name")}
                          value={this.state.DiscountFeeName}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                          focus={true} />
                      </Grid>
                    </Grid>

                    <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                      <label>Discount Fee Type</label>
                      <select value={this.state.InvoiceSVCFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSVCFeeType') }}>
                        <option value='%'>%</option>
                        <option value='$'>$</option>
                      </select>
                    </Grid>
                    <Grid item sm={12} md={8} className="invoice-svc-fee-field">
                      <Textfield
                        required={true}
                        error={this.state.error.InvoiceSVCFeeError}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{this.state.InvoiceSVCFeeType}</InputAdornment>,
                        }}
                        id="InvoiceSVCFee"
                        type="number"
                        labels={t("SVC Fee")}
                        value={this.state.InvoiceSVCFee}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                        focus={true} />
                    </Grid> */}
                  </Grid>
                </div>
              </Grid>
              {              
              (this.state.locDetails && this.state.locDetails.midType && this.state.locDetails.midType === config.MID_SURCHARGE) ?
                <>
                  <hr />
                  <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                    <div >
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="discount-label-field">
                          <label className="mr-2">Surcharge Enable</label>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={this.state.InvoiceSurcharge}
                                onChange={(e) => { this.handleChangeID(e, 'InvoiceSurcharge') }}
                                value={this.state.InvoiceSurcharge}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} className="fee-name-field">
                          {/* <Grid item xs={6}> <label>Fee Name: </label> </Grid> */}
                          <Grid item sm={12} className="tax-select-field taxSelect">
                            <label className="fee-name-label-settings">Surcharge Fee Name: </label>
                            <Textfield
                              required={true}
                              error={this.state.error.SurchargeFeeNameError}
                              id="SurchargeFeeName"
                              type="text"
                              // labels={t("Fee Name")}
                              value={this.state.SurchargeFeeName}
                              width={true}
                              onChange={(e) => this.handleChange(e, t)}
                              focus={true} />
                          </Grid>
                        </Grid>
                        <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                          <label>Surcharge Fee Type</label>
                          <select value={this.state.InvoiceSurchargeFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSurchargeFeeType') }}>
                            <option value='%'>%</option>
                            <option value='$'>$</option>
                          </select>
                        </Grid>
                        <Grid item sm={12} md={8} className="invoice-svc-fee-field">
                          <Textfield
                            required={true}
                            error={this.state.error.InvoiceSurchargeFeeError}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">{this.state.InvoiceSurchargeFeeType}</InputAdornment>,
                            }}
                            id="InvoiceSurchargeFee"
                            type="number"
                            labels={t("Surcharge Fee")}
                            value={this.state.InvoiceSurchargeFee}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            focus={true} />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </>
                : ''
              }
              <hr />
              <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                <div >
                  <Grid container spacing={3}>
                    <Grid item xs={12} className="discount-label-field">
                    <label>Tax Enable</label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={this.state.InvoiceTax}
                          onChange={(e) => { this.handleChangeID(e, 'InvoiceTax') }}
                          value={this.state.InvoiceTax}
                        />
                      }
                    />
                  </Grid>
                    <Grid item xs={12} className="fee-name-field">
                      <label>Fee Name: </label>
                      <span>Sales Tax </span>
                    </Grid>
                    <Grid item sm={12} md={6} className="tax-select-field taxSelect">
                      <label>Sales Tax Fee Type</label>
                      <select value={this.state.InvoiceSalesTaxFeeType} onChange={(e) => { this.onchangeType(e, 'InvoiceSalesTaxFeeType') }}>
                        <option value='%'>%</option>
                        <option value='$'>$</option>
                      </select>
                    </Grid>
                    <Grid item sm={12} md={10} className="invoice-svc-fee-field">
                      <Textfield
                        required={true}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{this.state.InvoiceSalesTaxFeeType}</InputAdornment>,
                        }}
                        error={this.state.error.InvoiceSalesTaxFeeError}
                        id="InvoiceSalesTaxFee"
                        type="number"
                        labels={t("Sales Tax Fee")}
                        value={this.state.InvoiceSalesTaxFee}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                        focus={true} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

            </Grid>

            <div className="mt-4">
              {(this.props.info.role == config.ADMIN_ROLE || this.props.info.role == config.MANAGER_ROLE) ? <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classesSe.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t("Save")} /> : ''}
              <Buttons
                className={classesSe.ml_2}
                variant="contained"
                color="secondary"
                text={t("Back")}
                // onClick={this.props.history.goBack}     
                onClick={this.props.callClose}             
                 />
            </div>

          </form >
        </LoadingOverlay>
      </>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<AdjustSettings theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {

  return {
    info: state.AdminUserReducer
  };
}
AdjustSettings.element = {
  callClose: element.func
}

export default connect(mapStateToProps, { userSettings, settingDataByCompanyId, settingDataByCompanyIdmId })(withTranslation()(withRouter(ApplyingStyles)))