import { isNumberWithOutZero, checkInvoice, checkName, isYearRange, isDescription, checkEmail, isPhone, isZip, checkSecretKey, isTwoDecimalPlace, isTwoDecimalPlaceSvc, isPayerName } from './util'
import config from '../constants/config'

const validator = (key, value) => {
    var ImageSize = config.UPLOAD_IMAGE_SIZE;
    switch (key) {
        // case 'email':
        //     if (!checkEmail(value)) {
        //         return true;
        //     } else {

        //         return false;
        //     }
        case 'secretKey1':
            if (!checkSecretKey(value)) {
                return true;
            } else {
                return false;
            }
        case 'userName':
        case 'customerAddress':
        case 'customerCity':
        case 'firstName':
            if (!checkName(value)) {
                return true;
            } else {
                return false;
            }
        case 'customer_id':
        case 'phone':
            if (!isPhone(value) && !value.toString().length === 11) {
                return true;
            } else {
                return false;
            }

        case 'phoneNumber':
            if (!isPhone(value) && !value.toString().length === 11) {
                return true;
            } else {
                return false;
            }
        case 'zip':
            if (value? (!isZip(value) && !value.toString().length === 6):'') {
                return true;
            } else {
                return false;
            }
        case 'imgeSize':
            if (value < ImageSize) {
                return true;
            } else {
                return false;
            }
        case 'amount':
            if (!isTwoDecimalPlace(value)) {
                return true;
            } else {
                return false;
            }
        case 'perTransactionLimit':
        case 'dailyTransactionLimit':
        case 'perRefundLimit':
        case 'dailyRefundLimit':
            if (!isTwoDecimalPlace(value)) {
                return true;
            } else {
                return false;
            }

        case 'svcFee':
            if (!isTwoDecimalPlaceSvc(value)) {
                return true;
            } else {
                return false;
            }
        case 'InvoiceSalesTaxFee':
            if (!isTwoDecimalPlace(value)) {
                return true;
            } else {
                return false;
            }
        case 'InvoiceSVCFee':
            if (!isTwoDecimalPlace(value)) {
                return true;
            } else {
                return false;
            }
        case 'InvoiceSurchargeFee':
            if (!isTwoDecimalPlace(value)) {
                return true;
            } else {
                return false;
            }
        case 'payerName':
            if (!isPayerName(value)) {
                return true;
            } else {
                return false;
            }
        case 'description': return false;
        case 'showAlert': return false;
        case 'notes':
            if (!isDescription(value)) {
                return true;
            } else {
                return false;
            }
        case 'year':
            if (!isYearRange(value)) {
                return true;
            } else {
                return false;
            }
        case "invoice":
            // if (!isNumberWithOutZero(value)) {
            //     return true;
            // } else {
            //     return false;
            // }
            if (!checkInvoice(value)) {
                return true;
            } else {
                return false;
            }
        case "creditOrDebit": return false;
        

        default:
            break;
    }
}

export default validator