import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import FormControl from "@material-ui/core/FormControl";

function TextfieldWithLable(props) {

    function handleChange(event) {
        props.onChange(event, event.target.value);
    }
 
    return ( 
        <FormControl  className={props.className} fullWidth component={props.component}>
            <Grid container align="center">
                <Grid item xs={1} sm={2} md={2} />
                <Grid item xs={10} sm={8} md={8}>
                    <TextField
                        required={props.required}
                        error={props.error}
                        id={props.id}
                        label={props.labels} 
                        value={props.value}
                        type={props.type}
                        fullWidth={props.width}
                        onChange={handleChange}
                        autoFocus={props.focus}
                        disabled={props.disabled}
                        margin="normal" />
                </Grid>
                <Grid item xs={1} sm={2} md={2} />
            </Grid>
        </FormControl> 
    );
}

TextfieldWithLable.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    labels: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    width: PropTypes.bool,
    focus: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool
}

TextfieldWithLable.defaultProps = {
    className: '',
    disabled: false,
    autoFocus: false,
    type: 'text',
    value: '',
    error: false
}

export default TextfieldWithLable;