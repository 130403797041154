import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { getCsrfToken, updateRecurrence, RecurrenceDetailsId, deleteRecurrence } from '../../actions/PaymentAction'
import { Textfield } from './../../components/Textfield';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Recurrence.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
// // import config from './../../constants/config'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import validator from '../../helpers/checkValidations';



const frequency = [{ 'value': '1', 'lable': 'Annually' },
{ 'value': '8', 'lable': 'Semi-Annually' },
{ 'value': 'A', 'lable': 'Trimesterly' },
{ 'value': '2', 'lable': 'Quarterly' },
{ 'value': '9', 'lable': 'Bi-Monthly' }
  , { 'value': '3', 'lable': 'Monthly' },
{ 'value': '4', 'lable': 'Bi-Weekly' },
{ 'value': '7', 'lable': '1st and 15th' },
{ 'value': '5', 'lable': 'Weekly' },
{ 'value': '6', 'lable': 'Daily' }]

class RecurrenceDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      NAME: "",
      EMAIL: "",
      PHONE: "",
      amount: 0,
      FREQUENCY: '',
      paytraceCredentials:{},
      StartDate: new Date(),
      deletedAt: new Date(),
      misMatchError: [],
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      error: {
        amountError: false,
      },
    }
  }

  componentDidMount() {
    let locationPaydetails =JSON.parse(localStorage.getItem('locationPaydetails'))!==null? JSON.parse(localStorage.getItem('locationPaydetails')):''
    if(locationPaydetails){
      setTimeout(()=>{
        const paytraceCredentials = { 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword, 'integratorId': locationPaydetails.paytraceIntegratorId }
      this.setState({'paytraceCredentials':paytraceCredentials})
      this.getRecurrenceDetails();
      },3000)
    }else{
      // alert('Select payment location.')
      setTimeout(()=>{
        const paytraceCredentials = { 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword, 'integratorId': locationPaydetails.paytraceIntegratorId }
      this.setState({'paytraceCredentials':paytraceCredentials})
      this.getRecurrenceDetails();
      },3000)
     
    }
    this.getCsrfTokens();
    
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } })
    })
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'amount' && value === '0') {
      alert('Amount should be greater than zero.')
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }
  getRecurrenceDetails() {
    let misMatchError =[];
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.RecurrenceDetailsId(params,this.state.paytraceCredentials).then((res) => {
      let transaction = res.payload.paymentData.data;
      if (transaction.success) {
        this.setState({ ...transaction.response, isLoading: false, amount: transaction.response.AMOUNT })
      }else{
        misMatchError.push(transaction.message)
        this.setState({misMatchError,isLoading: false})
      }
    });
  }


  handleSubmitDeleteRecurring(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;

    if (window.confirm('Are you sure you want to delete this item?')) {

      this.setState({ isLoading: true, isSubmit: true })
      this.props.deleteRecurrence(this.state,this.state.csrf,this.state.paytraceCredentials).then(result => {
         window.scrollTo(0,0)
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false })
              this.setState({ redirect: true })
            }, 3000)
          });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
            let message = result.payload.error.data.message
            misMatchError.push(message)
            this.setState({ isLoading: false, misMatchError, successMessages });
          }
          if (this.props.info.paymentInfo.data.response.length>0) {
            let responseErrors = JSON.parse(this.props.info.paymentInfo.data.response);
            if (responseErrors.response_code === 1) {
              for (let key in responseErrors.errors) {
                let i = 0;
                misMatchError.push(responseErrors.errors[key][i] + '\n')
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            } else {
              misMatchError.push(responseErrors.approval_message)
            }
          } 
          this.setState({ isSubmit: false, isLoading: false, misMatchError, successMessages });
        }
      });

    } else {
      return;
    }

  }
  handleSubmitUpdateRecurring(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let state = this.state
    let error = {}
    let successMessages = false;
    let errorFlag = false;

    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to update recurring?')) {

        this.setState({ isLoading: true, isSubmit: true })
        this.props.updateRecurrence(this.state, this.state.csrf,this.state.paytraceCredentials).then(result => {
          window.scrollTo(0, 0)
          if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false })
                this.setState({ redirect: true })
              }, 2000)
            });
          }
          if (result !== undefined && result.type === 'PAYMENT_ERROR') {
            if (result.payload.error.data && result.payload.error.data.statusCode === 403) {
              let message = result.payload.error.data.message
              misMatchError.push(message)
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
            } 
            this.setState({ isSubmit: false, isLoading: false, misMatchError, successMessages });
          }
        });

      } else {
        return;
      }
    }
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date })
  }

  handleChangeCheckbox(event) {

    this.setState({ [event.target.id]: event.target.checked });
  }
  getFrequency(val) {
    let fre = frequency.filter((item) => item.value === val)
    return fre[0];
  }

  render() {
    const { t } = this.props;
    const { redirect } = this.state;
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    return (
      <div className="rightPanel">
        {(redirect) ? <Redirect to='/Recurring' /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} /> : ''
              }

              <form className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                {(this.state.FINAL_STATUS === 'Deleted') ? <h3 className="errorMsg"><span>Warning : </span>This transaction has been deleted on {new Date(this.state.deletedAt).toLocaleString()}.</h3> : ''}
                {(this.state.FINAL_STATUS === 'Updated') ? <h3 className="noteMsg"><span>Note : </span>This transaction has been already updated on {new Date(this.state.updatedAt).toLocaleString()}</h3> : ''}
                <Grid container spacing={3} className="ViewTxnFormBox" >

                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="NAME"
                      type="text"
                      labels={t("Name")}
                      value={this.state.NAME}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="EMAIL"
                      type="email"
                      labels={t("Email")}
                      value={this.state.EMAIL}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="PHONE"
                      type="number"
                      labels={t("Phone")}
                      value={this.state.PHONE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required={true}
                      id="amount"
                      type="text"
                      labels={t("Amount")}
                      value={ (this.state.amount > 0) ? this.state.amount : ''}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      disabled={true}
                      id="FREQUENCY"
                      type="text"
                      labels={t("FREQUENCY")}
                      value={(this.getFrequency(this.state.FREQUENCY)) ? this.getFrequency(this.state.FREQUENCY)['lable'] : ''}
                      width={true} />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disabled={true}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline1"
                        label={t('Start Date')}
                        value={this.state.StartDate}
                        onChange={(date) => { this.handleDateChange(date, 'StartDate') }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t("Mask Card Number")}
                      value={this.state.MASKCARDNUMBER}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '3.25%' }}>

                  </Grid>
                  <Grid item xs={12} sm={12}>

                    <Buttons
                      variant="contained"
                      color="secondary"
                      text={t("Back")}
                      onClick={this.props.history.goBack} />

                    {(userPermissions.permissions.recurring && !this.state.isSubmit && (this.state.FINAL_STATUS === 'Success' || this.state.FINAL_STATUS === 'Updated')) ? <><Buttons
                      className={classes.btnRed}
                      variant="contained"
                      text={t("Delete")}
                      onClick={(e) => this.handleSubmitDeleteRecurring(e, t)} />
                      <Buttons
                        className={classes.ml_2}
                        variant="contained"
                        color="primary"
                        text={t("Update")}
                        onClick={(e) => this.handleSubmitUpdateRecurring(e, t)} /></> : ''}


                  </Grid>
                </Grid>

              </form>
            </Container>

          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { getCsrfToken, updateRecurrence, RecurrenceDetailsId, deleteRecurrence })(withTranslation()(RecurrenceDetails));