import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { DataGetApi } from "../../helpers/PostDataApi";
import { config } from "../../constants";
import LoadingOverlay from "react-loading-overlay";
import { formatCurrency } from "../../helpers/commonFunction";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router";
import DateFnsUtils from "@date-io/date-fns";
import { Table } from '../../components/Table'
import {
  Button,
  createMuiTheme,
  Grid,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import {
  getBatchFundData,
  getLastUpdatedBatchData,
} from "../../actions/BatchAction";
import { connect } from "react-redux";
import { groupBy as _groupBy, isNull, sumBy } from "lodash";
import SpinnerLoader from "./SpinnerLoader";
//import config2 from './../../constants/config'
import NewFundReporting from "./NewFundReporting"; 

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "#3b3f58",
    },
  },
  pagingWith: {
    width: "10%",
  },
  Underline: {
    color: "blue",
    cursor: "pointer",
  },
  buttonSearch: {
    position: "relative",
    top: "41px",
    left: "17px",
    marginRight: "10px",
  },
  buttonClear: {},
  updatedRow: {
    marginLeft: "20px",
  },
  labelDate: {
    position: "relative",
    top: 42,
  },
});

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTablePagination: {
        toolbar: {
          width: "160px !important ",
        },
        root: {
          width: "497px !important",
        },
      },
    },
  });

const getDatePickerThemes = () =>
  createMuiTheme({
    overrides: {
      MuiFormControl: {
        marginNormal: {
          marginLeft: "10px",
        },
      },
      MuiGrid: {
        container: {
          marginBottom: 50,
        },
      },
    },
  });
const FundReportingNew = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [clickCounter, setClickCounter] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [batchData, setBatchData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();

  const [responsive, setResponsive] = React.useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = React.useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const date = new Date();
  const toDayDate = date.toLocaleDateString();
  const [fromDate, setFromDate] = React.useState(
    new Date(date.setDate(date.getDate() - 30))
  );

  const [error, setError] = React.useState([]);
  const [toDate, setToDate] = React.useState(new Date());

  const [lastUpdatedDate, setLastUpdatedDate] = React.useState("N/A");
  const [isDisabledRefresh, setIsDisabledRefresh] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const getLastUpdatedBatchDate = () => {
    props.getLastUpdatedBatchData().then((res) => {
      let { data } = res.payload.data;
      if (data.success && !isNull(data.data)) {
        setLastUpdatedDate(data.data.created.at);
      }
    });
  };
  const handleRefreshBatchInfo = () => {
    setTimeout(() => {
      setClickCounter(1);
      setIsDisabledRefresh(false);
      setError([]);
    }, 60000);
    if (clickCounter >= 3) {
      setError([
        config.RATE_LIMITER_MESSAGES
      ]);
      setIsDisabledRefresh(true);
    }
    setClickCounter(clickCounter + 1);   
    setButtonLoader(true);
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user.token;
    DataGetApi(config.BASEURL + "updateBatchInfo", {}, token)
      .then((res) => {
        if (typeof res.data !== "undefined" && res.data.success) {
        //   getLastUpdatedBatchDate();
          getBatchFundData();
        } else if (
          typeof res.response.status !== "undefined" &&
          res.response.status === 429
        ) {
          setError( [config.RATE_LIMITER_MESSAGES]);
        }
        setButtonLoader(false);
      })
      .catch((errror) => {
        // setIsDisabledRefresh(false);
        setButtonLoader(false);
      });
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  /**
   * Handle the search button
   */
  const handleSearchBtn = () => {
    setLoading(true);
    getBatchFundData();
  };
  const handleClearBtn = () => {
    setLoading(true);
    setToDate(new Date());
    setFromDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    getBatchFundData();
  };

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: false,
  };

  const renderValue = (value, meta, update) => {
    let valueList = value.split(",");
    let valueLen = valueList.length;
    return (
      <Tooltip title="View transaction">
        <div>
          {value.split(",").map((ele, i) => (
            <span
              keys={i}
              className={classes.Underline}
              onClick={(ev) => history.push(`/batch/${ele}`)}
            >
              {ele} {valueLen === i + 1 ? " " : ","}
            </span>
          ))}
        </div>
      </Tooltip>
    );
  };

 /*  const columns = [    
    "Account Number",
    "Processing Date",
    "Merchant DBA Name",
    "Record Type",
    "Debit/Credit Indicator",
    "Total ACH",
  ];
 */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



 







  const getBatchFundData = () => {
    setError([]);
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user.token;
    let url = config.BASEURL;
    let ac_no = '';
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    // console.log(locationPaydetails, " locationPaydetails ")
    if (locationPaydetails) {
      if (locationPaydetails.midType === config.MID_TRADITIONAL) {
        ac_no = locationPaydetails.mId;
      } else if (locationPaydetails.midType === config.MID_SURCHARGE) {
        ac_no = locationPaydetails.mId2;
      } if (locationPaydetails.midType == "") {
        ac_no = locationPaydetails.mId;
      }
    }
    let param = {
      fromDate: fromDate,
      toDate: toDate,
      ac_no: ac_no
    };
    props
      .getBatchFundData(param)
      .then((response) => {
        // console.log(response, " response2 ");
        let result = response.payload.batchData;
        if (result.status === 200) {
        //   var CreatedGroups = _groupBy(result.data.data.batchesFund, function(b) {
        //     return b.created.at;
        //   });
        //   const dataSet = Object.keys(CreatedGroups).map((item) => {
        //     let dataSet = {
        //       batchNumber: CreatedGroups[item]
        //         .map((ele) => ele.number)
        //         .join(","),
        //       type: "D",
        //       date: item,
        //       depositAmount: formatCurrency(
        //         sumBy(CreatedGroups[item], function(o) {
        //           return o.net_amount;
        //         })
        //       ),
        //     };
        //     return Object.values(dataSet);
        //   });
          setLoading(false);
          setBatchData(result.data.data.batchesFund);
        } else {
          setLoading(false);
          setError("No transactions were found with these criteria.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log( error , "error")
        setError("Some things happen wrong please try again");
        setLoading(false);
      });
  };

  useEffect(() => {
  //  getBatchFundData();
    // getLastUpdatedBatchDate();
  }, []);

 


  const setColumn = () => {

    
  }

  return (

   





    <Paper className={classes.root}>
      <LoadingOverlay active={loading} spinner text="Loading your content...">
        {error.length > 0 ? <ErrorMessage errors={[error]} /> : ""}

        <MuiThemeProvider theme={getDatePickerThemes()}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container keys="1">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                InputProps={{ readOnly: true }}
                margin="normal"
                id="from_date-picker-inline"
                label="From Date"
                value={fromDate}
                maxDate={toDate}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="to_date-picker-inline"
                label="To Date"
                value={toDate}
                minDate={fromDate}
                maxDate={toDayDate}
                onChange={handleToDateChange}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <Grid>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  onClick={handleSearchBtn}
                >
                  Search
                </Button>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  color="secondary"
                  onClick={handleClearBtn}
                >
                  Clear
                </Button>
              </Grid>
              <Grid className={classes.updatedRow}>
                <label className={classes.labelDate}>
                  Last Updated Date: {lastUpdatedDate}{" "}
                </label>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  color="primary"
                  disabled={isDisabledRefresh}
                  onClick={handleRefreshBatchInfo}
                >
                  {buttonLoader ? <SpinnerLoader {...props} /> : "Refresh"}
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>



           
       <NewFundReporting />





{/*         <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Deposit Amount List"}
            data={batchData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider> */}
      </LoadingOverlay>
    </Paper>
  );
};

function mapStateToProps(state) {
  return { batchData: state.BatchReducer };
}

export default connect(
  mapStateToProps,
  { getBatchFundData, getLastUpdatedBatchData }
)(FundReportingNew);
