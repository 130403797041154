import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LoadingOverlay from 'react-loading-overlay';
import { Textfield } from './../../components/Textfield';
import { addProduct } from '../../actions/ProductAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import config from './../../constants/config'
import classesCus from './Products.module.scss';
import { DialogBox } from './../../components/DialogBox/DialogBox'
import TextfieldLable from './TextfieldLable'
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  name:'',
  createdBy: '',
  companyId: '',
  amount: '',
  notes: '',
  description: '',
  customFields: [],
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  error: {
    nameError: false, 
    notesError: false, 
    descriptionError: false, 
    amountError: false, 
  }
};

class AddProducts extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    
    this.setState({ isLoading: true })
    
    let userData = JSON.parse(localStorage.getItem('user'))
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false })
  }

  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
     
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false ,successMessages:false});
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true })
    
    if(state.notes ==="")
    {
      delete state.notes
    }
    //check cutom fields values
    const values = [...this.state.customFields];
    values.map((key,val)=>{ 
     return  ((key['value']==null || (typeof key['value'] === 'string' && key['value'].trim() === ''))?(error[key.label+'Error'] =true , errorFlag=true):error[key.label+'Error'] =false)
    })
    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      console.log(error, 'error')
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.setState({ isLoading: true })
      this.props.addProduct(state).then(result => {
        if (result !== undefined && result.type === 'PRODUCT_SUCCESS') {
          this.setState({ ...initialState, isLoading: false, successMessages: true }, () => {
            if(this.props.hasOwnProperty('isFromAddInvoices') !== true) { 
              setTimeout(function () {
              window.location.href = config.APPURL + "/Products"
              }, 2000)};
          })

        }
        if (result !== undefined && result.type === 'PRODUCT_ERROR') {
          this.setState({ misMatchError: this.props.info.productInfo.data.message, isLoading: false, successMessages });

        }
      });


    }

  }
  handleClose = () => {
    this.setState({ isOpen: false })
  }
  handleChangeCustom(event, t, i) {
    let error = {}
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields:values ,error} );
  }
  handleAdd() {
    this.setState({ isOpen: true })
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields]; 
    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  }
  handleRemove(i) { 
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  render() {

    const { classes, t } = this.props
    return (
      <div className={this.props.isFromAddInvoices === true ? '' :"rightPanel"}>
       { (this.props.isFromAddInvoices === true ) ? '' : <Topheader /> } 
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
             
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.productInfo.data.message]} /> : ''
              }
              <h3>Add Product</h3>
              <form className={classes.form} noValidate ref={this.myRef}>
                <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<TextfieldLable handleClose={this.handleClose} handleLabels={this.handleLabels} />} />
                <Grid container item xs={12} spacing={1}  >
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.nameError}
                      id="name"
                      type="text"
                      labels={t("Name")}
                      value={this.state.name}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="amount"
                      type="text"
                      labels={t("Amount")}
                      value={this.state.amount}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                       
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.descriptionError}
                      id="description"
                      type="text"
                      labels={t("Description")}
                      value={this.state.description}
                      width={true}
                      multiline={true}
                      rowsMax={5}
                      rows={5}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      error={this.state.error.notesError}
                      id="notes"
                      type="text"
                      labels={t("Notes")}
                      value={this.state.notes}
                      width={true}
                      multiline={true}
                      rowsMax={5}
                      rows={5}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid container item sm={12} xs={12} justify="flex-end">
                    <Buttons
                      className={classesCus.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t("AddMore")}
                      onClick={(e) => this.handleAdd(e)} />

                  </Grid>
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6} key={idx}>
                        <div key={`${field}-${idx}`}>
                         <CloseIcon  onClick={() => this.handleRemove(idx)}/>  
                          <Textfield 
                          required={true}
                            error={this.state.error[field.label+'Error']}
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ""}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            focus={true}
                          />
                        </div>
                      </Grid>
                    );
                  })}

                </Grid>
                <Grid item xs={12} sm={12} className="mt-2">
                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t("Save")} />
                  <Buttons
                    className={classesCus.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack} />
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<AddProducts theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {
  return {
    info: state.ProductReducer
  };
}

export default connect(mapStateToProps, { addProduct })(withTranslation()(withRouter(ApplyingStyles)))