import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserEdit, UserResendActivationLink } from '../../actions/AdminUserAction'
import { CompanyLocationDataById } from '../../actions/CompanyAction'
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import IntegrationDownshift from './../../helpers/multiSelect'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { fetchDetails } from '../../actions/AdminUserAction'

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', height: '4rem' };
  } else {
    return { width: '100%' };
  }
}

class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",
      user: JSON.parse(localStorage.getItem("user")),
      redirect: false,
      sideShow: true,
      isLoading: true,
      locationSelected: "",
      allLocations: [],
      previousSelectedLocation: [],
      misMatchError: [],
      permissions: {        
        editCustomer: false,
        refunds: false,
        refundsManual: false,
        textToInvoice: false,
        exportRecord: false,
        allLocaton: false,
        creatNewUser: false,
        createInvoice: false,
        ach: false,
        virtualTerminal: false,
        recurring: false,
        svcFeeEdit: false,
        void: false,
        perTransactionLimit: "",
        dailyTransactionLimit: "",
        perRefundLimit: "",
        dailyRefundLimit: "",
        emailNotification : false,
        viewAllTransactions: false,
        preAuthorize:false,
        creditSale:false
      },
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        perTransactionLimitError: false,
        dailyTransactionLimitError: false,
        perRefundLimitError: false,
        dailyRefundLimitError: false,
      },
    };
  }
  componentDidMount() {
    this.getUserData();
    this.getAllLocationData();
    let user = JSON.parse(localStorage.getItem("user"));
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS") {
        this.setState({ allLocations: res.payload.userData.locationSelectedwithmid });
      }
    });
  }

  getUserData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.UserDetailById(params).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false });
      let data = this.state.location;
      if (data && data.length > 0)
        this.setState({
          locationSelected: Object.keys(data).map((k, v) => data[k].id),
        });
    });
  }
  getAllLocationData() {
    let companyId = this.state.user.company._id;
    this.props.CompanyLocationDataById(companyId).then((res) => {
      this.setState({
        locationsData: res.payload.companyData.data.response,
        isLoading: false,
      });
    });
  }
  handleClickMenu(e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData();
  }
  handleChangeCheckbox(event) {
    if (event.target.id === "allLocaton") {
      var locationSelectedValue = [];
      var previousSelectedLocationValue = [];
      var setData = [];
      if (event.target.checked) {
        let locationsIds = this.state.allLocations.map((items) => items._id);
        previousSelectedLocationValue = this.state.locationSelected;
        locationSelectedValue = locationsIds;
        setData = this.state.allLocations.map((items) => items);
      } else {
        locationSelectedValue = this.state.previousSelectedLocation;
        setData = this.state.allLocations.map((items) => items);
      }
      this.setState({
        locationSelected: locationSelectedValue,
        previousSelectedLocation: previousSelectedLocationValue,
        location: setData,
      });
    }

    this.setState({
      permissions: {
        ...this.state.permissions,
        [event.target.id]: event.target.checked,
      },
    });
  }
  handleChange(event, t) {
    let error = {};
    let successMessages = false;
    let misMatchError = [];
    let { id, value } = event.target;

    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (
      id === "perTransactionLimit" ||
      id === "dailyTransactionLimit" ||
      id === "perRefundLimit" ||
      id === "dailyRefundLimit"
    ) {
      this.setState({
        permissions: { ...this.state.permissions, [id]: value },
      });
    }
    validator(id, value)
      ? (error[id + "Error"] = true)
      : (error[id + "Error"] = false);
    this.setState({ [id]: value, error, misMatchError, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    Object.keys(state).map((key, value) => {
      if (key === "phone" && !this.state.permissions.textToInvoice) {
        return false;
      }
      if (key === "defaultmidType" && !this.state.defaultmidType) {
        return false;
      }
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : (error[key + "Error"] = false);
      } else {
        return state[key] === "" ||
          (typeof state[key] === "string" && state[key].trim() === "")
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : "";
      }
    });
    Object.keys(this.state.permissions).map((key, value) => {
      if (validator(key, state.permissions[key])) {
        return validator(key, state.permissions[key])
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : (error[key + "Error"] = false);
      } else {
        return state.permissions[key] === "" ||
          (typeof state.permissions[key] === "string" &&
            state.permissions[key].trim() === "")
          ? ((error[key + "Error"] = true), (errorFlag = true))
          : "";
      }
    });

    if (state.locationSelected.length === 0) {
      misMatchError.push(t("LocationRequired"));
      errorFlag = true;
      this.setState({ misMatchError });
    }
    if (errorFlag) {
      window.scrollTo(0, 0, 0);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm("Are you sure you want to update?")) {
        this.setState({ isLoading: true });
        this.props.UserEdit(this.state).then((result) => {
          if (result !== undefined && result.type === "USER_EDIT_DATA") {
            window.scrollTo(0, 0, 0);
            this.setState(
              { successMessages: true, misMatchError, isLoading: false },
              () => {
                setTimeout(() => {
                  this.setState({ successMessages: false, redirect: true });
                }, 2000);
              }
            );
          }
          if (result !== undefined && result.type === "USER_ERROR") {
            this.setState({
              misMatchError: result.payload.error.data.message,
              isLoading: false,
              successMessages,
            });
          }
        });
      }
    }
  }
  handleSubmitResendLink(e, t) {
    let successMessages = false;
    if (window.confirm("Are you sure you want to resend the link?")) {
      this.setState({ isLoading: true });
      this.props.UserResendActivationLink(this.state.email).then((result) => {
        if (result !== undefined && result.type === "USER_EDIT_DATA") {
          this.setState({ successMessages: true, isLoading: false });
        }
        if (result !== undefined && result.type === "USER_ERROR") {
          this.setState({
            misMatchError: this.props.editInfo.data.message,
            isLoading: false,
            successMessages,
          });
        }
      });
    }
  }
  addLocation = (arr) => {
    let allLocationChceked =
      this.state.allLocations.length === arr.length ? true : false;
    this.setState({
      locationSelected: arr,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      },
    });
  };

  addLocation2 = (arr) => {
    
    let allLocationChceked = this.state.locationsData.length === arr.length ? true : false;
    this.setState({
      locationSelectedwithmid: arr,
      permissions: {
        ...this.state.permissions,
        allLocaton: allLocationChceked,
      },
    });
  };

  
  render() {
    const { t } = this.props;
    const { redirect } = this.state;
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Users" /> : ""}
        <Topheader />
        <div className="contentPart"> 
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading your content..."
          >
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? (
                <ErrorMessage errors={[this.state.misMatchError]} />
              ) : (
                  ""
                )}
              {this.state.successMessages && this.props.editInfo ? (
                <SuccessMessage
                  successes={[
                    this.props.editInfo.data.message
                      ? this.props.editInfo.data.message
                      : "Something went wrong please try again!",
                  ]}
                />
              ) : (
                  ""
                )}
              <form className={classes.form} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.userNameError}
                      id="userName"
                      type="text"
                      labels={t("Username")}
                      value={this.state.userName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.firstNameError}
                      id="firstName"
                      type="text"
                      labels={t("First Name")}
                      value={this.state.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.lastNameError}
                      id="lastName"
                      type="text"
                      labels={t("Last Name")}
                      value={this.state.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={this.state.permissions.textToInvoice}
                      error={
                        this.state.permissions.textToInvoice
                          ? this.state.error.phoneError
                          : false
                      }
                      id="phone"
                      type="text"
                      labels={t("Phone")}
                      value={this.state.phone}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      style={getValidationStyle(
                        !this.state.locationSelected && this.state.isSubmit
                      )}
                    >
                      <IntegrationDownshift
                        isLocationExists={true}
                        selectedLocations={this.state.locationSelectedwithmid}
                        locationData={
                          this.state.user.role === 1 || this.state.user.role === 2
                            ? this.state.allLocations
                            : this.state.locationsData
                        }
                        addLocation={this.addLocation}
                        addLocation2={this.addLocation2}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.perTransactionLimitError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      id="perTransactionLimit"
                      type="text"
                      labels={t("Per Transaction Limit")}
                      value={this.state.permissions.perTransactionLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.dailyTransactionLimitError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      id="dailyTransactionLimit"
                      type="text"
                      labels={t("Daily Transaction Limit")}
                      value={this.state.permissions.dailyTransactionLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.perRefundLimitError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      id="perRefundLimit"
                      type="text"
                      labels={t("Per Refund Limit")}
                      value={this.state.permissions.perRefundLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.dailyRefundLimitError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      id="dailyRefundLimit"
                      type="text"
                      labels={t("Daily Refund Limit")}
                      value={this.state.permissions.dailyRefundLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      {" "}
                      <h5>Permissions</h5>
                    </Grid>
                    <Grid container item xs={12}>
                      {this.state.role < 4 ? (
                        <Grid item xs={12} sm={6}>
                          <label>
                            <Checkbox
                              id="creatNewUser"
                              labels="Create New User"
                              onChange={(e) => {
                                this.handleChangeCheckbox(e);
                              }}
                              value={this.state.permissions.creatNewUser}
                              checked={this.state.permissions.creatNewUser}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <span>Create New User</span>
                          </label>
                        </Grid>
                      ) : (
                          ""
                        )}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="refunds"
                            checked={this.state.permissions.refunds}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.refunds}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Refunds</span>
                        </label>
                      </Grid>
                      {this.state.role < 4 ? (
                        <Grid item xs={12} sm={6}>
                          <label>
                            <Checkbox
                              id="editCustomer"
                              labels="Edit Customer"
                              onChange={(e) => {
                                this.handleChangeCheckbox(e);
                              }}
                              value={this.state.permissions.editCustomer}
                              checked={this.state.permissions.editCustomer}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <span>Edit Customer</span>
                          </label>
                        </Grid>
                      ) : (
                          ""
                        )}
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="textToInvoice"
                            checked={this.state.permissions.textToInvoice}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.textToInvoice}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Text To Invoice</span>
                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="refundsManual"
                            checked={this.state.permissions.refundsManual}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.refundsManual}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Refunds Manual</span>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="allLocaton"
                            checked={this.state.permissions.allLocaton}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.allLocaton}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>All Location</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="createInvoice"
                            checked={this.state.permissions.createInvoice}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.createInvoice}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Invoicing</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="ach"
                            checked={this.state.permissions.ach}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.ach}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>ACH </span>
                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="virtualTerminal"
                            checked={this.state.permissions.virtualTerminal}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.virtualTerminal}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Virtual Terminal</span>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="recurring"
                            checked={this.state.permissions.recurring}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.recurring}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Recurring billing</span>
                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="svcFeeEdit"
                            checked={this.state.permissions.svcFeeEdit}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.svcFeeEdit}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Svc Fee Edit</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="exportRecord"
                            checked={this.state.permissions.exportRecord}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.exportRecord}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Export Records</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="void"
                            checked={this.state.permissions.void}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.void}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Void</span>
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox id="emailNotification" checked={this.state.permissions.emailNotification} onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }} value={this.state.permissions.emailNotification} color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
                          <span>{t("emailNotifications")}</span>
                        </label>
                      </Grid>   

                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox id="viewAllTransactions"  checked={this.state.permissions.viewAllTransactions}  onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }} value={this.state.permissions.viewAllTransactions} color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
                          <span>{t("viewAllTransactions")}{this.state.permissions.viewAllTransactions}</span>
                        </label>
                      </Grid> 
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="preAuthorize"
                            checked={this.state.permissions.preAuthorize}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.preAuthorize}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Pre-Authorization</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="creditSale"
                            checked={this.state.permissions.creditSale}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.creditSale}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Credit Sale</span>
                        </label>
                      </Grid>

                      {this.state.role > 4 ? (
                        <Grid item xs={12} sm={6}>
                          <Checkbox
                            id="creatNewUser"
                            checked={this.state.permissions.creatNewUser}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.creatNewUser}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <label>
                            <span>Create New User</span>
                          </label>
                        </Grid>
                      ) : (
                          ""
                        )}
                    </Grid>
                  </Grid>

                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t("Update")}
                  />
                  <Buttons
                    className={classes.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack}
                  />
                  <Buttons
                    className={classes.ml_2}
                    variant="contained"
                    color="primary"
                    text={t("ResendLink")}
                    onClick={(e) => this.handleSubmitResendLink(e, t)}
                  />
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
EditAdmin.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    editInfo: state.AdminUserReducer.editInfo
  };
}

export default connect(mapStateToProps, { UserDetailById, UserEdit, UserResendActivationLink, CompanyLocationDataById, fetchDetails })(withTranslation()(EditAdmin));
