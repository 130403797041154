import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PersonAddDisabledOutlinedIcon from '@material-ui/icons/PersonAddDisabledOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import RedeemIcon from '@material-ui/icons/Redeem';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Table as tab } from '@material-ui/core/';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
//import { formatCurrency, getBaseAmount } from '../../helpers/commonFunction'
import Box from '@material-ui/core/Box';

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatCurrency } from '../../helpers/commonFunction';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExposureZero } from '@material-ui/icons';

const useStyles = makeStyles({
	table: {
		minWidth: "650",
		width: "100%",
		marginLeft: "44px",
		backgroundColor: "#f9f9f9",
		// backgroundColor: "#dbdcf9",
		display: "table",
		'border-spacing': "0",
		'border-collapse': "collapse",
	},

	childTable: {
		'& td': {
			width: "96px",
			padding: "10px"
		}
	},
	panelSummary: { flexDirection: "row-reverse", margin: "0px", padding: "0px" },
	childTableFund: {
		padding: "15px"
	}
});


class Table extends React.Component {
	constructor(props) {

		let date = new Date();
		super(props)
		this.state = {
			searchText: (sessionStorage.getItem("reportingtab") && JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).searchTxt) ? JSON.parse(sessionStorage.getItem("reportingtab")).searchTxt : "",
			reload: false,
			isTaxData: true,
			toDate: (sessionStorage.getItem("reportingtab") && JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).toDate) ? JSON.parse(sessionStorage.getItem("reportingtab")).toDate : new Date(),
			date: new Date(),
			fromDate: (this.props.location.pathname === "/AdminPortal") ? date.setDate(date.getDate() - 3) : (this.props.location.pathname === "/Invoices") ? date.setDate(date.getDate() - 45) :( (sessionStorage.getItem("reportingtab") && JSON.parse(sessionStorage.getItem("reportingtab")) && JSON.parse(sessionStorage.getItem("reportingtab")).fromDate ) ? JSON.parse(sessionStorage.getItem("reportingtab")).fromDate : date.setDate(date.getDate() - 30)),
			data: [],
			user: JSON.parse(localStorage.getItem('user'))
		}
		this.tableRef = React.createRef();
	}
	checkAddPermission = (title, permissions) => {

		switch (title) {
			case 'Company':
				if (permissions.addCompany) {
					return true;
				} else {
					return false;
				}
			case 'User':
				if (permissions.creatNewUser) {
					return true;
				} else {
					return false;
				}
			case 'Locations':
				if (permissions.addLocation) {
					return true;
				} else {
					return false;
				}
			case 'Invoice':
				if (permissions.createInvoice) {
					return true;
				} else {
					return false;
				}
			case 'SubAdmin':
				if (permissions.addSubAdmin) {
					return true;
				} else {
					return false;
				}
			case 'Admin':
				if (permissions.addAdmin || permissions.addUser) {
					return true;
				} else {
					return false;
				}
			case 'Tickets':
				return (this.state.user.role > 1) ? true : false;
			case 'Customers':
			case 'Products':
			case 'Pages':
				return true;
			default:
				return false
		}
	}
	checkPermission = (title, permissions) => {
		switch (title) {
			case 'Company':
				if (permissions.isActiveCompany && permissions.editCompany) {
					return this.returnEditAndActiveAction();
				} else if (permissions.isActiveCompany) {
					return this.returnActiveAction();
				} else if (permissions.editCompany) {
					return this.returnEditAction();
				} else {
					return '';
				}
			case 'Locations':
				if (permissions.isActiveLocation && permissions.editLocation) {
					return this.returnEditAndActiveAction();
				} else if (permissions.isActiveLocation) {
					return this.returnActiveAction();
				} else if (permissions.editLocation) {
					return this.returnEditAction();
				} else {
					return '';
				}
			case 'Users':
				if (permissions.isActiveUser && permissions.editUser) {
					return this.returnEditAndActiveAction();
				} else if (permissions.isActiveUser) {
					return this.returnActiveAction();
				} else if (permissions.editUser) {
					return this.returnEditAction();
				} else {
					return '';
				}
			case 'Customers':
				if (permissions.editCustomer) {
					return this.returnEditAndActiveAction();
				} else {
					return '';
				}
			case 'Auth Only':
				return this.returnPreAuth();
			default:
				// console.log("check action", title);
				return this.returnReloadAction()
		}
	}

	editBtnRedirect(e, data) {
		if (this.props.title === "Company") {
			this.props.history.push(this.props.title + 'Details/' + data._id)
		} else {
			this.props.history.push('/Edit' + this.props.title + '/' + data._id)
		}
	}

	onClickEditDetails(event, row) {
		this.props.companyDetailsAction(event, this.props.EditTab, row._id)
	}

	onClickAddNew(event, tab) {
		this.props.companyDetailsAction(event, this.props.AddTab)
	}

	DeleteBtn(event, data) {
		this.props.companyDeleteAction(event, data)
		this.tableReload();
	}

	ActiveBtn(event, data) {
		this.props.companyActiveAction(event, data)
		this.tableReload(true);
	}

	ActiveBtnCapture(event, data) {
		this.props.captureAuthPayment(event, data);
	}

	ActiveBtnVoid(event, data) {
		this.props.voidAuthPayment(event, data);
	}

	captureAmountModify(newValue, oldValue, rowData) {
		this.props.captureAmountModify(newValue, oldValue, rowData);
		setTimeout(() => {
			this.tableRef.current.onQueryChange();
		}, 1000);
	}

	tableReload(event, data) {
		this.props.tableReload(true);
		setTimeout(() => {
			this.tableRef.current.onQueryChange();
		}, 1000);
	}

	updateCustomer() {
		this.props.updateCustomer()
	}

	syncTransactions() {
		this.props.syncTransactions()
	}



	hardRefresh() {
		if (typeof this.props.hardRefresh !== 'undefined') {
			this.props.hardRefresh(this.tableRef);
		}
	}

	returnEditAndActiveAction = () => {
		return [{
			icon: 'edit',
			tooltip: `Edit ${(this.props.title) ? this.props.title : ''}`,
			onClick: (event, rowData) => { this.editBtnRedirect(event, rowData) }
		}, {
			icon: 'active',
			tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
			onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
		}];
	}

	returnPreAuth = () => {
		return [
			{
				icon: 'redeem',
				tooltip: `Capture Payment`,
				onClick: (event, rowData) => { if (window.confirm('Are you sure want to settle this payment(s)?')) { this.ActiveBtnCapture(event, rowData) }; }
			},
			{
				icon: 'redeemvoid',
				tooltip: `Void Payment`,
				onClick: (event, rowData) => { if (window.confirm('Are you sure want to void this payment(s)?')) { this.ActiveBtnVoid(event, rowData) }; }
			}, {
				icon: 'refresh',
				tooltip: 'Refresh Transaction',
				isFreeAction: true,
				onClick: () => this.tableReload()
			}
		];
	}

	returnActiveAction() {
		return [{
			icon: 'active',
			tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
			onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
		}];
	}

	returnEditAction() {
		return [{
			icon: 'edit',
			tooltip: `Edit ${(this.props.title) ? this.props.title : ''}`,
			onClick: (event, rowData) => { this.editBtnRedirect(event, rowData) }
		}];
	}

	returnReloadAction() {
		return [{
			icon: 'refresh',
			tooltip: 'Refresh Clients',
			isFreeAction: true,
			onClick: () => this.tableReload()
		}]
	}

	handleDateChange = (date, key) => {
		if (date == 'Invalid Date' || date == null) {
			return false
		} else {
			this.setState({ [key]: date }, () => {
				(key === "toDate") ? this.props.toDateFilter(this.state.toDate) : this.props.fromDateFilter(this.state.fromDate)
				setTimeout(() => {
					this.tableRef.current.onQueryChange()
				}, 1000)
			})
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.tableRefresh || this.props.tableRefresh) {
			this.tableRef.current.onQueryChange();
		}
	}
	componentDidMount() {
		this.hardRefresh();
	}
	render() {
		const companyDetailsAction = this.props.companyEditDetails;
		const classes = this.props.classes;
		return (
			<MaterialTable
				tableRef={this.tableRef}
				// searchText={this.state.searchText}
				localization={{
                    toolbar: {
						searchText: this.state.searchText,
                        searchPlaceholder: this.state.searchText,
                    },
                }}
				components={
					companyDetailsAction ? {
						Toolbar: props => (
							<div>
								<MTableToolbar {...props} />
								<div style={{ padding: '0px 10px' }}>
									{(this.checkAddPermission(this.props.title, this.props.permissions)) ? <button
										label="Users"
										role="tab"
										className="btn addBtn"
										{...companyDetailsAction}
										onClick={(e) => { this.onClickAddNew(e, this.props.UserAddTab) }}
									>
										{((this.props.title && this.props.search) ? 'Add ' + this.props.title : '')}
									</button> :
										<Button
											label="Users"
											role="tab"
											{...companyDetailsAction}
											color={"primary"}
											onClick={(e) => { this.onClickAddNew(e, this.props.UserAddTab) }}
										>
											{((this.checkAddPermission(this.props.title, this.props.permissions)) ? 'Add ' + this.props.title : '')}
										</Button>
									}
								</div>
							</div>
						),
						Action: props => {
							return <> &nbsp;
								<Tooltip title={(props.data.status && props.action.icon === 'active') ? 'in-active' : props.action.icon}>
									<Button
										onClick={(event) => props.action.onClick(event, props.data)}
										color={((props.action.icon === 'active' || props.action.icon === 'refresh') ? "default" : (props.action.icon === "edit") ? "primary" : "secondary")}
										variant="contained"
										style={{ textTransform: 'none' }}
										size="small"
									>
										{(props.action.icon === "edit") ? <EditIcon /> :
											(props.action.icon === "refresh") ? <RefreshIcon /> :
												(props.action.icon === "delete") ? <DeleteOutlineOutlinedIcon /> :
													(props.data.status) ? <PermIdentityOutlinedIcon /> : <PersonAddDisabledOutlinedIcon />}
									</Button>
								</Tooltip></>
						}
					} : {
						Toolbar: props => (
							<div>
								<div className='left-control top-seciton'>
									<MTableToolbar {...props} />
									{this.props.datefilter ? <div className={'dateFilter-table'}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant="inline"
												autoOk
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline1"
												maxDateMessage={'From date should not be greater than To date'}
												maxDate={this.state.toDate}
												// maxDate={this.state.toDate.setDate(this.state.toDate.getDate() + 30)}
												label={'From Date'}
												value={this.state.fromDate}
												onChange={(date) => { this.handleDateChange(date, 'fromDate') }}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant="inline"
												autoOk
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline1"
												// minDate={new Date()}
												label={'To Date'}
												value={this.state.toDate}
												onChange={(date) => { this.handleDateChange(date, 'toDate') }}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</div> : ''}


								</div>
								<div className="res-cus-center" style={{ padding: '0px 10px' }}>

									{(this.props.location.pathname !== '/Users' && this.state.user.role !== 3 && this.checkAddPermission(this.props.title, this.props.permissions)) ?
										<button className="btn addBtn" onClick={() => { this.props.history.push('/Add' + this.props.title) }} >
											{'Add ' + this.props.title}
										</button> : ''}

									{(this.state.user.role === 3 && this.checkAddPermission(this.props.title, this.props.permissions)) ?
										<button className="btn addBtn" onClick={() => { this.props.history.push('/Add' + this.props.title) }} >
											{((this.props.title === 'User' && !this.props.createNew) ? '' : 'Add ' + this.props.title)}
										</button> : ''}


									{(this.props.location.pathname === '/Users' && this.state.user.role === 2 && this.checkAddPermission(this.props.title, this.props.permissions)) ?
										<>
											<button className="btn addBtn" onClick={() => { this.props.history.push('/Add' + this.props.title) }} >
												{((this.props.title === 'User' && !this.props.createNew) ? '' : 'Add ' + this.props.title)}
											</button>
											<button className="btn addBtnml2" onClick={() => { this.props.history.push('/AddManager') }} >
												{((this.props.title && this.props.search) ? 'Add Manager' : '')}
											</button>
										</>
										: ''}
								</div>
							</div>
						),
						Action: props => {
							return <>
								&nbsp;
								<Tooltip title={(props.data.status && props.action.icon === 'active') ? 'in-active' : props.action.tooltip}>
									<Button
										onClick={(event) => props.action.onClick(event, props.data)}
										color={((props.action.icon === 'synctransaction' || props.action.icon === 'synccustomer' || props.action.icon === 'redeem' || props.action.tooltip == 'Save' || props.action.icon === 'active' || props.action.icon === "refresh") ? "default" : (props.action.icon === "edit") ? "primary" : "secondary")}
										variant="contained"
										// style={ { textTransform: 'none', fontSize: 10, fontWeight: 600 }}
										style={{ textTransform: 'none', fontWeight: 600 }}
										size="small"
									>
										{(props.action.tooltip == "Save") ? "Save" :
											(props.action.tooltip == "Cancel") ? "Cancel" :
												(props.action.icon === "redeem") ? "Capture" :
													(props.action.icon === "redeemvoid") ? "Void" :
														(props.action.icon === "synccustomer") ? "Sync Customer" :
															(props.action.icon === "synctransaction") ? "Sync Transactions" :
																(props.action.icon === "edit") ? <EditIcon /> :
																	(props.action.icon === "refresh") ? <RefreshIcon /> :
																		(props.action.icon === "delete") ? <DeleteOutlineOutlinedIcon /> :
																			(props.data.status) ? <PermIdentityOutlinedIcon /> : <PersonAddDisabledOutlinedIcon />
										}
									</Button>
								</Tooltip>
							</>
						}
					}

				}
				 
				onRowClick={this.props.onRowClick}
				options={{
					...this.props.options,
					search: this.props.search,
					debounceInterval: 1000,

					exportButton: (this.props.exports) ? {
						csv: this.props.exports,
						pdf: false
					} : false,


					exportCsv: (columns, data,) => {

						if (this.props.title === "Transactions") {
							this.props.clickHandler()
							return

						}
						if (this.props.title === "Transactions  ") {
							this.props.clickHandler()
							return
						}
						const newColumns = columns.filter(function (column) {

							return column.hidden !== true && column.title != "Action";
						})
						const label = newColumns.map((d, i) => {
							return d.title;
						})
						const body = data.map(p => {
							let roles;
							const obj = {}

							// console.log(p.locationSelectedwithmid
							// .map(items=>`${items.locationName}(${items.midType})`),"locationName")
							if (p.role === 2) {
								roles = "Admin"
							} else if (p.role === 3) {
								roles = "Manager"
							} else if (p.role === 4) {
								roles = "Regular User"
							}
							for (let x = 0; x < label.length; x++) {
								if (this.props.tablesName === 'tickets') {
									obj[newColumns[x].field] = (newColumns[x].title === 'TicketStatus') ? (p[newColumns[x].field]) ? 'Closed' : 'Open' : p[newColumns[x].field]
								} else if (this.props.tablesName === 'Customers') {
									obj[newColumns[x].field] = newColumns[x].title === "Name" ? `${p[newColumns[x].field]} ${p.lastName}` : p[newColumns[x].field]
								} else if (this.props.tablesName === 'Users') {
									obj[newColumns[x].field] = (newColumns[x].title === 'Company Name') ? p.company.dbaName : obj[newColumns[x].field] = (newColumns[x].title === 'Locations') ? p.locationSelectedwithmid
										.map(items => `${items.locationName}(${items.midType})`) : obj[newColumns[x].field] = (newColumns[x].title === 'Role') ? roles : (newColumns[x].title === 'Active Status') ? (p[newColumns[x].field]) ? 'Active' : 'In Active' : p[newColumns[x].field]
								} else {
									if (newColumns[x].field === "location") {
										obj[newColumns[x].field] = `${p[newColumns[x].field]} ${'(' + p.midType + ')'}`
									}
									else if (newColumns[x].title === "Base") {
										let svcFee = p.SVC_FEE ? p.SVC_FEE.toString().replace(/\$/g, "") : 0.00
										let surcharge_fee = p.SURCHARGE_FEE ? p.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0.00
										let tax = p.TAX ? p.TAX.toString().replace(/\$/g, "") : 0.00
										obj[newColumns[x].field] = formatCurrency(parseFloat(p.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax)))

									}
									else if (newColumns[x].title === "Name") {
										obj[newColumns[x].field] = `${p.CUSTOMERDATA.name ? p.CUSTOMERDATA.name : ((p.CUSTOMERDATA.firstName) ? p.CUSTOMERDATA.firstName + ' ' + p.CUSTOMERDATA.lastName : ((p.CUSTOMERDATA.customer_id) ? p.CUSTOMERDATA.customer_id : ''))} `
                                     }
									else {
										obj[newColumns[x].field] = p[newColumns[x].field]
									}
								} if (this.props.tablesName === 'PREAUTH') {
									let svcFee = p.SVC_FEE ? p.SVC_FEE.toString().replace(/\$/g, "") : 0.00
									let surcharge_fee = p.SURCHARGE_FEE ? p.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0.00
									let tax = p.TAX ? p.TAX.toString().replace(/\$/g, "") : 0.00
									obj[newColumns[x].field] = (newColumns[x].title === 'Payment Type') ? "Credit" : (newColumns[x].title === 'Base Amount') ? formatCurrency(parseFloat(p.AMOUNT) - (parseFloat(svcFee) + parseFloat(surcharge_fee) + parseFloat(tax))) : (newColumns[x].title === 'location' || newColumns[x].title === "midType") ? p[newColumns[x].field].replace(/,/g, '') + `(${p.midType})` : p[newColumns[x].field]
								}
							}
							return obj;
						})
							.reduce((d, i) => {
								d += Object.values(i).join(',')
								d += '\n'

								return d
							}, '')
						var hiddenElement = document.createElement('a');
						hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(label + '\n' + body);
						hiddenElement.target = '_blank';
						hiddenElement.download = this.props.title + '.csv';
						hiddenElement.click();
					},


					paging: (this.props.paging) ? this.props.paging : false,
					actionsColumnIndex: -1
				}}


				pageSize={10}
				columns={this.props.columns}
				data={this.props.data}
				cellEditable={
					(this.props.title.trim() === 'Auth Only') ?

						{
							onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
								return new Promise((resolve, reject) => {
									if (isNaN(newValue)) {
										alert("Enter numberic value.");
									} else {
										if (newValue > 0 && newValue <= rowData.AMOUNT) {
											this.captureAmountModify(newValue, oldValue, rowData);
										} else {
											if (newValue > rowData.AMOUNT) {
												alert("Capture amount can't be more than authorized amount.");
											} else if (newValue < 0) {
												alert("Capture amount can't be less than 0 .");
											}
										}
									}
									setTimeout(resolve, 1000);
								});
							}
						}

						: false
				}
				title={(this.props.title) ? this.props.title : ''}
				actions={((this.state.user.role === 1 || this.state.user.role === 0) && this.props.title !== 'Pages' && this.props.title !== 'Transactions' && this.props.title !== 'Tickets') ? [
					{
						icon: 'edit',
						tooltip: `Edit ${(this.props.title) ? this.props.title : ''}`,
						onClick: (event, rowData) => { companyDetailsAction ? this.onClickEditDetails(event, rowData) : this.editBtnRedirect(event, rowData) }
					}, {
						icon: 'active',
						tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
						onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
					}, {
						icon: 'delete',
						tooltip: `Delete ${(this.props.title) ? this.props.title : ''}`,
						onClick: (event, rowData) => { if (window.confirm('Are you sure want to delete the item?')) { this.DeleteBtn(event, rowData) }; }
					}, {
						icon: 'refresh',
						tooltip: 'Refresh Clients',
						isFreeAction: true,
						onClick: () => this.tableReload()
					}
				] : ((this.state.user.role === 2 || this.state.user.role === 3) && this.props.title !== 'Funding Report' && this.props.title !== 'Invoice' && this.props.title !== 'Tickets' && this.props.title !== 'Recurrence' && this.props.title.trim() !== 'Auth Only') ?

					(typeof this.props.permissions !== "undefined" && this.props.permissions.editCustomer) ? [
						{
							icon: 'edit',
							tooltip: `Edit ${(this.props.title) ? this.props.title : ''}`,
							onClick: (event, rowData) => { this.editBtnRedirect(event, rowData) }
						}, {
							icon: 'active',
							tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
							onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
						},
						(this.props.title == 'Customers') ?
							{
								icon: 'synccustomer',
								tooltip: `Sync Customer`,
								isFreeAction: true,
								onClick: () => this.updateCustomer()
							} :
							{
								icon: 'refresh',
								tooltip: 'Refresh Clients',
								isFreeAction: true,
								onClick: () => this.tableReload()
							}
					] : (this.props.title == 'Products') ?
						[{
							icon: 'edit',
							tooltip: `Edit ${(this.props.title) ? this.props.title : ''}`,
							onClick: (event, rowData) => { this.editBtnRedirect(event, rowData) }
						},
						{
							icon: 'active',
							tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
							onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
						}, {
							icon: 'refresh',
							tooltip: 'Refresh Clients',
							isFreeAction: true,
							onClick: () => this.tableReload()
						}
					] : (this.props.isSyncTransactions)  ?  
					[
						{
							icon: 'synctransaction',
							tooltip: `Sync Transactions`,
							isFreeAction: true,
							onClick: () => this.syncTransactions(),
						}
					]:(this.props.title === 'Transactions')  ? [] :[
							{
								icon: 'active',
								tooltip: `Active ${(this.props.title) ? this.props.title : ''}`,
								onClick: (event, rowData) => { if (window.confirm('Are you sure want to change the item status?')) { this.ActiveBtn(event, rowData) }; }
							}, {
								icon: 'refresh',
								tooltip: 'Refresh Clients',
								isFreeAction: true,
								onClick: () => this.tableReload()
							}
						] : this.checkPermission(this.props.title, this.props.permissions) ? this.checkPermission(this.props.title, this.props.permissions) : []}
				detailPanel={[

					rowData => ({
						icon: (rowData.hasOwnProperty('REFUNDED')) ? (rowData.REFUNDED.length > 0 ? ChevronRightIcon : " ") :
							(rowData.hasOwnProperty("reportRef")) ? (rowData.reportRef.length > 0 ? ChevronRightIcon : " ") : " ",

						render: rowData => {
							return <>
								<tab className={classes.table} size="small" aria-label="a dense table">
									<TableBody>
										{rowData.hasOwnProperty("REFUNDED") && rowData.REFUNDED.map(
											(item, index) => (
												<TableRow
													className={classes.childTable}
													key={index}
												>
													<TableCell style={{width: '6%'}} />
													<TableCell style={{width: '6%'}}>
														{item.REFUND_TXNID}{" "}
														{this.props.dataChild}
													</TableCell>
													<TableCell style={{width: '6%'}} />
													<TableCell style={{width: '6%'}}>
														<button style={{ textAlign: "center"}}
															type="button"
															className="btn RefundedBtn"
														>
							  								{item.FINAL_STATUS}
														</button>
													</TableCell>
													<TableCell style={{width: '10%'}}/>
													<TableCell style={{width: '10%'}}/>
													<TableCell style={{width: '10%'}}/>
													<TableCell style={{width: '10%',textAlign:'center'}}>
														{item.REFUND_BY?.firstName +
															" " +
															item.REFUND_BY?.lastName}
													</TableCell>
													<TableCell style={{width: '6%'}}/>
													<TableCell style={{width: '8%'}} />
													<TableCell style={{width: '8%'}} />
													<TableCell style={{width: '5%',textAlign:'center'}}>
														${parseFloat(item?.amt).toFixed(2)}
													</TableCell>
													<TableCell style={{width: '6%'}}/>
													<TableCell style={{width: '4%'}}/>
													<TableCell >
														{new Date(
															item?.REFUND_DATE
														).toLocaleString()}
													</TableCell>	
												</TableRow>
											)
										)}

										{rowData.hasOwnProperty("reportRef") ?
											<TableHead>
												<TableRow className={classes.childTable}>
													<TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reference No. </TableCell>
													<TableCell>        </TableCell>
													<TableCell>Batch Deposit</TableCell>
													<TableCell>Batch Fee</TableCell>
													<TableCell>Net Deposit</TableCell>
												</TableRow>
											</TableHead>
											: ""}

										{
											rowData.hasOwnProperty("reportRef") && rowData.reportRef.map(
												(item, index) => (
													<Accordion >
														<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary} aria-controls="panel2a-content" id="panel2a-header"	>
															<TableRow className={classes.childTable} key={index} >
																<TableCell>{item['Reference Number']}</TableCell>
																<TableCell>    </TableCell>
																<TableCell>{formatCurrency(item.depSumRef)}</TableCell>
																<TableCell>{formatCurrency(item.feeSumRef)}</TableCell>
																<TableCell>{formatCurrency(item.sumDepRef)}</TableCell>
															</TableRow>
														</AccordionSummary>
														<AccordionDetails>												 															<tab className={classes.table} size="small" aria-label="a dense table2">
															<TableHead >
																<TableRow className={classes.childTable}>
																	<TableCell>Reference No.</TableCell>
																	<TableCell>Record Sequence Number</TableCell>
																	<TableCell>Routing Number</TableCell>
																	<TableCell>Transaction Type</TableCell>
																	<TableCell>Amount</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{rowData.fundingDetails.map((items, idx) => (
																	(item['Reference Number'] === items['Reference Number']) ?
																		<TableRow key={idx + 'x'} className={classes.childTableFund}>
																			<TableCell  >{items['Reference Number']}</TableCell>
																			<TableCell  >{items['Record Sequence Number']}</TableCell>
																			<TableCell  >{items['Routing Number']}</TableCell>
																			<TableCell  >{items['Transaction Types']}</TableCell>
																			<TableCell  >{formatCurrency(items.Amount)}</TableCell>
																		</TableRow>
																		: ''
																)
																)
																}
															</TableBody>
														</tab>
														</AccordionDetails>
													</Accordion>
												)
											)
										}
									</TableBody>
								</tab>
							</>;
						},
					}),
				]}
			/>
		);
	}
}

const ApplyingStyles = (props) => {
	const classes = useStyles()
	return (<Table classes={classes} {...props} />)
}


export default withRouter(ApplyingStyles);