import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { getCsrfToken, getRefundedDailyData, TransactionDetailsId, refundAuth, voidTransaction, emailInvoice } from '../../actions/PaymentAction'
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Transactions.module.scss';
import Container from '@material-ui/core/Container';
import PrintIcon from '@material-ui/icons/Print';
import InvoicePrint from '../../helpers/InvoicePrint';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import config from './../../constants/config'
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import validator from '../../helpers/checkValidations';
import {formatAmount,formatCurrency,userRole} from '../../helpers/commonFunction'
import { Button, Typography } from '@material-ui/core';
import { Print } from '@material-ui/icons';

class Viewtransaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectTransaction: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      ORDERID: "",
      AMOUNT: "",
      APPROVALCODE: "",
      DATETIME: new Date(),
      TXNID: "",
      TAX:'',
      APPROVALCODE:"",
      RESPONSECODE: "",
      RESPONSETEXT: "",
      MASKCARDNUMBER: "",
      masked_card_number:"",
      misMatchError: [],
      foundError:'',
      TransactionData: [],
      CUSTOMERDATA: {},
      achDetails: {},
      paytraceCredentials: {},
      locationName: '',
      data:'',
      InvoiceData:'',
      user: JSON.parse(localStorage.getItem('user')),
      receiptSetting: { 'email': '', 'phone': '', 'isPhone': false, 'isEmail': false },
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      limit: {
        perTransactionLimit: false,
        dailyTransactionLimit: false,
        perRefundLimit: false,
        dailyRefundLimit: false,
      }
    }
  }
 
      
     
   
  

  componentDidMount() {
    let locationPaydetails =JSON.parse(localStorage.getItem('locationPaydetails'))!==null? JSON.parse(localStorage.getItem('locationPaydetails')):'' ;    
    setTimeout(()=>{
      const paytraceCredentials = { 
        'userName': locationPaydetails.paytraceUserName, 
        'password': locationPaydetails.paytracePassword, 
        'integratorId': locationPaydetails.paytraceIntegratorId 
      }
    this.setState({'paytraceCredentials':paytraceCredentials, 'locationName': locationPaydetails.locationName })
      this.getTransactionDetails();
    },1000)
    this.getRefundedDailyData();
    this.getCsrfTokens();
    if ( this.props.user.companyId.receiptSetting) {
      this.setState({ receiptSetting: { ...this.state.receiptSetting, ...this.props.user.companyId.receiptSetting } })
    }
    
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } })
    })
  }
  getTransactionDetails() {
    let misMatchError =[];
    const user = JSON.parse(localStorage.getItem('user'));    
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.TransactionDetailsId(params,this.state.paytraceCredentials).then((res) => {      
      let transaction = res.payload.paymentData.data;
      // console.log(transaction, " <<<<<<<<<<<<<<<<<<<<<<<<< transaction details ");
      if (transaction.success) {
        this.setState({ ...transaction.response}, () => {
          let cardType = '';
          if(this.state.CARDTYPE === ""){
            cardType = this.state.masked_card_number ? this.getCardType(this.state.masked_card_number.charAt(0)) : '';
          }
          this.setState({ isLoading: false, CARDTYPE: cardType });
        })
      }else{
        misMatchError.push(transaction.message);
        this.setState({misMatchError,isLoading: false});
      }
    });
  }
  getRefundedDailyData() {
    let userData = JSON.parse(localStorage.getItem('user'))
    let data = { 'date': new Date(), 'companyId': userData.company ? userData.company._id : '', 'role': userData.role, 'userId': userData._id }
    this.props.getRefundedDailyData(data).then((res) => {
      if (res) {
        this.setState({ TransactionData: res.payload.paymentData.data.response, updatedBy: userData._id })
      }
    })
  }
  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return ''
    }
  }
 
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });

  }
  handleSubmitVoid(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    if (window.confirm('Are you sure you want to void this transaction?')) {
      this.setState({ isLoading: true });      
      this.props.voidTransaction(this.state, this.state.csrf,this.state.paytraceCredentials).then(result => {
        // console.log("Result===ee=>", result)
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          window.scrollTo(0, 0)
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false })
              this.setState({ redirectTransaction: true })
            }, 4000)
            this.getTransactionDetails()
          });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          window.scrollTo(0, 0)
          if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401 || result.payload.error.data.statusCode === 409)) {
            let message = result.payload.error.data.message
            misMatchError.push(message)
            this.setState({ isLoading: false, misMatchError, successMessages });
          }else {
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.error.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
          } 
        }
      });
    }
  }
 
  handleEmailInvoice(e) {
    let misMatchError = [];
    let successMessages = false;
    // console.log(this.state," this.state ");   
    const data = { 'svcFee': this.state.SVC_FEE,'surchargeFee': this.state.SURCHARGE_FEE, 'total': this.state.AMOUNT, 'Tax':this.state.TAX, 'amount': (this.state.AMOUNT - this.state.SVC_FEE - this.state.TAX - this.state.SURCHARGE_FEE), 'customerId': this.state.CUSTOMERDATA._id, 'companyId': this.state.companyId, 'invoiceReceipt': true, 'invoice': this.state.ORDERID, 'email': this.state.email ? this.state.email : this.state.CUSTOMERDATA.email, 'FINAL_STATUS': this.state.FINAL_STATUS,'transaction_type': this.state.transaction_type, 'TXNID': this.state.TXNID, 'CARDTYPE': this.state.CARDTYPE, 'MASKCARDNUMBER': (this.state.MASKCARDNUMBER) ? this.state.MASKCARDNUMBER : this.state.masked_card_number, 'APPROVALCODE': this.state.APPROVALCODE, 'RESPONSETEXT': this.state.RESPONSETEXT, 'locationName': this.state.locationName,'dateOpened':this.state.DATETIME};    
    if (window.confirm('Are you sure you want to send the invoice receipt?')) {

      this.setState({ 'isLoading': true })
      this.props.emailInvoice(data).then(result => {
    
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          this.setState({InvoiceData:result.payload.paymentData.data.response});
          this.setState({ isLoading: false, successMessages: true, misMatchError });
          setTimeout(() => {
            this.setState({ successMessages: false });           
          }, 3000);
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          misMatchError.push(result.payload.error.data.message," ",result.payload.error.data.statusCode )
          this.setState({ isLoading: false, misMatchError, successMessages });
          setTimeout(() => {
            this.setState({ successMessages: false, misMatchError:[] });           
          }, 3000);
        }
      })
    }
  }

handlePrint() {
  InvoicePrint(this.state);
}

  checkMaxRefundLimit(val) {
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let maxRefundLimit = (userPermissions.permissions.perRefundLimit) ? userPermissions.permissions.perRefundLimit : 0
    let maxRefundDailyLimit = (userPermissions.permissions.dailyRefundLimit) ? userPermissions.permissions.dailyRefundLimit : 0
    let maxDailyRefundedAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0)
    if (val > (maxRefundDailyLimit - maxDailyRefundedAmount) && maxDailyRefundedAmount > 0 && maxRefundDailyLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, dailyRefundLimit: false } })
        }, 4000)
        return false;
      })
    }
    else if (val > maxRefundLimit && maxRefundLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, perRefundLimit: false } })
        }, 3000)
        return false;
      })
    } else {
      return true;
    }
  }
  
  handleSubmitRfundAuth(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    if (window.confirm('Are you sure you want to refund this transaction?')) {
      if (this.state.status_code === "S" && this.checkMaxRefundLimit(this.state.AMOUNT)) {
        this.setState({ isLoading: true })
        this.props.refundAuth(this.state, this.state.csrf,this.state.paytraceCredentials).then(result => {
          if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
            window.scrollTo(0, 0)
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(function () {
                window.location.href = config.APPURL + "/Transactions"
              }, 2000)
            });
          }
          if (result !== undefined && result.type === 'PAYMENT_ERROR') {
            window.scrollTo(0, 0)
            if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
              let message = result.payload.error.data.message
              misMatchError.push(message)
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.error.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
            }
            this.setState({ isLoading: false, misMatchError, successMessages });
          }
        });
      }
    } else {
      return;
    }
  }
  
           
  

  
  render() {
    
    
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0)
    const { t } = this.props;
    const { redirectTransaction } = this.state;
    let refund = (this.state.hasOwnProperty('REFUNDED') && this.state.REFUNDED.length > 0) ? this.state.REFUNDED : ( this.state.hasOwnProperty('REFUND_BY') && this.state.ORDERID ==="" ) ? [this.state.REFUND_BY] : [];
    return (
     
      <div className="rightPanel">
        {(redirectTransaction) ? <Redirect to='/Transactions' /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
              // {this.state.misMatchError ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} /> : ''
              }

              <form className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                {(this.state.limit.perRefundLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your per refund transaction limit is ${(userPermissions.permissions.perRefundLimit) ? userPermissions.permissions.perRefundLimit : 0} </h3> : ''}
                {(this.state.limit.dailyRefundLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your daily refund transaction limit is ${(userPermissions.permissions.dailyRefundLimit) ? userPermissions.permissions.dailyRefundLimit : 0}. Make a refund amount of ${(userPermissions.permissions.dailyRefundLimit - maxDailyAmount)}.</h3> : ''}
 {(this.state.status_code === 'V') ? <h3 className="noteMsg"><span>Note : </span>This transaction has been voided on {(this.state.UPDATE_VOID_DATE ? new Date(this.state.UPDATE_VOID_DATE).toLocaleString() : new Date().toLocaleString())}</h3> : ''}
                {this.state.REFUND ? <h3 className="successMsg"><span>Note : </span>This transaction has been refunded on {new Date(this.state.REFUND_DATE).toString()} </h3> : ''}
                
                <Grid container spacing={3} className="ViewTxnFormBox" >
                
                  <Grid item xs={12} sm={4}>
                    
                    <Textfield
                      id="Invoice"
                      type="text"
                      labels={t("Invoice")}
                      value={this.state.ORDERID}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="Amount"
                      type="text"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      labels={t("Amount")}
                    
                     // value={(this.state.TransactionData.AMOUNT !==  this.state.CAPTURE_AMOUNT) ? formatCurrency(this.state.CAPTURE_AMOUNT).replace("$","") : formatCurrency(this.state.AMOUNT).replace("$","")}
                      // value={(this.state.RESPONSECODE === '106')? this.state.AMOUNT: (this.state.AMOUNT !==  this.state.CAPTURE_AMOUNT) ? formatCurrency(this.state.CAPTURE_AMOUNT).replace("$","") : formatCurrency(this.state.AMOUNT).replace("$","") }
                      value={(this.state.AMOUNT && this.state.CAPTURE_AMOUNT ===0)? formatCurrency(this.state.AMOUNT).replace("$","") : formatCurrency(this.state.CAPTURE_AMOUNT).replace("$","")  }
    
                     
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="Name"
                      type="text"
                      labels={t("Name")}
                      //value={(this.state.firstName) ? this.state.firstName + ' ' + this.state.lastName : (this.state.CUSTOMERDATA.firstName)?this.state.CUSTOMERDATA.firstName + ' ' + this.state.CUSTOMERDATA.lastName:''}
                      value={this.state.CUSTOMERDATA.name}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="email"
                      type="email"
                      labels={t("Email")}
                      // value={this.state.email ? this.state.email : this.state.CUSTOMERDATA.email}
                      value={this.state.email ? this.state.email : ''}
                      value={(this.state.RESPONSECODE === '106') ? ' ':this.state.email ? this.state.email : this.state.CUSTOMERDATA.email}
                      //value={this.state.emai? this.state.email:' '}
                     
                      // value={this.state.EMAIL}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="phone"
                      type="number"
                      labels={t("Phone")}
                      value= {(this.state.RESPONSECODE === '106') ? ' ' : this.state.phone}
                      labled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="APPROVALCODE"
                      type="text"
                      labels={t("Approval Code")}
                      value={this.state.APPROVALCODE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="TXNID"
                      type="text"
                      labels={t("Transaction Number")}
                      value={this.state.TXNID}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="RESPONSECODE"
                      type="text"
                      labels={t("Response Code")}
                      value={this.state.RESPONSECODE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t("Mask Number")}
                      value={(this.state.CUSTOMERDATA && this.state.CUSTOMERDATA.masked_card_number) ? this.state.CUSTOMERDATA.masked_card_number : this.state.MASKCARDNUMBER}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="ZIP"
                      type="text"
                      labels={t("Zip")}
                      value={(this.state.ZIP) ? this.state.ZIP :''}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {(this.state.achDetails.hasOwnProperty('accountName')) ? <>
                    <Grid item xs={12}> <label><b>Bank Transfer Details</b></label></Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="accountName"
                        type="text"
                        labels={t("Account Name")}
                        value={this.state.achDetails.accountName}
                        disabled={true}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="accountNumber"
                        type="text"
                        labels={t("Account Number")}
                        value={this.state.achDetails.accountNumber}
                        disabled={true}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Textfield
                        id="routingNumber"
                        type="text"
                        labels={t("Routing Number")}
                        value={this.state.achDetails.routingNumber}
                        disabled={true}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                  </> : ''} 
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="transactionType"
                      type="text"
                      labels={t("Payment Type")}
                      value={(this.state.RESPONSECODE === '120' || this.state.CARDTYPE ==='ACH') ? 'ACH' :  (this.state.payment_type ==  "Debit")  ? "Debit" : "Credit"}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="transactionDate"
                      type="text"
                      labels={t("Transaction Date")}
                      value={new Date(this.state.createdAt).toLocaleDateString()}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>                 
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="transactionStatus"
                      type="text"
                      labels={t("Transaction Status")}
                      value={this.state.FINAL_STATUS === 'authOnly'? "Authorize Only":this.state.FINAL_STATUS}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Textfield
                      id="RESPONSETEXT"
                      type="text"
                      labels={t("Response Text")}
                      value={ this.state.RESPONSETEXT?(this.state.RESPONSETEXT +"and Status code "+ this.state.RESPONSECODE+ (this.state.hasOwnProperty("Approval_Message")?(" Reason: "+this.state.Approval_Message):" ")):''}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  
                  {refund.length > 0 ? 
                  <Grid item xs={12} sm={12}>
                     <Typography variant="h6" style={{fontWeight:"400", fontSize: "1.05rem"}} >REFUNDED BY</Typography> 
                  </Grid> : "" }
                  {( refund.length > 0 ) ? refund.map((item, index)=>
                  
                   ( <div>
                    
                      <Grid container spacing={3} >
                          <Grid item xs={12} sm={2}>
                            <Textfield
                              id="Rname"
                              type="text"
                              labels={t("Name")}
                              value={ item.hasOwnProperty('REFUND_BY') ? item.REFUND_BY.firstName +" "+ item.REFUND_BY.lastName : item.firstName +" "+ item.lastName}
                              disabled={true}
                              width={true} />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Rusernameame"
                            type="text"
                            labels={t("Username")}
                            value={ item.hasOwnProperty('REFUND_BY') ? item.REFUND_BY.userName: item.userName}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Remail"
                            type="text"
                            labels={t("Email")}
                            value={ item.hasOwnProperty('REFUND_BY') ? item.REFUND_BY.email: item.email}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Rrole"
                            type="text"
                            labels={t("Role")}
                            value={ item.hasOwnProperty('REFUND_BY') ? item.REFUND_BY.role: item.role}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Ramt"
                            type="text"
                            labels={t("Amount")}
                            value={ item.hasOwnProperty('REFUND_BY') ? "$ "+parseFloat(item.amt).toFixed(2): "$ "+parseFloat(this.state.AMOUNT).toFixed(2)}
                            disabled={true}
                            width={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Textfield
                            id="Rdate"
                            type="text"
                            labels={t("Date")}
                            value={ item.hasOwnProperty('REFUND_BY') ? new Date(item.REFUND_DATE).toLocaleDateString(): new Date(item.createdAt).toLocaleDateString()}
                            disabled={true}
                            width={true} />
                        </Grid>
                      </Grid>
                  </div>)
                  ):'' } 

                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={12}></Grid>

                  <Grid className="b-2p" style={{marginBottom: "25px"}}>
                  <Buttons
                      className={'emailInvoiceTerminal'}
                      variant="contained"
                      color="secondary"
                      text={t("Back")}
                      onClick={this.props.history.goBack} 
                    />
                    {(this.state.FINAL_STATUS === 'Voided' || this.state.FINAL_STATUS === 'Refunded' || this.state.FINAL_STATUS === 'authOnly' || this.state.FINAL_STATUS === 'Declined') ? '' : <Buttons
                      startIcon={<Icon>send</Icon>}
                      className={'emailInvoiceTerminal email-invoice'}
                      variant="contained"
                      text={t("Resend Receipt")}
                      onClick={(e) => this.handleEmailInvoice(e)} />}

                    {(this.state.status_code === 'S' && !this.state.REFUND && userPermissions.permissions.refunds) ? <Buttons
                      className={'emailInvoiceTerminal'}
                      variant="contained"
                      color="primary"
                      text={t("Refund")}
                      onClick={(e) => this.handleSubmitRfundAuth(e, t)} /> : ''}
                    {(this.state.FINAL_STATUS !== 'Voided' && this.state.status_code === 'P' && userPermissions.permissions.void) ? <Buttons
                      variant="contained"
                      color="primary"
                      className={'emailInvoiceTerminal'}
                      text={t("Void")}
                      onClick={(e) => this.handleSubmitVoid(e, t)} /> : ''}
                      
                    <Button
                       variant="contained"
                       color="primary"
                       className={'emailInvoiceTerminal'}
                     > 
                      <PrintIcon
                      style={{color:"white"}}
                      className={'printInvoice'}
                      onClick ={this.handlePrint.bind(this)}
                    />
                    </Button>
                    
                  </Grid>
                  
                  </Grid>
                {(this.state.status_code === 'P') ? <h3 className="noteMsg"><span>Note :</span>This transaction has not yet settled. If you want to cancel this transaction it can be voided immediately, or refunded after it has settled.</h3> : ''}
              </form>
            </Container>
                                                
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { getCsrfToken, getRefundedDailyData, emailInvoice, refundAuth, voidTransaction, TransactionDetailsId })(withTranslation()(Viewtransaction));