import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { DataGetApi } from "../../helpers/PostDataApi";
import { config } from "../../constants";
import LoadingOverlay from "react-loading-overlay";
import { formatCurrency, doesFileExist } from "../../helpers/commonFunction";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router";
import DateFnsUtils from "@date-io/date-fns";
import IntegrationDownshift from './../../helpers/multiSelect';
import { locationList } from '../../actions/LocationAction';
import FormControl from "@material-ui/core/FormControl";
import {
  Button,
  createMuiTheme,
  Grid,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import {
  getBatchData,
  getLastUpdatedBatchData,
} from "../../actions/BatchAction";
import { connect } from "react-redux";
import { isNull } from "lodash";
import { SuccessMessage } from "../../components/Message/SuccessMessage";
import SpinnerLoader from "./SpinnerLoader";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },

  container: {
    maxHeight: 440,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "#3b3f58",
    },
  },
  pagingWith: {
    width: "10%",
  },
  Underline: {
    color: "blue",
    cursor: "pointer",
  },
  buttonSearch: {
    position: "relative",
    top: "41px",
    left: "17px",
    marginRight: "10px",
  },
  buttonClear: {},
  updatedRow: {
    marginLeft: "20px",
  },
  labelDate: {
    position: "relative",
    top: 42,
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTablePagination: {
        toolbar: {
          width: "160px !important ",
        },
        root: {
          width: "497px !important",
        },
      },
    },
  });

const getDatePickerThemes = () =>
  createMuiTheme({
    overrides: {
      MuiFormControl: {
        marginNormal: {
          marginLeft: "10px",
        },
      },
      MuiGrid: {
        container: {
          marginBottom: 50,
        },
      },
    },
  });

function BatchReporting(props) {
  const classes = useStyles();
  const [clickCounter, setClickCounter] = React.useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [batchData, setBatchData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [responsive, setResponsive] = React.useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = React.useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = React.useState("");
  const [error, setError] = React.useState([]);
  const [toDate, setToDate] = React.useState(new Date());
  const [lastUpdatedDate, setLastUpdatedDate] = React.useState("N/A");
  const [isDisabledRefresh, setIsDisabledRefresh] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [isBatchData, issetBatchData] = React.useState(false);
  const [loadingtext, setLoadingText] = React.useState('Loading your content...');
  const [data, setData] = React.useState([]);
  const [locationSelectedwithmid, setLocationSelectedwithmid] = React.useState([JSON.parse(localStorage.getItem('locationPaydetails'))]);
  // const [locationSelected, setlocationSelected] = React.useState([JSON.parse(localStorage.getItem('locationPaydetails'))._id]);

  let history = useHistory();
  const date = new Date();
  const toDayDate = date.toLocaleDateString();
  const [fromDate, setFromDate] = React.useState(
    new Date(date.setDate(date.getDate() - 30))
  );
  
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  
  /**
   * Handle the search button
   */
  const handleSearchBtn = () => {
    setLoading(true);
    getBatchRecord();
  };
  const handleClearBtn = () => {
    setLoading(true);
    setToDate(new Date());
    setFromDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    getBatchRecord();    
  };
  
  useEffect(() => {
    if(JSON.parse(localStorage.getItem("user")).role !== config.SUPER_ADMIN_ROLE){
      getLocationData();
    }
    handleRefreshBatchInfo();    
  }, [locationSelectedwithmid]);

  const handleRefreshBatchInfo = () => {    
    setTimeout(() => {
      setClickCounter(1);
      setIsDisabledRefresh(false);
      setError([]);      
    }, 60000);

    // console.log(locationSelectedwithmid, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<< locationSelectedwithmid >>>>>>>>>>>>>>>>>>>>>>>>>>> ");

    if (clickCounter >= 3) {
      setError( [config.RATE_LIMITER_MESSAGES]);
      setIsDisabledRefresh(true);
    }
    setClickCounter(clickCounter + 1);
    
    if(lastUpdatedDate !== "N/A") { setButtonLoader(true); }

    let user = JSON.parse(localStorage.getItem("user"));
    let companyId = (user.role !== config.SUPER_ADMIN_ROLE && user.company._id) ? user.company._id : "" ;
    let token = user.token;
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    // console.log(locationPaydetails, "<<<<<<<< locationPaydetails >>>>>>>>");
    // let locationPaydetails = locationSelectedwithmid[locationSelectedwithmid.length - 1];
    const paytraceCredentials = {
      'locationMid': locationPaydetails  ? locationPaydetails.locationMid : '', 
      'paytraceKey': locationPaydetails  ? locationPaydetails.paytraceKey : '', 
      'userName': locationPaydetails  ? locationPaydetails.paytraceUserName : '', 
      'password': locationPaydetails  ? locationPaydetails.paytracePassword : ''
    };
    // console.log(paytraceCredentials, "<<<<<<<<<<<<<<< paytraceCredentials >>>>>>>>>>>>>>>>");

    let file = doesFileExist(config.PAYTRACE_AWS_PATH + paytraceCredentials.paytraceKey)
    if (window.paytrace && file) {
      let res = window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + paytraceCredentials.paytraceKey);
    } else {
      let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
      setError([msg]);
      setTimeout(() => {
        setError([]);
      }, 3000);
    }
    setLoadingText('Synchronising batch data...');
    DataGetApi(config.BASEURL + "updateBatchInfo", {'companyId': companyId,locationId: locationPaydetails ? locationPaydetails._id : '',
    locationMid:  locationPaydetails ? locationPaydetails.locationMid : '' }, token, paytraceCredentials)
      .then((res) => {
        setLoadingText('Loading your content...');
        if (typeof res.data !== "undefined" && res.data.success) {
          getLastUpdatedBatchDate();
          getBatchRecord();
        } else if (res.hasOwnProperty('response') &&
          typeof res.response.status !== "undefined" &&
          res.response.status === 429
        ) {
          // setError([config.RATE_LIMITER_MESSAGES]);
          setTimeout(() => {
            setError([]);
          }, 3000);
        }else{
          getLastUpdatedBatchDate();
          getBatchRecord();
          // getLocationData();
          // setError(res.data.messages);
          setTimeout(() => {
            setError([]);
          }, 3000);
        }
        setLoading(false)   
        setButtonLoader(false);  
      })
      .catch((error) => {
        setLoading(false) 
        setButtonLoader(false);
      });
  };

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    selectableRows: false,  
    download: true,
    downloadOptions: { 
      filename: "Batchdata.csv",
      filterOptions:{
        useDisplayedColumnsOnly: true
      }  
    } 
  };

  const columns = [
    {
      name: "Batch No.",     
      options: {
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip title="View batch">
              <span
                className={classes.Underline}
                onClick={() => history.push("/batch/" + value + "/" + tableMeta.tableData[tableMeta.rowIndex][10] + "/" + tableMeta.tableData[tableMeta.rowIndex][9]  )}                
              >
                {value}
                {/* {console.log(" <<<<<<<<<<<<<<<<<<<<< tableMeta >>>>>>>>>>>>>>>>>>>>>>>> ", tableMeta)} */}
              </span>
            </Tooltip>
          );
        },
      },
    },
    "Location",
    "TXN Count",
    "Net Amt",
    "Sales Count",
    "Sales Amt",
    "Refund Count",
    "Refund Amt",
    "Created At",
    // "Batch Close Date",
    // "Batch Status",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBatchRecord = () => {
    setLoading(true);
    setLoadingText('Loading your content...');
    setError([]);    
    let user = JSON.parse(localStorage.getItem("user")); 
    let companyId = user.company._id ? user.company._id : "";
    let param = {
      fromDate: fromDate,
      toDate: toDate,
      companyId:companyId,
      locationId: locationSelectedwithmid.map( item => item._id ),
      locationMid: locationSelectedwithmid.map( item => item.locationMid ), 
    };
    // console.log(param, " param ");
    props
      .getBatchData(param)
      .then((response) => {        
        let result = response.payload.batchData;
        if (result.status == 200) {
          // console.log(result, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<< result.data.data.batches", response);
          let dataSet = result.data.data.batches.map((item) => { 
            // console.log(item.number,  "<<item.number,");              
            let dataSet1 = {
              number: item.number,
              // Location: (props.locationData &&props.locationData.length > 0) ?  props.locationData[0].locationName+'('+ props.locationData[0].midType +')' : "",
              Location: item.locationId.locationName + ''+( ( item.locationMid === item.locationId.mId )?'( Traditional )':( ( item.locationMid === item.locationId.mId2 )?'( Surcharge )': +'') )  ,              
              transaction_count: item.transaction_count,
              net_amount: item.net_amount
                ? formatCurrency(item.net_amount)
                : "$0.00",
              sales_count: item.sales_count,
              sales_amount: item.sales_amount ? formatCurrency(item.sales_amount) :"$0.00" ,
              refund_count: item.refund_count,
              refund_amount: item.refund_amount
                ? formatCurrency(item.refund_amount)
                : "$0.00",
              created_at: item.created.at,
              locationMid: item.locationMid,
              locationId: item.locationId._id,
            };
            delete dataSet1.created;
            return Object.values(dataSet1);
          });
          setLoading(false);
          // console.log(dataSet,"dataset  >>>>>>>>>");
          setBatchData(dataSet);
        } else {
          // setError("No transactions were found with these criteria.");
          setTimeout(() => {
            setError([]);
          }, 3000);
        }
        setLoading(false);
      })
      .catch(() => {
        // setError("Some things happen wrong please try again");
        setTimeout(() => {
          setError([]);
        }, 3000);
        setLoading(false);
      });
  };

  const getLastUpdatedBatchDate = () => {
    setLoading(true);
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    let param = {
      fromDate: fromDate,
      toDate: toDate,
      locationId: locationPaydetails._id,
      locationMid: locationPaydetails.locationMid
    };
    localStorage.setItem('batchParams', JSON.stringify(param) );
    // console.log(param, " param ");
    props.getLastUpdatedBatchData(param).then((res) => {
      // console.log(res, "<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>");
      let { data } = res.payload.data;
      if (data.success && !isNull(data.data)) {
        setLastUpdatedDate(new Date(data.data.updatedAt).toLocaleDateString() );
      }
      setLoading(false);
    });
  };

  const getLocationData = () => {    
    let datau = { 'companyId': JSON.parse(localStorage.getItem("user")).company._id }
    let midArray = [];
    props.locationList(datau).then((res) => {
      // this.setState({ data: res.payload.locationData.data.response, isLoading: false })
      midArray = res.payload.locationData.data.response;
      let midArray2 = [];
      let val = {};
      let val1 = {};
      let selectedMid = [];
      for (let x = 0; x < midArray.length; x++) {
        val = midArray[x];
        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
            }
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey2;
            val1.paytracePassword = val.paytracePassword2;
            val1.paytraceUserName = val.paytraceUserName2;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
            }
            midArray2.push(val1);
            val1 = {};
          }
        } else {
          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {
            if (midArray[x].mId) {
              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId";
              val1.address1 = val.address1;
              val1.address2 = val.address2;
              val1.contactName = val.contactName;
              val1.company = val.company;
              val1.city = val.city;
              val1.country = val.country;
              val1.createdAt = val.createdAt;
              val1.email = val.email;
              val1.id = val.id;
              val1.isDeleted = val.isDeleted;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.notes = val.notes;
              val1.paytraceKey = val.paytraceKey;
              val1.paytracePassword = val.paytracePassword;
              val1.paytraceUserName = val.paytraceUserName;
              val1.phone = val.phone;
              val1.state = val.state;
              val1.status = val.status;
              val1.timeZone = val.timeZone;
              val1.zip = val.zip;
              val1._v = val._v;
              if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
                selectedMid.push(val1)
              }
              midArray2.push(val1);
              val1 = {};
            }
          }
        }
      }
      setData(midArray2);
      // setLocationSelectedwithmid(selectedMid);
      // setlocationSelected(selectedMid[0]._id);
      // console.log(locationSelected, "<<<<<<<<<1", selectedMid );
      // console.log(locationSelectedwithmid, "<<<<<<<<<2", selectedMid[0]._id, data);

      // if (midArray2 && midArray2.length > 0) {
      // this.setState({ data: midArray2, isLoading: false });
      // this.setState({ locationSelected: Object.keys(midArray2).map((k, v) => midArray2[k].id), locationSelectedwithmid: [JSON.parse(localStorage.getItem('locationPaydetails'))] });

      // this.setState({
      //   locationsData: midArray2,
      //   isLoading: false,
      // });
      // }
    });
  }  

  // const addLocation = (arr) => {
  //   // let misMatchError = [];
  //   // let allLocationChceked = locationSelected.length === arr.length ? true : false;    
  //   setlocationSelected(arr);
  // }

  const addLocation2 = (arr) => {
    setLocationSelectedwithmid(arr);
    // let allLocationChceked = locationSelectedwithmid.length === arr.length ? true : false;     
    // getBatchRecord();
    setError([]);
    // handleRefreshBatchInfo(); 
  };
   
  return (
    
    <Paper className={classes.root}>
      { (JSON.parse(localStorage.getItem("user")).role !== config.SUPER_ADMIN_ROLE)?
        <Grid className="contentPart" container direction="row" className="navGrid"  style={{ marginBottom: '10px', marginLeft: '2px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth style={{ zIndex:"101" }}>
              <IntegrationDownshift
                isLocationExists={true}
                selectedLocations={locationSelectedwithmid}
                locationData={data}
                // addLocation={addLocation}
                addLocation2={addLocation2}
              />
            </FormControl>
          </Grid>
        </Grid>
        : ''
      }
      <LoadingOverlay active={loading} spinner text={loadingtext}>
        {error.length > 0 ? <ErrorMessage errors={[error]} /> : ""}


        <MuiThemeProvider theme={getDatePickerThemes()}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                InputProps={{ readOnly: true }}
                margin="normal"
                id="from_date-picker-inline"
                label="From Date"
                value={fromDate}
                maxDate={toDate}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="to_date-picker-inline"
                label="To Date"
                value={toDate}
                minDate={fromDate}
                maxDate={toDayDate}
                onChange={handleToDateChange}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <Grid>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  onClick={handleSearchBtn}
                >
                  Search
                </Button>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  color="secondary"
                  onClick={handleClearBtn}
                >
                  Clear
                </Button>
              </Grid>
              <Grid className={classes.updatedRow, 'custom-class'}>
                <label className={classes.labelDate}>
                  Last Updated Date: {lastUpdatedDate}
                </label>
                <Button
                  className={classes.buttonSearch}
                  variant="outlined"
                  color="primary"
                  disabled={isDisabledRefresh}
                  onClick={handleRefreshBatchInfo}
                >
                  {buttonLoader ? <SpinnerLoader {...props} /> : "Refresh"}
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Batch Lists"}
            data={batchData}
            columns={columns}
            options={options}
            classes={'datatables-noprint'}
          />
        </MuiThemeProvider>
      </LoadingOverlay>
    </Paper>
  );
}

function mapStateToProps(state) {
  
  return { batchData: state.BatchReducer };
}

export default connect(
  mapStateToProps,
  { getBatchData, getLastUpdatedBatchData, locationList }
)( BatchReporting );