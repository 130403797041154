import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

class Colorcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    componentDidMount() {

    }

    handleClickOpen = () => {
        this.setState({
            isOpen: true
        })
    }
    
    handleClose = () => {
        this.setState({
            isOpen: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    <InfoIcon />
                </Button>
                <Dialog
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid item xs={12} md={6}>
                                <div className={classes.demo}>
                                    <List>
                                        {this.props.filteredArray.map((item, index) => (
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    <FiberManualRecordIcon style={{ color: Object.values(item) }} />
                                                </ListItemIcon>
                                                <ListItemText>{Object.keys(item)}</ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

const ApplyingStyles = (props) => {
    const classes = useStyles()
    return (<Colorcode classes={classes} {...props} />)
}

export default withRouter(ApplyingStyles, Colorcode)