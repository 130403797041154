const initialState = () => ({
	signup_response: { status: '' },
	email: '',
	exp: '',
	iat: '',
	name: '',
	userId: '',
	invoices:{},
	invoice_detail:{}
});
const UserReducer = (state = initialState, { type, payload }) => {
 
	switch (type) {
		case 'Empty_state':
			return { ...state, [payload.property]: payload.value };
		case 'USERS_DETAILS':
			return { ...state, ...payload.value};
			case 'HASH':
			return { ...state, ...payload.value};
			case 'HISTORY':
			return { ...state, ...payload.value};
		default:
			return state;
	}
};

export { UserReducer };