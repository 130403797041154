import { ActionType } from './../constants';

const initialState = {
  signin: [],
  loading: false,
  error: null,
  msg: null
};


const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.PRODUCT_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionType.PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productInfo: action.payload.productData
      };

    case ActionType.PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        productInfo: action.payload.error
      };

    default:
      return state;
  }
}

export { ProductReducer };
