import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { getNyTaxReports } from '../../actions/RequestFormAction'
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingOverlay from 'react-loading-overlay';
import classes from './NyTaxReports.module.scss'
import { Topheader } from './../../components/Topheader';
import Container from '@material-ui/core/Container';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
let date = new Date();
const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  district: '',
  paymentStatus: 'All',
  recordLimit: 100,
  toDate:new Date(),
  date:new Date(),
  fromDate:new Date (date.setDate(date.getDate() - 30)),
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  error: {

  }
};

const district = [
  { title: "Clymer" },
  { title: "Fredonia" },
  { title: "Ripley" }
]

const paidStatus = [
  { title: 'All' },
  { title: 'Paid' },
  { title: 'Unpaid' },
]
const limit = [
  { title: 100 },
  { title: 500 },
  { title: 1000 },
]
const mapToFormat = (values) => {
  return values.map(Obj => (
    { value: Obj.title, label: Obj.title }
  ))
};

class NyTaxReports extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    
  }

  setDistictValue(val) {
    if (val !== null) {
      this.setState({ district: val.value })
    } else {
      this.setState({ district: '' })
    }
  }
  setPaymentValue(val) {
    if (val !== null) {
      this.setState({ paymentStatus: val.value })
    } else {
      this.setState({ paymentStatus: 'All' })
    }
  }

  setRecordLimitValue(val) {
    if (val !== null) {
      this.setState({ recordLimit: val.value })
    } else {
      this.setState({ recordLimit: 100 })
    }
  }



  handleChange(event, t) {
    let successMessages = false;
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (this.state.createdBy === "") {
      let userData = JSON.parse(localStorage.getItem('user'))
      this.setState({ createdBy: userData._id, companyId: userData.company._id })
    }

    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    this.setState({ isSubmit: true, isLoading: true })
    let getDataArray = {
      district: this.state.district,
      paymentStatus: this.state.paymentStatus,
      recordLimit: this.state.recordLimit,
      fromDate : this.state.fromDate,
      toDate:this.state.toDate
    }
    this.props.getNyTaxReports(getDataArray).then(
      response => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(response.payload.invoiceData.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = new Date().toLocaleString() + 'Ny Reports' + '.dat';
        hiddenElement.click()
        this.setState({ isLoading: false })
      
      }, error => {
        this.setState({ isLoading: false })

        console.log(error)
      }
    )


  }

  handleDateChange = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false
    } else {
      this.setState({ [key]: date }, () => {
        // (key==="toDate")?this.props.toDateFilter(this.state.toDate):this.props.fromDateFilter(this.state.fromDate)
        setTimeout(() => {
          // this.tableRef.current.onQueryChange()
        }, 1000)
      })
    }
  }

  render() {
 
const districtLoc = this.props.UserDetail.locationIds.reduce((acc,curr,i)=>{
  acc.push({'title':curr.locationName})
    return acc;
},[])
    const { t } = this.props
    return (

      <div className="content">

        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <Container component="main" maxWidth="xl" >
            <label className="mt5" > <h2> NY Reports </h2> </label>  <br /> <br />
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[this.props.info.ticketInfo.data.message]} /> : ''
            }

            <form className={classes.form} noValidate>
              {(this.state.update && this.state.ticketNumber !== 0) ? <h3 className=""><span>Ticket Number : {(this.state.ticketNumber) ? this.state.ticketNumber : ''}</span></h3> : ''}
              <Grid container spacing={3} className="ViewTxnFormBox" >
                <Grid item xs={12} sm={4} style={{marginTop:'3rem'}}>
                  <FormControl fullWidth >
                    <TypableSelect
                      placeholder={t("SelectDistrict")}
                      isClearable={true}
                      suggestions={mapToFormat(districtLoc)}
                      onChange={val => this.setDistictValue(val)}
                    />
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={4} >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      autoOk
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline1"
                      maxDateMessage={'From date should not be greater than To date'}
                      maxDate={this.state.toDate}
                      label={'From Date'}
                      value={this.state.fromDate}
                      onChange={(date) => { this.handleDateChange(date, 'fromDate') }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      autoOk
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline1"

                      // maxDate={this.state.toDate.setDate(this.state.toDate.getDate() + 30)}
                      label={'To Date'}
                      value={this.state.toDate}
                      onChange={(date) => { this.handleDateChange(date, 'toDate') }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>

                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>

                <Grid item xs={12} >
                   <Grid item xs={12}> 
                        <Buttons
                          variant="contained"
                          color="primary"
                          text={t("Download")}
                          onClick={(e) => this.handleSubmit(e, t)} />
                     
                    </Grid>
                </Grid>

              </Grid>
            </form>
          </Container>

        </LoadingOverlay>
      </div>

    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    UserDetail: state.AdminUserReducer,
  };
}


export default connect(mapStateToProps, { getNyTaxReports })(withTranslation()(withRouter(NyTaxReports)))