export const cachesFunction = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            if(names && names.length > 0) {
                for (const name of names) {
                    caches.delete(name);
                }
                 // delete browser cache and hard reload
                // window.location.reload(true);
            }
        });
    }
}
