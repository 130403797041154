import React,{Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

class Breadcrumb extends Component{
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      tab: 0,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: '',
      state: '',
      city: '',
      sideShow: true,
      isLoading: true,
    }
  }
  handleClick(event,val) {
    event.preventDefault();
     this.props.history.push('/'+val);
  }
  handleClickTab(event,val) {
    event.preventDefault();
    this.props.handleChangeTab(event,val)
  }
render(){
  const { classes } = this.props
  
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={(e)=>this.handleClick(e,this.props.root)}>
            {this.props.root}
          </Link>
          <Link color="inherit" href="/getting-started/installation/" onClick={(e)=>this.handleClickTab(e,this.props.tab)}>
           {this.props.tabSelected}
          </Link>
          <Link color="inherit" href="/getting-started/installation/" onClick={(e)=>this.handleClickTab(e,this.props.tab)}>
           {(this.props.tabPanel)?this.props.tabPanel:''}
          </Link>
        </Breadcrumbs>
      </Paper> 
      
    </div>
  );
}
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles()
  return (<Breadcrumb classes={classes} {...props} />)
}
export default withRouter(ApplyingStylesOnClasses)

