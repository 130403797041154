import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Pages.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';

class Pages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
    }
  }

  handleChangeId(id) {
    this.props.history.push('/AddPages/?content=' + id)
  }
  tableReload(status) {
    this.setState({ reload: status })
  }
  getTable(query) {
    if (this.state.reload) {
      query.search = ""
      this.setState({ reload: false })
    }
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search == "undefined" || query.search == "") ? false : true
      };
      DataGetApi(url + 'contentList', param, token).then(result => {
      
        resolve({
          data: (typeof query.search === undefined || query.search === "") ? result.data.response : result.data.response.filter((row => {
            return (row.userName.toLowerCase().indexOf(String(query.search)) !== -1 || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) != -1 || row.email.toLowerCase().indexOf(String(query.search)) !== -1)
          })),
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
      }).catch(error => {
        console.log('fetching clients error : ', error);
        // toast.error('There was an error trying to fetch clients');
        reject(error);
      })
    })
  }


  render() {
    const { ContentData } = this.state;

    const columns = [
      { title: 'Page Name', field: 'pageName', render: rowData => <Tooltip title="View Page"><span className={classes.Underline} onClick={(e) => this.handleChangeId(rowData._id)}  >{rowData.pageName}</span></Tooltip> },
      { title: 'Page Title', field: 'pageTitle' },
      { title: 'Date', field: 'createdDate', render: (date) => (new Date(date.createdDate).toLocaleString()) },];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true
              }}
              columns={columns}
              createNew={false}
              data={(query) => this.getTable(query)}
              title={'Pages'}
              search={true}
              exports={true}
              paging={true}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.ContentReducer
  };
}

export default connect(mapStateToProps, {  })(withTranslation()(Pages));