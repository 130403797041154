
const isNumber = (val) => {
    if (/^[0-9]*$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}

const isTwoDecimalPlace = (val) => {
    if (/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const isTwoDecimalPlaceSvc = (val) => {
    if (/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}

const checkEmail = (val) => {
    //let  misMatchError = [];
    if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
        return true;
    } else {
       return false;
    }
}
const checkSecretKey = (val) => {
    /* eslint-disable */
    if (/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/.test(val)) {
        return true;
    } else {
        return false;
    }
}

const isPhone = (val) => {
    if (/^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const isZip = (val) => {
    if (/^\d{5}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const isPayerName = (val) => {
    if (/^[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]{1,20}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const isYearRange = (val) => {
    if (/^(19[5-9]\d|20[0-4]\d|2050)$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const isDescription = (val) => {
    if (/^.{0,100}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}
const checkName = (val) => {
    if (/^.{0,20}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}

const checkInvoice = (val) => {
    if (/^.{0,40}$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}

const isNumberWithOutZero = (val) => {
    if (/^[1-9][0-9]*$/g.test(val)) {
        return true;
    } else {
        return false;
    }
}

export {checkInvoice, checkName, isDescription, isPhone, isZip, checkEmail, checkSecretKey, isNumber, isPayerName, isTwoDecimalPlace, isTwoDecimalPlaceSvc, isYearRange, isNumberWithOutZero } 