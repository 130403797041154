import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";
import { Topheader } from './../../components/Topheader';
import classes from './Terminal.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import spacing from '@material-ui/core/Grid'; 
import { UserDetailById, settingDataByCompanyId, settingDataByCompanyIdmId, fetchDetails } from '../../actions/AdminUserAction';
import { TransactionDetailsId, TransactionDetailById, refundAuthManual, voidTransaction, getRefundedDailyData, refundAuth, getTransactionsDataForRefund, getCsrfToken, getTransactionListTodayData, getInvoiceNumber, InvoiceDetailById, getPendingStatusInvoiceList } from '../../actions/PaymentAction'
import { countryDataList } from '../../actions/CompanyAction'
import { customerDetailsByCompany, customerDetailsByCompanyCustom } from '../../actions/CustomerAction'
import { updateInvoice, emailInvoice } from '../../actions/RequestFormAction'
import { Buttons } from './../../components/Buttons';
import { Textfield } from './../../components/Textfield';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import config from './../../constants/config'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardActions, Collapse, IconButton } from '@material-ui/core';
import MaskedInput from 'react-maskedinput'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography';
import { Checkbox } from '@material-ui/core';
import { checkCreditOrDebit } from '../../helpers/PostDataApi';
import validator from '../../helpers/checkValidations';
import TypableSelectDetails from './../../helpers/TypableSelectDetails';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from '@material-ui/core/Tooltip';
import { DialogBox } from './../../components/DialogBox/DialogBox'
import AdjustSettings from './AdjustSettings'
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { doesFileExist, formatAmount } from '../../helpers/commonFunction'
import  moment  from 'moment'


import { formatCurrency } from '../../helpers/commonFunction'
// import { DebounceInput } from 'react-debounce-input';
// import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable'
import _, { debounce, find, forOwn, isEmpty } from 'lodash';
import binlookup from "binlookup";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Notification from '../../components/Notifications/Notification';
import { changeLanguage } from 'i18next';
import { cachesFunction } from "../../utils/cache";


const credit_or_debit = binlookup();
const mapToFormat = (values) => {
  return values.map(Obj => (
    { value: Obj._id, label: (Obj.firstName && Obj.lastName) ? Obj.firstName + ' ' + Obj.lastName + ' ' + (Obj.customerCompanyName ? Obj.customerCompanyName : "") + ' - ' + (Obj.customer_id ? Obj.customer_id : "") : Obj.name ? Obj.name + ' - ' + (Obj.customer_id ? Obj.customer_id : "") : "- " + Obj.customer_id }

  ))

};

const getremainingTotalAmount = (currentObj, attr) => {
  //if(currentObj.props.UserDetail.settings.isMultiPayInvoice  == undefined) return 
  //filter( i =>  currentObj.props.UserDetail.settings.isMultiPayInvoice ==false &&   i.STATUS  !==  "Partial Paid")
  return attr.filter(i => (currentObj.props.UserDetail.settings.isMultiPayInvoice == true) ? true : i.STATUS !== "Partial Paid").map(i => {
    let sumPartialAmount = i.partialPayments.reduce((a, b) => a + b.amount, 0);
    let getTotalAmount = (i.totalAmount) ? i.totalAmount : i.amount;
    return {
      ...i, ...{
        remainingTotalAmount: parseFloat(getTotalAmount - sumPartialAmount)
      }
    }
  }
  )
}

const mapToFormatInvoice = (currentObj, values) => {
  return getremainingTotalAmount(currentObj, values).filter(i => i.remainingTotalAmount > 0).map(Obj => (
    {
      value: Obj.invoice,
      label:
        <div>
          <div>{Obj.invoice} - {(Obj.isPaid) ? (Obj.isPaid && Obj.isPartialPayment) ? "Partial Paid" : 'Paid' : 'Pending'} - {(Obj.customerId && Obj.customerId.firstName) ? Obj.customerId.firstName + ' ' + Obj.customerId.lastName : Obj.NAME1} - {Obj.remainingTotalAmount ? '$' + Obj.remainingTotalAmount.toFixed(2) : '$0.00'}</div>
        </div>
    }
  ))
};

const mapToFormatPendingTransaction = (values) => {

  var arrValueSum = [];
  let sum = 0.00;
  values.forEach((item, index) => {
    if (item.REFUNDED.length > 0) {
      item.REFUNDED.forEach((ritem, idex) => {
        sum = sum + parseFloat(ritem.amt);
      }).push({ 'TXNID': item.TXNID, "totalRefund": sum })
      sum = 0.00;
    }
  })

  return values.filter(items => {
    let indexofvalue = _.findIndex(arrValueSum, { TXNID: items.TXNID });
    if (indexofvalue !== -1) {
      return arrValueSum[indexofvalue].totalRefund < items.AMOUNT
    } else {
      return true;
    }
  }).map(Obj => (
    {
      value: Obj.TXNID,
      amount: Obj.AMOUNT,
      label:
        <div>
          <p>{Obj.TXNID} - {(Obj.status_code === 'P') ? 'Pending' : 'Settled'} - {(Obj.CUSTOMERDATA.firstName || Obj.CUSTOMERDATA.lastName) ? Obj.CUSTOMERDATA.firstName + ' ' + Obj.CUSTOMERDATA.lastName : ''} -
            {_.findIndex(arrValueSum, { TXNID: Obj.TXNID }) !== -1 && arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund !== "undefined" ? "$" + (parseFloat(Obj.AMOUNT) - parseFloat(arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund)).toFixed(2) : "$" + Obj.AMOUNT.toFixed(2)}
          </p>
        </div>
    }
  ))
};
const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};




const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const IOSSwitchRed = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#FF0000',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#FF0000',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Terminal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userSettings: false,
      isPartialPayment: false,
      isChange: false,
      isVoidBtn: false,
      functionToCallInTopHeader: null,
      totalAmountValue: 0,
      redirectTransaction: false,
      redirectDashboard: false,
      isPaid: false,
      isCredit: false,
      isRefund: false,
      isPreAuth: false,
      isInvoice: true,
      isNewCustomer: false,
      cardPanelShow: true,
      mask_number: '',
      americanExpress: false,
      customer_id: '',
      isLoading: false,
      isOpen: false,
      amount: '',
      email: '',
      total: 0.00,
      svcFee: 0.00,
      surchargeFee: 0.00,
      tax: 0.00,
      taxType: '%',
      svcType: '%',
      surchargeType: '%',
      invoice: '',
      notes: "",
      createdBy: '',
      companyId: '',
      discountType: 1,
      discount: 0,
      // zip: 0,
      // zipErrorMessage:'',
      isSubmit: false,
      addNewCard: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      InvoicePendingData: [],
      RefundPendingData: [],
      TransactionData: [],
      TransactionRefundData: [],
      successMessages: false,
      discountErr: false,
      updateCard: false,
      tabValue: 0,
      indexValue: 0,
      customerAccountNumber: '',
      userDetails: JSON.parse(localStorage.getItem('user')),
      locDetails: JSON.parse(localStorage.getItem('locationPaydetails')),
      customerDetails: { '_id': '', 'firstName': '', 'lastName': '' },
      userData: { permissions: {} },
      paytraceCredentials: {},
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      feeSettings: { InvoiceDiscount: false, InvoiceTax: false, IncoiceSurcharge: false },
      ach: {
        isOpen: false,
        accountName: '',
        accountNumber: '',
        routingNumber: '',
      },
      transactionDetails: { TXNID: '' },
      manualRefund: { amount: '' },
      errorAch: {
        accountNameError: false,
        accountNumberError: false,
        routingNumberError: false,
      },
      card: {
        isOpen: false,
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      errorManualRefund: {
        amountError: false,
      },
      error: {
        amountError: false,
        emailError: false,
        svcFeeError: false,
        surchargeError: false,
        zipError: false,
        customerAccountNumberError: false
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      },
      limit: {
        perTransactionLimit: false,
        dailyTransactionLimit: false,
        perRefundLimit: false,
        dailyRefundLimit: false,
      },
      refundedSumValues: [],
      cardType: '',
      isDebitCard: false,
      mId: '',
      mIdType: '',
      showAlert: '',
    }
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
  }

  async componentDidMount() {
    await this.dataLoad();
    cachesFunction();
    if(this.state.userDetails.permissions.creditSale === false && this.state.userDetails.permissions.preAuthorize === false && this.state.userDetails.permissions.refunds === false && this.state.userDetails.permissions.refundsManual === false){
       alert("Your not authorize to use virtual terminal.");
       this.setState({redirectDashboard: true});
    }else if(this.state.userDetails.permissions.creditSale){
      this.setState({isCredit: true});
    }else if(this.state.userDetails.permissions.refunds){
      this.setState({isRefund: true});
    }else if(this.state.userDetails.permissions.preAuthorize){
      this.setState({isPreAuth: true});
    }
  }
  async dataLoad() {
    let terminalData;
    if (localStorage.getItem('terminalData') != '') {
      terminalData = JSON.parse(localStorage.getItem('terminalData'));
      if (terminalData != null && terminalData != '') {
        if (this.state.locationId === JSON.parse(localStorage.getItem('locationPaydetails'))._id) {
          this.setState({ ...terminalData, total: 0.00 });
        } else {
          this.setState({
            ...terminalData,
            ...{
              total: 0.00,
              customerAccountNumber: '',
              customerDetails: { '_id': '', 'firstName': '', 'lastName': '' },
              cardType: '',
              isDebitCard: false,
              card: {
                isOpen: false,
                cardHolderName: '',
                cardNumber: '_______________',
                cardExpiryDate: '__/____',
                cardCsc: '___',
                customerToken: false,
                customerTokenUpdate: false,
              },
              errorCard: {
                cardNumber: false,
                cardHolderName: false,
                cardExpiryDate: false,
                cardCsc: false
              },
              email: '',
              zip: '',
              isNewCustomer: false,
              cardPanelShow: true,
              mask_number: '',
              americanExpress: false,
              customer_id: '',
              CoustmerData: [],
              CardData: [],
              name: ''
            }
          });
        }
        localStorage.setItem('terminalData', '');
        this.setState({
          userDetails: JSON.parse(localStorage.getItem('user')),
          locDetails: JSON.parse(localStorage.getItem('locationPaydetails')),
          showAlert: ''
        });
      }
      let locwithmid2 = this.state.locDetails;
      if ((/\d/.test(this.state.card.cardNumber) === true || this.state.mask_number !== "")) {
        if (locwithmid2.midType === config.MID_TRADITIONAL && this.state.isDebitCard === false) {
          this.setState({ showAlert: true });
          setTimeout(() => {
            this.setState({ showAlert: false });
          }, 30000);
        } else if (locwithmid2.midType === config.MID_SURCHARGE && this.state.isDebitCard === true) {
          this.setState({ showAlert: false });
          this.confirm_switch_loc("TERMINAL SWITCH", "This terminal is not authorized to get payment from a debit card. You have to switch to the Traditional terminal.", "Click here to switch to the Traditional Terminal.");
        }
      }
    } else {
      this.setState({ amount: 0 });
    }
    this.setState({ feeSettings: { InvoiceTax: false, InvoiceDiscount: false, InvoiceSurcharge: false, DiscountFeeName: '', SurchargeFeeName: '', InvoiceSalesTaxFee: 0, InvoiceSVCFee: 0, surchargeFee: 0 } });
    let user = JSON.parse(localStorage.getItem('user'));
    let misMatchError = [];
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationPaydetails) {
      this.setState({ misMatchError });   // use to rmove location error display 
      if (locationPaydetails.midType === config.MID_TRADITIONAL) {
        this.setState({ mIdType: locationPaydetails.midType, mId: locationPaydetails.mId });
      } else if (locationPaydetails.midType === config.MID_SURCHARGE) {
        this.setState({ mIdType: locationPaydetails.midType, mId: locationPaydetails.mId2 });
      } if (locationPaydetails.midType == "") {
        this.setState({ mIdType: '', mId: locationPaydetails.mId });
      }
      await this.settingDataByCompanyIdmId();
      setTimeout(() => {
        const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword }
        this.setState({ 'paytraceCredentials': paytraceCredentials, 'locationId': locationPaydetails._id }, () => {
          let file = doesFileExist(config.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey)
          if (window.paytrace && file) {
            let res = window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
          } else {
            let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
            misMatchError.push(msg)
            this.setState({ misMatchError });
          }
        });
      }, 3000);
    } else {
      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
    }
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
      locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let data = fetchDetails({ id: user._id, 'locationId': locationId });
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS") {
        this.setState({ userData: { ...res.payload.userData } }, () => {
          if (this.state.userData.permissions && !this.state.userData.permissions.virtualTerminal) {
            alert('Access denied!');
            this.props.history.push('/Dashboard');
          }
        })
      }
    });
    this.getCustomerList();
    this.getCsrfTokens();
    this.getInvoicePendingList();
    // this.getInvoiceData();
    this.getTransactionListDailyData();
    this.getTransactionsDataForRefund();
    this.getRefundedDailyData();
    this.getTransactionIdData();
    // this.settingDataByCompanyId();
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id });
  }

  confirm_switch_loc = (title, msg, label1, e) => {
    confirmAlert({
      title: title,
      message: msg,
      buttons: [
        (this.state.locDetails.mId2 != '' || this.state.locDetails.mId != '') ? {
          label: label1,
          onClick: () => this.hitLoc(e)
        } : ''
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypressEscape: () => { },
    });
  };

  async hitLoc(e) {
    let curloc = this.state.locDetails;
    let loclist = this.state.userDetails;
    let dataloc = {};
    let indexloc = 0;
    this.setState({ isLoading: true });
    this.setState({ showAlert: false });
    if (curloc.midType == config.MID_SURCHARGE) {
      dataloc = await loclist.locationSelectedwithmid.filter((item) => ((item._id === curloc._id) && (item.midType === config.MID_TRADITIONAL)));
      indexloc = await loclist.locationSelectedwithmid.findIndex((item) => ((item._id === curloc._id) && (item.midType === config.MID_SURCHARGE)));
    } else if (curloc.midType == config.MID_TRADITIONAL) {
      dataloc = await loclist.locationSelectedwithmid.filter((item) => ((item._id === curloc._id) && (item.midType === config.MID_SURCHARGE)));
      indexloc = await loclist.locationSelectedwithmid.findIndex((item) => ((item._id === curloc._id) && (item.midType === config.MID_SURCHARGE)));
    }
    if (dataloc[0]) {
      localStorage.setItem('terminalData', JSON.stringify(this.state));
      localStorage.setItem("binLook", JSON.stringify({ index: indexloc, item: dataloc[0] }))
      this.state.functionToCallInTopHeader();
      this.setState({ isLoading: false });
      // this.dataLoad();
    } else {
      alert("Your not authorized to switch " + curloc.midType + " terminal");
      window.location.reload();
    }
  }

  getCardDebitCredit(cardNo = null) {
    var cardno = cardNo !== null ? cardNo.substring(0, 6) : this.state.card.cardNumber.replaceAll("_", "") ? this.state.card.cardNumber.replaceAll("_", "") : null;
    if (cardno !== null && cardno.length > 5) {
      checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardno).then(card => {

        if (typeof card.data.type !== "undefined") {
  
          let isDebitCard = (card.data.type && card.data.type === "DEBIT") ? true : false;
          this.setState({ isDebitCard: isDebitCard });
          let locwithmid2 = this.state.locDetails;
          if (locwithmid2.midType === config.MID_TRADITIONAL && isDebitCard === false) {
            this.setState({ showAlert: true });
            setTimeout(() => {
              this.setState({ showAlert: false });
            }, 30000);
          } else if ((locwithmid2.midType === config.MID_SURCHARGE) && isDebitCard === true) {
            this.setState({ showAlert: false });
            this.confirm_switch_loc("TERMINAL SWITCH", "This terminal is not authorized to get payment from a debit card. You have to switch to the Traditional terminal.", "Click here to switch to the Traditional Terminal.");
          } else {
            this.setState({ showAlert: false });
          }

          this.setState({ isDebitCard: isDebitCard }, () => {
            if (this.state.amount > 0) {
              if (this.state.feeSettings.InvoiceDiscount) {
                let svcFee = parseFloat(this.state.svcFee);
                if (this.state.isDebitCard) {
                  svcFee = 0;
                  this.setState({ svcFee: svcFee.toFixed(2) });
                } else {
                  if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                    this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
                      this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee });
                    })
                  } else {
                    svcFee = parseFloat(this.state.feeSettings.InvoiceSVCFee / 100) * parseFloat(this.state.amount);
                    this.setState({ svcFee: svcFee.toFixed(2) });
                  }
                }
                this.setState({ total: (parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(svcFee)).toFixed(2) });
              }

              if (this.state.feeSettings.InvoiceSurcharge) {
                let surcharge = parseFloat(this.state.surchargeFee);
                if (this.state.isDebitCard) {
                  surcharge = 0;
                  this.setState({ surchargeFee: surcharge.toFixed(2) });
                } else {
                  if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
                    this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType, surchargeFee: this.state.feeSettings.InvoiceSurchargeFee }, () => {
                      this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSurchargeFee });
                    })
                  } else {
                    surcharge = parseFloat(this.state.feeSettings.InvoiceSurchargeFee / 100) * parseFloat(this.state.amount);
                    this.setState({ surchargeFee: surcharge.toFixed(2) });
                  }
                }
                this.setState({ total: parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(surcharge.toFixed(2)) });
              }
            }
          });
          return;
        } else {
          // alert("Enter valid debit or credit card.");
          // this.setState({ card: { ...this.state.card, cardNumber: '_________________' } });
          return;
        }
      }).catch(e => {
        // console.log("error",e)
      });
    } else {
      this.setState({ showAlert: false });
    }
  }

  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    })
  }

  HandleChangedTotal = (event, t) => {
    let { id, value } = event.target;
    this.setState({ transactionDetails: { ...this.state.transactionDetails, AMOUNT: value } });
  }

  handleCreate = (inputValue) => {

    this.setState({ selectLabel: inputValue + this.state.dash })
  }

  settingDataByCompanyId() {
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
      locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let param = {
      id: this.state.userDetails.company.id ? this.state.userDetails.company.id : null,
      userId: this.state.userDetails._id ? this.state.userDetails._id : null,
      locationId: locationId
    };
    this.props.settingDataByCompanyId(param).then((res) => {
      this.setState({ isLoading: true })
      if (res.payload.error) {
        //svc disabled
        this.setState({ feeSettings: { InvoiceDiscount: false, InvoiceTax: false, InvoiceSurcharge: false, DiscountFeeName: '', SurchargeFeeName: '' } }, () => {
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ feeSettings: { ...(res.payload.userData.data) ? res.payload.userData.data.response : {} } }, () => {
          if (this.state.amount > 0) {
            if (this.state.feeSettings.InvoiceTax) {
              if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
                this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                });
              } else {
                this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * this.state.amount, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                })
              }
            }

            if (this.state.feeSettings.InvoiceDiscount) {
              if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee });
                })
              } else {
                let svc = 0.0;
                let svcVal = 0.0;
                svc = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount;
                svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2));
                this.setState({ svcFee: svcVal }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
                })
              }
            }

            if (this.state.feeSettings.InvoiceSurcharge) {
              if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
                this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType, surchargeFee: this.state.feeSettings.InvoiceSurchargeFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSurchargeFee });
                })
              } else {
                let sch = 0.0;
                let schVal = 0.0;
                sch = (this.state.feeSettings.InvoiceSurchargeFee / 100) * this.state.amount;
                schVal = parseFloat((Math.round(sch * 100) / 100).toFixed(2));
                this.setState({ surchargeFee: schVal }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.surchargeFee) });
                })
              }
            }
          }
          this.setState({ isLoading: false });
        })
      }

    })
  }

  async settingDataByCompanyIdmId() {
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
      locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let param = {
      id: this.state.userDetails.company.id ? this.state.userDetails.company.id : null,
      userId: this.state.userDetails._id ? this.state.userDetails._id : null,
      locationId: this.state.locDetails._id,
      midType: this.state.locDetails.midType ? this.state.locDetails.midType : ''
    };
    this.setState({ tax: 0, surchargeFee: 0, svcFee: 0 });
    this.props.settingDataByCompanyIdmId(param).then((res) => {
      this.setState({ isLoading: true })
      if (res.payload.error) {
        //svc disabled
        this.setState({ feeSettings: { InvoiceDiscount: false, InvoiceTax: false, InvoiceSurcharge: false, DiscountFeeName: '', SurchargeFeeName: '', InvoiceSalesTaxFee: 0, InvoiceSVCFee: 0, InvoiceSurchargeFee: 0 } }, () => {
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ ...this.state, ...{ userSettings: res.payload.userData.data } })
        this.setState({ feeSettings: { ...(res.payload.userData.data) ? res.payload.userData.data.response : {} } }, () => {
          if (this.state.amount > 0) {
            this.setState({ total: parseFloat(this.state.amount) });
            if (this.state.feeSettings.InvoiceTax) {
              if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
                this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                });
              } else {
                this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * this.state.amount, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                })
              }
            }
            if (this.state.feeSettings.InvoiceDiscount) {
              if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee });
                })
              } else {
                let svc = 0.0;
                let svcVal = 0.0;
                svc = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount;
                svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2));
                this.setState({ svcFee: svcVal }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
                })
              }
            }
            if (this.state.feeSettings.InvoiceSurcharge) {
              if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
                this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType, surchargeFee: this.state.feeSettings.InvoiceSurchargeFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSurchargeFee });
                })
              } else {
                let sch = 0.0;
                let schVal = 0.0;
                sch = (this.state.feeSettings.InvoiceSurchargeFee / 100) * this.state.amount;
                schVal = parseFloat((Math.round(sch * 100) / 100).toFixed(2));
                this.setState({ surchargeFee: schVal }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.surchargeFee) });
                })
              }
            }
          }
          this.setState({ isLoading: false });
        })
      }
    })
  }

  getTransactionsDataForRefund() {
    let userData = JSON.parse(localStorage.getItem('user'));
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
      locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let midType = '';
    if (localStorage.getItem('locationPaydetails') !== null) {
      midType = (localStorage.getItem('locationPaydetails') && JSON.parse(localStorage.getItem('locationPaydetails')).midType) ? JSON.parse(localStorage.getItem('locationPaydetails')).midType : '';
    }
    let data = { 'date': new Date(), 'companyId': userData.company ? userData.company._id : '', 'role': userData.role, 'userId': userData._id, 'locationId': locationId, 'midType': midType }
    this.props.getTransactionsDataForRefund(data).then((res) => {

      if (res) {
        this.setState({ RefundPendingData: res.payload.paymentData.data.response });
      }
    })
  }


  getTransactionListDailyData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = { 'date': new Date(), 'companyId': userData.company ? userData.company._id : '', 'role': userData.role, 'userId': userData._id }
    this.props.getTransactionListTodayData(data).then((res) => {
      if (res) {
        this.setState({ TransactionData: res.payload.paymentData.data.response });
      }
    })
  }


  getCustomerList() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.customerDetailsByCompany({ 'companyId': userData.company._id }).then((res) => {
      if (res.payload.customerData.data.statusCode === 200) {
        this.setState({ CoustmerData: res.payload.customerData.data.response });
      }
    })
  }

  getCustomerListCoustmize = async (name) => {
    if (name.length > 3) {
      this.setState({ isLoading: true });
      let customerData = [];
      let userData = JSON.parse(localStorage.getItem('user'));
      customerData = await this.props.customerDetailsByCompanyCustom({ 'companyId': userData.company._id, 'name': name }).then((res) => {
        if (res.payload.customerData.data.statusCode === 200) {
          this.setState({ CoustmerData: res.payload.customerData.data.response, isLoading: false }, () => {
            return mapToFormat(this.state.CoustmerData);
          })
        }
      })
      return customerData;
    }
  }


  // getInvoiceData() {
  //   let invoice = this.props.location.search.split('?');
  //   const params = { 'id': invoice[1] };
  //   this.setState({ isLoading: true });
  //   this.props.InvoiceDetailById(params).then((res) => {
  //     if (res.payload.paymentData.data.statusCode === 200) {
  //       this.setState({ ...res.payload.paymentData.data.response, isLoading: false }, () => {
  //         this.setState({ email: this.state.customerId.email });
  //         this.setState({ customerDetails: { ...res.payload.paymentData.data.response.customerId } }, () => {
  //         })
  //       })
  //       let CustomerId = res.payload.paymentData.data.response ? res.payload.paymentData.data.response.customerId._id : "";
  //       let val = { 'value': CustomerId };
  //       this.setClientValue(val);
  //     }
  //   });
  // }

  getTransactionIdData() {
    let transactionId = this.props.location.search.split('?');
    const params = { 'id': transactionId[1] };
    this.setState({ isLoading: true });
    if (params.id !== undefined) {
      this.props.TransactionDetailById(params).then((res) => {
        if (res.payload.paymentData.data.statusCode === 200) {
          this.setState({ ...res.payload.paymentData.data.response }, () => {
            let SURCHARGE_FEE = parseFloat(this.state.SURCHARGE_FEE) > 0 ? this.state.SURCHARGE_FEE : 0.00
            let SVC_FEE = parseFloat(this.state.SVC_FEE) > 0 ? this.state.SVC_FEE : 0.00
            let TAX = parseFloat(this.state.TAX) > 0 ? this.state.TAX : 0.00
            let totalTax = parseFloat(SURCHARGE_FEE) + parseFloat(SVC_FEE) + parseFloat(TAX)
            let baseAmt = parseFloat(this.state.AMOUNT) - parseFloat(totalTax)

            this.setState({ amount: parseFloat(baseAmt).toFixed(2) });
            let customer = (res.payload.paymentData.data.response.CUSTOMERDATA && res.payload.paymentData.data.response.CUSTOMERDATA.length > 0) ? res.payload.paymentData.data.response.CUSTOMERDATA._id : ""
            this.setState({ customerDetails: { ...customer } }, () => {
            })
          })
          let CustomerId = _.isEmpty(res.payload.paymentData.data.response.CUSTOMERDATA) ? "" : res.payload.paymentData.data.response.CUSTOMERDATA._id;
          let val = { 'value': CustomerId };
          this.setState({ isPreAuth: true, isRefund: false, isCredit: false });
          this.setClientValue(val);
        }
      });
    }
  }


  getInvoicePendingList() {
    let userData = JSON.parse(localStorage.getItem('user'))
    this.props.getPendingStatusInvoiceList({ 'companyId': userData.company._id }).then((res) => {
      this.setState({ InvoicePendingData: res.payload.paymentData.data.response });
    })
  }


  getUniqueInvoiceNumber() {
    this.setState({ isLoading: true })
    this.props.getInvoiceNumber().then((res) => {
      this.setState({ invoice: res.payload.paymentData.data.invoice, isLoading: false })
    })
  }


  getRefundedDailyData() {
    let userData = this.state.userDetails;
    let data = { 'date': new Date(), 'companyId': userData.company ? userData.company._id : '', 'role': userData.role, 'userId': userData._id }
    this.props.getRefundedDailyData(data).then((res) => {
      if (res) {
        this.setState({ TransactionRefundData: res.payload.paymentData.data.response, updatedBy: userData._id })
      }
    })
  }


  handleChange(event, t) {
    // const {error} = {...this.state}
    // const CurrentError =error;
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (id === 'zip' && value.toString().length === 6) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
      this.setState({ [id]: value, error, misMatchError, isSubmit: false });

    if (!this.state.feeSettings.InvoiceTax && !this.state.feeSettings.InvoiceDiscount && !this.state.feeSettings.InvoiceSurcharge && id === "amount") {
      this.setState({ total: parseFloat(value).toFixed(2) });
    } else if (id === "amount") {
      if (this.state.feeSettings.InvoiceTax) {
        if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
          this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax)).toFixed(2) });
          })
        } else {
          let tax = (this.state.feeSettings.InvoiceSalesTaxFee / 100) * value;
          let taxval = parseFloat((Math.round(tax * 100) / 100).toFixed(2));
          this.setState({ tax: parseFloat(taxval), taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax)).toFixed(2) });
          })
        }
      }

      if (this.state.feeSettings.InvoiceDiscount) {
        if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
          this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
            let SVCFee = parseFloat(this.state.feeSettings.InvoiceSVCFee);
            if (this.state.isDebitCard) {
              SVCFee = 0;
              this.state.svcFee = 0;
            }
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(SVCFee)).toFixed(2) });
          })
        } else {
          let svc = (this.state.feeSettings.InvoiceSVCFee / 100) * value;
          let svcVal = parseFloat((Math.round(svc * 100) / 100).toFixed(2));
          this.setState({ svcFee: svcVal }, () => {
            let svcFee = parseFloat(this.state.svcFee);
            if (this.state.isDebitCard) {
              svcFee = 0;
              this.state.svcFee = 0;
            }
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(svcFee)).toFixed(2) });
          })
        }
      }

      if (this.state.feeSettings.InvoiceSurcharge) {
        if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
          this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType, surchargeFee: this.state.feeSettings.InvoiceSurchargeFee }, () => {
            let Surcharge = parseFloat(this.state.feeSettings.InvoiceSurchargeFee);
            if (this.state.isDebitCard) {
              Surcharge = 0;
              this.state.svcFee = 0;
            }
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(Surcharge)).toFixed(2) });
          })
        } else {
          let sur = (this.state.feeSettings.InvoiceSurchargeFee / 100) * value;
          let surVal = parseFloat((Math.round(sur * 100) / 100).toFixed(2));
          this.setState({ surchargeFee: surVal }, () => {
            let surchargeFee = parseFloat(this.state.surchargeFee);
            if (this.state.isDebitCard) {
              surchargeFee = 0;
              this.state.surchargeFee = 0;
            }
            this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(surchargeFee)).toFixed(2) });
          })
        }
      }
    } else if (id === "svcFee") {
      (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
      this.setState({ svcFee: parseFloat(value) }, () => {
        let svc = (this.state.svcFee === "" || this.state.svcFee === 0) ? 0 : parseFloat(this.state.svcFee);
        this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(svc)).toFixed(2) })
      })
    } else if (id === "surchargeFee") {
      (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
      this.setState({ surchargeFee: value }, () => {
        let sur = (this.state.surchargeFee === "" || this.state.surchargeFee === 0) ? 0 : parseFloat(this.state.surchargeFee);
        this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(sur)).toFixed(2) })
      })
    }
  }

  fetchSettingsData() {
    this.props.UserDetailById({ 'id': this.state.userDetails._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({ ...res.payload.userData.data.settings, isLoading: false }, () => {
          let InitialsvcFee = (this.props.UserDetail.settings.InvoiceSVCFee / 100) * this.state.amount;
          if (this.props.UserDetail.settings.InvoiceDiscount) {
            this.setState({ svcFee: InitialsvcFee, }, () => {
              this.setState({ zip: 0, svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
            });
          } else {
            this.setState({ svcFee: 0, }, () => {
              this.setState({ zip: 0, svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
            });
          }
          let InitialsurchargeFee = (this.props.UserDetail.settings.InvoiceSurchargeFee / 100) * this.state.amount;
          if (this.props.UserDetail.settings.InvoiceSurcharge) {
            this.setState({ surchargeFee: InitialsurchargeFee, }, () => {
              this.setState({ zip: 0, surchargeFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.surchargeFee) });
            });
          } else {
            this.setState({ surchargeFee: 0, }, () => {
              this.setState({ zip: 0, surchargeFee: parseFloat(this.state.surchargeFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.surchargeFee) });
            });
          }
        });
      }
    });
  }

  setClientValueInvoice(val) {
    let misMatchError = [];
    let error = {};
    if (val !== null) {
      this.setState({ isInvoice: true, error, misMatchError });
      this.setState({ invoice: val.value, error: { ...this.state.error, invoiceError: false, amountError: false, emailError: false } });
      const data = getremainingTotalAmount(this, this.state.InvoicePendingData).filter((item) => item.invoice === val.value);

      if (data[0].amount) {
        this.setState({ amount: (data[0].isPartialPayment) ? data[0].remainingTotalAmount : data[0].amount, totalAmount: data[0].totalAmount, remainingTotalAmount: data[0].remainingTotalAmount }, () => {
          this.setState({ customerDetails: { ...data[0].customerId } }, () => {
            let val = { 'value': this.state.customerDetails._id }
            this.setState({ customerDetails: { ...this.state.customerDetails, 'firstName': data[0].customerId.firstName, 'lastName': data[0].customerId.lastName }, email: data[0].customerId.email });
            // this.setClientValue(val)
          })
          if (!this.state.feeSettings.InvoiceTax && !this.state.feeSettings.InvoiceDiscount) {
            this.setState({ total: parseFloat(this.state.amount) });
          }
          else if (this.state.amount > 0) {
            if (this.state.feeSettings.InvoiceTax) {
              if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
                this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                })
              } else {
                this.setState({ tax: (this.state.feeSettings.InvoiceSalesTaxFee / 100) * this.state.amount, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                })
              }
            }

            if (this.state.feeSettings.InvoiceDiscount) {
              if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType }, () => {
                  let InvoiceSVCFee = this.state.feeSettings.InvoiceSVCFee
                  if (this.state.isDebitCard) {
                    InvoiceSVCFee = 0;
                  }
                  this.setState({
                    total: parseFloat(this.state.amount) + this.state.tax + InvoiceSVCFee
                  })
                })
              } else {
                this.setState({ svcFee: (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount }, () => {
                  let svcFee = this.state.svcFee
                  if (this.state.isDebitCard) {
                    this.state.svcFee = 0;
                    svcFee = 0;
                  }
                  this.setState({
                    svcFee: this.state.svcFee.toFixed(2), total: parseFloat(this.state.amount) +
                      this.state.tax +
                      svcFee
                  })
                })
              }
            }

            if (this.state.feeSettings.InvoiceSurcharge) {
              if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
                this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType }, () => {
                  let InvoiceSurcharge = this.state.feeSettings.InvoiceSurchargeFee;
                  if (this.state.isDebitCard) {
                    InvoiceSurcharge = 0;
                  }
                  this.setState({
                    total: parseFloat(this.state.amount) + this.state.tax +
                      InvoiceSurcharge
                  })
                })
              } else {
                this.setState({ surchargeFee: (this.state.feeSettings.InvoiceSurchargeFee / 100) * this.state.amount }, () => {
                  let surFee = this.state.surchargeFee
                  if (this.state.isDebitCard) {
                    this.state.surchargeFee = 0;
                    surFee = 0;
                  }
                  this.setState({
                    surchargeFee: this.state.surchargeFee.toFixed(2), total: parseFloat(this.state.amount) +
                      this.state.tax +
                      surFee
                  })
                })
              }
            }

          }
        })
      }
      this.setState({ isPartialPayment: data.length > 0 ? data[0].isPartialPayment : false })
    } else {
      this.setState({ invoice: '' }, () => {
        this.setState({ customerDetails: { ...this.state.customerDetails, 'firstName': '', 'lastName': '' }, isPartialPayment: false });
      })
    }

  }

  setClientValuePendingTxn(val) {
    if (val !== null) {
      let misMatchError = [];
      let transactionId = { 'id': val.value }
      this.setState({ isLoading: true })
      this.props.TransactionDetailsId(transactionId, this.state.paytraceCredentials).then((res) => {
        let transaction = res.payload.paymentData.data;
        if (transaction.success) {
          this.getTransactionsDataForRefund()
          let oldAmount = transaction.response.hasOwnProperty('REFUNDED') && (transaction.response.REFUNDED.length > 0) ? transaction.response.REFUNDED.reduce((a, b) => { return parseFloat(a) + parseFloat(b.amt) }, 0.00) : 0.00
          this.setState({ ...this.state, transactionDetails: { ...transaction.response, 'updatedBy': this.state.userDetails._id, oldAmount: parseFloat(transaction.response.AMOUNT - parseFloat(oldAmount)).toFixed(2) } }, () => {
            this.setState({ isVoidBtn: true })
            this.totalAmountValue()
          })
          this.setState({ isLoading: false })
        } else {
          misMatchError.push(transaction.message)
          this.setState({ misMatchError, isLoading: false })
        }
      });
    } else {
      this.setState({ ...this.state.transactionDetails, transactionDetails: {} })
    }
  }

  setClientValue = (val, actionMeta = null) => {
    // for removing details onclicking X
    if (actionMeta && actionMeta.action === "clear") {
      this.setState({ email: '', cardType: '', mask_number: '', name: '', customer_id: '', cardPanelShow: false, zip: '', amount: '' })
    }
    if (val === null) {
      return false
    }
    this.setState({ isLoading: true });
    if (actionMeta && actionMeta.action === "create-option") {

      this.setState({ isNewCustomer: true }, () => {
        this.updateNewCustomerDetails(val.value);
      })
    } else {
      this.setState({ isNewCustomer: false }, () => {
        this.setState({ customerDetails: { ...this.state.customerDetails, 'firstName': '', 'lastName': '' } });
      })
      // this.setState({selectLabel : val.label});
    }
    if (val !== null) {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false
      }

      const cardDataInital = {
        isOpen: false,
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false
      }

      setTimeout(() => {
        const data = this.state.CoustmerData.filter((item) => item._id === val.value);
        console.log("data===============get==========>",data)
        this.setState({ isLoading: false });
        //For set mask number
        this.setState({ ach: { ...this.state.ach, isOpen: false }, customerId: val.value, errorCard: { ...errCard }, misMatchError, error: { ...this.state.error }, cardPanelShow: false, addNewCard: false });
        if (data.length > 0 && this.state.isNewCustomer === false) {
          let maskCardNumber = data[0].masked_card_number
          if (maskCardNumber) {
            this.getCardDebitCredit(maskCardNumber)
          }
          let name = data[0].firstName ? data[0].firstName : data[0].lastName ? data[0].lastName : (data[0].firstName && data[0].lastName) ? data[0].firstName + ' ' + data[0].lastName : data[0].name ? data[0].name : " "

          this.setState({ email: data[0].email, cardType: data[0].cardType, mask_number: data[0].masked_card_number, cardPanelShow: true, name: name, customer_id: data[0].customer_id, zip: data[0].zip });
        }

        if (this.state.amount > 0) {
          if (this.state.feeSettings.InvoiceTax) {
            if (this.state.feeSettings.InvoiceSalesTaxFeeType === '$') {
              this.setState({ tax: this.state.feeSettings.InvoiceSalesTaxFee, taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax)).toFixed(2) });
              })
            } else {
              let tax = (this.state.feeSettings.InvoiceSalesTaxFee / 100) * this.state.amount;
              let taxval = parseFloat((Math.round(tax * 100) / 100).toFixed(2));
              this.setState({ tax: parseFloat(taxval), taxType: this.state.feeSettings.InvoiceSalesTaxFeeType }, () => {
                this.setState({ total: parseFloat(parseFloat(this.state.amount) + parseFloat(this.state.tax)).toFixed(2) });
              })
            }
          }
          if (this.state.feeSettings.InvoiceDiscount) {
            let svcFee = parseFloat(this.state.svcFee);
            if (this.state.isDebitCard) {
              svcFee = 0;
              this.setState({ svcFee: svcFee.toFixed(2) });
            } else {
              if (this.state.feeSettings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.state.feeSettings.InvoiceSVCFeeType, svcFee: this.state.feeSettings.InvoiceSVCFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSVCFee });
                })
              } else {
                svcFee = parseFloat(this.state.feeSettings.InvoiceSVCFee / 100) * parseFloat(this.state.amount);
                this.setState({ svcFee: svcFee.toFixed(2) });
              }
            }
            this.setState({ total: (parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(svcFee)).toFixed(2) });
          }
          if (this.state.feeSettings.InvoiceSurcharge) {
            let surcharge = parseFloat(this.state.surchargeFee);
            if (this.state.isDebitCard) {
              surcharge = 0;
              this.setState({ surchargeFee: surcharge.toFixed(2) });
            } else {
              if (this.state.feeSettings.InvoiceSurchargeFeeType === '$') {
                this.setState({ surchargeType: this.state.feeSettings.InvoiceSurchargeFeeType, surchargeFee: this.state.feeSettings.InvoiceSurchargeFee }, () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.feeSettings.InvoiceSurchargeFee });
                })
              } else {
                surcharge = parseFloat(this.state.feeSettings.InvoiceSurchargeFee / 100) * parseFloat(this.state.amount);
                this.setState({ surchargeFee: surcharge.toFixed(2) });
              }
            }
            this.setState({ total: parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(surcharge) });
          }
        }
      }, 2000)
    }
  }

  handleChangeCard(event, t) {
    if (event.charCode === undefined) {
      let pastecardkey = event.clipboardData ? (event.clipboardData || window.clipboardData).getData('text') : '';
      let cardNumber = pastecardkey && pastecardkey.length > 0 ? pastecardkey.substring(0, 6) : pastecardkey
      if (pastecardkey.length > 5) {
        this.setState({ card: { ...this.state.card, cardNumber: pastecardkey } }, () => {
          let cardNo = cardNumber || null;
          if (cardNo.length === 6) {
            this.getCardDebitCredit(cardNo);
          }
        });
      } else {
        this.setState({ showAlert: false });
      }
    } else {
      if (this.state.card.cardNumber && this.state.card.cardNumber.trim().replaceAll("_", "") != '' && this.state.card.cardNumber.length === 6) {
        let cardNo = null;
        this.getCardDebitCredit(cardNo);
      }
    }
    // document.getElementById('cardNumber').value = '_____________________';
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;

    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    let cardNumberValue = value ? value.split("_") : "";
    this.setState({ card: { ...this.state.card, [id]: cardNumberValue[0] }, errorCard: { ...this.state.errorCard, [id]: false }, error, misMatchError, isSubmit: false }, () => {
      let firstNumber = this.state.card.cardNumber;
      if (firstNumber !== undefined) {
        // this.setState({ 'cardType': this.getCardType(firstNumber.charAt(0)) })
        this.setState({ card: { ...this.state.card, customerToken: true, 'cardType': this.getCardType(firstNumber.charAt(0)) } });
        // this.setState({ card: { cardNumber: this.state.card.cardNumber.trim().replaceAll("_", "") } });
        if (firstNumber[0] === "3") {
          this.setState({ americanExpress: true })
        } else {
          this.setState({ americanExpress: false })
        }
      }
    });
    let paste = event.clipboardData ? (event.clipboardData || window.clipboardData).getData('text') : '';
    if (paste.length) {
      if (paste[0] === "3") {
        this.setState({ americanExpress: true })
      } else {
        this.setState({ americanExpress: false })
      }
      // event.preventDefault();
      // document.getElementById('cardNumber').value = '';
      // this.setState({ card: { ...this.state.card, cardNumber: '_________________' } });
    }
  }


  handleChangeAch(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ ach: { ...this.state.ach, [id]: value }, errorCard: { ...this.state.errorCard, [id]: false }, errorAch: { ...this.state.errorAch, [id + 'Error']: false }, error, misMatchError, isSubmit: false });
  }


  handleSubmit(e, t) {
    try {
      e.preventDefault();
      let misMatchError = [];
      let logoError = [];
      let successMessages = false;
      let error = {};
      let errorFlag = false;
      this.setState({ isSubmit: true });
      if (this.state.customerDetails.firstName === "" && (typeof this.state.customerId == "undefined" || this.state.customerId == null)) {
        misMatchError.push('Select client name.')
        this.setState({ misMatchError });
        window.scrollTo(0, 0, 0);
        return false;
      }
      // if ((this.props.UserDetail.settings.isZip === true) && (this.state.zip === "" || this.state.zip === 0)) {
      //   misMatchError.push('Enter the zip code.')
      //   this.setState({ misMatchError, error: { zipError: true } });
      //   window.scrollTo(0, 0, 0)
      //   return false;
      // }
      if (this.state.customerDetails.firstName === "" && (typeof this.state.customerId == "undefined" || this.state.customerId == null)) {
        misMatchError.push('Select client name.')
        this.setState({ misMatchError });
        window.scrollTo(0, 0, 0);
        return false;
      }
      if (this.state.invoice && this.state.invoice.length > 40) {
        misMatchError.push('Invoice number should not be more than 40 character.')
        this.setState({ misMatchError });
        window.scrollTo(0, 0, 0);
        return false;
      }
      if (this.state.email && !(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.email))) {
        misMatchError.push(' Please Enter a valid email address.')
        this.setState({ misMatchError });
        let error = { ...this.state.Error };
        error.emailError = true;
        this.setState({ error });
        window.scrollTo(0, 0, 0);
        return false;
      }
      if (this.state.zip && this.state.zip.length <=4 ) {
        misMatchError.push('Please Enter a Valid Zip Code.')
        this.setState({ misMatchError });
        let error = { ...this.state.Error };
        error.zipError = true;
        this.setState({ error });
        window.scrollTo(0, 0, 0);
        return false;
      }

      if (this.state.timestamp_PAID === "") {
        delete this.state.timestamp_PAID;
      }
      if (this.state.notes === '') {
        delete this.state.notes;
      }
      if (this.state.invoice === '') {
        delete this.state.invoice;
        this.setState({ isInvoice: false });
      }
      if (this.state.email === '' || typeof this.state.email === 'undefined' || this.state.email == null) {
        delete this.state.email
      }

      if (this.state.InvoiceSalesTaxFee == "" || this.state.InvoiceSalesTaxFee === 0) {
        delete this.state.InvoiceSalesTaxFee;
      }
      if (!this.state.svcFee || this.state.svcFee === '') {
        delete this.state.svcFee;
      }
      if (this.state.ORDERID === '') {
        delete this.state.ORDERID;
      }
      if (this.state.ZIP === '') {
        delete this.state.ZIP;
      }
      if (this.state.zip === '') {
        delete this.state.zip;
      }
      if (this.state.showAlert === '') {
        delete this.state.showAlert;
      }
      if (this.state.customerAddress === '') {
        delete this.state.customerAddress;
      }
      if (this.state.firstName === '') {
        delete this.state.firstName;
      }
      if (this.state.lastName === '') {
        delete this.state.lastName;
      }
      if (this.state.customerCity === '') {
        delete this.state.customerCity;
      }
      
      if (this.state.AVSRESPONSE === '') {
        delete this.state.AVSRESPONSE;
      }
      if (this.state.CSCRESPONSE === '') {
        delete this.state.CSCRESPONSE;
      }
      delete this.state.customerAccountNumber;
       

    
      // if(this.state.card.cardNumber.indexOf('_') === -1  && this.state.cardPanelShow === false){
      //   this.setState({cardType: this.getCardType(this.state.card.cardNumber.charAt(0))});         
      // }   
      if (this.state.isPaid || (this.checkMaxTransactionLimit(this.state.total) && this.checkCardValues(this.state))) {
        if (this.state.mask_number === "") {
          delete this.state.mask_number;
        }
        Object.keys(this.state).map((key, value) => {
         
          if (key === 'email' && (this.state.email === "" || typeof this.state.email == 'undefined' || this.state.email == null)) {
            return false;
          }
        
          if (key === "cardType" && this.state.cardType === "") {
           
            return false;
          } else if (key === "name" && (this.state.name === "" || this.state.name === " ")) {
            return false;
          } else if (key === "creditOrDebit" && this.state.creditOrDebit === "") {
            return false;
          } else if (key === "email" && this.state.email === "") {
            return false;
          } else if (key === "transaction_type" && this.state.transaction_type === "") {
            return false;
          } else if (key === "CARDTYPE" && this.state.CARDTYPE === "") {
            return false;
          } else if (key === "ZIP" && this.state.ZIP === "") {
            return false;
          } else if (key === "zip" && (this.state.zip === '' || this.state.zip === null)) {
            return false;
          } else if (key === "customerCompanyName" && (this.state.customerCompanyName === '')) {
            return false
          } else if (key === "phone" && (this.state.phone === '')) {
            return false
          } else if (key === "AVSRESPONSE" && (this.state.AVSRESPONSE === '')) {
            return false;
          } else if (key === "CSCRESPONSE" && (this.state.CSCRESPONSE === '')) {
            return false;
          } else if (key === "APPROVALCODE" && (this.state.APPROVALCODE === '')) {
            return false;
          } else if (key === "pay_mId" && (this.state.pay_mId === '')) {
            return false;
          } else if (!this.state.mId2 && this.state.mIdType === "" && key === 'mIdType') {
            return "";
          } else if ((validator(key, this.state[key]))) {
            return ((validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false));
          } 
          else {
            return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : '';
          }
        });
        if (this.state.paytraceCredentials.paytraceKey) {
       
          let file = doesFileExist(config.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
          if (!file) {
            window.scrollTo(0, 0, 0);
            let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
            misMatchError.push(msg);
            this.setState({ misMatchError });
            errorFlag = true;
          }
        }
        if (!this.state.paytraceCredentials.hasOwnProperty('password')) {
          window.scrollTo(0, 0, 0);
          let msg = 'Select the payment location.';
          misMatchError.push(msg);
          this.setState({ misMatchError });
          errorFlag = true;
        }

        if (errorFlag) {
          console.log("misMatchError, logoError, error, successMessages", misMatchError, logoError, error, successMessages)
          this.setState({ misMatchError, logoError, error, successMessages });
          return false;
        }
         else {
          window.paytrace.submitEncrypted('#DemoFormTerminal');
          let userMaskNumber = this.state.card.cardNumber.replaceAll('_', '') ? this.state.card.cardNumber.substring(0, 6) + '******' + this.state.card.cardNumber.substr(-4) : ''
          this.setState({
            card: { ...this.state.card, cardNumber: '', cardCsc: '', 'userMaskNumber': userMaskNumber ? userMaskNumber : '' },
            isLoading: true,
            encrypted_number: localStorage.getItem('0'),
            encrypted_csc: localStorage.getItem('1')
          }, () => {
     
            delete this.state.InvoicePendingData
            // delete this.state.CoustmerData
            localStorage.removeItem('0');
            localStorage.removeItem('1');
            window.scrollTo(0, 0, 0);
            console.log(this.state, '----state');
            this.props.updateInvoice(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {
              localStorage.setItem('terminalData', '');
              if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
                this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                  setTimeout(() => {
                    this.setState({ successMessages: false });
                    if (this.state.isPaid) {
                      this.setState({ redirectInvoice: true });
                    } else {
                      this.setState({ redirectTransaction: true });
                    }
                  }, 2000);
                });
              }
              if (result !== undefined && result.type === 'INVOICE_ERROR') {
                if (result.payload.error.data) {
                  let message = result.payload.error.data.message;
                  if (message !== "Transaction has been aborted.") {
                    misMatchError.push(message);
                  }

                  if (result.payload.error.data.response.length > 0) {
                    let responseErrors = JSON.parse(result.payload.error.data.response);
                    if (responseErrors.response_code === 1) {
                      for (let key in responseErrors.errors) {
                        let i = 0;
                        misMatchError.push(responseErrors.errors[key][i] + '\n');
                      }
                      // this.setState({ isLoading: false, misMatchError, successMessages });
                    }
                    if (responseErrors.response_code === 1001) {
                      misMatchError.push(responseErrors.status_message);
                    }
                    if (responseErrors.csc_response === "NO Match") {
                      misMatchError.push('This transaction was declined due to "102" "' + responseErrors.approval_message + '"  CSC is incorrect');
                    }
                    if (responseErrors.response_code === 102) {
                      misMatchError.push('This transaction was declined due to "102" "' + responseErrors.approval_message + ' ' + responseErrors.avs_response + '"');
                    }
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }
              }
            });
          });

        }
      }
    } catch (error) {
      console.log("----------Catch error------",error)
    }
  }


  checkMaxTransactionLimit(val) {
    let maxTransactionLimit = (this.props.UserDetail.permissions.perTransactionLimit) ? this.props.UserDetail.permissions.perTransactionLimit : 0
    let maxDailyTransactionLimit = (this.props.UserDetail.permissions.dailyTransactionLimit) ? this.props.UserDetail.permissions.dailyTransactionLimit : 0
    let maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0);
    if (val > (maxDailyTransactionLimit - maxDailyAmount) && maxDailyAmount > 0 && maxDailyTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: false } });
        }, 5000)
        return false;
      });
    }
    else if (val > maxTransactionLimit && maxTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, perTransactionLimit: false } });
        }, 5000)
        return false;
      });
    } else {
      return true;
    }
  }
  

  handleSubmitEmailInvoice(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.name === "" || this.state.mask_number === "" || this.state.notes === "") {
      delete this.state.name;
      delete this.state.notes;
      delete this.state.mask_number;
      delete this.state.endAddress;
      delete this.state.problemType;
    }
    if (this.state.svcFee === "") {
      delete this.state.svcFee;
    }
    if (this.state.zip === '' || this.state.zip === 0) {
      delete this.state.zip;
    }
    if (this.state.timestamp_PAID === "") {
      delete this.state.timestamp_PAID;
    }
    Object.keys(this.state).map((key, value) => {
      if ((validator(key, this.state[key]))) {
        return (validator(key, this.state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.setState({ saveDraft: true, emailSent: true, isLoading: true }, () => {
        this.props.emailInvoice(this.state).then(result => {
          window.scrollTo(0, 0, 0)
          if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false })
              }, 2000)
            });
          }
          if (result !== undefined && result.type === 'INVOICE_ERROR') {
            let message = this.props.info.invoiceInfo.data.message;
            misMatchError.push(message)
            this.setState({ misMatchError, isLoading: false, successMessages }, () => {
              setTimeout(() => {
                let misMatchError = []
                this.setState({ misMatchError })
              }, 3000)
            });
          }
        });
      });
    }
  }


  checkCardValues(state) {
    let errorAch = {};
    let errorFlag = false;
    let successMessages = false;
    if (!state.card.isOpen) {
      if (this.state.cardPanelShow && this.state.mask_number) {
        return true;
      }
      if ((state.card.cardNumber.indexOf('_') > -1)) {
        this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } })
        window.scrollTo(0, 0)
        return false;
      } else if ((state.card.cardExpiryDate.indexOf('_') > -1)) {
        this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } })
        window.scrollTo(0, 0)
        return false;
      } else if ((state.card.cardCsc.indexOf('_') > -1) && !state.isRefundManual) {
        this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } })
        window.scrollTo(0, 0)
        return false;
      } else {
        return true;
      }
    } else {
      Object.keys(state.ach).map((key, value) => {
        if ((validator(key, state.ach[key]))) {
          return (validator(key, state.ach[key])) ? (errorAch[key + 'Error'] = true, errorFlag = true) : (errorAch[key + 'Error'] = false);
        } else {
          return (state.ach[key] === '' || (typeof state.ach[key] === 'string' && state.ach[key].trim() === '')) ? (errorAch[key + 'Error'] = true, errorFlag = true) : '';
        }
      });
      if (errorFlag) {
        this.setState({ errorAch, successMessages });
        window.scrollTo(0, 0);
        return false;
      } else {
        return true;
      }
    }
  }


  backToMaskCard(e, t) {
    //For set mask number
    const errCard = {
      cardNumber: false,
      cardHolderName: false,
      cardExpiryDate: false,
      cardCsc: false
    }
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____'
    }
    this.setState({ card: { ...cardDataInital }, errorCard: { ...errCard }, updateCard: false });
    const data = this.state.CoustmerData.filter((item) => item._id === this.state.customerId._id);
    if (data.length > 0 && data[0].customer_id) {
      this.setState({ mask_number: data[0].masked_card_number, cardPanelShow: true, name: data[0].firstName + ' ' + data[0].lastName })
    }
  }

  handleChangeCheckbox(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  achPaymentPopUp(event) {
    let misMatchError = [];
    let InitialsvcFee = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount;
    if (this.state.ach.isOpen) {
      this.setState({ svcFee: InitialsvcFee, misMatchError, mId: this.state.locDetails.mId, showAlert: false, cardPanelShow: true, card: { ...this.state.card, isOpen: false }, ach: { ...this.state.ach, isOpen: false }, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
        this.setState({ svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) })
      });
    } else {
      this.setState({ cardPanelShow: false, svcFee: 0, card: { ...this.state.card, isOpen: true }, ach: { ...this.state.ach, isOpen: true }, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
        this.setState({ svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee })
      });
    }
  }


  cardPaymentPopUp(event) {
    let misMatchError = [];
    if (this.state.card.isOpen) {
      let InitialsvcFee = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount;
      if (this.state.ach.isOpen) {
        this.setState({ cardPanelShow: true, svcFee: InitialsvcFee, misMatchError, card: { ...this.state.card, isOpen: false }, ach: { ...this.state.ach, isOpen: false }, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
          this.setState({ svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) })
        });
      }
      this.setState({ misMatchError, cardPanelShow: true, card: { ...this.state.card, isOpen: false }, ach: { ...this.state.ach, isOpen: false }, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
      });
    }
    else {
      this.setState({ cardPanelShow: false, misMatchError, card: { ...this.state.card, isOpen: true }, ach: { ...this.state.ach, isOpen: true }, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } });
    }
  }


  getCardType(val) {
    let number = Number(val)
    switch (number) {
      case 4:
        return 'Visa'
      case 5:
        return 'MasterCard'
      case 3:
        return 'American Express'
      case 6:
        return 'Discover Card'
      default:
        return ''
    }
  }


  handleSubmitAddNewCard(e, t) {
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    }
    this.setState({ card: { ...cardDataInital }, cardPanelShow: false, updateCard: true, addNewCard: true }, () => {

    })
  }

  openAdjustSettings() {
    this.setState({ isOpen: true });
  }

  handleClose = async () => {
    this.setState({ isOpen: false, isLoading: true });
    // this.settingDataByCompanyId();
    this.settingDataByCompanyIdmId();
  }

  handleChangeCreditToggle(e, name) {
    let error = {}
    if (name === 'isCredit') {
      this.setState({ ...this.state, isCredit: true, isRefund: false, isPreAuth: false, isVoidBtn: false, isRefundManual: false, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } });
    } else if (name === 'isRefund') {
      this.setState({ ...this.state, isCredit: false, isRefund: true, isPreAuth: false, isRefundManual: false, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } });
    } else if (name === 'isPreAuth') {
      this.setState({ ...this.state, isCredit: false, isRefund: false, isPreAuth: true, isRefundManual: false, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } });
    }
  }


  checkMaxRefundLimit(val) {
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let maxRefundLimit = (userPermissions.permissions.perRefundLimit) ? userPermissions.permissions.perRefundLimit : 0
    let maxRefundDailyLimit = (userPermissions.permissions.dailyRefundLimit) ? userPermissions.permissions.dailyRefundLimit : 0
    let maxDailyRefundedAmount = this.state.TransactionRefundData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0);
    if (val > (maxRefundDailyLimit - maxDailyRefundedAmount) && maxDailyRefundedAmount > 0 && maxRefundDailyLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, dailyRefundLimit: false } })
        }, 4000)
        return false;
      });
    }
    else if (val > maxRefundLimit && maxRefundLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0)
        setTimeout(() => {
          this.setState({ limit: { ...this.state.limit, perRefundLimit: false } })
        }, 3000)
        return false;
      })
    } else {
      return true;
    }
  }


  handleSubmitRefund(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let errorManualRefund = {};
    let successMessages = false;
    let errorFlag = false;
    let oldAmount = this.state.transactionDetails.oldAmount > 0 ? parseFloat(this.state.transactionDetails.oldAmount) : 0;
    let newAmount = this.state.transactionDetails.AMOUNT
    // let caseOne = this.state.isRefund && this.state.isRefundManual ? true : false
    let caseOne = this.state.isRefund || this.state.isRefundManual ? true : false
    this.setState({ misMatchError });
    if (!caseOne && (isNaN(newAmount) && newAmount <= 0)) {
      // if (caseOne && (isNaN(newAmount) || newAmount >= 0)) {
      misMatchError.push('Please enter valid refund amount.')
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0)
      return false;
    }
    if (!caseOne && newAmount > oldAmount) {
      misMatchError.push('Refund amount cannot be greater than the original transaction')
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0)
      return false;
    }
    if (window.confirm('Are you sure you want to refund this transaction?')) {
      //case 1 manual refund
      if (this.state.isRefund && this.state.isRefundManual) {
        if (this.checkCardValues(this.state) && this.checkMaxRefundLimit(this.state.manualRefund.amount)) {
          Object.keys(this.state.manualRefund).map((key, value) => {
            if ((validator(key, this.state.manualRefund[key]))) {
              return (validator(key, this.state.manualRefund[key])) ? (errorManualRefund[key + 'Error'] = true, errorFlag = true) : (errorManualRefund[key + 'Error'] = false)
            } else {
              return (this.state.manualRefund[key] === '' || (typeof this.state.manualRefund[key] === 'string' && this.state.manualRefund[key].trim() === '')) ? (errorManualRefund[key + 'Error'] = true, errorFlag = true) : ''
            }
          });
          if (errorFlag) {
            this.setState({ misMatchError, logoError, errorManualRefund, successMessages });
            return false;
          } else {
            window.paytrace.submitEncrypted('#DemoFormTerminal');
            let userMaskNumber = this.state.card.cardNumber.replaceAll('_', '') ? this.state.card.cardNumber.substring(0, 6) + '******' + this.state.card.cardNumber.substr(-4) : ''

            this.setState({
              card: { ...this.state.card, cardNumber: userMaskNumber, cardCsc: '' },
              isLoading: true,
              encrypted_number: localStorage.getItem('0'),
              encrypted_csc: localStorage.getItem('1')
            }, () => {
              delete this.state.InvoicePendingData
              localStorage.removeItem('0');
              localStorage.removeItem('1');
              this.props.refundAuthManual(this.state, this.state.csrf, this.state.paytraceCredentials).then(result => {

                window.scrollTo(0, 0, 0);
                if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
                  this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                    setTimeout(() => {
                      this.setState({ successMessages: false });
                      this.setState({ redirectTransaction: true });
                    }, 2000)
                  });
                }
                if (result !== undefined && result.type === 'PAYMENT_ERROR') {
                  if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
                    let message = result.payload.error.data.message;
                    misMatchError.push(message);
                    this.setState({ isLoading: false, misMatchError, successMessages });
                  }
                  if (this.props.payment.paymentInfo.data.response.length > 0 && this.props.payment.paymentInfo.data.statusCode !== 401 && this.props.payment.paymentInfo.data.statusCode !== 500) {
                    let responseErrors = JSON.parse(this.props.payment.paymentInfo.data.response);
                    if (responseErrors.response_code === 1) {
                      for (let key in responseErrors.errors) {
                        let i = 0;
                        misMatchError.push(responseErrors.errors[key][i] + '\n');
                      }
                      this.setState({ isLoading: false, misMatchError, successMessages });
                    } else {
                      misMatchError.push(responseErrors.approval_message);
                    }
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }
              });
            })
          }
        }
      } else {
        if (this.checkMaxRefundLimit(this.state.transactionDetails.AMOUNT)) {
          this.setState({ isLoading: true });
          this.props.refundAuth(this.state.transactionDetails, this.state.csrf, this.state.paytraceCredentials).then(result => {
            if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
              window.scrollTo(0, 0)
              let TXNID = this.state.transactionDetails.TXNID;
              this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                setTimeout(function () {
                  window.location.href = config.APPURL + "/Viewtransaction/" + TXNID
                }, 2000)
              });
            }
            if (result !== undefined && result.type === 'PAYMENT_ERROR') {
              window.scrollTo(0, 0);
              if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
                let message = result.payload.error.data.message;
                misMatchError.push(message);
                this.setState({ isLoading: false, misMatchError, successMessages });
              }
              if (this.props.payment.paymentInfo.data.response.length > 0 && this.props.payment.paymentInfo.data.statusCode !== 401 && this.props.payment.paymentInfo.data.statusCode !== 500) {
                let responseErrors = JSON.parse(this.props.payment.paymentInfo.data.response);
                if (responseErrors.response_code === 1) {
                  for (let key in responseErrors.errors) {
                    let i = 0;
                    misMatchError.push(responseErrors.errors[key][i] + '\n');
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                } else {
                  misMatchError.push(responseErrors.approval_message);
                }
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
          });
        }
      }
    }
  }


  handleCheckRefundManual(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.checked });
  }


  handleChangeManualRefund(event, t) {
    let errorManualRefund = {};
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (errorManualRefund[id + 'Error'] = true) : (errorManualRefund[id + 'Error'] = false);
    this.setState({ manualRefund: { ...this.state.manualRefund, [id]: value }, misMatchError, errorManualRefund });
  }


  handleChangeName(e, t) {
    let { value } = e.target;
    if (this.state.customerId.lastName !== "") {
      let firstName = value.split(' ')[0] ? value.split(' ')[0] : '';
      let lastName = value.split(' ')[1] ? value.split(' ')[1] : '';
      this.setState({ customerId: { ...this.state.customerId, firstName: firstName, lastName: lastName } });
    } else {
      this.setState({ customerId: { ...this.state.customerId, firstName: value, lastName: '' } });
    }
  }


  handleCheckAsPaid(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.checked }, () => {
      let misMatchError = [];
      let InitialsvcFee = (this.state.feeSettings.InvoiceSVCFee / 100) * this.state.amount;
      if (this.state.isPaid) {
        this.setState({ svcFee: 0, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
          this.setState({ svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
        });
      } else {
        this.setState({ svcFee: InitialsvcFee.toFixed(2), misMatchError, errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false } }, () => {
          this.setState({ svcFee: parseFloat(this.state.svcFee).toFixed(2), total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
        });
      }
    })
  }


  handleChangeCheckboxUpdate(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }


  handleDebounce(e) {
    this.debounce(this.getCustomerListCoustmize(e.target.value), 200);
  }


  debounce = (func, delay, immediate) => {
    let timerId;
    return (...args) => {
      const boundFunc = func.bind(this, ...args);
      clearTimeout(timerId);
      if (immediate && !timerId) {
        boundFunc();
      }
      const calleeFunc = immediate ? () => { timerId = null } : boundFunc;
      timerId = setTimeout(calleeFunc, delay);
    }
  }


  cutomerDataPromise = (inputValue) => {
    let userData = JSON.parse(localStorage.getItem('user'));
    return new Promise((resolve) => {
      this.props.customerDetailsByCompanyCustom({ 'companyId': userData.company._id, 'name': inputValue }).then((res) => {
        if (res.payload.customerData.data.statusCode === 200) {
          this.setState({ CoustmerData: res.payload.customerData.data.response })
          resolve(mapToFormat(res.payload.customerData.data.response))
        }
      })
    });
  }

  promiseOptions = _.debounce(async (inputValue) => {
    let arrData = await this.cutomerDataPromise(inputValue)
    return arrData;
  }, 200);
  updateNewCustomerDetails(inputValue) {
    if (this.state.isNewCustomer) {
      // let firstName = inputValue.split(' ')[0] ? inputValue.split(' ')[0] : ''
      // let lastName = inputValue.split(' ')[1] ? inputValue.split(' ')[1] : ''
      this.setState({ ...this.state, name: inputValue }, () => {
      })
    } else {
      this.setState({ customerDetails: { ...this.state.customerDetails, firstName: '', lastName: '' } })
    }
  }
  handleInputClientChange = (inputValue, actionMeta) => {
    let error = {};
    let misMatchError = [];
    this.setState({ error, misMatchError })
  };

  totalAmountValue = () => {
    let totalAmounData = this.state.transactionDetails.hasOwnProperty('REFUNDED') ?
      this.state.transactionDetails.AMOUNT - this.state.transactionDetails.REFUNDED.reduce((a, b) => {
        return (parseFloat(a) + parseFloat(b.amt)).toFixed(2)
      }, 0) : this.state.transactionDetails.AMOUNT
    this.setState({ transactionDetails: { ...this.state.transactionDetails, AMOUNT: totalAmounData } });
  }

  HandleChangedTotal = (event, t) => {
    let { id, value } = event.target;
    this.setState({ isChange: true, transactionDetails: { ...this.state.transactionDetails, AMOUNT: value } })
  }


  handleSubmitVoid(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    if (window.confirm('Are you sure you want to void this transaction?')) {
      this.setState({ isLoading: true })
      window.scrollTo(0, 0)
      this.props.voidTransaction(this.state.transactionDetails, this.state.csrf, this.state.paytraceCredentials).then(result => {
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false })
              window.location.reload(true);
            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          window.scrollTo(0, 0)
          if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401 || result.payload.error.data.statusCode === 409)) {
            let message = result.payload.error.data.message
            misMatchError.push(message)
            this.setState({ isLoading: false, misMatchError, successMessages });
          } else {
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.error.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
          }
        }
      });
    }
  }


  mapToFormatPendingTransaction(values) {
    var arrValueSum = [];
    let sum = 0.00;
    values.forEach((item, index) => {
      if (item.REFUNDED.length > 0) {
        item.REFUNDED.forEach((ritem, idex) => {
          sum = sum + parseFloat(ritem.amt);
        })
        arrValueSum.push({ 'TXNID': item.TXNID, "totalRefund": sum })
        sum = 0.00;
      }

    });

    return values.filter(items => {
      let indexofvalue = _.findIndex(arrValueSum, { TXNID: items.TXNID });
      if (indexofvalue !== -1) {
        return arrValueSum[indexofvalue].totalRefund < items.AMOUNT
      } else {
        return true;
      }
    }).map(Obj => (
      {
        value: Obj.TXNID,
        amount: Obj.AMOUNT,
        label:
          <div>
            <p>{Obj.TXNID} - {moment(Obj.createdAt).utcOffset('-07:00').format("MM/DD/YYYY HH:mm:ss A")} - {(Obj.status_code === 'P') ? 'Unsettled' : (Obj.status_code === 'V') ? 'Voided' : (Obj.status_code === 'S') ? '' : (Obj.status_code === 'D') ? 'Declined' : ''} - {Obj.hasOwnProperty('CUSTOMERDATA') && (Obj.CUSTOMERDATA.firstName || Obj.CUSTOMERDATA.lastName) ? Obj.CUSTOMERDATA.firstName + ' ' + Obj.CUSTOMERDATA.lastName : ''} -
              {_.findIndex(arrValueSum, { TXNID: Obj.TXNID }) !== -1 && arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund !== "undefined" ? "$" + (parseFloat(Obj.AMOUNT) - parseFloat(arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund)).toFixed(2) : formatCurrency(Obj.AMOUNT.toFixed(2))}
            </p>
          </div>
      }
    ))
  };

  reRender = async () => {
    window.location.reload();
    // localStorage.setItem('terminalData', JSON.stringify(this.state));
    // await this.dataLoad();
  }


  render() {
    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0);
    const maxDailyRefundAmount = this.state.TransactionRefundData.reduce((item, curr) => {
      return item + curr.AMOUNT
    }, 0);
    const renderTime = ({ remainingTime }) => {
      if (remainingTime === 0) {
        return <div className="timer">Closing</div>;
      }
      return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
      );
    };
    const { t } = this.props;
    const { redirectInvoice, RefundPendingData, CoustmerData, InvoicePendingData, redirectTransaction, redirectDashboard } = this.state;
    let refundDropdownValue = (this.state.transactionDetails.TXNID) ? this.state.transactionDetails.TXNID + ' - ' + moment(this.state.transactionDetails.createdAt).utcOffset('-07:00').format("MM/DD/YYYY HH:mm:ss A") + ' - ' + ((this.state.transactionDetails.status_code === 'P') ? 'Unsettled' : (this.state.transactionDetails.status_code === 'V') ? 'Voided' : (this.state.transactionDetails.status_code === 'S') ? 'Settled' : (this.state.transactionDetails.status_code === 'D') ? 'Declined' : '') + ' - ' + ((this.state.transactionDetails.CUSTOMERDATA.firstName || this.state.transactionDetails.CUSTOMERDATA.lastName) ? this.state.transactionDetails.CUSTOMERDATA.firstName + '  ' + this.state.transactionDetails.CUSTOMERDATA.lastName : "") + ' - $' + parseFloat(this.state.transactionDetails.oldAmount).toFixed(2) : ""
     
    return (
      <div className="rightPanel">
         

        {(redirectTransaction) ? <Redirect to='/Transactions' /> : ''}
        {(redirectInvoice) ? <Redirect to='/Invoices' /> : ''}
        {(redirectDashboard) ? <Redirect to='/dashboard' /> : ''}
        <Topheader terminalClickHandler={this.reRender} setCallable={callable => this.setState({ functionToCallInTopHeader: callable })} />
        <div className="contentPart">
          <Container component="main" maxWidth={false} className="terminal-container">
            {this.state.misMatchError.map((e, i) => (
              < ErrorMessage key={i} errors={[e]} />
            ))}

            {this.state.successMessages ?
              <SuccessMessage successes={[(this.props.info.invoiceInfo) ? this.props.info.invoiceInfo.data.message : this.props.payment.paymentInfo.data.message]} /> : ''
            }

            <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text='Fetching your response ...'>
              <div className="terminal-inner">
                {(this.state.limit.perRefundLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your per refund transaction limit is ${(this.props.UserDetail.permissions.perRefundLimit) ? this.props.UserDetail.permissions.perRefundLimit : 0} </h3> : ''}
                {(this.state.limit.dailyRefundLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your daily refund transaction limit is ${(this.props.UserDetail.permissions.dailyRefundLimit) ? this.props.UserDetail.permissions.dailyRefundLimit : 0}.Make a refund amount of ${(this.props.UserDetail.permissions.dailyRefundLimit - maxDailyRefundAmount)}.</h3> : ''}
                {(this.state.limit.perTransactionLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your per transaction limit is ${(this.props.UserDetail.permissions.perTransactionLimit) ? this.props.UserDetail.permissions.perTransactionLimit : 0} </h3> : ''}
                {(this.state.limit.dailyTransactionLimit) ? <h3 className="errorMsg"><span>Warning : </span>Your daily transaction limit is ${(this.props.UserDetail.permissions.dailyTransactionLimit) ? this.props.UserDetail.permissions.dailyTransactionLimit : 0}.You have already made the payment of ${maxDailyAmount}, Make a paymeny of ${(this.props.UserDetail.permissions.dailyTransactionLimit - maxDailyAmount)}.</h3> : ''}
                <Grid container className="warning-display">
                  <Grid item xs={10} sm={10}>
                    {this.state.showAlert && !this.state.isSubmit && (/\d/.test(this.state.card.cardNumber) === true || this.state.mask_number != '') ? <Notification callHitLoc={this.hitLoc.bind(this)} card={this.state.isDebitCard} /> : ''}
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    {this.state.showAlert && !this.state.isSubmit && (/\d/.test(this.state.card.cardNumber) === true || this.state.mask_number != '') ?
                      <div className="timer-wrapper">
                        <CountdownCircleTimer
                          isPlaying size={90}
                          duration={30}
                          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                          onComplete={() => [true, 1000]}
                        >
                          {renderTime}
                        </CountdownCircleTimer>
                        <a href="#" onClick={(e) => { this.setState({ showAlert: false }) }} className="cancel-icon"><span class="material-icons">cancel</span></a>
                      </div>
                      : ''}
                  </Grid>
                </Grid>
                <h3 className="terminal-title">Virtual Terminal</h3>
                <Grid item xs={6} sm={6}>
                {this.props.UserDetail.permissions.creditSale ? (
                    <>
                  <label className={classes.mr_2}>Credit</label>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={this.state.isCredit}
                        onChange={(e) => { this.handleChangeCreditToggle(e, 'isCredit') }}
                        value={this.state.isCredit}
                      />
                    }
                  />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {this.props.UserDetail.permissions.refunds || this.props.UserDetail.permissions.refundsManual ? (
                    <>
                      {" "}
                      <label className={classes.mr_2}>Refund</label>{" "}
                      <FormControlLabel
                        control={
                          <IOSSwitchRed
                            checked={this.state.isRefund}
                            onChange={(e) => {
                              this.handleChangeCreditToggle(e, "isRefund");
                            }}
                            value={this.state.isRefund}
                          />
                        }
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {this.props.UserDetail.permissions.preAuthorize ? (
                    <>
                      {" "}
                      <label className={classes.mr_2}>Authorize Only</label>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={this.state.isPreAuth}
                            onChange={(e) => { this.handleChangeCreditToggle(e, 'isPreAuth') }}
                            value={this.state.isPreAuth}
                          />
                        }
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <form className={classes.form} id="DemoFormTerminal">
                  <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<AdjustSettings callClose={this.handleClose.bind(this)} />} />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                      <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Tooltip title="Fill Card Details"  style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow>
                            <span className={classes.cursorPointer} >

                              {/* {this.state.ach.isOpen ? '-' : '-'} */}
                              {(this.state.showAlert === '') ? ((this.state.mIdType !== config.MID_SURCHARGE) ? ' Credit/Debit ' : ' Credit ') : ''}
                              {this.state.showAlert !== '' && /\d/.test(this.state.card.cardNumber) === true ? (this.state.isDebitCard ? ' Debit ' : ' Credit ') : ''}
                              {(this.state.showAlert !== '' && /\d/.test(this.state.card.cardNumber) === false && !this.state.cardPanelShow) ? ((this.state.mIdType !== config.MID_SURCHARGE) ? ' Credit/Debit ' : ' Credit ') : ''}
                              {(this.state.showAlert !== '' && this.state.cardPanelShow && this.state.mask_number) ? (this.state.isDebitCard ? ' Debit ' : ' Credit ') : ''}
                              {/*{(this.state.showAlert !== '' && /\d/.test(this.state.card.cardNumber) === true )?(this.state.isDebitCard ? 'Debit ' : 'Credit ') : ''} */}
                              {'  '}
                              Card
                            </span>
                          </Tooltip>
                          <Card className={classNames(['cardData', 'customCardWraper', ((this.state.cardPanelShow && this.state.mask_number)) ? 'none' : 'block'])}>
                            <div className="Card_header">
                              {(!this.state.updateCard) ? <Typography variant="h5" component="h2">
                                Card Details
                              </Typography> : <><Typography variant="h5" component="h2">
                                Card Details
                              </Typography>
                                <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>back</a></>}
                            </div>
                            <CardContent ref={this.cardRef} className="cardContents">
                              {(this.state.errorCard.cardNumber) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardNumber) ? 'errorCard' : ""])}>{t("Card Number")}</label>}
                              <MaskedInput type="text" data-value={this.state.card.cardNumber} value={this.state.card.cardNumber} className="pt-encrypt" mask={(this.state.americanExpress ? '111111111111111' : '1111111111111111')} labels={t("Card Number1111")} id="cardNumber" name="encrypted_number" size="20" onChange={(e) => this.handleChangeCard(e, t)} />
                              <Grid container item xs={12}>
                                <Grid item md={6} xs={12} >
                                  {(this.state.errorCard.cardExpiryDate) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Card Expiry Date Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardExpiryDate) ? 'errorCard' : ""])}>{t("Expiry Date")}</label>}
                                  <MaskedInput mask="11/1111" value={this.state.card.cardExpiryDate} name="cardExpiryDate" id="cardExpiryDate" placeholder="mm/yyyy" onChange={(e) => this.handleChangeCard(e, t)} />
                                </Grid>
                                {
                                  (this.state.isRefundManual === false || this.state.isRefundManual === undefined) ?
                                    <Grid className="custom-spacing" item md={6} xs={12} >
                                      {(this.state.errorCard.cardCsc) ? <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("Card CSC Invalid")}</label> : <label className={classNames(["MuiTypography-gutterBottom", (this.state.errorCard.cardCsc) ? 'errorCard' : ""])}>{t("CSC")}</label>}
                                      <MaskedInput type="text" value={this.state.card.cardCsc} mask={this.state.americanExpress ? "1111" : "111"} className="pt-encrypt" id="cardCsc" name="encrypted_csc" onChange={(e) => this.handleChangeCard(e, t)} />
                                    </Grid> : ""
                                }
                              </Grid>
                            </CardContent>
                            <CardActions>
                              {(!this.state.updateCard) ? <> <Checkbox
                                id="customerToken"
                                labels="Create New User"
                                checked={this.state.card.customerToken}
                                onChange={(e) => { this.handleChangeCheckbox(e) }}
                                value={this.state.card.customerToken}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }} />
                                <label>Save card for future payments</label></> : <> <Checkbox
                                  id="customerToken"
                                  labels="Create New User"
                                  checked={this.state.card.customerToken}
                                  onChange={(e) => { this.handleChangeCheckboxUpdate(e) }}
                                  checked={this.state.card.customerToken}
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }} /><label>Update card for future payments</label></>}
                            </CardActions>
                          </Card>
                          {/* for mask mask number */}
                          < div xs={12} className={(this.state.cardPanelShow && this.state.mask_number) ? 'block' : 'none'}>

                            <Card className={classNames([(this.state.cardPanelShow) ? 'maskData' : 'blur', 'customCardWraper'])}>
                              <div className="Card_header">
                                <Typography variant="h5" component="h2">
                                  Name : {this.state.name}

                                </Typography>
                              </div>
                              <CardContent ref={this.cardRef} className="cardContents">
                                <label className="MuiTypography-gutterBottom">{t("Mask Card Number")}</label>
                                <Grid container item xs={12}>
                                  {(this.state.mask_number) ?  this.state.mask_number.substring(0, 6) +"******" + this.state.mask_number.substr(-4) : ''}
                                 
                                </Grid>
                              </CardContent>
                              <CardActions>
                                <Buttons
                                  id="AddNewCard"
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  className={"addNewCardBtn"}
                                  onClick={(e) => this.handleSubmitAddNewCard(e, t)}
                                  text={t("Add Another Card")} />
                              </CardActions>
                            </Card>
                          </div>
                        </Grid>
                         {/* <Grid item xs={12} sm={12} md={12}>
                          {(this.props.UserDetail.permissions.ach && this.props.UserDetail.companyId.ACH) ? <Tooltip title="Fill Account Details">
                            <span className={classes.cursorPointer} onClick={(e) => { this.achPaymentPopUp(e) }}>{this.state.ach.isOpen ? '-' : '+'}ACH Payment</span>
                          </Tooltip> : 'ACH feature not enabled.'}
                        </Grid>  */}
                        {/* <Grid item xs={12} sm={12} md={12} className={classNames(['achBakground', (this.state.ach.isOpen && this.props.UserDetail.permissions.ach) ? 'block' : 'none'])}>
                          <Typography variant="h6" component="h3">
                            ACH Details
                              </Typography>
                          <Textfield
                            required={true}
                            error={this.state.errorAch.accountNameError}
                            id="accountName"
                            type="text"
                            labels={t("Account Name")}
                            value={this.state.ach.accountName}
                            width={true}
                            onChange={(e) => { this.handleChangeAch(e, t) }}
                            variant="outlined"
                          />
                          <Textfield
                            required={true}
                            error={this.state.errorAch.accountNumberError}
                            id="accountNumber"
                            type="text"
                            labels={t("Account Number")}
                            value={this.state.ach.accountNumber}
                            width={true}
                            onChange={(e) => { this.handleChangeAch(e, t) }}
                            variant="outlined"
                          />
                          <Textfield
                            required={true}
                            error={this.state.errorAch.routingNumberError}
                            id="routingNumber"
                            type="text"
                            labels={t("Routing Number")}
                            value={this.state.ach.routingNumber}
                            width={true}
                            onChange={(e) => { this.handleChangeAch(e, t) }}
                            variant="outlined"
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    {(this.state.isCredit || this.state.isPreAuth) ? <Grid item xs={12} sm={12} md={8}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="terminal-select">
                          {/* <TypableSelectDetails
                            // openMenuOnClick={true}
                            placeholder={'Invoice Number'} suggestions={mapToFormatInvoice(this, InvoicePendingData)}
                            onChange={val => this.setClientValueInvoice(val)}
                            isSelected={(this.state.invoice) ? this.state.invoice : ''}
                            isClearable={true}
                          /> */}
                          <Textfield
                            // required={true}
                            error={this.state.error.invoiceError}
                            id="invoice"
                            type="text"
                            labels={t("Invoice")}
                            value={this.state.invoice}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined"
                          />
                        </Grid>
                       <Grid item xs={12} sm={12} md={12} className="terminal-select" >
                          {
                            //commented for new consolidated pipe implimentation start
                            // (this.state.invoice) ? <Textfield
                            //   id="client"
                            //   type="text"
                            //   // disabled={true}
                            //   labels={t("Customer Name")}
                            //   value={(this.state.customerDetails && this.state.customerDetails.firstName && this.state.customerDetails.lastName) ? this.state.customerDetails.firstName + ' ' + this.state.customerDetails.lastName : this.state.customerDetails.firstName}
                            //   width={true}
                            //   onChange={(e) => this.handleChangeName(e, t)}
                            //   variant="outlined"
                            // /> :
                            //commented for new consolidated pipe implimentation end


                            // <AsyncSelect 
                            // placeholder={t("Customer Name")}
                            // isClearable={true} 
                            // // cacheOptions
                            //  loadOptions={(val)=>this.promiseOptions(val)} 
                            //  onChange={val => this.setClientValue(val)}
                            //  />


                            <CreatableSelect
                              placeholder={t("Name / Customer ID")}
                              isClearable={true}
                              options={mapToFormat(CoustmerData)}
                              onInputChange={this.handleInputClientChange}
                              value={{ value: (this.state.name) ? this.state.name : '', label: (this.state.name && this.state.name !== '' ? this.state.name + '-' + this.state.customer_id : t("Name / Customer ID")) }}
                              onChange={this.setClientValue}
                            
                             />

                            // <TypableSelectDetails
                            //   placeholder={t("Customer Name")}
                            //   suggestions={mapToFormat(CoustmerData)}
                            //   onChange={val => this.setClientValue(val)} 
                            //   isSelected={(this.state.name) ? this.state.name : ''}
                            // />
                          }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Textfield
                            required={true}
                            error={this.state.error.customer_idError}
                            id="customer_id"
                            type="text"
                            labels={t("CustomerId")}
                            value={this.state.customer_id}
                            width={true}
                            onChange={(e) => { this.handleChange(e, t) }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Textfield
                            required={true}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            error={this.state.error.amountError}

                            id="amount"
                            type="text"
                            labels={t("Amount")}
                            value={(parseFloat(this.state.amount) > 0 ? this.state.amount : '')}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            // onBlur={this.setState( {amount: parseFloat(this.state.amount).toFixed(2)})}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Textfield
                            // required={true}
                            error={this.state.error.emailError}
                            id="email"
                            type="text"
                            labels={t("Email")}
                            value={this.state.email}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined"
                          />
                        </Grid>
                        {(this.state.feeSettings.InvoiceDiscount && this.state.feeSettings.InvoiceSVCFee > 0 && this.state.feeSettings.DiscountFeeName !== "") ?
                          <Grid item xs={12} sm={12} md={12}>

                            <Grid item xs={12} sm={12} md={12} title="Update Setting">
                              {/* <Grid item xs={8} sm={8} md={8}   >  */}
                                <Tooltip title="Update Setting"  aria-label="Update" style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow>
                                  <Buttons variant="outlined" color="primary" className={classes.cursorPointer}  className={classes.buttonMargin} text={this.state.feeSettings.DiscountFeeName}   onClick={(e) => { this.openAdjustSettings(e) }} />
                                </Tooltip>                                                             
                                                           
                                  ({(this.state.feeSettings.InvoiceSVCFeeType === '$') ? this.state.feeSettings.InvoiceSVCFeeType + this.state.feeSettings.InvoiceSVCFee.toFixed(2) : this.state.feeSettings.InvoiceSVCFee.toFixed(2) + this.state.feeSettings.InvoiceSVCFeeType})
                                
                              {/* </Grid> */}
                            </Grid>

                            <Textfield
                              disabled={(this.props.UserDetail.permissions.svcFeeEdit) ? false : true}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              error={this.state.error.svcFeeError}
                              id="svcFee"
                              type="number"
                              value={this.state.svcFee}
                              // value={(this.state.svcFee > 0) ? Number(this.state.svcFee).toFixed(2) : '0.00'}
                              width={true}
                              onChange={(e) => this.handleChange(e)}
                              // disabled={this.state.feeSettings.InvoiceDiscount ? false : true}
                              variant="outlined"
                            />
                          </Grid> : ''}

                        {(this.state.feeSettings.InvoiceSurcharge && this.state.feeSettings.InvoiceSurchargeFee > 0 && this.state.feeSettings.SurchargeFeeName !== "") ?
                          <Grid item xs={12} sm={12} md={12} >
                            {((this.state.feeSettings.InvoiceSurcharge && !this.state.isDebitCard) && (this.state.mIdType == config.MID_SURCHARGE || this.state.mIdType.trim() == '')) ? <Grid item xs={8} sm={8} md={8}>
                              <Tooltip title="Update Setting"  aria-label="Update2" style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow>
                                <Buttons variant="outlined" color="primary" className={classes.cursorPointer} className={classes.buttonMargin} text={this.state.feeSettings.SurchargeFeeName} onClick={(e) => { this.openAdjustSettings(e) }} />
                              </Tooltip>
                              ({(this.state.feeSettings.InvoiceSurchargeFeeType === '$') ? this.state.feeSettings.InvoiceSurchargeFeeType + this.state.feeSettings.InvoiceSurchargeFee.toFixed(2) : this.state.feeSettings.InvoiceSurchargeFee.toFixed(2) + this.state.feeSettings.InvoiceSurchargeFeeType})
                            </Grid> : ''

                            }

                            <Textfield
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              error={this.state.error.surchargeError}
                              id="surcharge"
                              type="number"
                              value={this.state.surchargeFee}
                              // value={(this.state.surchargeFee > 0) ? Number(this.state.surchargeFee).toFixed(2) : '0.00'}
                              width={true}
                              onChange={(e) => this.handleChange(e)}
                              variant="outlined"
                            />
                          </Grid> : ''}


                           
                        {this.state.feeSettings.InvoiceTax ? <Grid item xs={12} sm={12} md={12} >
                          {/* <Grid item xs={6} sm={6} md={6}> */}
                            <Tooltip title="Update Setting" aria-label="Update3"  style={{ maxWidth: 300 }} enterDelay={400} leaveDelay={200} arrow> 
                              <Buttons variant="outlined" color="primary" className={classes.buttonMargin} onClick={(e) => { this.openAdjustSettings(e) }} text={t('Sales Tax')} />
                            </Tooltip>
                            ({(this.state.feeSettings.InvoiceSalesTaxFeeType === '$') ?
                              this.state.feeSettings.InvoiceSalesTaxFeeType + this.state.feeSettings.InvoiceSalesTaxFee.toFixed(2)
                              : this.state.feeSettings.InvoiceSalesTaxFee.toFixed(2) + this.state.feeSettings.InvoiceSalesTaxFeeType})
                          {/* </Grid> */}
                          {this.state.feeSettings.InvoiceTax ? '' : <Buttons variant="outlined" color="primary" onClick={(e) => { this.openAdjustSettings(e) }} text={t('SalesTaxDisabled')} />}
                          <Textfield
                            disabled={true}
                            required={true}
                            error={this.state.error.taxError}
                            id="tax"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            type="text"
                            // labels={t("Tax")}
                            value={(this.state.tax > 0) ? this.state.tax.toFixed(2) : '0.00'}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined" 
                          />
                        </Grid> : ''}

                        {/* <Grid item xs={12} sm={12} md={12} >
                          <Textfield
                            id="customerCity"
                            type="text"
                            labels={t("City")}
                            value={this.state.customerCity}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined"
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={12} md={12} >
                          <Textfield
                            id="customerAddress"
                            type="text"
                            placeholder="Address"
                            multiline={true}
                            rows={4}
                            value={this.state.customerAddress}
                            width={true}
                            variant="outlined"
                            onChange={(e) => this.handleChange(e, t)}
                          />
                        </Grid> */}
                        


                        {this.props.UserDetail.settings.isZip ? <Grid item xs={12} sm={12} md={12}>
                          <Textfield
                            error={this.state.error.zipError}
                            id="zip"
                            type="text"
                            labels={t("Zip")}
                            value={(this.state.zip !== null && this.state.zip) ? this.state.zip : ''}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined"
                          //   helperText="Please Enter valid ZIP code"
                          // //required={true}
                            // helperText={this.state.zip !== null && this.state.zip.length<=5 ? "Please Enter valid ZIP code":''}
                           // error={"Please Enter valid ZIP code"}

                          />
                        </Grid> : ''}

                        <Grid item xs={12} sm={12} md={12}>
                          <Textfield
                            id="total"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            type="text"
                            labels={t("Total")}
                            value={(this.state.amount > 0) ? parseFloat(this.state.total).toFixed(2) : '0.00'}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            disabled={true}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                          <Textfield
                            id="notes"
                            type="text"
                            labels={t("Notes")}
                            value={this.state.notes}
                            width={true}
                            onChange={(e) => this.handleChange(e, t)}
                            variant="outlined"
                          />
                        </Grid>
                        {this.state.invoice ? <Grid item xs={12} sm={12} md={12} >
                          <label>
                            <Checkbox
                              id="isPaid"
                              labels="Mark As Paid"
                              checked={this.state.isPaid}
                              onChange={(e) => { this.handleCheckAsPaid(e) }}
                              value={this.state.isPaid}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }} />
                            <span>Mark As Paid</span></label>
                        </Grid> : ''}
                      </Grid>

                    </Grid>
                      : ''}
                    {this.state.isRefund ?
                      <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={3}>
                          {this.props.UserDetail.permissions.refundsManual ? <Grid item xs={12} sm={12} md={12}>
                            <label>
                              <Checkbox
                                id="isRefundManual"
                                labels="Manual Refund"
                                checked={(this.props.UserDetail.permissions.refundsManual && !this.props.UserDetail.permissions.refunds) ? true : this.state.isRefundManual}
                                onChange={(e) => { this.handleCheckRefundManual(e) }}
                                value={this.state.isRefundManual}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                              <span>Manual Refund</span>
                            </label>
                          </Grid> : ''}
                          {(this.state.isRefundManual || (this.props.UserDetail.permissions.refundsManual && !this.props.UserDetail.permissions.refunds)) ? <><Grid item xs={12} sm={12} md={12} className="terminal-select">
                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                required={true}
                                error={this.state.errorManualRefund.amountError}
                                id="amount"
                                type="text"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(e) => this.handleChangeManualRefund(e, t)}
                                labels={t("Amount")}
                                value={(this.state.manualRefund) ? this.state.manualRefund.amount : ''}
                                width={true}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          </> : ''}
                          {(this.props.UserDetail.permissions.refunds && !this.state.isRefundManual) ? <>
                            <Grid item xs={12} sm={12} md={12} className="terminal-select">
                              <TypableSelectDetails
                                // openMenuOnClick={true}
                                placeholder={'Transaction Number'} suggestions={this.mapToFormatPendingTransaction(RefundPendingData)}
                                onChange={val => this.setClientValuePendingTxn(val)}
                                isSelected={(this.state.transactionDetails.TXNID) ? refundDropdownValue : ""}
                                // value={(this.state.transactionDetails.TXNID) ? this.state.transactionDetails.TXNID : ''}
                                isClearable={true}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                id="firstName"
                                type="text"
                                labels={t("First Name")}
                                value={(this.state.transactionDetails.CUSTOMERDATA) ? this.state.transactionDetails.CUSTOMERDATA.firstName : ''}
                                width={true}
                                disabled={true}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                id="lastName"
                                type="text"
                                labels={t("Last Name")}
                                value={(this.state.transactionDetails.CUSTOMERDATA) ? this.state.transactionDetails.CUSTOMERDATA.lastName : ''}
                                disabled={true}
                                width={true}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                id="email"
                                type="text"
                                labels={t("Email")}
                                value={(this.state.transactionDetails.CUSTOMERDATA) ? this.state.transactionDetails.CUSTOMERDATA.email : ''}
                                disabled={true}
                                width={true}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                id="cardNumber"
                                type="text"
                                labels={t("Card Number")}
                                // value={(this.state.transactionDetails.MASKCARDNUMBER) ? this.state.transactionDetails.MASKCARDNUMBER : ''}
                                value={(!isEmpty(this.state.transactionDetails.CUSTOMERDATA)) ? (this.state.transactionDetails.CUSTOMERDATA.masked_card_number ? this.state.transactionDetails.CUSTOMERDATA.masked_card_number : this.state.transactionDetails.MASKCARDNUMBER) : this.state.transactionDetails.MASKCARDNUMBER}
                                disabled={true}
                                width={true}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                              <Textfield
                                id="total"
                                type="text"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                labels={t("Total")}
                                value={this.state.isChange ? this.state.transactionDetails.AMOUNT : this.state.transactionDetails.oldAmount}
                                disabled={false}
                                onChange={(e) => this.HandleChangedTotal(e, t)}
                                width={true}
                                onChange={(e) => this.HandleChangedTotal(e, t)}
                                variant="outlined"
                              />
                            </Grid></> : ''}
                        </Grid>
                      </Grid> : ''}
                    <Grid item xs={12} className="text-right" >

                      { /*Email invoice removed for consolidate pipe
                       */ }
                      {/* {(this.state.invoice && (this.state.isCredit || this.state.isRefund)) ? <Buttons
                        id="emailInvoice"
                        type="submit"
                        variant="contained"
                        className={'emailInvoiceTerminal email-invoice'}
                        onClick={(e) => this.handleSubmitEmailInvoice(e, t)}
                        startIcon={<Icon>send</Icon>}
                        text={t("Email Invoice")} /> : ''} */}
                   <Grid item xs={12}
                    className="text-right" 
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    
                   >

                       {/* <Buttons
                        className={'emailInvoiceTerminal'}
                        variant="contained"
                        color="primary"
                        text={t("+Pay Link")}
                        onClick={this.props.history.goBack} />
                        <Textfield
                                id="paytraceUrl"
                                type="text"
                                variant="outlined"
                                disabled={false}
                                >
                                </Textfield> */}
                        </Grid>

                      {this.state.isCredit || this.state.isPreAuth ? <Buttons
                        id="signUp"
                        type="button"
                        variant="contained"
                        color="primary"
                        className={'emailInvoiceTerminal'}
                        onClick={(e) => this.handleSubmit(e, t)}
                        text={(this.state.isPaid) ? 'Update' : (this.state.isCredit ? t("Charge Card") : (this.state.isPreAuth ? t("Authorize Card") : ''))} />
                         : <Buttons
                        id="signUp"
                        type="button"
                        variant="contained"
                        // color="secondary"
                        className={'emailInvoiceTerminal btnRed'}
                        onClick={(e) => this.handleSubmitRefund(e, t)}
                        text={t("Run Refund")} />}
                      {(this.state.isVoidBtn && this.state.transactionDetails.status_code === 'P' && this.props.UserDetail.permissions.void && !this.state.isRefundManual && !this.state.isCredit) ? <Buttons
                        variant="contained"
                        color="primary"
                        className={'emailInvoiceTerminal'}
                        text={t("Void")}
                        onClick={(e) => this.handleSubmitVoid(e, t)} /> : ''}
                      <Buttons
                        className={'emailInvoiceTerminal'}
                        variant="contained"
                        color="secondary"
                        text={t("Back")}
                        onClick={this.props.history.goBack} />
                    </Grid>
                    
                  </Grid>

                </form>
              </div>
            </LoadingOverlay>
          </Container>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    payment: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
    CurrentLocation: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { TransactionDetailsId, TransactionDetailById, fetchDetails, voidTransaction, settingDataByCompanyId, settingDataByCompanyIdmId, getRefundedDailyData, refundAuth, refundAuthManual, getCsrfToken, emailInvoice, UserDetailById, getPendingStatusInvoiceList, InvoiceDetailById, updateInvoice, customerDetailsByCompanyCustom, customerDetailsByCompany, getTransactionListTodayData, getInvoiceNumber, countryDataList, getTransactionsDataForRefund })(withTranslation()(Terminal));
