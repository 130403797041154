import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { UserDetailByLink } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import { config } from '../../constants'
import queryString from 'query-string';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { DataApi } from './../../helpers/PostDataApi';
import css from './user-style.css';
// import CheckIcon from '@mui/icons-material/Check';
import CheckIcon from '@material-ui/icons/Check';

class userActivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      misMatchError: [],
      userName: '',
      password: '',
      confirmPassword: '',
      errorMsg: null,
      successMessages: false,
      successDisplay: false,
      sideShow: true,
      isLoading: true,
      isError: false,
      btnDisabled: true,
      company:{image:''},
      pswStrength: {status:'', noPresent: false, symbolPresent: false, upCasePresent: false, lowCasePresent: false, pswLength: 0, show: false},
      error: {
        passwordError: false,
        userNameError: false,
        confirmPasswordError: false
      }
    }
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params) {
      this.props.UserDetailByLink(params).then((res) => {
        this.setState({company:{...res.payload.userData.data.response.company}, userName: res.payload.userData.data.response.userName, createdAt: res.payload.userData.data.response.createdAt, isLoading: false });
      }).catch((err) => {
        this.setState({ errorMsg: this.props.info.userInfo.data.message, isError: true, isLoading: false });
      })
    }
    const password = document.querySelector('#password');

    password.addEventListener('focusout', (event) => {
      this.setState({pswStrength: {...this.state.pswStrength,  show: false} });
    });

  }



  handleChange(event, t) {
    console.log(event, " <<<<<<<<<<<<<<<<<<<<<<< =========================", event.keyCode);
    let error = {}
    let { id, value } = event.target;
    let { password, confirmPassword } = this.state;
    this.setState({ [id]: value, error });
    let misMatchError = [];
    let successMessages = false;
    let pswStrength = this.state.pswStrength;
    pswStrength.noPresent = /\d/.test(value);
    pswStrength.symbolPresent = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);;
    pswStrength.upCasePresent = /[A-Z]/.test(value);
    pswStrength.lowCasePresent = /[a-z]/.test(value);
    pswStrength.pswLength = value.length ;

    // console.log(value, 'Here is ========>',pswStrength, /\d/.test(value));
     
    if (pswStrength.noPresent && pswStrength.symbolPresent && pswStrength.upCasePresent && pswStrength.lowCasePresent && pswStrength.pswLength > 7) {
      pswStrength =  {...this.state.pswStrength,  status: 'Strong', show: false };
    }else if(pswStrength.noPresent && pswStrength.lowCasePresent && pswStrength.pswLength > 5){
      pswStrength =  {...this.state.pswStrength,  status: 'Weak', show: true }; 
    } else {
      pswStrength =  {...this.state.pswStrength,  status: 'Very Weak', show: true };  
    }
  
    if ((id === 'confirmPassword' && password !== event.target.value) || (confirmPassword && id === 'password' && confirmPassword !== value)) {
      misMatchError[0] = t("MisMatchPassword");
      this.setState({ pswStrength, misMatchError, successMessages, btnDisabled: true });
    } else {
      this.setState({ pswStrength, misMatchError, successMessages, btnDisabled: false });
    }

  }

 

  handleChangeConfirm(event, t) {
    let error = {}
    let { id, value } = event.target;
    let { password, confirmPassword } = this.state;
    this.setState({ [id]: value, error });
    let misMatchError = [];
    let successMessages = false;
  
  
    if ((id === 'confirmPassword' && password !== event.target.value) || (confirmPassword && id === 'password' && confirmPassword !== value)) {
      misMatchError[0] = t("MisMatchPassword");
      this.setState({ misMatchError, successMessages, btnDisabled: true });
    } else {
      this.setState({ misMatchError, successMessages, btnDisabled: false });
    }

  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = Object.assign({}, this.state);
    let error = {};
    let errorFlag = false;
    if(this.state.pswStrength.status !== 'Strong'){
      misMatchError[0] = t("Password Should be Strong!");
      this.setState({ misMatchError, error, successMessages });
      return false;
    }

    Object.keys(state).map((key, value) => state[key] === '' ? (error[key + 'Error'] = true, errorFlag = true) : '');
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      DataApi(state, config.BASEURL + 'adminUserUpdatePassword').then((response) => {
        let { status, data } = response;
        if (status === 200) {
          this.setState({ successMessages: true, successDisplay: data.message, userName: '' });
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  redirectLogin(e){
    window.location=config.APPURL;
  }

 

  render() {

    const { t } = this.props;
    const { imageStyle } = styles;
    const { userName, createdAt } = this.state;
    let lastUpdate = new Date(createdAt);
    let currentDateTime = new Date();
    let diffTime = currentDateTime - lastUpdate;
    let TimeOutdiff = Math.round((diffTime / 1000) / 60);

   
    return (
      <div className={classes.userLink}>

        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='please wait...'>
          <div className={classes.paper}>

            {(this.state.isError ? <div className={classes.error}>{this.state.errorMsg}</div> :
              <img src={this.state.company.image?config.PAYTRACE_AWS_PATH+this.state.company.image:config.IMGURL+'titanium-logo.png'} style={imageStyle} height={'100rem'} alt="logo" />
            )}
            {(TimeOutdiff > config.ACTIVATION_LINK ? <p className={classes.error}>Link has been expired with in 60 minutes. <a href={config.APPURL}>back</a></p> :
              <Container component="main" maxWidth="sm" >

                {(this.state.isError ? '' : <form className={classes.form} noValidate>
                  <Textfield
                    required={true}
                    error={this.state.error.userNameError}
                    id="userName"
                    type="userName"
                    labels={t("Username")}
                    value={userName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={false}
                    disabled={true}
                  />
                  <Textfield
                    required={true}
                    error={this.state.error.passwordError}
                    id="password"
                    type="password"
                    labels={t("Password")}
                    value={this.state.password}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={false}
                    className='marginpsw'
                     />
                    <div className='pswfield'>
                    {(this.state.password.length > 0 )? <div className='instruction-section'>
                        {this.state.pswStrength.status === 'Very Weak' ?
                          <b className='red'>Very Weak</b> : ''
                        }
                        {this.state.pswStrength.status === 'Weak' ?
                          <b className='red'>Weak</b> : ''
                        }
                        {this.state.pswStrength.status === 'Strong' ?
                          <b className='green'>Strong</b> : ''
                        }

                        { this.state.pswStrength.show === true ?
                        <>
                          <h4>Strong Password Tips : </h4>
                          <div className="instructionsview">
                            {this.state.pswStrength.pswLength > 8 ? <CheckIcon className='check-icon'  /> :'-'} At Least 8 Characters</div>
                          <div className="instructionsview">{this.state.pswStrength.noPresent == true? <CheckIcon className='check-icon' /> :'-'} At Least One Number
                          </div>
                          <div className="instructionsview">
                            {this.state.pswStrength.symbolPresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Special Character
                          </div>
                          <div className="instructionsview">
                            {this.state.pswStrength.upCasePresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Upper Case Letter
                          </div>
                          <div className="instructionsview">
                            {this.state.pswStrength.lowCasePresent == true? <CheckIcon className='check-icon'  /> :'-'} At Least One Lower Case Letter
                          </div>                          
                       </>
                      : ''}

                      </div>  
                    : ''}
                    </div>


                  <Textfield
                    required={true}
                    error={this.state.error.confirmPasswordError}
                    id="confirmPassword"
                    type="password"
                    labels={t("Confirm Password")}
                    value={this.state.confirmPassword}
                    width={true}
                    onBlur={()=>{console.log("console.log")}}
                    onChange={(e) => this.handleChangeConfirm(e, t)}
                    focus={false} />
                  {(this.state.successMessages) ? <Buttons
                    id="login"
                    type="button"
                    width={true}
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={(e) => this.redirectLogin(e)}
                    text={t("Click Here Login")}
                    disabled={this.state.btnDisabled}
                  /> : <Buttons
                    id="save"
                    type="button"
                    width={true}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t("Save")}
                    disabled={this.state.btnDisabled}
                  />}
                </form>)}

              </Container>)}
         
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[this.state.successDisplay]} /> : ''
            }

          </div>
        </LoadingOverlay>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}
userActivation.propTypes = {
  t: PropTypes.func
}


const styles = {
  imageStyle: { maxWidth: '95%', },
};
export default connect(mapStateToProps, { UserDetailByLink })(withTranslation()(userActivation));
