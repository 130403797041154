import { formatCurrency, } from './commonFunction'

const InvoicePrint = (data) => {
  var my_window = window.open('mywindow', 'status=1,width=650,height=450');
  my_window.document.write(
    `<html>
      <head>
        <title>
        Print Invoice
        </title>
      </head>` );
  my_window.document.write(`<body  onafterprint="self.close()">`)
  my_window.document.write(`<p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">Hi <strong style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">${data.CUSTOMERDATA.name.charAt(0).toUpperCase() + data.CUSTOMERDATA.name.slice(1).toLowerCase()}</strong>,</p>`)
  my_window.document.write(`<h3 style="padding: 0; box-sizing: border-box; font-family: sans-serif; margin: 25px 0; text-align: center; font-size: 25px; font-weight: 700; color: rgba(53, 94, 170, 1);">INVOICE</h3>`)
  my_window.document.write(`<table style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; width: 100%; border-collapse: collapse;" width="100%">
       
    ${(data.CUSTOMERDATA && data.CUSTOMERDATA.name) ?
      ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
             <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Customer Name </strong></td>
             <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.CUSTOMERDATA.name.charAt(0).toUpperCase() + data.CUSTOMERDATA.name.slice(1).toLowerCase()}</td>
          ${data.firstName ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1).toLowerCase() + data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1).toLowerCase() : ``}</td>
         </tr>`: ``}

    ${(data.CUSTOMERDATA && data.CUSTOMERDATA.customer_id) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Customer Id </strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.CUSTOMERDATA.customer_id}</td>
         </tr>`: ``}

    ${data.TXNID ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Reference Number </strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.TXNID}</td>
      </tr>`: ``}

    ${data.transaction_type ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Transaction Type </strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.transaction_type}</td>
      </tr>`: ``}
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
       <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Entry Method </strong></td>
       <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">Keyed</td>
      </tr>
      ${(data.FINAL_STATUS) ?
        `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Transaction Status </strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.FINAL_STATUS}</td>
         </tr>`: ``
      }
    ${(data.CARDTYPE) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Card Brand </strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.CARDTYPE}</td>
       </tr>`: ``
    }
    ${(data.CUSTOMERDATA && data.CUSTOMERDATA.masked_card_number) ?

      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Card Number</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.CUSTOMERDATA.masked_card_number}</td>
       </tr>`: `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
         <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Card Number</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.MASKCARDNUMBER}</td>
         </tr>`
    }
    ${(data.APPROVALCODE) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Approval Code</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.APPROVALCODE}</td>
      </tr>`: ``
    }
 
    ${(data.RESPONSETEXT) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Approval Message</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.RESPONSETEXT}</td>
    </tr>`: ``
    }
    
    ${(data.AVSRESPONSE) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">AVS Result</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.AVSRESPONSE}</td>
    </tr>`: ``
    }
   
    ${(data.CSCRESPONSE) ?
      ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">CSC Result</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.CSCRESPONSE}</td>
    </tr>`: ``}
   
    ${(data.ORDERID) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Invoice Number</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.ORDERID}</td>
  </tr>`: ``} 

    ${(data.createdAt) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Transaction Date </strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${new Date(data.createdAt).toLocaleDateString()}</td>
    </tr>`: ``}

   <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Subtotal</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(parseFloat(data.AMOUNT) - (parseFloat(data.SVC_FEE) + parseFloat(data.SURCHARGE_FEE) + parseFloat(data.TAX)))}</td>
  </tr>
    ${(data.SVC_FEE && data.SVC_FEE > 0) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">SVC Fee amount</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(data.SVC_FEE)}</td>
    </tr>`: ``}
        
    ${(data.SURCHARGE_FEE && data.SURCHARGE_FEE > 0) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Surcharge amount</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(data.SURCHARGE_FEE)}</td>
    </tr>`: ``}
    ${(data.TAX) ?
      `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Sales Tax amount</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(data.TAX)}</td>
    </tr>`: ``}
</table>

<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
  <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Total amount</td>
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(data.AMOUNT)}</td>
    </td>
  </tr> 
</table>
<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
  <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Payment Status</td>
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${data.FINAL_STATUS}</td>
  </tr>
  </table>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 16px; font-weight: 600; margin-bottom: 10px; text-align: center;">Thank you for your business. </p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 5px; margin-top: 30px;">Regards,</p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 2px;">${data.user.company ? data.user.company.dbaName : 'Titanium Payments'}</p>
 
    ${(typeof data.receiptSetting !== "undefined" && data.receiptSetting.isEmail) ?
      ` <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;"><b style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;font-weight: 600;">Email:&nbsp;</b>${data.receiptSetting.email}</p>` : ``
    }
     
      ${(typeof data.receiptSetting !== "undefined" && data.receiptSetting.isPhone) ?
      `<p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;"><b style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;font-weight: 600;">Phone:&nbsp;</b>${data.receiptSetting.phone}</p>` : ``
    } 
  
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;"></p>
  
  `)

  my_window.document.write(`</body></html>`);
  //  my_window.document.write.close();
  //  my_window.focus();
  my_window.print();
  //  my_window.close();

}

export default InvoicePrint;

