import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Geosuggest from 'react-geosuggest';
import {  addressAutoSuggest, getDrivingDetails, setInputState, reset,appendToNotes } from './../../actions/RequestFormAction';
import { config } from '../../constants';
import fetch from 'isomorphic-unfetch';
import '../../geosuggest.css'
import classes from './AutoSuggestInput.module.scss';

class AutoSuggestInput extends React.Component {
	state = { suggestions: [], startAddress: '', endAddress: '', showMap: false, query: '', city: '', place: {}, newPrice: '' };

	render () {
		const {
			getDrivingDetails, distanceDetails, serviceType, amount, basePrice,
			startAddress, endAddress, pickUpLocation, doYouHaveKeysForVehicle, neutral,
			doAllWheelsTurn, doesFrontWheelsTurn, doesBackWheelsTurn, appendToNotes, regularGasOrDiesel
		} = this.props.input;
		const { setInputState } = this.props
		const { showMap } = this.state;

		return (
			<div>
				<div style={{marginTop: '1rem'}}>
				{/* <div className={classes.formControlWrapper}> */}
					<Geosuggest
							id={'origin'} placeholder={'origin'}
							onChange={() => setInputState('startAddress', document.getElementById('origin').value)}
							onSuggestSelect={() =>{
								setInputState('startAddress', document.getElementById('origin').value);
								if (showMap)
									this.setState({ showMap: false });
							}}
						/>					
				{/* </div> */}

					
				</div>
				<div style={{marginTop: '1rem'}}>
					{(serviceType.trim() === 'Towing') ?
						// <div className={classes.formControlWrapper}>
							<Geosuggest
								id={'destination'} placeholder={'destination'}
								onChange={() => {
									setInputState('endAddress', document.getElementById('destination').value);
									if (showMap)
										this.setState({ showMap: false });
								}}
								onSuggestSelect={() => setInputState('endAddress', document.getElementById('destination').value)}
							/>
						// </div>
						: ''
					}
					{
						(startAddress.indexOf('&') > 0 || startAddress.indexOf('#') > 0 || endAddress.indexOf('&') > 0 || endAddress.indexOf('&') > 0) ? (
							<div style={{ color: 'red', marginBottom: '1rem' }}> 
								<b> "&" and "#" are not supported in addresses </b> <br /> 
							</div>
						): <div />
					}

					<Button
						variant='contained' color='secondary' className={classes.saveforLetterBtn} style={{color: '#FFF'}}
						disabled={(!startAddress || ((serviceType === 'Towing')? !endAddress: !startAddress)) /* || !doYouHaveKeysForVehicle || ((serviceType === 'Towing') ? !neutral : false) */ }
						onClick={async () => {
							setInputState('notes', '');
							if (doYouHaveKeysForVehicle === 'NO') {
								this.props.appendToNotes('The customer does not have keys for the vehicle');
							}
							if (neutral === 'NO') {
								this.props.appendToNotes('The vehicle does not go in neutral.');
							}
							if (doAllWheelsTurn === 'NO') {
								if (doesFrontWheelsTurn === 'NO') {
									this.props.appendToNotes('The front wheels of the vehicle does not turn.');
								}
								if (doesBackWheelsTurn === 'NO') {
									this.props.appendToNotes('The back wheels of the vehicle does not turn.');
								}
							}

							let zipCode = await fetch(config.BASEURL+`getZip?origin=${document.getElementById('origin').value}`)
											.then(response => response.json())
											.then(myJson => myJson.zip)
											.catch(err => {}/* console.log('Error', err) */);
							if (!(zipCode)) {
								zipCode = prompt('API Fetch Error, Please enter the zip code origin:');
							}
							await setInputState('zipForCalc', zipCode);

							if (!(serviceType)) {
								alert('Select a Service Type value to get the details');
								setInputState('serviceType', '');
							} else if (!doYouHaveKeysForVehicle) {
								alert('Please answer the question, do you have keys for the vehicle');
								setInputState('doYouHaveKeysForVehicle', '');
							} else if (serviceType === 'Towing' && !neutral) {
								alert('Please answer the question, will the vehicle go in neutral');
								setInputState('neutral', '');
							} else if (serviceType === 'Towing' && !doAllWheelsTurn) {
								alert('Please answer the question, do all four wheels on the vehicle turn');
								setInputState('doAllWheelsTurn', '');
							} else if (doAllWheelsTurn === 'NO' && (!doesFrontWheelsTurn || !doesBackWheelsTurn)) {
								if (!doesFrontWheelsTurn) {
									alert('Please answer the question, will both front wheels turn');
									setInputState('doesFrontWheelsTurn', '');
								} if (!doesBackWheelsTurn) {
									alert('Please answer the question, will both back wheels turn');
									setInputState('doesBackWheelsTurn', '');
								}
							} else if (serviceType === 'Fuel/Fluids' && regularGasOrDiesel !== 'REGULAR GAS') {
								alert('Only regular gas vehicles are supported');
								setInputState('regularGasOrDiesel', '');
								// const option = confirm("Press OK button to convert the service type into Towing!");
								// if (option) {
								// 	setInputState('serviceType', 'Towing');
								// }
							} else if (zipCode && serviceType) {
								let newPrice = 0;
								if (neutral === 'NO') {
									newPrice += 17; // $17.00
								} 

								if (doAllWheelsTurn === 'NO') {
									if (doesFrontWheelsTurn === 'NO' && doesBackWheelsTurn === 'NO') {
										newPrice += 39;
									} else if (doesFrontWheelsTurn === 'NO') {
										newPrice += 26;
									} else if (doesBackWheelsTurn === 'NO') {
										newPrice += 29;
									}
								}  

								if (doYouHaveKeysForVehicle === 'NO') {
									newPrice += 23;
								}
								this.setState({ newPrice });
								await this.props.getDrivingDetails(startAddress, endAddress, zipCode, serviceType, pickUpLocation, newPrice);

								let system = await fetch(config.BASEURL+`getSystem?zipCode=${zipCode}&serviceType=${serviceType}`)
											.then(response => response.json())
											.then(myJson => myJson["SYSTEM"])
											.catch(err => {}/* console.log('Error', err) */);

								await setInputState('system', system);

								await this.setState({showMap: true});
							} 
						}}
					> Calculate Cost </Button> <br />
					{
						(Object.keys(distanceDetails).length && amount && startAddress && endAddress) ? (
								<div style={{ padding: '1rem' }}>
									<strong style={{ color: '#000' }}>
										<FormControl fullWidth>
											<Grid container align="center">
												<Grid item xs={6} sm={6} md={6}>
													Distance: {distanceDetails.distance} <br />
												</Grid>

												<Grid item xs={6} sm={6} md={6}>
													{(amount.indexOf('$') > 0) ?  (`Cost: ${amount}`) : (`Cost: $ ${amount}`)}
												</Grid>
											</Grid>
										</FormControl>
									</strong>

									<FormControl fullWidth>
										<Grid style={{ fontSize: '1.1rem' }} container align="center">
											<Grid item xs={6} sm={6} md={6} />

											<Grid item xs={6} sm={6} md={6}>
												<hr />
												Base Price: $ {basePrice} <br />
												Additional Price: $ {parseFloat(amount - basePrice).toFixed(2)}
											</Grid>
										</Grid>
									</FormControl>
								</div>
							) : (startAddress && amount && serviceType !== 'Towing') ? (
							<div style={{ padding: '1rem' }}>
								<strong style={{ color: '#000' }}>
									{(String(amount).indexOf('$') > 0) ?  (`Cost: ${amount}`) : (`Cost: $ ${parseFloat(amount).toFixed(2)}`)}
								</strong>
								<div style={{ color: '#000' }}>
									<hr />
									Base Price: $ {parseFloat(basePrice).toFixed(2)} <br />
									Additional Price: $ {parseFloat(amount - basePrice).toFixed(2)}
								</div>
							</div>
						) : '' 
					}
				</div>
				
				{
					(showMap && parseFloat(amount) > 0 && startAddress && endAddress && serviceType === 'Towing') ? (
						<iframe title="iframeMap" frameBorder="0" style={{ border: 0, width: '100%', height: '30rem' }}
							src={`https://www.google.com/maps/embed/v1/directions?origin=${startAddress}&destination=${endAddress}&key=${config.MAP_API_KEY}`}
							allowFullScreen />
					) : (showMap && parseFloat(amount) > 0 && startAddress && serviceType !== 'Towing') ? (
						<iframe title="iframeMapStartAddress" frameBorder="0" style={{ border:0, width: '100%', height: '30rem' }}
							src={`https://www.google.com/maps/embed/v1/place?q=${startAddress}&key=${config.MAP_API_KEY}`}
							allowFullScreen />
					) : ''
				}
			</div>
		);
	}
}

// const mapStateToProps = ({input}) => ({
// 	suggestionsData: input.suggestionsData, distanceDetails: input.distanceDetails, serviceType: input.serviceType,
// 	amount: input.amount, startAddress: input.startAddress, endAddress: input.endAddress, pickUpLocation: input.pickUpLocation,
// 	doYouHaveKeysForVehicle: input.doYouHaveKeysForVehicle, neutral: input.neutral, doAllWheelsTurn: input.doAllWheelsTurn, 
// 	doesFrontWheelsTurn: input.doesFrontWheelsTurn, doesBackWheelsTurn: input.doesBackWheelsTurn,
// 	basePrice: input.basePrice, regularGasOrDiesel: input.regularGasOrDiesel
// });
function mapStateToProps(state) {
	return {
	  input: state.InputReducer,
	};
  }
  


export default connect(mapStateToProps,{addressAutoSuggest, getDrivingDetails, setInputState,reset,appendToNotes})(withTranslation()(AutoSuggestInput));
