import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { userSettings, UserDetailById } from '../../actions/AdminUserAction'
import { Buttons } from './../../components/Buttons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import classesSe from '../Settings/Settings.module.scss';
import LoadingOverlay from 'react-loading-overlay';


const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

 


const initialState = {

  LableName:'',   
  sideShow: true,
  isLoading: false,
  userDetails: JSON.parse(localStorage.getItem('user')),
  error: {
    phoneError: false,
  }

};

class TextfieldLable extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {
    let state = this.state;
    this.setState({ isSubmit: true })
    this.setState({isLoading:true})
    this.props.handleLabels(state)
    this.props.handleClose()
  }
  onchangeType(e, type) {
    let successMessages = false;
    this.setState({ [type]: e.target.value, successMessages })
  }
  render() {

    const { classes, t } = this.props
    return (
      <>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>Lable Settings </Typography>
          </Toolbar>
        </AppBar>
        {/* {this.state.misMatchError.length > 0 ?
          < ErrorMessage errors={[this.state.misMatchError]} /> : ''
        }
        {this.state.successMessages ?
          <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
        } */}
        <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text='Loading your content...'>
        <form className={classes.form} noValidate ref={this.myRef}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
              <div  >
                <Grid container spacing={3}>
                   
                  <Grid item xs={12} className="fee-name-field">
                    {/* <Grid item xs={6}> <label>Fee Name: </label> </Grid> */}
                    <Grid item sm={12} className="tax-select-field taxSelect">
                      <label className="fee-name-label-settings">Lable Name: </label>
                      <Textfield
                        required={true}
                        error={this.state.error.LableNameError}
                        id="LableName"
                        type="text"
                        // labels={t("Fee Name")}
                        value={this.state.LableName}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                        focus={true} />
                    </Grid>
                  </Grid>

                   
                  
                </Grid>
              </div>
            </Grid>
            <hr />
           

          </Grid>

          <div className="mt-4">
            <Buttons
              id="signUp"
              type="button"
              variant="contained"
              color="primary"
              className={classesSe.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text={t("Save")} />
           
          </div>

        </form >
        </LoadingOverlay>
      </>
    )
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles()
  const theme = useTheme();
  return (<TextfieldLable theme={theme} classes={classes} {...props} />)
}

function mapStateToProps(state) {

  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { userSettings, UserDetailById })(withTranslation()(withRouter(ApplyingStyles)))