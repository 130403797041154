import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import PropTypes, { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import FormControl from '@material-ui/core/FormControl';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { regularUserAdd, fetchDetails } from '../../actions/AdminUserAction'
import IntegrationDownshift from './../../helpers/multiSelect'
import config from './../../constants/config'
import validator from '../../helpers/checkValidations';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingOverlay from 'react-loading-overlay';

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', height: '4rem' };
  } else {
    return { width: '100%' };
  }
};

class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alllocationEnabled : false,
      redirect: false,
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: config.REGULAR_USER_ROLE,
      sideShow: true,
      isLoading: true,
      isLocation: false,
      company: '',
      createdBy: '',
      data: [],
      misMatchError: [],
      permissions: {
        refunds: false,
        refundsManual: false,
        allLocaton: false,
        creatNewUser: false,
        createInvoice: false,
        ach: false,
        virtualTerminal: false,
        textToInvoice: false,
        recurring: false,
        svcFeeEdit: false,
        exportRecord: false,
        void: false,
        perTransactionLimit: '',
        dailyTransactionLimit: '',
        perRefundLimit: '',
        dailyRefundLimit: '',
        emailNotification: false,
        viewAllTransactions: false,
        preAuthorize: false,
        creditSale: false
      },
      userData: { permissions: {} },
      isSubmit: false,
      locationSelected: [],
      previousSelectedLocation : [],
      previousSelectedData : [] ,
      user: JSON.parse(localStorage.getItem('user')),
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        perTransactionLimitError: false,
        dailyTransactionLimitError: false,
        perRefundLimitError: false,
        dailyRefundLimitError: false,
      },
    }
  }
  componentDidMount() {
    this.setState({ company: this.state.user.company._id, createdBy: this.state.user._id, isLoading: false })
    let user = JSON.parse(localStorage.getItem('user'))
    let data = fetchDetails({ id: user._id })
    if (this.props.info.permissions && !this.props.info.permissions.creatNewUser) {
     // alert('Access denied!')
     // this.props.history.push('/Dashboard')
    }
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS") {
        this.setState({ dataLoc: { ...res.payload.userData.locationSelectedwithmid }, userData: { ...res.payload.userData } }, () => {
          if (this.state.userData.permissions && !this.state.userData.permissions.creatNewUser) {
          //  alert('Access denied!')
         //   this.props.history.push('/Dashboard')
          }
          Object.keys(this.state.dataLoc).map((k, v) => {
            this.state.data.push(this.state.dataLoc[k])
          })
          if (this.state.data.length > 0) {
            this.setState({
              locationSelected: Object.keys(this.state.data).map((k, v) =>
                this.state.data[k])
            })
          }
        })
      }
    })
  }

  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleChangeCheckbox(event) {
    if (event.target.id === "allLocaton") {
      var locationSelectedValue = [];
      var previousSelectedLocationValue = [];
      var setData =  []
  
      if (event.target.checked) {  
         let locationsIds = this.state.data.map((items) => items._id);        
          previousSelectedLocationValue = this.state.locationSelected            
         locationSelectedValue = locationsIds
         setData = this.state.data.map ( (items) => items);
      } else {
        locationSelectedValue = this.state.previousSelectedLocation
        setData = this.state.data.map ( (items) => items);      
      }    
      this.setState( {
        locationSelected : locationSelectedValue,
        previousSelectedLocation: previousSelectedLocationValue,
        data : setData,
        alllocationEnabled: !this.state.alllocationEnabled
      })
    }
    this.setState({ permissions: { ...this.state.permissions, [event.target.id]: event.target.checked } });
  }

  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (id === 'perTransactionLimit' || id === 'dailyTransactionLimit' || id === "perRefundLimit" || id === "dailyRefundLimit") {
      this.setState({ permissions: { ...this.state.permissions, [id]: value } });
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true })
    Object.keys(state).map((key, value) => {
      if (key === "phone" && !this.state.permissions.textToInvoice) {
        return false;
      }
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    Object.keys(this.state.permissions).map((key, value) => {
      if ((validator(key, state.permissions[key]))) {
        return (validator(key, state.permissions[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state.permissions[key] === '' || (typeof state.permissions[key] === 'string' && state.permissions[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });
    if (state.locationSelected.length === 0) {
      misMatchError.push(t('LocationRequired'))
      errorFlag = true;
      this.setState({ misMatchError });
    }

    if (errorFlag) {
      window.scrollTo(0, 0, 0)
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      this.setState({ isLoading: true })
      this.props.regularUserAdd(this.state).then(result => {
        window.scrollTo(0, 0, 0)
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true, misMatchError, isLoading: false }, () => {
            setTimeout(() => {
              this.setState({ redirect: true, successMessages: false })
            }, 2000)
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages,  isLoading: false });
        }
      });
    }
  }

  addLocation = (arr) => {
    let misMatchError = [];
    if (arr) {
      let allLocationChecked  = this.state.data.length ===  arr.length  ?  true : false;       

      this.setState({ locationSelected: arr, misMatchError ,alllocationEnabled : allLocationChecked  } , () => {
      
      } )
    }
  }

  addLocation2 = (arr) => {
    let misMatchError = [];
    if (arr) {
      let allLocationChecked  = this.state.data.length ===  arr.length  ?  true : false;       

      this.setState({ locationSelected: arr, misMatchError ,alllocationEnabled : allLocationChecked  } , () => {
      
      } )
    }
  }
 
  render() {
    const { t } = this.props;
    const { data, redirect } = this.state;  
    return (

      <div className="rightPanel" >
        {(redirect) ? <Redirect to="/Users" /> : ''}
        <Topheader />
        <div className="contentPart">               
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
              
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
              }

              <form className={classes.form} noValidate>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.userNameError}
                      id="userName"
                      type="text"
                      labels={t("Username")}
                      value={this.state.userName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.firstNameError}
                      id="firstName"
                      type="text"
                      labels={t("First Name")}
                      value={this.state.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.lastNameError}
                      id="lastName"
                      type="text"
                      labels={t("Last Name")}
                      value={this.state.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                    required={this.state.permissions.textToInvoice}
                    error={this.state.permissions.textToInvoice ? this.state.error.phoneError : false}
                      // required={true}
                      // error={this.state.error.phoneError}
                      id="phone"
                      type="text"
                      labels={t("Phone")}
                      value={this.state.phone}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth style={getValidationStyle(!this.state.locationSelected && this.state.isSubmit)} >
                      <IntegrationDownshift 
                      isLocationExists={true}
                      selectedLocations={ data }
                      locationData={(this.state.user.role === 1 || this.state.user.role === 2) ? data : this.state.user.location}
                      addLocation={this.addLocation}
                      addLocation2={this.addLocation2}
                      />

                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.perTransactionLimitError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="perTransactionLimit"
                      type="text"
                      labels={t("Per Transaction Limit")}
                      value={this.state.permissions.perTransactionLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.dailyTransactionLimitError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="dailyTransactionLimit"
                      type="text"
                      labels={t("Daily Transaction Limit")}
                      value={this.state.permissions.dailyTransactionLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.perRefundLimitError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="perRefundLimit"
                      type="text"
                      labels={t("Per Refund Limit")}
                      value={this.state.permissions.perRefundLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.error.dailyRefundLimitError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="dailyRefundLimit"
                      type="text"
                      labels={t("Daily Refund Limit")}
                      value={this.state.permissions.dailyRefundLimit}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <h5>Permissions</h5>

                    <Grid container item xs={12}>
                      {(this.state.role < 4) ? <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="creatNewUser"
                            labels="Create New User"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.creatNewUser}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Create New User</span>
                        </label>
                      </Grid> : ''}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="refunds"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.refunds}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Refunds General</span>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="textToInvoice"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.textToInvoice}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Text To Invoice</span>
                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="refundsManual"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.refundsManual}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Refunds Manual</span>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="allLocaton"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.alllocationEnabled}
                            color="primary"
                            checked = {this.state.alllocationEnabled}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>All Location</span>
                        </label>
                      </Grid> */}
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="createInvoice"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.createInvoice}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Invoicing</span>

                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="ach"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.ach}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>ACH </span>
                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="virtualTerminal"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.virtualTerminal}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Virtual Terminal</span>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="recurring"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.recurring}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Recurring billing</span>

                        </label>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="svcFeeEdit"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.svcFeeEdit}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Svc Fee Edit</span>

                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="exportRecord"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.exportRecord}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Export Records</span>

                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="void"
                            onChange={(e) => { this.handleChangeCheckbox(e) }}
                            value={this.state.permissions.void}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Void</span>
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox id="emailNotification" onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }} value={this.state.permissions.emailNotification} color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
                          <span>{t("emailNotifications")}</span>
                        </label>
                      </Grid> 

                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox id="viewAllTransactions" onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }} value={this.state.permissions.viewAllTransactions} color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
                          <span>{t("viewAllTransactions")}</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="preAuthorize"
                            checked={this.state.permissions.preAuthorize}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.preAuthorize}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Pre-Authorization</span>
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="creditSale"
                            checked={this.state.permissions.creditSale}
                            onChange={(e) => {
                              this.handleChangeCheckbox(e);
                            }}
                            value={this.state.permissions.creditSale}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <span>Credit Sale</span>
                        </label>
                      </Grid>

                      {(this.state.role > 4) ? <Grid item xs={12} sm={6}>
                        <Checkbox
                          id="creatNewUser"
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.permissions.creatNewUser}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <label>
                          <span>Create New User</span>
                        </label>
                      </Grid> : ''}
                    </Grid>

                  </Grid>
                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t("Save")} />
                  <Buttons
                    className={classes.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t("Back")}
                    onClick={this.props.history.goBack} />

                </Grid>


              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>


    )
  }
}
AddUser.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { fetchDetails, regularUserAdd })(withTranslation()(AddUser));
