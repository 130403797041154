import React, { Component } from "react";
import { DataGetApi } from "../../helpers/PostDataApi";
import { config } from "../../constants";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
import { Table } from "../../components/Table";
import { formatCurrency } from "../../helpers/commonFunction";
import IntegrationDownshift from './../../helpers/multiSelect';
import { locationList } from '../../actions/LocationAction'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { groupBy, sumBy, first } from "lodash";
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable , { MTableToolbar }from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
	table: {
		minWidth: "650",
		width: "100%",
		marginLeft: "44px",
		backgroundColor: "#f9f9f9",
		// backgroundColor: "#dbdcf9",
		display: "table",
		'border-spacing': "0",
		'border-collapse': "collapse",
	},

	childTable: {
		'& td': {
			width: "96px",
			padding: "10px"
		}
	},
	panelSummary: { flexDirection: "row-reverse", margin: "0px", padding: "0px" },
	childTableFund: {
		padding: "15px"
	}
});


class NewFundReporting extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      toDate: new Date(),
      userData: JSON.parse(localStorage.getItem("user")),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      tab: 0,
      preAuthActiveData: true,
      successMessages: false,
      misMatchError: [],
      msg: "",
      settingData: {},
      locationSelected: [],
      locationSelectedwithmid: [JSON.parse(localStorage.getItem('locationPaydetails'))],
      data: []
    };
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
   if(this.state.userData.role !== config.SUPER_ADMIN_ROLE){
     await this.getLocationData();
   } 
  }

  getLocationData() {    
    let data = { 'companyId': this.state.userData.company._id }
    let midArray = [];
    this.props.locationList(data).then((res) => {
      // this.setState({ data: res.payload.locationData.data.response, isLoading: false })
      midArray = res.payload.locationData.data.response;
      let midArray2 = [];
      let val = {};
      let val1 = {};
      let selectedMid = [];
      for (var x = 0; x < midArray.length; x++) {
        val = midArray[x];
        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
            }
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2";
            val1.address1 = val.address1;
            val1.address2 = val.address2;
            val1.contactName = val.contactName;
            val1.company = val.company;
            val1.city = val.city;
            val1.country = val.country;
            val1.createdAt = val.createdAt;
            val1.email = val.email;
            val1.id = val.id;
            val1.isDeleted = val.isDeleted;
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;
            val1.notes = val.notes;
            val1.paytraceKey = val.paytraceKey;
            val1.paytracePassword = val.paytracePassword;
            val1.paytraceUserName = val.paytraceUserName;
            val1.phone = val.phone;
            val1.state = val.state;
            val1.status = val.status;
            val1.timeZone = val.timeZone;
            val1.zip = val.zip;
            val1._v = val._v;
            if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
              selectedMid.push(val1)
            }
            midArray2.push(val1);
            val1 = {};
          }
        } else {
          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {

            if (midArray[x].mId) {

              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId";
              val1.address1 = val.address1;
              val1.address2 = val.address2;
              val1.contactName = val.contactName;
              val1.company = val.company;
              val1.city = val.city;
              val1.country = val.country;
              val1.createdAt = val.createdAt;
              val1.email = val.email;
              val1.id = val.id;
              val1.isDeleted = val.isDeleted;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.notes = val.notes;
              val1.paytraceKey = val.paytraceKey;
              val1.paytracePassword = val.paytracePassword;
              val1.paytraceUserName = val.paytraceUserName;
              val1.phone = val.phone;
              val1.state = val.state;
              val1.status = val.status;
              val1.timeZone = val.timeZone;
              val1.zip = val.zip;
              val1._v = val._v;
              if(JSON.parse(localStorage.getItem('locationPaydetails')).locationMid === val1.locationMid){
               selectedMid.push(val1)
              }
              midArray2.push(val1);
              val1 = {};
            }
          }
        }
      }

      // if (midArray2 && midArray2.length > 0) {
      this.setState({ data: midArray2, isLoading: false });       
      this.setState({ locationSelected: Object.keys(midArray2).map((k, v) => midArray2[k].id),  locationSelectedwithmid:  selectedMid });
      this.setState({
        locationsData: midArray2,
        isLoading: false,
      });
      // }
    });
  }  

  addLocation = (arr) => {
    let misMatchError = [];
    let allLocationChceked = this.state.data.length === arr.length ? true : false;
    this.setState({
      locationSelected: arr, misMatchError,
    })
  }

  addLocation2 = (arr) => {
    let allLocationChceked = this.state.locationSelectedwithmid.length === arr.length ? true : false;
    this.setState({
      locationSelectedwithmid: arr,      
    });
    this.tableRef.current.onQueryChange()  
  }
  
  // handleDepositAmount(rowData) {
  //   let { fundingDetails } = rowData.resultData;
  //   return Object.keys(fundingDetails).map((i) => {
  //     let sumAmount = sumBy(fundingDetails[i], (item) => item.Amount);
  //     return {
  //       [i]: sumAmount,
  //     };
  //   });
  // }

  handleDateChange = (date, key) => {
		if (date == 'Invalid Date' || date == null) {
			return false
		} else {
			this.setState({ [key]: date }, () => {
				setTimeout(() => {
					this.tableRef.current.onQueryChange()
				}, 1000)
			})
		}
	}

  render() {
    let columns = [
      {
        title: "Processing Date",
        field: "ProcessingDate",
      },
      {
        title: "Effective Date",
        field: "EffectiveDate",
      },
      {
        title: "Merchant DBA Name",
        field: "MerchantDBAName",
      }, 
      {
        title: "Merchant Id",
        field: "AccountNumber",
        sortable: false,
      }, 
      {
        title: "DDA Number",
        field: "DDANumber",
        sortable: false,
      },     
      {
        title: "Net Deposit Amount",
        field: "NetDepositAmount",
      }, 
    ];
   

    
    const { data, locationSelectedwithmid } = this.state;
    const classes = this.props.classes;
    return (
      <div>
        { (this.state.userData.role !== config.SUPER_ADMIN_ROLE) ?                      

        <Grid className="contentPart" container direction="row" className="navGrid"  style={{ marginBottom: '10px' }}>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ zIndex:"11" }}>
              <IntegrationDownshift
                isLocationExists={true}
                selectedLocations={locationSelectedwithmid}
                locationData={data}
                addLocation={this.addLocation}
                addLocation2={this.addLocation2}
              />
            </FormControl>
          </Grid>
        </Grid>
        : ''}
        <LoadingOverlay active={this.state.isLoading} spinner  text='Loading your content...'>
        <MaterialTable
            columns = {columns}
            tableRef={this.tableRef}
            title={"Funding Reporting"}
            options={{
              exportButton: {
                csv: true,
                pdf: false
              }
           }}
            components={{
              Toolbar: props => (
                <div>
                  <div className='left-control top-seciton'>
                    <MTableToolbar {...props} />
                    <div className={'dateFilter-table'}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline1"
                          maxDateMessage={'From date should not be greater than To date'}
                          maxDate={this.state.toDate}
                          // maxDate={this.state.toDate.setDate(this.state.toDate.getDate() + 30)}
                          label={'From Date'}
                          value={this.state.fromDate}
                          onChange={(date) => { this.handleDateChange(date, 'fromDate') }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline1"
                          // minDate={new Date()}
                          label={'To Date'}
                          value={this.state.toDate}
                          onChange={(date) => { this.handleDateChange(date, 'toDate') }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              )
            }}
            data={query => 
               new Promise((resolve, reject) => {
                let orderBy = query.orderBy !== undefined ? query.orderBy.field : "";
                let orderDirection = query.orderDirection !== "" ? query.orderDirection : "";
                 if (this.state.reload) {
                  query.search = "";
                  this.setState({ reload: false });
                }
                let user = JSON.parse(localStorage.getItem("user"));
                let token = user.token;
                let url = config.BASEURL;
                let midType = "";
                let allmids = [];
                if(this.state.locationSelectedwithmid[0] !== null){
                  allmids = this.state.locationSelectedwithmid.map( item => item.locationMid );
                }

                let param = {
                  'fromDate': this.state.fromDate,
                  'toDate': this.state.toDate,
                  'ac_no': allmids,
                  'role':this.state.userData.role,
                  // ac_no: [ac_no],
                  'per_page': query.pageSize,
                  'page': + (query.page + 1),
                //  'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true,
                  'orderBy': orderBy,
                  'orderDirection': orderDirection
                };
                DataGetApi(url + "getBatchFundData", param, token).then((result) => {
                  this.setState({ reload: false });
                  let modifiedData2 = [];
                  let optionsdate = { year: '2-digit', month: '2-digit', day: '2-digit' };
                  if(result.data.hasOwnProperty('data')){
                  modifiedData2 = result.data.data.batchesFund.map((item) => {
                    return {
                      ...item,
                      'ProcessingDate': new Date(item["Date"]).toLocaleString('en-US', optionsdate),
                      'EffectiveDate': new Date(item.fundingDetails[0]['Effective Date']).toLocaleString('en-US', optionsdate),
                      'MerchantDBAName': item.fundingDetails[0]['Merchant DBA Name'],
                      'AccountNumber': item.fundingDetails[0]['Account Number'],
                      'DDANumber': item.fundingDetails[0]['DDA Number'],
                      'NetDepositAmount':  formatCurrency(item["sumDepDate"])
                    }
                  });
                }
                  if (typeof query.search === "undefined" || query.search === "") {           
                    resolve({
                      data: modifiedData2,
                      page: result.data.pagination.page -1 ,
                      totalCount: result.data.pagination.totalCount,
                    });
                  } else {
                    let filterData = modifiedData2.filter((row) => {
                      return (
                        String(row.NetDepositAmount).indexOf(query.search) !== -1 ||
                        String(row.AccountNumber).indexOf(query.search) !== -1 ||
                        String(row.MerchantDBAName.toLowerCase()).indexOf(query.search.toLowerCase()) !== -1 ||
                        String(row.DDANumber.toLowerCase()).indexOf(query.search.toLowerCase()) !== -1 ||
                        String(new Date(row.ProcessingDate).toLocaleString()).indexOf(query.search) !== -1             
                      );
                    });
                    if (filterData.length > 0) {
                      query.filters.push(filterData);
                    }
                    resolve({
                      data: filterData,
                      page: result.data.pagination.page - 1,
                      totalCount: filterData.length,
                    });
                  }
                });
              })
            }
            detailPanel={[
              rowData => ({
                icon: (rowData.hasOwnProperty('reportRef')) ? (rowData.reportRef.length > 0 ? ChevronRightIcon : " ") :" ",
                render: rowData => {
                  return <>
                    <tab className={classes.table} size="small" aria-label="a dense table">
                      <TableBody>
                          <TableHead>
                            <TableRow className={classes.childTable}>
                              <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reference No. </TableCell>
                              <TableCell>        </TableCell>
                              <TableCell>Batch Deposit</TableCell>
                              <TableCell>Batch Fee</TableCell>
                              <TableCell>Net Deposit</TableCell>
                            </TableRow>
                          </TableHead>
                        {
                          rowData.hasOwnProperty("reportRef") && rowData.reportRef.map(
                            (item, index) => (
                              <Accordion >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary} aria-controls="panel2a-content" id="panel2a-header"	>
                                  <TableRow className={classes.childTable} key={index} >
                                    <TableCell>{item['Reference Number']}</TableCell>
                                    <TableCell>    </TableCell>
                                    <TableCell>{formatCurrency(item.depSumRef)}</TableCell>
                                    <TableCell>{formatCurrency(item.feeSumRef)}</TableCell>
                                    <TableCell>{formatCurrency(item.sumDepRef)}</TableCell>
                                  </TableRow>
                                </AccordionSummary>
                                <AccordionDetails>												 															
                                  <tab className={classes.table} size="small" aria-label="a dense table2">
                                    <TableHead >
                                      <TableRow className={classes.childTable}>
                                        <TableCell>Reference No.</TableCell>
                                        <TableCell>Record Sequence Number</TableCell>
                                        <TableCell>Routing Number</TableCell>
                                        <TableCell>Transaction Type</TableCell>
                                        <TableCell>Amount</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rowData.fundingDetails.map((items, idx) => (
                                        (item['Reference Number'] === items['Reference Number']) ?
                                          <TableRow key={idx + 'x'} className={classes.childTableFund}>
                                            <TableCell>{items['Reference Number']}</TableCell>
                                            <TableCell>{items['Record Sequence Number']}</TableCell>
                                            <TableCell>{items['Routing Number']}</TableCell>
                                            <TableCell>{items['Transaction Types']}</TableCell>
                                            <TableCell>{formatCurrency(items.Amount)}</TableCell>
                                          </TableRow>
                                          : '')
                                        )
                                      }
                                    </TableBody>
                                 </tab>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )
                        }
                      </TableBody>
                    </tab>
                  </>;
                },
              }),
            ]}
        />
        </LoadingOverlay>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {  
  const classes = useStyles()
  return <NewFundReporting classes={classes}  {...props} />;
};

export default connect(
  mapStateToProps,
  { locationList }
)(withTranslation()(ApplyingStylesOnClasses));