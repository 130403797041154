import { ActionType } from './../constants';

const initialState = {
  signin: [],
  loading: false,
  error: null,
  msg: null
};


const SigninReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SIGNIN_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionType.SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        signInInfo: action.payload.signinData
      };

    case ActionType.SIGNIN_ERROR:
      return {
        ...state,
        loading: false,
        signInInfo: action.payload.error
      };

    default:
      return state;
  }
}

export { SigninReducer };
