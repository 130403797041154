import React from "react"
import { Button, Collapse, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Buttons } from './../../components/Buttons';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';


const Notification = props => {
  const [open, setAlterOpen] = React.useState(true)
  const setOpen = attr => {
    setAlterOpen(attr);
  }
  return (
    <>
         <Collapse in={open}>
        <Alert severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              {/* <CloseIcon fontSize="inherit" /> */}
            </IconButton>
          }
        >
          <AlertTitle>TERMINAL SWITCH</AlertTitle>
          <p>This terminal is not authorized to get payment from a {props.card ? 'debit card' : 'credit card'}.</p>
          <Button size="small" color="primary" variant="outlined" onClick={() => props.callHitLoc()}  > Click here to switch to the {props.card ? 'traditional' : 'surcharge'}  terminal  </Button>
          {/* <Button onClick={() => {
                setOpen(false);
              }}> Cancel </Button> */}
        </Alert>
      </Collapse>
    </>
  )
}
Notification.propTypes = {
  callHitLoc: PropTypes.func,
  card: PropTypes.string
}
export default Notification
