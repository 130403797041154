import React  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SuccessMessage.module.scss';
 
function SuccessMessage(props) {
    return (
        <div>
            {props.successes.length > 0 ?
            <div   className={classNames([css.successes, props.className])}>
                {props.successes.map((seccess, index) => (
                    <div key={index}>{seccess}</div>
                ))}
            </div> : null}            
        </div>
    ) 
}

SuccessMessage.propTypes = {
    className: PropTypes.string,
    successes: PropTypes.arrayOf(PropTypes.string)
}

SuccessMessage.defaultProps = {
    className: '',
    successes: []
}

export default SuccessMessage;