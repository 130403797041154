import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Recurrence.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency } from '../../helpers/commonFunction';

class Recurring extends Component {
  constructor(props) {
    const date = new Date();
    super(props)
    this.state = {
      sideShow: true,
      isMounted:false,
      reload: false,
      isLoading: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
    }
    this.tableRef = React.createRef()
  }
  toDateFilter(date) {
    this.setState({ toDate: date })
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date })
  }
  getFrequency(val){
    let value = val.toLowerCase();
    let daily = 'daily';
    let quarterly = 'quarterly';
    let weekly = 'weekly';
    let annually = 'annually';
    let trimesterly = 'trimesterly';
    let biWeekly = 'bi-weekly';
    let biMonthly = 'bi-monthly';
    let monthly = 'monthly';
    let semiAnnually = 'semi-annually';
    if(daily.indexOf(value) !== -1){
      return "6";
    }else if(quarterly.indexOf(value) !== -1){
      return "2";
    }else if(weekly.indexOf(value) !== -1){
      return "5";
    }else if(annually.indexOf(value) !== -1){
      return "1";
    }else if(trimesterly.indexOf(value) !== -1){
      return "A";
    }else if(biWeekly.indexOf(value) !== -1){
      return "4";
    }else if(biMonthly.indexOf(value) !== -1){
      return "3";
    }else if(monthly.indexOf(value) !== -1){
      return "9";
    }else if(semiAnnually.indexOf(value) !== -1){
      return "8";
    }
  } 
   
 
  handleChangeId(id) {
    this.props.history.push('/RecurrenceDetails/' + id)
  }
  getTableData(query) {
    //** Refresh data //
    let orderBy = (query.orderBy!==undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection!=="") ? query.orderDirection : "";
    if (this.state.reload) {
      query.search = ""
      this.setState({ reload: false })
    }
    // End Refresh data
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true
        , 'toDate': this.state.toDate, 'fromDate': this.state.fromDate,'orderBy':orderBy,'orderDirection':orderDirection
      };
      DataGetApi(url + 'getRecurrenceList', param, token).then(result => {
        if (typeof query.search === "undefined" || query.search === "") {
          resolve({
            data: result.data.response,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          })
        } else {
          let filterData = result.data.response.filter((row => {
            return ((row.FREQUENCY === this.getFrequency(String(query.search))) || String(new Date(row.startDate).toLocaleString()).indexOf(String(query.search)) !== -1 || row.EMAIL.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || row.NAME.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || row.FINAL_STATUS.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || String(row.RECURRID).indexOf(String(query.search)) !== -1 || String(row.AMOUNT).indexOf(query.search) !== -1)
          }))
          if (filterData.length > 0) {
            query.filters.push(filterData)
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          })
        }
      })
    })
  }
  tableReload(status) {
    this.setState({ reload: status })
  }
  componentWillUnmount() {
    this.state.isMounted = false;
 }
  render() {
    const columns = [
      { title: 'Recurring Id', field: 'RECURRID', render: rowData => <Tooltip title="View Details"><span className={classes.Underline} onClick={(e) => this.handleChangeId(rowData.RECURRID)}  >{rowData.RECURRID}</span></Tooltip> },
      { title: 'Name', field: 'NAME' },
      { title: 'Amount', field: 'AMOUNT', render: rowData => (rowData.AMOUNT)?formatCurrency(rowData.AMOUNT):'$0.00' },
      { title: 'Frequency Type', field: 'FREQUENCY', lookup: { '6': 'Daily', '5': 'Weekly', '7': '1st and 15th', '4': 'Bi-Weekly', '3': 'Monthly', '9': 'Bi-Monthly', '2': 'Quarterly', '1': 'Annually', '8': 'Semi-Annually', 'A': 'Trimesterly' } },
      { title: 'Email', field: 'EMAIL' },
      { title: 'Status', field: 'FINAL_STATUS', render: rowData => 
      (rowData.FINAL_STATUS === "Success") ? <button type="button" className="btn paidBtn" >	Succeed</button> :
      (rowData.FINAL_STATUS === "Updated") ? <button type="button" className="btn RefundedBtn" >Updated</button> :
      (rowData.FINAL_STATUS === "Deleted") ? <button type="button" className="btn PenddingBtn" >Deleted</button> :
      (rowData.FINAL_STATUS === "Requested") ? <button type="button" className="btn RequestedBtn" >Requested</button>  : '' },
      { title: 'Card Number', field: 'MASKCARDNUMBER' },
      { title: 'Start Date', field: 'startDate', render: (date) => (new Date(date.startDate).toLocaleString()) },];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true
              }}
              columns={columns}
              createNew={false}
              data={(query) => this.getTableData(query)}
              title={'Recurring Billing'}
              search={true}
              exports={this.props.UserDetail.permissions.exportRecord}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={true}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(Recurring));