export default {
    VERSION: 'V.1.8',
    BASEURL: process.env.REACT_APP_BASEURL,
    APPURL: process.env.REACT_APP_APPURL,
    MAP_API_KEY: process.env.REACT_APP_MAP_API_KEY,
    IMGURL: process.env.REACT_APP_IMGURL,
    PDFURL: process.env.REACT_APP_PDFURL,
    ACTIVATION_LINK: 300,
    PAYMENT_LINK: 5,
    UPLOAD_IMAGE_SIZE: 5 * 1048576,
    MANAGER_ROLE: 3,
    ADMIN_ROLE: 2,
    SUPER_ADMIN_ROLE: 1,
    SUB_ADMIN_ROLE: 0,
    REGULAR_USER_ROLE: 4,
    TERMINALID: 2994035,
    PAYTRACE_KEY: 'liberty.pem',
    //PAYTRACE_AWS_PATH: 'https://paytrace-keys.s3.us-east-2.amazonaws.com/',
    PAYTRACE_AWS_PATH: 'https://paytrace-keys-prod2.s3.us-east-2.amazonaws.com/',
    // PAYTRACE_AWS_PATH: 'https://paytrace-key-staging.s3.us-east-2.amazonaws.com/',
    TEST_PAYMENTPAGE: 'https://testpayments.anywherecommerce.com/merchant/paymentpage',
    TEST_SECURED_CARD: 'https://testpayments.anywherecommerce.com/merchant/securecardpage',
    SUBSCRIPTION_SECURED_CARD: 'https://testpayments.anywherecommerce.com/merchant/subscriptionpage/register',
    RATE_LIMITER_MESSAGES: "Refresh limit exceeded. Please wait for 1 minute before syncing the Database",
    MID_TRADITIONAL: "Traditional",
    MID_SURCHARGE: "Surcharge",
    BIN_CHECKER_URL: 'https://api.bincodes.com/bin/',
     BIN_API_KEY: 'f409f72efed25ba7b4c1b6d0d750e793',

}