import { ActionType } from './../constants';

const initialState = {
  signin: [],
  loading: false,
  error: null,
  msg: null
};


const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CUSTOMER_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ActionType.CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customerInfo: action.payload.customerData
      };

    case ActionType.CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        customerInfo: action.payload.error
      };

    default:
      return state;
  }
}

export { CustomerReducer };
