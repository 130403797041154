import { ActionType } from './../constants';

const initialState = {
  userId: '',
  companyId: '',
  userEmail: '',
  locationIds: {},
  logo: '',
  permissions: {},
  settings: {},
  role: '',
  profile: null,
  companyStatus: true,
  companyDeleted: false,
  status: true,
  signin: [],
  loading: false,
  error: null,
  msg: null
};


const AdminUserReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case ActionType.USER_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ActionType.USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.userData
      };
    case ActionType.USER_EDIT_DATA:  
      return {
        ...state,
        loading: false,
        editInfo: action.payload.userData
      };
    case ActionType.FETCH_SUCCESS:
      return {
        ...state,
        userDataValue : action.payload.userData,
        userId: action.payload.userData._id,
        companyId: action.payload.userData.company ? action.payload.userData.company : '',
        userEmail: action.payload.userData.email,
        defaultLocation: action.payload.userData.defaultLocation ? action.payload.userData.defaultLocation : '',
        locationIds: action.payload.userData.location ? action.payload.userData.location : '',
        logo: action.payload.userData.company ? action.payload.userData.company.image : '',
        permissions: action.payload.userData.permissions ? action.payload.userData.permissions : '',
        settings: action.payload.userData.settings ? action.payload.userData.settings : '',
        role: action.payload.userData.role,
        profile: action.payload.userData.image ? action.payload.userData.image : null,
        companyStatus: action.payload.userData.company ? action.payload.userData.company.status : true,
        companyDeleted: action.payload.userData.company ? action.payload.userData.company.isDeleted : false,
        status: action.payload.userData.status
      };
    case ActionType.USER_ERROR:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.error
      };
    case 'INITIALIZE_STATE':
      return initialState;
    default:
      return state;
  }
}

export { AdminUserReducer };
