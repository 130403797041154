import React, { Component } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { locationList, getAllLocation, locationListOfCompanyAdmin } from "../../actions/LocationAction";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { first, find  } from "lodash";
import { config } from "../../constants";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      reload: false,
      misMatchError: [],
      locationListArray: [],
      userDetails: JSON.parse(localStorage.getItem("user")),
      personName: [],
      locationIds: [],

      error: {},
    };
  }

  componentDidMount() {
   
    if(this.state.userDetails.role === config.SUPER_ADMIN_ROLE){
      this.getAllLocation()
    }else{
      // this.getLocationData();
      this.getLocationDataNew();
    }
   
  }


  getAllLocation() {
    this.props.getAllLocation( { selected : [ "_id" ,"locationName" ] } ,  this.state.userDetails.token)
    .then(
      res => { 
        const locationListArray = res.payload.locationData.data.response.map(
          (items) => {
            return { id: items.id, locationName: items.locationName };
          }
        );  
        this.setState({ locationListArray });
      }
    )
  }

  getLocationDataNew(){
    let user = JSON.parse(localStorage.getItem("user"));
    // console.log(user.location, " user ");
    let locationIds = user.location.reduce((acc, curr) => {
      acc.push(curr._id)
      return acc
    }, []);
    // console.log(locationIds, " user 1");
    locationIds = locationIds.filter( function( item, index, inputArray ) {
      return inputArray.indexOf(item) == index;
    });
    // console.log(locationIds, " user 2");
    let data = { companyId: user.company._id, locationIds: locationIds};
    this.props.locationListOfCompanyAdmin(data).then((res) => { 
      // const locationListArray = res.payload.locationData.data.response.map(
      //   (items) => {
      //     return { id: items.id, locationName: items.locationName };
      //   }
      // );  
      
      let midArray = res.payload.locationData.data.response;

      let midArray2 = [];
      let val = {};
      let val1 = {};
      // console.log(midArray, "user data get");
      for (var x = 0; x < midArray.length; x++) {
        val = midArray[x];

        if (midArray[x].mId && midArray[x].mId2) {
          if (midArray[x].mId) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId + "_Traditional";
            val1.midType = "Traditional";
            val1.locationMid = val.mId;
            val1.midSelect = "mId";     
            val1.id = val.id;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;  
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName;               
            midArray2.push(val1);
            val1 = {};
          }
          if (midArray[x].mId2) {
            val1._id = val._id;
            val1._id_mid = val._id + "_" + val.mId2 + "_Surcharge";
            val1.midType = "Surcharge";
            val1.locationMid = val.mId2;
            val1.midSelect = "mId2"; 
            val1.id = val.id;
            val1.mId = val.mId;
            val1.mId2 = val.mId2;          
            val1.locationName = val.locationName.length > 40 ? val.locationName.substr(0, 40) + '...' : val.locationName; 
            midArray2.push(val1);
            val1 = {};
          }
        } else {
          if (midArray[x].mId && (!midArray[x].mId2 || midArray[x].mId2 == '' || midArray[x].mId2 == 'undefined')) {
            if (midArray[x].mId) {
              val1._id = val._id;
              val1._id_mid = val._id + "_" + val.mId + "_Traditional";
              val1.midType = "";
              val1.locationMid = val.mId;
              val1.midSelect = "mId"; 
              val1.id = val.id;            
              val1.mId = val.mId;
              val1.mId2 = val.mId2;
              val1.locationName = val.locationName.length > 50 ? val.locationName.substr(0, 50) + '...' : val.locationName;     
              midArray2.push(val1);
              val1 = {};
            }
          }
        }
      }

      this.setState({ locationListArray: midArray2 });
      // console.log(this.state.locationListArray, "user location");
    });
  }
  getLocationData() {
    let user = JSON.parse(localStorage.getItem("user"));    
    let data = { companyId: user.company._id };
    this.props.locationList(data).then((res) => { 
      const locationListArray = res.payload.locationData.data.response.map(
        (items) => {
          return { id: items.id, locationName: items.locationName };
        }
      );      
      this.setState({ locationListArray });
    });
  }

  handleChange(event) {
    // console.log(event.target.value, " event.target.value ");
    let locationId = this.state.locationListArray
      .filter((items) => event.target.value.indexOf((items.locationName.length > 40) ? items.locationName.substr(0, 40) + '...' + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType) : items.locationName + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType)) !== -1)
      .map((items) => items);
    // return false
    this.setState(
      {
        ...this.state,
        personName: event.target.value,
        locationIds: locationId,
      },
      () => this.props.handleChange(this.state.locationIds)
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">Locations</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={this.state.personName}
            onChange={(e) => this.handleChange(e)}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {this.state.locationListArray.map((items) => (
              <MenuItem key={items._id_mid} value={(items.locationName.length > 40) ? items.locationName.substr(0, 40) + '...' + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType) : items.locationName + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType)}>
                <Checkbox
                  checked={
                    this.state.personName.indexOf((items.locationName.length > 40) ? items.locationName.substr(0, 40) + '...' + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType) : items.locationName + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType)) > -1
                  }
                />
                <ListItemText primary={(items.locationName.length > 40) ? items.locationName.substr(0, 40) + '...' + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType) : items.locationName + ((items.midType !== '') ? "(" + items.midType + ")" : items.midType)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <LocationFilter classes={classes} {...props} />;
};

export default connect(
  mapStateToProps,
  { locationList, getAllLocation, locationListOfCompanyAdmin }
)(withTranslation()(ApplyingStylesOnClasses));
