import axios from 'axios';
// const user =  JSON.parse(localStorage.getItem('user'))

// const paytraceCredentials = { 'userName': user.company.paytraceUserName, 'password': user.company.paytracePassword, 'integratorId': user.company.paytraceIntegratorId }

export const DataApi = (data = null, apiURL = null, token = null, isHeader = false, csrf = null , paytraceCredentials = null) => {
     try{
    let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token, ...csrf,...paytraceCredentials }
    if (isHeader) {
        return axios.post(apiURL, data, { headers })

            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    } else {
        return axios.post(apiURL, data)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    }
}catch(error){
    return error;
}
}

export const DataPutApi = (apiURL = null, paramData, token = null,paytraceCredentials = null) => {
    return axios.put(apiURL, {
        params: paramData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer ' + token ,...paytraceCredentials}
    }, )
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

export const DataGetApi = (apiURL = null, paramData, token = null,paytraceCredentials = null) => {
    return axios.get(apiURL, {
        params: paramData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'bearer ' + token,...paytraceCredentials }
    },
    )
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(error)
            return error;
        });
}

export const checkCreditOrDebit = (apiURL = null, apiKey, bin) => {
      return axios
        .get(apiURL + `?format=json&api_key=${apiKey}&bin=${bin}`)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
    };