import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
// import { indexOf as _indexOf, find as _find } from "lodash";


function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { locationData, itemProps, selectedItem } = suggestionProps;
  const isSelected = (selectedItem || '').indexOf(locationData.locationName + "( " + locationData.midType + " )") > -1;
  return (
    <MenuItem
      {...itemProps}
      key={(locationData.locationName !== '' && locationData.midType !== '') ? locationData.locationName + '( ' + locationData.midType + ' )' : locationData.locationName}
      selected={true}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {(locationData.locationName !== '' && locationData.midType !== '') ? locationData.locationName + '( ' + locationData.midType + ' )' : locationData.locationName}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function getSuggestions(value, data , focusStatus = false) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ?  (focusStatus) ? data : []
    : (
      data.filter(suggestion => {
        const keep = count < 5 && suggestion.locationName.slice(0, inputLength).toLowerCase() === inputValue;
        if (keep) {
          count += 1;
        }
        return keep;
      })
    )
}

function DownshiftMultiple(props) {
  const { classes, isLocationExists, locationData, selectedLocations } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [focusStatus, setFocusStatus] = React.useState(false);

  

  useEffect(() => {
    const init = isLocationExists && selectedLocations ? selectedLocations : [];
    setSelectedItem(init);
  }, [selectedLocations])

  function handleKeyDown(event) {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }


  function handleFocusEvent(data) {
    setFocusStatus(data);
  }

  function handleInputChange(event) {
    setFocusStatus(false);
    setInputValue(event.target.value);
  }

  function handleChange(item) {
    if (item) {
      let newSelectedItem = [...selectedItem];
      if (!selectedItem.find(s => (s._id_mid === item._id_mid))) {
        newSelectedItem = [...newSelectedItem, item];
      }
      setInputValue('');
      props.addLocation(newSelectedItem.map(m => m._id));
      props.addLocation2(newSelectedItem.map(m => m));
      setSelectedItem(newSelectedItem);
    }
  }


  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    let indexofArray = newSelectedItem.filter(i => (i._id_mid === item._id_mid));
    const indexOfArrayInFilter = newSelectedItem.findIndex((item) => {
      return item._id_mid === indexofArray[0]._id_mid && item._id === indexofArray[0]._id && item.midType === indexofArray[0].midType
    });
    newSelectedItem.splice(indexOfArrayInFilter, 1);
    props.addLocation(newSelectedItem.map(m => m._id));
    props.addLocation2(newSelectedItem.map(m => m));
    setSelectedItem(newSelectedItem);
  };
  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={(selectedItem.length > 0) ? selectedItem : []}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: 'Select multiple location',
        });

        return (
          <div className={classes.container}>
            {
              renderInput({
                fullWidth: true,
                classes,
                label: 'Location',
                InputLabelProps: getLabelProps(),
                InputProps: {
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item._id + item.midType}
                      tabIndex={-1}
                      label={(item.locationName !== '' && item.midType !== '') ? item.locationName + '( ' + item.midType + ' )' : item.locationName}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur : event =>{
                    setFocusStatus(false)
                  },
                  onChange: event => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus : event => {
                    handleFocusEvent(true);                   
                    onChange(event);                    
                  },
                },
                inputProps,
              })
            }
            {
              <Paper className={classes.paper} square> {
                // console.log("23423423" ,getSuggestions(inputValue2, locationData))
              }
                {getSuggestions(inputValue2, locationData , focusStatus).map((locationData, index) =>
                  renderSuggestion({
                    locationData,
                    index,
                    itemProps: getItemProps({ item: locationData }),
                    highlightedIndex,
                    selectedItem
                  }),
                )}
              </Paper>
            }
          </div>
        );
      }}
    </Downshift>
  );
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // height: 0,
    marginTop: 8
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    minHeight: '27px'
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function IntegrationDownshift(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DownshiftMultiple classes={classes} selectedLocations={props.selectedLocations} isLocationExists={props.isLocationExists} addLocation={props.addLocation} addLocation2={props.addLocation2} locationData={props.locationData} />
    </div>
  );
}
