import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { TextfieldWithLable } from './../../components/TextfieldWithLable';
import { Textfield } from './../../components/Textfield';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import classes from './RequestForm.module.scss';
import LoadingOverlay from 'react-loading-overlay';
import classNames from 'classnames'
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { connect } from 'react-redux';
import { setInputErrorState, fetchDetails, setInputState, reset, invoiceGenerate, emailInvoice, saveFormData } from './../../actions/RequestFormAction';
import { CallerInfo } from '../CallerInfo'
import { VehicleInfo } from '../VehicleInfo'
import { ServiceInfo } from '../ServiceInfo'
import { PickupDropLocation } from '../PickupDropLocation'
import { AutoSuggestInput } from '../AutoSuggestInput'
import InputAdornment from '@material-ui/core/InputAdornment';
import { Topheader } from './../../components/Topheader';
import validator from '../../helpers/checkValidations';

const exceptionsValidation = [
  'zipForCalc', 'error', 'success', 'distanceDetails', 'suggestionsData', 'touched', 'notes',
  'endAddress', 'pickUpNotes', 'problemType', 'open', 'userAccount', 'basePrice', 'system'
];


class RequestForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      token: '',
      responseError: false,
      misMatchError: [],
      paytraceCredentials:{},
      successMessages: false,
      redirectTransaction: false,
      saveDraft: false,
      error: {
        passwordError: false,
        userNameError: false
      }
    }
  }

  handleClickOpen = () => { this.props.setInputState('open', true); this.props.setInputState('success', false); };

  handleClose = () => { this.props.setInputState('open', false); this.redirect(); };
  UNSAFE_componentWillMount() {
    //redirect to intake form for road assitence client
    let user = JSON.parse(localStorage.getItem('user'))
    
    if (user.company._id !== '5ee85adef500853860700888') {
      this.props.history.push('/AddInvoice')
    }
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    const paytraceCredentials = { 'userName': user.company.paytraceUserName, 'password': user.company.paytracePassword, 'integratorId': user.company.paytraceIntegratorId }
    this.setState({'paytraceCredentials':paytraceCredentials})

    this.props.invoiceGenerate()
    this.props.reset()

    if (user.token) {
      this.props.setInputState('userAccount', user.email);
      this.props.setInputState('companyId', user.company._id);
      this.props.setInputState('createdBy', user._id, );
    }

  }
  setMethod(event) {
    this.props.setInputState(event.target.name, event.target.value);
  }

  handleChangeName(event) {
    this.setState({ [event.target.name]: event.target.value, responseError: false });
  }
  textHandler(event) {
    //    const inputDemo= {invoice: "", firstName: "", lastName: "", phoneNumber: "", year: ""};
    const { setInputState, input, setInputErrorState } = this.props;
    const { touched } = this.props.input;
    let stop = null;
    let pause = null;
    let value = event.target.value.toString();
    let id = event.target.id;
    switch (event.target.id) {
      case 'email':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        } break;
      case 'phoneNumber':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        } break;
      // case 'phoneNumber':
      //   if (value.length > 10 || isNaN(value)) {
      //     pause = true;
      //   } break;
      case 'zip':
        if (value.length > 5) {
          stop = true;
        } break;
      case 'year':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        } break;
      case 'amount':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        } break;

      default:
        stop = false;
    }
     
    if (!event.target.id) {
      setInputState('amount', event.target.value);
    }
    else {
      if (pause) {
        setInputState(event.target.id, input[event.target.id]);
      } else {

        if (stop) {
          setInputErrorState(id, value)
        }
        else {
          setInputState(event.target.id, event.target.value);
        }
      }
    }
  }



  displayErrorFields(state) {
    Object.keys(state).forEach((key) => {
      if (exceptionsValidation.indexOf(key) >= 0) { }
      else if (!state[key].length > 0 ) {
        this.props.setInputErrorState(key, '');
      }
    });
  }

  dynamicFieldValidation(serviceType, doAllWheelsTurn) {
    const newExceptions = [];
    switch (serviceType) {
      case 'Fuel/Fluids':
        newExceptions.push('neutral', 'doAllWheelsTurn', 'doesFrontWheelsTurn', 'doesBackWheelsTurn');
        break;
      case 'Towing':
        newExceptions.push('regularGasOrDiesel');
        if (doAllWheelsTurn === 'YES') {
          newExceptions.push('doesFrontWheelsTurn', 'doesBackWheelsTurn');
        }
        break;
      default:
        newExceptions.push('regularGasOrDiesel', 'neutral', 'doAllWheelsTurn', 'doesFrontWheelsTurn', 'doesBackWheelsTurn');
        break;
    }

    exceptionsValidation.push(...newExceptions);
  }

  validate(state) {
    let valid = true;
     
    this.dynamicFieldValidation(this.props.input.serviceType, this.props.input.doAllWheelsTurn);

    Object.keys(state).forEach(key => {
      if (exceptionsValidation.indexOf(key) >= 0) { }
      else if ((key === 'make' || key === 'color') ? (!Object.keys(state[key]).length > 0) : !state[key].length > 0) {
        valid = false;
      }
    });
    Object.keys(state.error).forEach(key => {
      if(state.error[key]){
        valid = false;
      }
    })
    return valid;
  }
 
  saveAsDraft(state) {
    let misMatchError = [];
    let successMessages = false;

    if (this.validate(this.props.input)) {
      this.setState({ isLoading: true })
      this.props.saveFormData({ ...state, saveAsDraft: true }).then((result) => {
        window.scrollTo(0, 0)
        if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
          
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
          });
          this.handleClickOpen();
          setInputState('setInputState', false)
        }
        if (result !== undefined && result.type === 'INVOICE_ERROR') {
          misMatchError.push(this.props.info.invoiceInfo.data.message)
          this.setState({ successMessages, isLoading: false, misMatchError }, () => {
            this.props.reset()
          });
        }
      });
    }
  }
  sendLinkToPhone(state) {
    let misMatchError = [];
    let successMessages = false;

    if (this.validate(this.props.input)) {
      this.setState({ isLoading: true })
      window.scrollTo(0, 0)
      
      this.props.saveFormData({ ...state, type: 'emailLink', saveAsDraft: false }).then((result) => {
        if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
          });
          this.handleClickOpen();
          setInputState('setInputState', false)

        }
        if (result !== undefined && result.type === 'INVOICE_ERROR') {
          misMatchError.push(this.props.info.invoiceInfo.data.message)
          this.setState({ successMessages, isLoading: false, misMatchError }, () => {
            this.props.reset()
          });

        }

      });
    }

  }

  redirect() {

    if (this.props.input.invoice) {
      this.props.reset()
      let Id = this.props.info.invoiceInfo.data.response.id
      this.props.history.push('/InvoiceEdit/' + Id)
    }
  }
  render() {
    const {
      error, invoice, email, amount, success, notes, sendTo, touched, open, regularGasOrDiesel, willAnyoneBeWithTheVehicle, serviceType
    } = this.props.input;
    const { t, setInputState, reset } = this.props;
    const { redirectTransaction } = this.state
    if (success) { this.handleClickOpen(); setInputState('setInputState', false); }
    return (
      <div className="rightPanel">
        <Topheader />
        {(redirectTransaction) ? <Redirect to='/Invoices' /> : ''}

        <div className={classNames([classes.Appheader, classes.requestformpage])}>
          <LoadingOverlay
            className={classes.requestformpageLoading}
            active={this.state.isLoading}
            spinner
            text='Fetching your response ...'>
            <div className={classes.intakePageHeading}>
              <img src="/assets/images/RAL-Logo.png" style={{ maxWidth: '95%' }} height={'100rem'} alt="logo" /> <br />
              <h2> Roadside Assistance</h2>
            </div>
            <br />

            <FormControl>
              <Grid container align='center'>
                <Grid item xs={6} sm={6} md={6}>
                  <a href='/AdminPortal' style={{ textDecoration: 'none' }}>
                    <Button className={classes.adminBtn} color={'secondary'} style={{ color: '#FFF', width: '10rem' }} variant="contained">
                      Admin Page
                </Button>
                  </a>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <a href='https://Refundlink.roadsideassistanceltd.com' style={{ textDecoration: 'none' }}>
                    <Button className={classes.refundBtn} color={'secondary'} style={{ color: '#FFF', width: '10rem' }} variant="contained">
                      Refund Request
                </Button>
                  </a>
                </Grid>
              </Grid>
            </FormControl>
            {this.state.misMatchError.map((e) => (
              < ErrorMessage errors={[e]} />
            ))}
            {this.state.successMessages ?
              <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''
            }
            <DialogBox open={open} handleClose={this.handleClose.bind(this)} message={(this.state.successMessages) ? this.props.info.invoiceInfo.data.message : ''} />
            <Paper className={classes.containerControl} id={'paper'}>
              <form autoComplete={'none'}>
                <TextfieldWithLable
                  required={true}
                  error={this.state.error.userNameError}
                  id="invoice"
                  type="text"
                  labels={t("Invoice")}
                  value={invoice}
                  width={true}
                  onChange={(e) => this.textHandler(e)}
                  disabled={true}
                />
                <CallerInfo onChange={(evt) => this.textHandler(evt)} /> <hr />
                <VehicleInfo
                  onChange={evt => this.textHandler(evt)}
                  onChangeDropDown={event => setInputState(event.target.name, event.target.value)}
                />

                <hr />
                <ServiceInfo onChange={event => setInputState(event.target.name, event.target.value)} /> <hr />
                <PickupDropLocation
                  onChangeDropDown={event => setInputState(event.target.name, event.target.value)}
                  onChangeTextHandler={evt => this.textHandler(evt)}
                />
                <Grid container align='center'>
                  {/* <Grid item xs={2} sm={2} md={2}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoSuggestInput />
                  </Grid>
                </Grid>
                <strong> Payment Info. </strong>
                <TextfieldWithLable
                  required={true}
                  error={error['email']}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={email}
                  width={true}
                  onChange={evt => this.textHandler(evt)}
                />
                <br style={{ lineHeight: '5.5rem' }} />
                <FormControl>
                  <Grid container align='center'>
                    <Grid item xs={1} sm={2} md={2} />

                    <Grid item xs={5} sm={4} md={4}>
                      <Textfield
                        required={true}
                        error={error['amount']}
                        id="amount"
                        type="text"
                        labels={t("Amount")}
                        value={amount}
                        width={true}
                        onChange={(evt) => this.textHandler(evt)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} sm={4} md={4}>
                      <Textfield

                        value={
                          isNaN(((parseFloat(amount) * 3.50 / 100) + parseFloat(amount)).toFixed(2)) ?
                            ''
                            : ((parseFloat(amount) * 3.50 / 100) + parseFloat(amount)).toFixed(2)
                        }
                        labels={t("Total Amount")}
                        fullWidth disabled={true}
                      />
                    </Grid>

                    <Grid item xs={1} sm={2} md={2} />
                  </Grid>
                </FormControl>
                <TextfieldWithLable
                  id="notes"
                  type="text"
                  labels={t("Notes")}
                  value={notes}
                  width={true}
                  onChange={(evt) => this.textHandler(evt)}
                  rows={4}
                  multiline={true}
                />


                <RadioGroup
                  aria-label="Options" name="gender2" value={sendTo} style={styles.radioGroupStyle}
                  onChange={(event) => setInputState('sendTo', event.target.value)}
                >

                  <p style={styles.textPStyle}> <i> How would you like to send the payment link? </i> </p>

                  <FormControlLabel
                    value="email" control={<Radio color="primary" />} label="Email" labelPlacement="start"
                  />
                  <FormControlLabel
                    value="phone" control={<Radio color="primary" />} label="Phone" labelPlacement="start"
                  />
                </RadioGroup>
                <Grid container align="center">
                  <Grid item xs={12} sm={4}>
                    <Button className={classes.saveforLetterBtn}
                      fullWidth variant="contained" color="secondary"
                      onClick={() => {
                        if (this.validate(this.props.input)) { this.saveAsDraft(this.props.input); }
                        else {
                          this.displayErrorFields(this.props.input);
                          alert('Please complete the required fields of the form!');
                        }
                      }}
                    > Save for Later </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button className={classes.sendPayment}
                      fullWidth variant="contained"
                      onClick={() => {
                        if (this.validate(this.props.input)) {
                          this.sendLinkToPhone(this.props.input)
                          if (document.getElementById('origin'))
                            document.getElementById('origin').value = '';
                          if (document.getElementById('destination'))
                            document.getElementById('destination').value = '';
                          reset()
                        }
                        else {
                          this.displayErrorFields(this.props.input);
                          alert('Please complete the required fields of the form!');
                        }
                      }}
                      disabled={willAnyoneBeWithTheVehicle === 'NO' || (serviceType === 'Fuel/Fluids' && regularGasOrDiesel === 'DIESEL GAS')}
                      color="primary"
                    >
                      Send Payment Link
    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth variant="contained"
                      onClick={async () => {
                        if (document.getElementById('origin'))
                          document.getElementById('origin').value = '';
                        if (document.getElementById('destination'))
                          document.getElementById('destination').value = '';
                        reset();
                        setTimeout(() => reset(), 10);
                      }}
                    >
                      Reset
    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
const styles = {
  radioGroupStyle: { margin: '30px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  stateStyle: { width: '62%', margin: '0.5rem', marginTop: '1rem' },
  textPStyle: { fontSize: '19px', color: '#000', marginRight: '50px' },
  zipStyle: { width: '62%', marginLeft: '1rem' }
};
RequestForm.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    input: state.InputReducer,
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { setInputErrorState, saveFormData, fetchDetails, emailInvoice, invoiceGenerate, setInputState, reset })(withTranslation()(RequestForm));