import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Table } from '../../components/Table'
import { Topheader } from './../../components/Topheader';
import { companyList, companyDelete, companyStatus } from '../../actions/CompanyAction'
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config'
import {DataGetApi} from '../../helpers/PostDataApi'

class Company extends Component {
  constructor(props) {
    const date = new Date();
    super(props)
    this.state = {
      data: [],
      sideShow: true,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
    }
    
  }
  companyDeleteStatus(e, data) {
    this.props.companyDelete(data).then((res) => {
      window.location.reload(false)
    })
  }

  companyStatusToggle(e, data) {
    this.props.companyStatus(data).then((res) => {
   window.location.reload(false)
    })
  }
  toDateFilter(date) {
    this.setState({ toDate: date })
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date })
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
   
  handleClickRefresh() {
   window.location.reload(false)
  }
  tableReload(status){
    this.setState({reload:status})
  }
  getTableData(query){
    if(this.state.reload){
      query.search=""
      this.setState({reload:false})
    }
    return   new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL
      let param = {'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll':(typeof query.search=="undefined" || query.search=="")?false:true
      , 'toDate': this.state.toDate, 'fromDate': this.state.fromDate     };
      DataGetApi(url + 'companyList', param, token).then(result => {
        resolve({
          data: (typeof query.search=="undefined" || query.search=="")?result.data.response:result.data.response.filter((row=>{
            return (row.dbaName.toLowerCase().indexOf(String(query.search.toLowerCase())) !== -1 || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !=-1 ||  row.contactName.toLowerCase().indexOf(String(query.search)) !== -1 || String(row.mcc).toLowerCase().indexOf(query.search) !== -1)
          })),
          page: result.data.pagination.page - 1,
          totalCount: result.data.pagination.totalCount,
        })
      }).catch(error => {
        console.log('fetching clients error : ', error);
        reject(error);
      })
    })
  }
  render() {  

    const columns = [
      { title: 'MCC', field: 'mcc', type: 'string' },
      { title: 'DBA Name', field: 'dbaName' },
      { title: 'Contact Name', field: 'contactName' },
      { title: 'Gateway', field: 'Gateway' },
      { title: 'Host', field: 'Host', lookup: { 34: 'İstanbul', 63: 'Azure' } },
      { title: 'Creation Date', field: 'timeZone', render: (date) => (new Date(date.timeZone).toLocaleString()) },
    ];

    const data = this.state.data;
    const title = "Company";
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <div className="contentPart">
            <Table
              tableReload={(e)=>this.tableReload(e)}
              companyDeleteAction={(e, data) => this.companyDeleteStatus(e, data)}
              companyActiveAction={(e, data) => this.companyStatusToggle(e, data)}
              columns={columns}
              data={(query)=>this.getTableData(query)}
              permissions={userPermissions.permissions}
              title={title}
              search={true}
              exports={true}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={false}
            />
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, { companyList, companyDelete, companyStatus })(withTranslation()(Company));
