import { ActionType } from "./../constants";

const initialState = {
  status: true,
  loading: false,
  error: null,
  msg: null,
  batchInfo:[],
  lastUpdateBatchDate : []
};

const BatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.BATCH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        batchInfo: action.payload.batchData,
      };
    case ActionType.BATCH_LAST_UPDATED_DATE:
      return {
        ...state,
        loading: false,
        lastUpdateBatchDate: action.payload.LastbatchSuccess,
      };

    case ActionType.BATCH_ERROR:
      return {
        ...state,
        loading: false,
        batchInfo: action.payload.error,
      };
    case "INITIALIZE_STATE":
      return initialState;
    default:
      return state;
  }
};

export { BatchReducer };
