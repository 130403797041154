
export const  doesFileExist = (urlToFile)=> {
    let xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    // xhr.setRequestHeader('Content-Type', 'application/xml','Access-Control-Allow-Origin','http://localhost:3006');
    // xhr.send();
   
    if (xhr.status == "404") {
        return false;
    } else {
        return true;
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


export const formatCurrency = (cents)=> {
  return  cents ? formatter.format(parseFloat(cents)) : "$0.00";
  // return cents?"$"+Number(cents).toFixed(2):'$0.00';
}

export const formatAmount = (cents)=> {
  return cents?Number(cents).toFixed(2):'0.00';
}

export const formatDate = (cents)=> {
  return cents?Number(cents).toFixed(2):'0.00';
}

export const formatDateTime = (cents)=> {
  return cents?Number(cents).toFixed(2):'0.00';
}

    /**
   * Get base amount from transaction collection
   * @param {Object} item
   * @returns
   */
    export const getBaseAmount = (item) => {
        let svc_fee = (  item.SVC_FEE !== undefined)  ? item.SVC_FEE.toString().replace(/\$/g, "") : 0;
        let surcharge_fee = (item.SURCHARGE_FEE  !== undefined) ? item.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0;
        let tax =  (item.TAX  !== undefined)   ? item.TAX.toString().replace(/\$/g, "") : 0;
        return ( formatCurrency(parseFloat(parseFloat(item.AMOUNT) - ( parseFloat(tax) + parseFloat(svc_fee) + parseFloat(surcharge_fee)))));
    };

    export const getBaseAmount1 = (item) => {
       let svc_fee = (  item.SVC_FEE !== undefined)  ? item.SVC_FEE.toString().replace(/\$/g, "") : 0;
       let surcharge_fee = (item.SURCHARGE_FEE  !== undefined) ? item.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0;
       let tax =  (item.TAX  !== undefined)   ? item.TAX.toString().replace(/\$/g, "") : 0;
       console.log("small tax", tax)
       if(item.CAPTURE_AMOUNT)
       return ( formatCurrency(parseFloat(parseFloat(item.CAPTURE_AMOUNT))));
       else{
        return ( formatCurrency(parseFloat(parseFloat(item.AMOUNT))));
       }
    };
     
    export const getBaseAmount2 = (item) => {
      let svc_fee = (  item.SVC_FEE !== undefined)  ? item.SVC_FEE.toString().replace(/\$/g, "") : 0;
      let surcharge_fee = (item.SURCHARGE_FEE  !== undefined) ? item.SURCHARGE_FEE.toString().replace(/\$/g, "") : 0;
      let tax =  (item.TAX  !== undefined)   ? item.TAX.toString().replace(/\$/g, "") : 0;    
      if(item.CAPTURE_AMOUNT)
        return (formatCurrency(parseFloat(parseFloat(item.CAPTURE_AMOUNT) + ( parseFloat(tax) + parseFloat(svc_fee) + parseFloat(surcharge_fee)))));
      else{
        return (formatCurrency(parseFloat(parseFloat(item.AMOUNT) + ( parseFloat(tax) + parseFloat(svc_fee) + parseFloat(surcharge_fee)))));
      }
    };

    export const userRole = (role)=>{
      let userRole = new Map();
      userRole.set(1,"Super Admin");
      userRole.set(2,"Admin");
      userRole.set(3,"Manager");
      userRole.set(4,"User");
      return userRole.get(role)
    }