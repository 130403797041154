import { combineReducers } from 'redux';
import { LoginReducer } from './LoginReducer';
import { SigninReducer } from './SigninReducer';
import  {InputReducer}  from './InputReducer'
import { UserReducer } from './UserReducer';
import { PaymentReducer } from './PaymentReducer';
import { CompanyReducer } from './CompanyReducer';
import { LocationReducer } from './LocationReducer';
import { AdminUserReducer } from './AdminUserReducer';
import { InvoiceReducer } from './InvoiceReducer';
import { CustomerReducer } from './CustomerReducer';
import { ProductReducer } from './ProductReducer';
import { ContentReducer } from './ContentReducer';
import { TicketReducer } from './TicketReducer';
import { BatchReducer } from './BatchReducer';

import {ResetReducer} from './ResetReducer'

const appReducers = combineReducers({ContentReducer,ProductReducer,TicketReducer,AdminUserReducer,CompanyReducer,LocationReducer,LoginReducer,SigninReducer,InputReducer,user: UserReducer,PaymentReducer,InvoiceReducer,CustomerReducer,BatchReducer,ResetReducer});

export default appReducers;