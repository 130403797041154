import React from 'react';
import { withRouter } from 'react-router-dom'
import cookie from 'react-cookies'
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { grey } from '@material-ui/core/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { LocationBar } from '../../components/LocationBar'
import { initializeState } from '../../actions/LogoutAction'
import { connect } from 'react-redux'
import { config } from '../../constants'
import classNames from 'classnames'
import IdleTimer from 'react-idle-timer'
import classes from './Header.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative', 
    },
    paper: {
      marginRight: theme.spacing(2),
      position: 'absolute',
      width: '200'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    pageHeading: {
      fontWeight: '300',
      padding: theme.spacing(2),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,                   
      backgroundColor: 'inherit',
      '&:hover': {
        // backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,     
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '100%',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fake: {
      backgroundColor: grey[200],
      height: theme.spacing(1),
      margin: theme.spacing(2),
      // Selects every two elements among any group of siblings.
      '&:nth-child(2n)': {
        marginRight: theme.spacing(3),
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    paperBox: {
      padding: theme.spacing(2),
      margin: theme.spacing(3),
    },
    textField: {
      width: '100%',
    },
    username: {
      color: 'inherit',
    },
    plusMinusBtn: {
      fontSize: '1.5rem',
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    }
  }),
);

class Topheader extends React.Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.state = {
      functionToCallInLocationBar: null,
      open: false,
      drop: false,
      redirect: false,
      user: JSON.parse(localStorage.getItem('user')),
      updateLocationBar: {}
    }   
  }

  componentDidMount() {
    if (typeof this.props.setCallable !== "undefined")
      this.props.setCallable(this.childFunction);      
  }

  childFunction = () => {
    this.state.functionToCallInLocationBar()
  }
  componentDidUpdate(Prevprops) {
    if (this.state.user.role !== config.SUPER_ADMIN_ROLE) {
      if (!this.props.info.status) {
        alert('User has been Deactivated !');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('locationArrIds');
        cookie.remove('locationId', { path: '/' });
        this.props.initializeState();
        this.props.history.push('/');
      }
      if (!this.props.info.companyStatus) {
        alert('Company has been Deactivated !');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        cookie.remove('locationId', { path: '/' });
        this.props.initializeState();
        this.props.history.push('/');
      }
      if (this.props.info.companyDeleted) {
        alert('Company has been Deleted !');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        cookie.remove('locationId', { path: '/' });
        this.props.initializeState();
        this.props.history.push('/');
      }
    }
  }



  logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('locationId', { path: '/' });
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('locationId');
    localStorage.removeItem('locationPaydetails');
    localStorage.removeItem('selected');
    localStorage.removeItem('locationArrIds');
    localStorage.removeItem('localDefaultLocation');
    localStorage.removeItem('adminId');
    this.props.initializeState();
    this.props.history.push('/Login');
  }
  bodyclickHandler = () => {
    document.body.classList.toggle('toggle');
  }
  
  handleClick = () => {
    this.setState({ open: (this.state.open) ? false : true });
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };

  toHeaderClickHandler = ()=> {
    this.props.terminalClickHandler()
  }

  handleOnIdle = (e) => {
    localStorage.removeItem('user');
    localStorage.removeItem('locationId', { path: '/' });
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('locationId');
    localStorage.removeItem('locationPaydetails');
    localStorage.removeItem('selected');
    localStorage.removeItem('locationArrIds');
    localStorage.removeItem('localDefaultLocation');
    localStorage.removeItem('adminId');
    this.props.initializeState();
    this.props.history.push('/Login');
  }
  fetchRole = (val) => {
    switch (val) {
      case 0:
        return 'Sub Admin';
      case 1:
        return 'Super Admin';
      case 2:
        return 'Admin';
      case 3:
        return 'Manager';
      case 4:
        return 'User';
      default:
        return 'Manager';
    }
  }
  render() {
    const { classes } = this.props
    const { _id } = this.state.user
    return (

      <header className="customHeader">
        <div className={classes.root}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 30}
          onActive={this.handleOnActive}
          onIdle={(e) => this.handleOnIdle(e)}
          onAction={this.handleOnAction}
          debounce={250}
        />
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={(e) => this.bodyclickHandler(e)}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer">
                <MenuIcon />
              </IconButton>

              {/* <Typography className={classes.title} variant="h6" noWrap> */}

              <Typography className={classNames([classes.title, 'roleName'])} variant="h6" noWrap>
                {this.state.user.company ? this.state.user.company.dbaName : 'Titanium Payments'} {'(' + this.fetchRole(this.state.user.role) + ')'}
              </Typography>

              <div className={classes.search, 'custom-styling-res'}>
                {(this.state.user.role > 1) ? 
                <LocationBar clickEventHandle = { this.toHeaderClickHandler }   setCallableLocation={callable => this.setState({ functionToCallInLocationBar: callable })} passLocationData={this.state.updateLocationBar} location={(this.state.user.locationSelectedwithmid

                ) ? this.state.user.locationSelectedwithmid

                  : ''} /> : ''}
              </div>

              <ClickAwayListener onClickAway={(e) => this.handleClickAway(e)}>
                <div className="userName">
                  <Button className={classes.username} onClick={(e) => this.handleClick(e)}><Typography noWrap>{(this.state.user.role === 1 || this.state.user.role === 0 || this.state.user.role === 3 || this.state.user.role === 4) ? (this.props.info.hasOwnProperty('userDataValue') ? ('Welcome ' + this.props.info.userDataValue.firstName.charAt(0).toUpperCase(0) + this.props.info.userDataValue.firstName.slice(1)) : ('Welcome ' + this.state.user.firstName.charAt(0).toUpperCase(0) + this.state.user.firstName.slice(1))) : (this.state.user.company) ? this.state.user.company.dbaName : ''}</Typography></Button>
                  {this.state.open ? (
                    <Paper className={classes.paper} style={{ zIndex: '1' }}>
                      <MenuList>
                        <Link component={RouterLink} to={`/Profile/${_id}`}>
                          <MenuItem>Profile</MenuItem>
                        </Link>
                        <MenuItem onClick={(e) => { if (window.confirm('Are you sure want to logout.')) { this.logOut(e) } }}>Logout</MenuItem>
                      </MenuList>
                    </Paper>
                  ) : null}
                </div>
              </ClickAwayListener>

              <img className="profile-logo" alt="logo" src={(this.props.info.profile) ? config.PAYTRACE_AWS_PATH + this.props.info.profile : this.state.user.image ? config.PAYTRACE_AWS_PATH + this.state.user.image : config.IMGURL + 'blank.png'} />
            </Toolbar>
          </AppBar>
        </div>

      </header>

    );
  }
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles()
  return (<Topheader classes={classes} {...props} />)
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { initializeState })(withRouter(ApplyingStylesOnClasses));