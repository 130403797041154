import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { getInvoiceList } from '../../actions/PaymentAction'
import { fetchDetails } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Invoice.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi } from '../../helpers/PostDataApi';
import { switchCase } from '@babel/types';
import { formatCurrency } from '../../helpers/commonFunction';
import { Colorstatus } from '../ColorCodeStatus';


class Invoice extends Component {
  constructor(props) {
    const date = new Date();
    super(props)
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      userData: { permissions: {} },
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 45)),
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === "FETCH_SUCCESS" && this.props.userInfo.userId === "") {
        this.setState({ userData: { ...res.payload.userData } }, () => {
        })
      }
    })
  }

  toDateFilter(date) {
    this.setState({ toDate: date })
  }

  fromDateFilter(date) {
    this.setState({ fromDate: date })
  }

  handleChangeId(invoice, status, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.company.id === '5ee85adef500853860700888') {
      this.props.history.push('/InvoiceEdit/' + id);
    } else {
      if (status) {
        this.props.history.push('/EditInvoice/' + id);
      } else {
        this.props.history.push('/Terminal/?' + id);
      }
    }
  }

  tableReload(status) {
    this.setState({ reload: status })
  }

  getData(query) {
    let orderBy = (query.orderBy !== undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection !== "") ? query.orderDirection : "";
    if (this.state.reload) {
      query.search = "";
      this.setState({ reload: false })
    }
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      // let locationId = localStorage.getItem('locationArrIds');
      let token = user.token;
      let url = config.BASEURL;
      let locationId = '';
      if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
      }
      let param = {
        'locationIds': locationId,
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true
        , 'toDate': this.state.toDate, 'fromDate': this.state.fromDate, 'locationIds': locationId, 'orderBy': orderBy, 'orderDirection': orderDirection
      };
      DataGetApi(url + 'getInvoiceList', param, token).then(result => {
     
        let modifiedData = [];
        modifiedData = result.data.response.map(item => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
          firstName: item.customerId ? item.customerId.firstName : "",
          lastName: item.customerId ? item.customerId.lastName : "",
          paymentStatus: (item.STATUS === "PAID") ? 'PAID' : (item.STATUS === "UNPAID") ? 'UNPAID' : 'PENDING',
        }))
        if (typeof query.search === "undefined" || query.search === "") {
          resolve({
            data: modifiedData,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        } else {
          let filterData = modifiedData.filter((row => {
            return (row.location.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || row.paymentStatus.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 || (row.customerId != null && (row.customerId['firstName'].toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 || row.customerId['lastName'].toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1)) || row.invoice.indexOf(String(query.search)) !== -1 || String(row.amount).indexOf(query.search) !== -1);
          }))
          if (filterData.length > 0) {
            query.filters.push(filterData);
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          });
        }
      })
    })
  }

  calCRemainingAmount(attributes) {
    const { totalAmount, total, partialPayments } = attributes;
    //  let taxAmount = tax ? tax : 0
    //  let svcFeeAmount = svcFee ? svcFee : 0 
    let remainingAmount = totalAmount - (partialPayments.reduce((a, b) => a + b.amount, 0))
    return formatCurrency(totalAmount ? remainingAmount > 0 ? remainingAmount : 0 : total - total)
  }

  render() {
    const colorStatus = [
      { 'Pending': '#f5aa02' },
      { 'Paid': '#03af38' },
      { 'Sent': '#5bc0de' },
      { "Declined": "#e45e5e" }
    ]
    const columns = [
      {
        title: 'Invoice Number', field: 'invoice', customFilterAndSearch: (item, rowData) => item === rowData.invoice, render: rowData => <Tooltip title="View Details"><span
          className={classes.Underline} onClick={(e) => this.handleChangeId(rowData.invoice, rowData.isPaid, rowData._id)}  >{rowData.invoice}</span></Tooltip>
      },
      { title: 'First Name', field: 'firstName', render: rowData => (rowData.customerId && rowData.customerId.firstName) ? rowData.customerId.firstName : '' },
      { title: 'Last Name', field: 'lastName', render: rowData => (rowData.customerId && rowData.customerId.lastName) ? rowData.customerId.lastName : '' },
      // { title: 'Amount', field: 'amount', render: rowData => (String(rowData.amount).indexOf('.') === -1) ? '$' + String(rowData.amount) + '.00' : '$' + String(rowData.amount) },
      { title: 'Amount', field: 'amount', render: rowData => (rowData.amount) ? formatCurrency(rowData.amount) : '$0.00' },
      // { title: 'SVC Fee', field: 'svcFee', render: rowData => (rowData.svcFee) ?formatCurrency(rowData.svcFee): '$0.00' },
      // { title: 'Tax', field: 'tax', render: rowData => formatCurrency(rowData.tax)},
      { title: 'Total', field: 'total', render: rowData => rowData.totalAmount ? formatCurrency(rowData.totalAmount) : formatCurrency(rowData.total) },
      {
        title: 'Remaining Amount', field: 'total',
        render: rowData => this.calCRemainingAmount(rowData)
      },
      { title: 'location', field: 'location', render: rowData => (rowData.midType !== null && rowData.midType !== "" && rowData.hasOwnProperty('midType')) ? rowData.location + '(' + rowData.midType + ')' : rowData.location },
      // { title :'Public Notes', field: 'publicNotes'  },
      // { title :'Private Notes', field: 'privateNotes'  },
      // { title :'Term Notes', field: 'termNotes'  },
      { title: 'Email-Status', field: 'emailSent', lookup: { 'true': <button type="button" className="btn sentBtn" >Sent</button>, 'false': '' } },
      {
        title: 'Payment-Status', field: 'STATUS', lookup: {
          'PAID': <button type="button" className="btn paidBtn" >Paid</button>,
          'Paid': <button type="button" className="btn paidBtn" >Paid</button>,
          'UNPAID': <button type="button" className="btn PenddingBtn" >Unpaid</button>,
          'PENDING': <button type="button" className="btn PenddingBtn" >Pending</button>,
          'Pending': <button type="button" className="btn PenddingBtn" >Pending</button>,
          'Declined': <button type="button" className="btn DeclinedBtn" >Declined</button>,
          'Partial Paid': <button type="button" className="btn paidBtn" >Partial Paid</button>,
          'authOnly': <button type="button" className="btn paidBtn" >Auth Only</button>,
        }
      },
      { title: 'Date', field: 'dateOpened', render: (date) => (new Date(date.dateOpened).toLocaleDateString()) }]

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          {(!this.props.userInfo.permissions && this.props.userInfo.userId === "")}
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true,
                search: true,
              }}
              columns={columns}
              permissions={(this.props.userInfo.userId === "") ? this.state.userData.permissions : this.props.userInfo.permissions}
              data={query => this.getData(query)}
              title={'Invoice'}
              search={true}
              exports={(this.props.userInfo.userId === "") ? this.state.userData.permissions.exportRecord : this.props.userInfo.permissions.exportRecord}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={true}
            />
          </LoadingOverlay>
          <Colorstatus title="Invoice Color Status" filteredArray={colorStatus} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    userInfo: state.AdminUserReducer
  };
}
export default connect(mapStateToProps, { getInvoiceList })(withTranslation()(Invoice));