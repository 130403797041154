import { Button, Container, IconButton, makeStyles } from "@material-ui/core";

import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import { SuccessMessage } from "../../components/Message/SuccessMessage";
import { Topheader } from "../../components/Topheader";
import XLSX from "xlsx";
import { DataApi } from "../../helpers/PostDataApi";
import { config } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");
function UploadExcel(props) {
  const [state, setState] = useState({
    isLoading: false,
    misMatchError: [],
    successMessages: false,
    fileData: [],
  });
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        setState({ ...state, fileData: data });
        //this.props.setTable(data);
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmitButton = () => {
    setState({ ...state, successMessages: "" });
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user.token;
    DataApi(
      { fileData: state.fileData },
      config.BASEURL + "uploadExcel",
      token,
      true
    ).then((response) => {
      let responseMessages = response.data.messages;
      if (response.status === 200) {
        setState({ ...state, successMessages: responseMessages });
      } else {
        setState({ ...state, successMessages: responseMessages });
      }
    });
  };
  return (
    <div className="rightPanel">
      <Topheader />
      <div className="contentPart">
        <LoadingOverlay
          active={state.isLoading}
          spinner
          text="Loading your content..."
        >
          <Container component="main" maxWidth="xl">
            {state.misMatchError.length > 0 ? (
              <ErrorMessage errors={[state.misMatchError]} />
            ) : (
              ""
            )}
            {state.successMessages ? (
              <SuccessMessage successes={[state.successMessages]} />
            ) : (
              ""
            )}
            Upload Excel File :
            <input
              accept={SheetJSFT}
              className={props.classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                className="ml5"
                variant="contained"
                color="default"
                component="span"
              >
                Choose file
              </Button>
            </label>
            <Button
              onClick={handleSubmitButton}
              className="ml5"
              variant="contained"
              color="primary"
              component="span"
            >
              Upload
            </Button>
          </Container>
        </LoadingOverlay>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {};
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <UploadExcel classes={classes} {...props} />;
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(ApplyingStylesOnClasses));
