import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table'
import classes from './Customers.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config'
import { DataGetApi, DataApi } from '../../helpers/PostDataApi';
import {customerStatus} from '../../actions/CustomerAction'
import { Button } from '@material-ui/core';
import { doesFileExist } from '../../helpers/commonFunction'
import { ThreeSixtySharp } from '@material-ui/icons';
import { UserDetailById } from '../../actions/AdminUserAction'

class Customers extends Component {
  constructor(props) {
    const date = new Date();
    super(props)
    this.state = {
      exportRecord:false,
      isMounted: false,
      isLoading: false,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      user: JSON.parse(localStorage.getItem('user'))
    }
  }
  componentDidMount() {
    this.fetchDetailsData();
    this.setState({ isMounted: true })
  }
  componentWillUnmount() {
    this.setState({ isMounted: false })
  }
  handleChangeId(id) {
    //used for recurrence create
    this.props.history.push('/CustomerDetails/' + id);
    // this.props.history.push('/EditCustomers/' + id);
  }
  tableReload(status) {
    this.setState({ reload: status })
  }
  toDateFilter(date) {
    this.setState({ toDate: date })
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date })
  }
  fetchDetailsData() {
    
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ 'id': userData._id })
    .then((res) => {
      
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        // let data = res.payload.userData.data.company.customFields;
        let data = res.payload.userData.data.permissions.exportRecord;
        // let dataArr = data.filter((el) => {
        //   return el !== "";
        // })
        console.log("dataArr------->",res)
        this.setState({ 'exportRecord': data, isLoading: false });
      }
    })
  }

  getTableData(query) {
    let orderBy = (query.orderBy!==undefined) ? query.orderBy.field : "";
    let orderDirection = (query.orderDirection!=="") ? query.orderDirection : "";
    if(!this.state.isMounted){
      return false;
    }
    if (this.state.reload) {
      query.search = ""
      this.setState({ reload: false })
    }
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL;
      let locationId='';
      if (localStorage.getItem("locationArrIds") !== null) {
          locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
      }
      let param = {
        'role': user.role, 'userId': user._id, 'companyId': user.company._id,
        'per_page': query.pageSize, 'page': + (query.page + 1), 'searchAll': (typeof query.search === "undefined" || query.search === "") ? false : true,
        'toDate': this.state.toDate, 'fromDate': this.state.fromDate,'orderBy':orderBy,'orderDirection':orderDirection,'locationId':locationId
      };
      DataGetApi(url + 'customerList', param, token).then(result => {
        

        var aterickCheck = new RegExp("\\s*\\*\\s*");  
        //  result.data.response.filter(row=>{
        //   if(row.masked_card_number !== undefined && row.masked_card_number !== null && row.masked_card_number !== ""){
        //   // if(aterickCheck.test(row.masked_card_number) === false ){
        //    // row.masked_card_number= '************'+row.masked_card_number.substr(-4)
        //    // row.masked_card_number = '************' + row.masked_card_number.substr(-4) 
        //    // } 
        // }         
        // });
        // result.data.response = result.data.response.filter(row=>{
        //   if(!!row.masked_card_number){
        //     const data = ["************5439", "************0057", "************6909","************2376","************1094"];   
        //    if(!data.includes(row.masked_card_number) ){
        //      return row;
        //    }
        //    row.masked_card_number = (row.masked_card_number?row.masked_card_number.toString().replace(/^.{12}/g, '************'):'');
        //   //  row.masked_card_number = row.masked_card_number .substring(0, 6) + '******' + row.masked_card_number.substr(-4) 
        //   // } 
        // }         
        // })
       resolve({
          data: (typeof query.search === "undefined" || query.search === "") ? result.data.response :  result.data.response.filter((row => {
            
          return (
            (row.firstName !== "" && row.firstName !== null &&  row.firstName !== undefined && typeof row.firstName !== 'undefined' && String(row.firstName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
            (row.lastName !== "" && row.lastName !== null &&  row.lastName !== undefined && typeof row.lastName !== 'undefined' && String(row.lastName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
            (row.name !== "" && row.name !== null &&  row.name !== undefined && typeof row.name !== 'undefined' && String(row.name.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
            (row.email !== "" && row.email !== null &&  row.email !== undefined && typeof row.email !== 'undefined' && String(row.email.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1)||
            (row.lastName !== "" && row.lastName !== null &&  row.lastName !== undefined && typeof row.lastName !== 'undefined' && row.firstName !== "" && row.firstName !== null &&  row.firstName !== undefined && typeof row.firstName !== 'undefined' && String(row.firstName.toLowerCase()+' '+row.lastName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
            (row.customer_id !== "" && row.customer_id !== null &&  row.customer_id !== undefined && typeof row.customer_id !== 'undefined' && String(row.customer_id.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1)
  
          )
          })),
          page: result.data.pagination.page - 1,
          // totalCount: result.data.pagination.totalCount,
          totalCount: (typeof query.search === "undefined" || query.search === "") ? result.data.pagination.totalCount :  result.data.response.filter((row => {
            return (
              (row.firstName !== "" && row.firstName !== null &&  row.firstName !== undefined && typeof row.firstName !== 'undefined' && String(row.firstName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
              (row.lastName !== "" && row.lastName !== null &&  row.lastName !== undefined && typeof row.lastName !== 'undefined' && String(row.lastName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
              (row.name !== "" && row.name !== null &&  row.name !== undefined && typeof row.name !== 'undefined' && String(row.name.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1) ||
              (row.email !== "" && row.email !== null &&  row.email !== undefined && typeof row.email !== 'undefined' && String(row.email.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1)||
              (row.lastName !== "" && row.lastName !== null &&  row.lastName !== undefined && typeof row.lastName !== 'undefined' && row.firstName !== "" && row.firstName !== null &&  row.firstName !== undefined && typeof row.firstName !== 'undefined' && String(row.firstName.toLowerCase()+' '+row.lastName.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1)||
              (row.customer_id !== "" && row.customer_id !== null &&  row.customer_id !== undefined && typeof row.customer_id !== 'undefined' && String(row.customer_id.toLowerCase()).indexOf(String(query.search.toLowerCase())) !== -1)
              
            )
            })).length,
        })
      })
    })
  }
  getAllCustomer=(query)=>
  {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let token = user.token;
      let url = config.BASEURL;
      let locationId='';
      if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
      }
      let param = {
        'role': user.role, 
        'userId': user._id, 
        'companyId': user.company._id,
        'toDate': this.state.toDate, 
        'fromDate': this.state.fromDate,        
        'locationId':locationId
      };
      DataGetApi(url + 'customerListExport', param, {}).then(result => {
        console.log(" Line 129 Result===>",result.data);  
      });
  })
} 

  companyStatusToggle(e, data) {
    this.props.customerStatus(data).then((res) => {
     window.location.reload(false)
    })
  }

 updateCustomer = (e)=>{
  let user = JSON.parse(localStorage.getItem("user"));
    let companyId = user.company._id ? user.company._id : ""
    let token = user.token;
    let locationId = '';
    if (localStorage.getItem("locationArrIds") !== null) {
        locationId = (JSON.parse(localStorage.getItem("locationArrIds")).length > 1) ? null : JSON.parse(localStorage.getItem("locationArrIds"))[0];
    }
    let misMatchError = [];
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    const paytraceCredentials = { 'paytraceKey': locationPaydetails.paytraceKey, 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword }
    let file = doesFileExist(config.PAYTRACE_AWS_PATH + paytraceCredentials.paytraceKey)
      if (window.paytrace && file) {
        let res = window.paytrace.setKeyAjax(config.PAYTRACE_AWS_PATH + paytraceCredentials.paytraceKey);
      } else {
        let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
            misMatchError.push(msg);
            this.setState({ misMatchError });
      }
      let data  = {'companyId': companyId,'locationId':locationId}
      this.setState({ isLoading:true  })
      DataApi(data, config.BASEURL + "payTraceCustomerExport", {}, true, {} , paytraceCredentials)
      .then((res) => {
        if (typeof res.data !== "undefined" && res.data.success) {
          this.setState({ isLoading : false, reload : true })
        }   
      })
      .catch((error) => {
        console.log("error====>", error)
      });
 }
  render() {
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const columns = [
      { title: 'Name', field: 'firstName', render: rowData => <Tooltip title="View Details"><span className={classes.Underline} onClick={(e) => this.handleChangeId(rowData._id)}  >{rowData.firstName ? rowData.firstName + ' ' +rowData.lastName : rowData.name ? rowData.name : "N/A"}</span></Tooltip> },
      { title: 'Customer ID', field: 'customer_id',render: rowData => rowData.customer_id ? rowData.customer_id : 'N/A' },
      { title: 'Email', field: 'email', render: rowData => rowData.email ? rowData.email : 'N/A' },
      { title: 'Phone', field: 'phone', render: rowData => rowData.phone ? rowData.phone : 'N/A' },
      { title: 'Masked Card Number', field: 'masked_card_number', render: rowData => rowData.masked_card_number ? rowData.masked_card_number.toString().replace(/^.{12}/g, '************') : 'N/A' },
      { title: 'Date', field: 'createdDate', render: rowData => new Date(rowData.createdDate).toLocaleString() },];
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
        <button onClick={(query)=>this. getAllCustomer(query)}></button>
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
             
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true
              }}
              updateCustomer = {(e) => this.updateCustomer(e)}
              columns={columns}
              data={(query) => this.getTableData(query)} 
              companyActiveAction={(e, data) => this.companyStatusToggle(e, data)}
              title={'Customers'}
              search={true}
              permissions={userPermissions.permissions}
              tableRefresh = {this.state.reload}
              exports={this.state.exportRecord===true?true:false}
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={this.state.user.role === config.ADMIN_ROLE ? false :true}
              tablesName={'Customers'}
            />
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer
  };
}

export default connect(mapStateToProps, {customerStatus, UserDetailById})(withTranslation()(Customers));