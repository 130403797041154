import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid, TextField, Select, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

  amountLabel : {
    marginTop : "12px"
  }

}))

function AmountFilter(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    filterType: 1,
    amount: "",
    amountFromLabel: "Amount",
    amountToLabel: "Max Amount",
    amountMaxHide: false,
    isSubmit: false,
    misMatchError: [],
    error: [],
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handle filter type of amount
   * @param {event} e
   */
  const handleFilterTypeEvent = (e) => {
    let setstate = {
      ...state,
      ...{
        filterType: e.target.value,
        amountMaxHide: false,
        amountFromLabel: "Amount",
      },
    };
    if (e.target.value === 4) {
      setstate = {
        ...state,
        ...{
          filterType: e.target.value,
          amountMaxHide: true,
          amountFromLabel: "Min Amount",
        },
      };
    }
    setState(setstate);
  };

  /**
   * Handle ok button event
   */
  const handleOkButton = () => {
    props.handleOkButtonEvent(state);
  };

  const handleChange = (event) => {
    let { id, value } = event.target;
    setState({ ...state, ...{ [id]: value } });
  };
  return (
    <div className  ={props.classes.amountLabel} >
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Amount
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid container spacing={1}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={12}>
              <MenuItem>
                <TextField
                  id="amount"
                  label={state.amountFromLabel}
                  onChange={(e) => handleChange(e)}
                  value={state.amount}
                />
              </MenuItem>
              {state.amountMaxHide ? (
                <MenuItem>
                  <TextField
                    id="maxAmount"
                    label={state.amountToLabel}
                    onChange={(e) => handleChange(e)}
                    value={state.maxAmount}
                  />
                </MenuItem>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
              Filter type :
              <Select
                labelId="demo-simple-select-label"
                id="filterType"
                value={state.filterType}
                onChange={(e) => handleFilterTypeEvent(e)}
              >
                <MenuItem value={1}>Equal</MenuItem>
                <MenuItem value={2}>Less than equal</MenuItem>
                <MenuItem value={3}>Greater than equal </MenuItem>
                <MenuItem value={4}>Between</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
              <Button onClick={handleClose}> Close </Button>
              <Button onClick={(e) => handleOkButton(e)}> Ok </Button>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <AmountFilter classes={classes} {...props} />;
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(ApplyingStylesOnClasses));
