import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RenderMenuItem } from './../../components/RenderMenuItem';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Topheader } from './../../components/Topheader';
import { Textfield } from './../../components/Textfield';
import classes from './InvoiceEdit.module.scss';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import classNames from 'classnames'
import { connect } from 'react-redux';
import { InvoiceDetailById, setInputState, intakeInvoiceLink, updateFormData } from './../../actions/RequestFormAction';
import { config } from '../../constants'
import { History } from '../History'
import { DataApi, DataPutApi } from '../../helpers/PostDataApi'
import LoadingOverlay from 'react-loading-overlay';
import { Buttons } from './../../components/Buttons';

const initialState = { valid: false };



class InvoiceEdit extends Component {
    state = initialState;
    constructor(props) {
        super(props)
        this.state = {
            invoice: '',
            firstName: '', lastName: '', phoneNumber: '',
            year: '', make: '', model: '', color: '',
            serviceType: '', problemType: '',
            willAnyoneBeWithTheVehicle: '',
            doYouHaveKeysForVehicle: '',
            regularGasOrDiesel: '',
            neutral: '',
            doAllWheelsTurn: '', doesFrontWheelsTurn: '', doesBackWheelsTurn: '',
            pickUpLocation: '', pickUpNotes: '',
            startAddress: '', endAddress: '',
            email: '',
            amount: '', basePrice: '', notes: '',
            sendTo: 'phone',
            zipForCalc: '',
            system: '',
            resendTimeText: '',
            history: {},
            misMatchError: [],
            responseError: false,
            sideShow: true,
            isLoading: true,
            error: {
                passwordError: false,
                userNameError: false
            }
        }
    }
    UNSAFE_componentWillMount() {
        let user = JSON.parse(localStorage.getItem('user'))
        if (user.token) {
            const { match: { params } } = this.props;
            this.props.InvoiceDetailById({ 'id': params.id }).then((result) => {
                this.setState({ ...result.payload.value, isLoading: false })
            })
            //redirect to intake form for road assitence client
            if (user.company._id !== '5ee85adef500853860700888') {
                this.props.history.push('/AddInvoice')
            }


        } else {
            this.props.history.push('/Login')
        }

    }

    handleSubmitEmailInvoice(e, t) {
        e.preventDefault();
        let misMatchError = [];
        let logoError = [];
        let successMessages = false;
        let error = {};
        let errorFlag = false;
        // console.log('>>>',this.props.input)
        // return false;
        this.setState({ isSubmit: true, isLoading: true })

        this.props.intakeInvoiceLink(this.props.input).then(result => {
            window.scrollTo(0, 0, 0)
            if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
                this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                    setTimeout(() => {
                        this.setState({ successMessages: false })
                    }, 3000)
                });
            }
            if (result !== undefined && result.type === 'INVOICE_ERROR') {
                let message = result.payload.error.data.message
                misMatchError.push(message)
                this.setState({ misMatchError, isLoading: false, successMessages }, () => {

                    setTimeout(() => {
                        let misMatchError = []
                        this.setState({ misMatchError })
                    }, 3000)
                });

            }
        });
    }
    handleUpdate(e) {
        let misMatchError = [];
        let successMessages = false;
        let error = {};
        this.setState({ isSubmit: true })
        e.preventDefault()

        this.props.updateFormData(this.props.input).then((result) => {
            window.scrollTo(0, 0)
            if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
                this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                    setTimeout(() => {
                        this.setState({ successMessages: false })
                        this.setState({ redirectTransaction: true })
                    }, 2000)

                });
            }
            if (result !== undefined && result.type === 'INVOICE_ERROR') {
                if (result.payload.error.data && result.payload.error.data.statusCode === 403) {
                    let message = result.payload.error.data.message
                    misMatchError.push(message)
                    this.setState({ isLoading: false, misMatchError, successMessages });
                }

                this.setState({ isLoading: false, misMatchError, successMessages });
            }
        })
    }

    textHandler(event) {
        const { setInputState, input } = this.props;
        let stop = null;
        let misMatchError = []
        this.setState({ misMatchError })
        let value = event.target.value.toString();
        switch (event.target.id) {
            case 'phoneNumber':
                if (value.length > 10 || isNaN(value)) {
                    stop = true;
                } break;
            case 'zip':
                if (value.length > 5) {
                    stop = true;
                } break;
            default:
                stop = false;
        }

        if (!event.target.id) {
            setInputState('amount', event.target.value);
        }
        else {
            if (stop)
                setInputState(event.target.id, input[event.target.id]);
            else
                setInputState(event.target.id, event.target.value);
        }
    }

    render() {

        const {
            system,sendTo, resendPaymentLink, resendTimeText, firstTimeText, timestamp_PAID, dateOpened, make, color, startAddress, endAddress, firstName, lastName, phoneNumber, invoice, STATUS, email, problemType, model, pickUpLocation, pickUpNotes, year, amount, notes, serviceType
        } = this.props.input;
         
        const SERVICE_TYPE = ['Fuel/Fluids', 'Jump Start', 'Lockout', 'Towing', 'Tire Change'];
        const choices =
            ['YET_TO_PAY', 'VISITED', 'PAID', 'DISPATCHED'];
        const problem = [
            ``, `Won't Start`, `Belt Broken`, `Brakes`, `Engine Fire`, `Engine Problem`, `Fuel System Problem`,
            `Head / Brake Lights`, `Ignition Problems`, `Items Hanging`, `Key Stuck in Ignition`,
            `Multiple Tire / No Spare`, `Oil / Fuel Leak`, `Overheating`, `	Stuck in Park / Gear`,
            `Tire Pressure Low`, `Transmission Problem`, `Vandalism`, `Windshield (Cracked / Broken)`, `Other`
        ];
        const MAKE = [`AM General`, `AMC`, `Acura`, `Alfa Romeo`, `Aston Martin`, `Audi`, `BACKDRAFT`, `BMW`, `Bentley`, `Buick`,
            `Cadillac`, `Chevrolet`, `Chrysler`, `Daewoo`, `Daihatsu`, `Datsun`, `Dodge`, `EAGLE`, `EXCALIBUR`, `FIAT`,
            `Ferrari`, `Ford`, `GEO`, `GMC`, `GRUMMAN`, `Honda`, `Hummer`, `Hyundai`, `INTERNATIONAL`, `Infiniti`, `Isuzu`,
            `Jaguar`, `Jeep`, `Kia`, `Lamborghini`, `Land Rover`, `Lexus`, `Lincoln`, `Lotus`, `MARUTI`, `MG`, `Maserati`,
            `Maybach`, `Mazda`, `Mercedes Benz`, `Mercury`, `Merkur`, `Mini`, `Mitsubishi`, `Nissan`, `OTHER`, `Oldsmobile`,
            `Peugeot`, `Plymouth`, `Pontiac`, `Porsche`, `Rolls Royce`, `Saab`, `Saturn`, `Scion`, `Smart`, `Sterling`,
            `Subaru`, `Suzuki`, `Tesla`, `Toyota`, `Volkswagen`, `Volvo`, `WHEEGO`, `WILLYS`, `Yugo`]
        const COLOR = [`BEIGE`, `BLACK`, `BLUE`, `BROWN`, `BURGUNDY`, `CHAMPAGNE`, `GOLD`, `GRAY`, `GRAY-VIOLET`, `GREEN`,
            `LIGHT BLUE`, `LIGHT BROWN`, `LIGHT GRAY`, `LIGHT GREEN`, `MAROON`, `OLIVE-GREEN`, `ORANGE`, `PEARL-WHITE`,
            `PEWTER`, `PINK`, `PURPLE`, `RED`, `SAND`, `SILVER`, `TAN`, `TEAL`, `WHITE`, `YELLOW`]

        const { setInputState, t } = this.props;
        return (
            <div className="rightPanel">
                <Topheader />
                <Container component="main">
                    {this.state.misMatchError.map((e, id) => (
                        < ErrorMessage errors={[e]} key={id} />
                    ))}

                    {this.state.successMessages ?
                        <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''
                    }
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text='Loading your content...'>
                        {/* <Paper className={classes.pt_1}> */}
                        <form autoComplete={'none'}>
                            <SnackbarContent
                                className={(system==="SYSTEM 1")?classes.mySnackbarRed:classes.mySnackbarBlue}
                                message={
                                    'Submit this purchase order into DISPATCH '+system
                                }
                            />

                            <h2 className={classes.form_heading}> {'Invoice Overview'} </h2>
                            <Grid container alignItems="flex-start" style={{ width: '100%', textAlign: 'left' }}>
                                <Grid item container alignItems="flex-start" xs={12} sm={6}>
                                    <Grid item xs={12} sm={5} className="ml_2">
                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'invoice'} type={'number'} value={invoice} labels={t("Invoice")}
                                            onChange={(evt) => this.textHandler(evt)} disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Textfield className={classes.invoice_wth}
                                            id={'amount'} type={'text'} value={amount} labels={t("Amount")}
                                            onChange={(evt) => this.textHandler(evt)}
                                            disabled={(timestamp_PAID && STATUS === "PAID") ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="ml_2">
                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'notes'} type={'text'} value={notes} labels={t('Notes')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="ml_2">
                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'pickUpLocation'} type={'text'} value={pickUpLocation} labels={t('Pickup Location')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="ml_2">

                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'pickUpNotes'} type={'text'} value={pickUpNotes} labels={t('Pickup Notes')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={6}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                                            <InputLabel shrink htmlFor="age-label-placeholder">
                                                Status
                          </InputLabel>
                                            <Select
                                                value={STATUS ? STATUS : ''} name="STATUS" inputProps={{ id: 'STATUS-required' }}
                                                style={{ marginRight: '1rem' }}
                                                input={<Input name="age" id="age-label-placeholder" />}
                                                onChange={
                                                    event => {
                                                        setInputState(event.target.name, event.target.value);
                                                    }
                                                }
                                            >
                                                <MenuItem value=''> <em>{''}</em> </MenuItem>
                                                {RenderMenuItem(choices)}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])} >
                                            <InputLabel shrink htmlFor="age-label-placeholder">
                                                Service type
                          </InputLabel>
                                            <Select
                                                value={serviceType} name="serviceType" inputProps={{ id: 'serviceType-required' }}
                                                style={{ marginRight: '1rem' }}
                                                onChange={
                                                    event => {
                                                        setInputState(event.target.name, event.target.value);
                                                    }
                                                }
                                            >
                                                <MenuItem value=''> <em>{''}</em> </MenuItem>
                                                {RenderMenuItem(SERVICE_TYPE)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])} >
                                            <InputLabel shrink htmlFor="age-label-placeholder">
                                                Problem type
                          </InputLabel>
                                            <Select
                                                value={problemType} name="problemType" inputProps={{ id: 'problemType-required' }}
                                                style={{ marginRight: '1rem' }}
                                                onChange={
                                                    event => {
                                                        setInputState(event.target.name, event.target.value);
                                                    }
                                                }
                                            >
                                                <MenuItem value=''> <em>{''}</em> </MenuItem>
                                                {RenderMenuItem(problem)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Textfield className={classes.invoice_wth}
                                            id={'year'} type={'number'} value={year} labels={t('Year')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])} >
                                            <InputLabel shrink htmlFor="age-label-placeholder">
                                                make
                          </InputLabel>
                                            <Select
                                                value={make} name="make" inputProps={{ id: 'make-required' }}
                                                style={{ marginRight: '1rem' }}
                                                onChange={
                                                    event => {
                                                        setInputState(event.target.name, event.target.value);
                                                    }
                                                }
                                            >
                                                <MenuItem value=''> <em>{''}</em> </MenuItem>
                                                {RenderMenuItem(MAKE)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Textfield className={classes.invoice_wth}
                                            id={'model'} type={'text'} value={model} labels={t('Model')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])} >
                                            <InputLabel shrink htmlFor="age-label-placeholder">
                                                color
                          </InputLabel>
                                            <Select
                                                value={color} name="color" inputProps={{ id: 'color-required' }}
                                                style={{ marginRight: '1rem' }}
                                                onChange={
                                                    event => {
                                                        setInputState(event.target.name, event.target.value);
                                                    }
                                                }
                                            >
                                                <MenuItem value=''> <em>{''}</em> </MenuItem>
                                                {RenderMenuItem(COLOR)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="standard-full-width"
                                            label="Date opened"
                                            style={{ marginTop: '2rem' }}
                                            value={dateOpened || ''}
                                            // helperText="Full width!"
                                            fullWidth
                                            // margin="normal" 
                                            disabled
                                        />
                                        <TextField
                                            label="Date Of Payment"
                                            id="standard-full-width"
                                            value={timestamp_PAID || ''}
                                            placeholder='Time of Payment'
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            id="standard-full-width"
                                            value={firstTimeText || ''}
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            id="standard-full-width"
                                            value={resendTimeText || ''}
                                            fullWidth
                                            disabled
                                        />

                                    </Grid>
                                </Grid>

                            </Grid>

                            <h2 className={classes.form_heading}> {'Contact Information'} </h2>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item container alignItems="flex-start" xs={12} sm={6}>
                                    <Grid item xs={12} sm={6} className="ml_2">
                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'firstName'} type={'text'} value={firstName} labels={t('First Name')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Textfield className={classes.invoice_wth}
                                            id={'lastName'} type={'text'} value={lastName} labels={t('Last Name')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="ml_2">
                                        <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                            id={'phoneNumber'} type={'number'} value={phoneNumber} labels={t('Phone Number')}
                                            onChange={(evt) => this.textHandler(evt)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6} className="ml_2">
                                    <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                        id={'email'} type={'email'} value={email} labels={t('Email')}
                                        onChange={(evt) => this.textHandler(evt)}
                                    />
                                    <br /> <br />
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item xs={12} sm={6} className={classNames([classes.text_left, classes.pl_1])}>
                                            <Button variant="contained" color="primary" onClick={(e) => this.handleSubmitEmailInvoice(e)} >
                                                Resend Pay Link
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <RadioGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                aria-label="Options" name="gender2" value={this.state.resendPaymentLink}
                                                onChange={(event) => setInputState('resendPaymentLink', event.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="phone" control={<Radio color="primary" />} label="Phone" labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="email" control={<Radio color="primary" />} label="Email" labelPlacement="start"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <br />

                            <h2 className={classes.form_heading}> {'Location Info.'} </h2>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item container alignItems="flex-start" xs={12} sm={6} className="ml_2">
                                    <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                        id={'startAddress'} type={'text'} value={startAddress} labels={t('Origin')}
                                        onChange={(evt) => this.textHandler(evt)}
                                        disabled={true} />
                                    <Textfield className={classNames([classes.invoice_wth, classes.pl_1])}
                                        id={'endAddress'} type={'text'} value={endAddress} labels={t('Destination')}
                                        onChange={(evt) => this.textHandler(evt)}
                                        disabled={true} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <br /> <br />
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item xs={12} sm={6} className={classNames([classes.text_left, classes.pl_1])}>
                                            <Button variant="contained" color="primary" onClick={(e) => this.handleSubmitEmailInvoice(e)}>
                                                Resend Receipt
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <RadioGroup
                                                aria-label="Options" name="resendReceipt" value={this.state.resendReceipt}
                                                onChange={(event) => setInputState('resendReceipt', event.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                            >
                                                <FormControlLabel
                                                    value="phone" control={<Radio color="primary" />} label="Phone" labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="email" control={<Radio color="primary" />} label="Email" labelPlacement="start"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    {(this.state.status === 'PAID') ?
                                        (
                                            <div>
                                                <br />
                                                <center>
                                                    <strong style={{ color: 'red' }}> Make sure you dispatch as the invoice has been paid </strong>
                                                </center>
                                            </div>
                                        ) : ''}

                                </Grid>


                            </Grid>
                            <Grid item xs={12} sm={3} className={classNames([classes.text_left, classes.pl_1, classes.mt_30, classes.pb_1])}>
                                <Buttons
                                    className={'emailInvoiceTerminal'}
                                    variant="contained"
                                    color="primary"
                                    text={t("Update")}
                                    onClick={(e) => this.handleUpdate(e)} />
                                <Buttons
                                    className={'emailInvoiceTerminal'}
                                    variant="contained"
                                    color="secondary"
                                    text={t("Back")}
                                    onClick={this.props.history.goBack} />
                            </Grid>

                        </form>
                        {/* </Paper> */}
                    </LoadingOverlay>
                </Container>
            </div>
        )

    }
}

InvoiceEdit.propTypes = {
    t: PropTypes.func
}

// const mapStateToProps = state => { return state; };

function mapStateToProps(state) {
    return {
        input: state.InputReducer,
        info: state.InvoiceReducer,
        UserDetail: state.AdminUserReducer,
    }
}

export default connect(mapStateToProps, { updateFormData, InvoiceDetailById, intakeInvoiceLink, setInputState, })(withTranslation()(InvoiceEdit));