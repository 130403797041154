import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { CustomerDetailsId } from '../../actions/CustomerAction'
import { getCsrfToken, createRecurrence } from '../../actions/PaymentAction'
import InputAdornment from '@material-ui/core/InputAdornment';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Customers.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
// // import config from './../../constants/config'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Checkbox } from '@material-ui/core';
import TypableSelect from './../../helpers/TypableSelect';
import validator from '../../helpers/checkValidations';
import FormControl from '@material-ui/core/FormControl';


const mapToFormat = (values) => {
  return values.map(Obj => (
    { value: Obj.value, label: Obj.lable }
  ))
};

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};

const frequency = [
{ 'value': '1', 'lable': 'Annually' },
{ 'value': '8', 'lable': 'Semi-Annually' },
{ 'value': 'A', 'lable': 'Trimesterly' },
{ 'value': '2', 'lable': 'Quarterly' },
{ 'value': '9', 'lable': 'Bi-Monthly' },
{ 'value': '3', 'lable': 'Monthly' },
{ 'value': '4', 'lable': 'Bi-Weekly' },
{ 'value': '7', 'lable': '1st and 15th' },
{ 'value': '5', 'lable': 'Weekly' },
{ 'value': '6', 'lable': 'Daily' }]

class CustomerDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      firstName: "",
      name: "",
      lastName: "",
      createdBy: '',
      companyId: '',
      email: "",
      phone: "",
      customFields: [],
      paytraceCredentials:{},
      amount: 0,
      frequency: '',
      customer_id: "",
      masked_card_number: "",
      customerCompanyName:"",
      customerAccountNumber: '',
      zip: '',
      StartDate: new Date(),
      misMatchError: [],
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      error: {
        customer_idError: false,
        amountError: false,
        phoneError: false,
        customer_idError: false,
        amountError: false,
        phoneError: false,
      },
    }
  }

  componentDidMount() {
    this.getCustomerDetails();
    this.getCsrfTokens();
    let userData = JSON.parse(localStorage.getItem('user'))
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'))
    if(locationPaydetails){
      setTimeout(()=>{
        const paytraceCredentials = { 'userName': locationPaydetails.paytraceUserName, 'password': locationPaydetails.paytracePassword, 'integratorId': locationPaydetails.paytraceIntegratorId }
      this.setState({'paytraceCredentials':paytraceCredentials})
      },3000)
    }else{
      alert('Select payment location.')
    }
    
    this.setState({ createdBy: userData._id, companyId: userData.company._id })
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } })
    })
  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'amount' && value === '0') {
      alert('Amount should be greater than zero.')
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
  }
  getCustomerDetails() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })

    this.props.CustomerDetailsId(params).then((res) => {
      let transaction = res.payload.customerData.data;
      if (transaction.success) {
        this.setState({ ...transaction.response, isLoading: false })
      }
    });
  }
  handleSubmitRecurring(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let state = this.state
    let error = {}
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true })
    if(this.state.lastName ===""){
      delete this.state.lastName;
    }
    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to start recurring?')) {
        this.setState({ isLoading: true });
        this.props.createRecurrence(this.state, this.state.csrf,this.state.paytraceCredentials).then(result => {
          if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
            window.scrollTo(0, 0);
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false })
                this.setState({ redirect: true })
              }, 2000)
            });
          }
          if (result !== undefined && result.type === 'PAYMENT_ERROR') {
            window.scrollTo(0, 0)
            if (result.payload.error.data && (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)) {
              let message = result.payload.error.data.message
              misMatchError.push(message)
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n')
                }
                this.setState({ isLoading: false, misMatchError, successMessages });
              } else {
                misMatchError.push(responseErrors.approval_message)
              }
            }
            this.setState({ isSubmit: false, isLoading: false, misMatchError, successMessages });
          }
        });

      }
    }
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date })
  }
  setClientValue(val) {
    let misMatchError = [];
    if (val !== null) {
      this.setState({ frequency: val.value, misMatchError })
    } else {
      this.setState({ frequency: '', misMatchError })
    }
  }
  handleChangeCheckbox(event) {

    this.setState({ [event.target.id]: event.target.checked });
  }

  handleAdd() {
    this.setState({ isOpen: true })
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields];

    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  }
  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  render() {
    let userPermissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const { t } = this.props;
    const { redirect } = this.state;
    return (
      <div className="rightPanel">
        {(redirect) ? <Redirect to='/Recurring' /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Loading your content...'>
            <Container component="main" maxWidth="xl" >
              {this.state.misMatchError.length > 0 ?
                < ErrorMessage errors={[this.state.misMatchError]} /> : ''
              }
              {this.state.successMessages ?
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} /> : ''
              }

              <form className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf["csrf-token"]} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf["csrf-secret"]} />
                {(this.state.error.customer_idError) ? <h3 className="errorMsg">Unable to process recurring as user card details are empty."</h3> : ''}
                {(!userPermissions.permissions.recurring) ? <h3 className="errorMsg">Kindly contact to super admin for recurring access.</h3> : ''}
                {(this.state.status_code === 'P') ? <h3 className="errorMsg">This transaction not settle yet! please try after {24 - Math.round(((new Date() - new Date(this.state.DATETIME)) / 3600000))} hours.</h3> : ''}
                {(this.state.status_code === 'V') ? 'This transaction has been voided on ' + (this.state.UPDATE_VOID_DATE ? new Date(this.state.UPDATE_VOID_DATE).toLocaleString() : new Date().toLocaleString()) : ''}
                {this.state.REFUND ? <h3 className={classes.successMsg}>This transaction has been refunded on {new Date(this.state.REFUND_DATE).toString()} </h3> : ''}
                <Grid container spacing={3} className="ViewTxnFormBox" >
                <Grid item xs={12} sm={6}>
                    <Textfield
                      id="name"
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("Name")}
                      value={(this.state.name) ? this.state.name : ' '}
                      disabled={true}
                      width={true} />
                  </Grid>

                  {/* <Grid item xs={12} sm={4}>
                    <Textfield
                      id="firstName"
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("FirstName")}
                      value={(this.state.firstName )?this.state.firstName : ' '}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="lastName"
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t("LastName")}
                      value={(this.state.lastName )?this.state.lastName : ' '}
                      disabled={true}
                      width={true} />
                  </Grid>
                  <Grid container item sm={4} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.customerCompanyNameError}
                      id="customerCompanyName"
                      type="text"
                      labels={t("Company Name")}
                      value={this.state.customerCompanyName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      disabled={true}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="email"
                      type="email"
                      labels={t("Email")}
                      value={this.state.email}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                    error={this.state.error.phoneError}
                      id="phone"
                      type="text"
                      labels={t("Phone")}
                      value={this.state.phone}
                      // disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item sm={4} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.customerAccountNumberError}
                      id="customerAccountNumber"
                      type="text"
                      labels={t("CustomerId")}
                      value={this.state.customer_id}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item sm={4} xs={12}  >
                    <Textfield
                      required={true}
                      error={this.state.error.zipError}
                      id="zip"
                      type="text"
                      labels={t("Zip Code")}
                      value={this.state.zip}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item sm={4} xs={12}  >
                    <Textfield
                      id="customerCity"
                      type="text"
                      labels={t("City")}
                      value={this.state.customerCity}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      disabled={true}
                      />
                    </Grid>
                    <Grid container item sm={4} xs={12}  >
                      <Textfield
                        id="customerAddress"
                        type="text"
                        placeholder="Address"
                        multiline={true}
                        rows={4}
                        value={this.state.customerAddress}
                        width={true}
                        variant="outlined"
                        onChange={(e) => this.handleChange(e, t)}
                        disabled={true}
                      />
                    </Grid>
                  {/* <Grid item xs={12} sm={12}> <label><b>Recurring Billing Schedule</b></label></Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required={true}
                      id="amount"
                      type="text"
                      labels={t("Amount")}
                      value={(this.state.amount > 0) ? this.state.amount : ''}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true} />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '2%' }}>
                    <FormControl className="frequencyInput" fullWidth style={getValidationStyle(this.state.frequency === '' && this.state.isSubmit)} >
                      <TypableSelect
                        placeholder={'Frequency type'} suggestions={mapToFormat(frequency)}
                        onChange={val => this.setClientValue(val)}
                        isClearable={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline1"
                        label={t('StartDate')}
                        value={this.state.StartDate}
                        onChange={(date) => { this.handleDateChange(date, 'StartDate') }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t("Card Number")}
                      value={(this.state.MASKCARDNUMBER) ? this.state.MASKCARDNUMBER : this.state.masked_card_number}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid> */}
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6}>
                        <div key={`${field}-${idx}`}>

                          <Textfield
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ""}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}                             
                            disabled={true}
                          />


                        </div>
                      </Grid>
                    );
                  })}
                  {/* <Grid item xs={12} sm={8} style={{ marginTop: '3.25%' }}>
                    <Checkbox
                      id="customer_receipt"
                      labels="Create New User"
                      onChange={(e) => { this.handleChangeCheckbox(e) }}
                      value={this.state.customer_receipt}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }} />
                    <label>Send an email receipt</label>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    {/* {userPermissions.permissions.recurring ? <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="primary"
                      text={t("Recurring")}
                      onClick={(e) => this.handleSubmitRecurring(e, t)} /> : ''} */}
                    <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t("Back")}
                      onClick={this.props.history.goBack} />

                  </Grid>
                </Grid>

              </form>
            </Container>

          </LoadingOverlay>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { getCsrfToken, createRecurrence, CustomerDetailsId })(withTranslation()(CustomerDetails));