import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserEdit } from '../../actions/AdminUserAction'
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';

class AddManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      role: 2,
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      creatNewUser: false,
      allFeatures: false,
      refunds: false,
      refundsManual:false,
      allLocaton: false,
      preAuthorize : false,
      creditSale : false,
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
    }
  }
  componentDidMount() {
    this.getUserData()
  }
  getUserData() {
    const { match: { params } } = this.props;
    this.setState({ isLoading: true })
    this.props.UserDetailById(params).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false })
    });
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData()
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });

  }
  handleChange(event, t) {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;


    Object.keys(state).map((key, value) => {
      if ((validator(key, state[key]))) {
        return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
      } else {
        return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {

      this.props.UserEdit(this.state).then(result => {
        if (result !== undefined && result.type === 'USER_EDIT_DATA') {
          this.setState({ successMessages: true });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.editInfo.data.message, successMessages });
        }
      });

    }

  }

  render() {
    const { t } = this.props;

    return (

      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='Loading your content...'>
          <Container component="main" maxWidth="xl" >
            {this.state.misMatchError.length > 0 ?
              < ErrorMessage errors={[this.state.misMatchError]} /> : ''
            }
            {this.state.successMessages ?
              <SuccessMessage successes={[this.props.editInfo.data.message]} /> : ''
            }
            <form className={classes.form} noValidate>
              <Grid container spacing={3} >
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.userNameError}
                    id="userName"
                    type="text"
                    labels={t("User Name")}
                    value={this.state.userName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.firstNameError}
                    id="firstName"
                    type="text"
                    labels={t("First Name")}
                    value={this.state.firstName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                </Grid>
                <Grid item xs={6} sm={3}>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.lastNameError}
                    id="lastName"
                    type="text"
                    labels={t("Last Name")}
                    value={this.state.lastName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.emailError}
                    id="email"
                    type="email"
                    labels={t("Email")}
                    value={this.state.email}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid xs={12} >
                  <Grid container item xs={12}>
                    {(this.state.role < 4) ? <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="creatNewUser"
                          labels="Create New User"
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.creatNewUser}
                          checked={this.state.creatNewUser}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Create New User</span>
                      </label>
                    </Grid> : ''}
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refunds"
                          checked={this.state.refunds}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.refunds}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Refunds General</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refundsManual"
                          checked={this.state.refundsManual}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.refundsManual}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Refunds Manual</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="allLocaton"
                          checked={this.state.allLocaton}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.allLocaton}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>All Location</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="createInvoice"
                          checked={this.state.createInvoice}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.createInvoice}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Invoicing</span>

                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="ach"
                          checked={this.state.ach}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.ach}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>ACH </span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="virtualTerminal"
                          checked={this.state.virtualTerminal}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.virtualTerminal}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Virtual Terminal</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="recurring"
                          checked={this.state.recurring}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.recurring}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Recurring billing</span>

                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="svcFeeEdit"
                          checked={this.state.svcFeeEdit}
                          onChange={(e) => { this.handleChangeCheckbox(e) }}
                          value={this.state.svcFeeEdit}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Svc Fee Edit</span>

                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="preAuthorize"
                          checked={this.state.preAuthorize}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.preAuthorize}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                        <span>Pre-Authorization</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="creditSale"
                          checked={this.state.creditSale}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.creditSale}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                        <span>Credit Sale</span>
                      </label>
                    </Grid>

                    {(this.state.role > 4) ? <Grid item xs={12} sm={6}>
                      <Checkbox
                        id="creatNewUser"
                        checked={this.state.creatNewUser}
                        onChange={(e) => { this.handleChangeCheckbox(e) }}
                        value={this.state.creatNewUser}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <label>
                        <span>Create New User</span>
                      </label>
                    </Grid> : ''}
                  </Grid>
                </Grid>

                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Save")} />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={this.props.history.goBack} />
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>

    )
  }
}
AddManager.propTypes = {
  t: PropTypes.func
}

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    editInfo: state.AdminUserReducer.editInfo
  };
}

export default connect(mapStateToProps, { UserDetailById, UserEdit })(withTranslation()(AddManager));
