import fetch from 'isomorphic-unfetch';
import { config } from '../constants'; 

const convertDistance = (dist) => {
	dist = dist.toString();
	if (dist.indexOf('ft') >= 0) {
		if (dist.indexOf(',') >= 0) { return parseFloat(dist.replace(",", "")) * 0.000189394; } 
		else { return parseFloat(dist) * 0.000189394; }
	} else {
		if (dist.indexOf(',') >= 0) { return parseFloat(dist.replace(",", "")); } 
		else { return parseFloat(dist);	}
	}
};

export const getCost = (distance, zipCode, serviceType, pickUpLocation, newPrice) => (
	(dispatch) => {
		fetch(config.BASEURL+`ratePrice?distance=${convertDistance(distance)}&zipCode=${zipCode}&serviceType=${serviceType}&pickUpLocation=${pickUpLocation}&newPrice=${newPrice}`, { method: 'GET' })
			.then((response) => response.json())
			.then((myJson) => {
				dispatch({
					type: 'SET_STATE', 
					payload: { property: 'amount', value: myJson.finalPrice }
				});

				dispatch({
					type: 'SET_STATE', 
					payload: { property: 'basePrice', value: myJson.initialPrice }
				});
			})
			.catch((err) => { 
			//	error(dispatch, err);
				});
	}
);
